import React from 'react';
import { Row, Form } from 'react-bootstrap';

export const SessionScores = React.forwardRef((props, ref) => {
  const emptyList = () => {
    return (
      <Row className="vCenter emptyList">
        <span>No scores posted.</span>
      </Row>
    );
  };

  const setupBody = () => {
    if (true) {
      // no such thing yet
      return emptyList();
    } else {
      return null;
    }
  };

  return (
    <Form onSubmit={null} className="sessionScores">
      {setupBody()}
    </Form>
  );
});
