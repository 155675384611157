import React, {
  useState,
  useCallback,
  useImperativeHandle,
  useRef,
} from 'react';
import {
  Button,
  Row,
  Col,
  Form,
  Popover,
  OverlayTrigger,
} from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { adminActions, alertActions } from '../../redux/_actions';
import {
  genderType,
  leagueType,
  imgType,
  FILE_SIZE,
} from '../../redux/_constants';
import * as Yup from 'yup';
import { SketchPicker } from 'react-color';
import { useDropzone } from 'react-dropzone';
import { Typeahead } from 'react-bootstrap-typeahead';
import { MEDIAURL } from '../../utilities/constants';
import { espnTricodes } from '../../utilities/tricodes';

export const TeamProfile = React.forwardRef((props, ref) => {
  const { teamId, isEditing, isSaving, reset, setIsLoading } = props;
  const { teams } = useSelector((state) => state.admin);
  const team = teams.byId[teamId];
  const [colors, setColors] = useState(
    team.colors
      ? JSON.parse(team.colors)
      : ['#00A2FF', '#5E5E5E', '#FFFFFF', '#000000']
  );
  const [logo, setLogo] = useState(
    team.logos ? JSON.parse(team.logos).metaData.filename : null
  );
  const dispatch = useDispatch();
  const { alert } = useSelector((state) => state);
  const hiddenFileInput = React.useRef(null);
  const [changedLogo, setChangedLogo] = useState(false);
  let typeaheadRef = useRef();

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Required'),
    slug: Yup.string().required('Required'),
    colors: Yup.array().of(Yup.string()),
    altNames: Yup.string().nullable(),
    triCode: Yup.string().nullable(),
    rtnId: Yup.string().nullable(),
    logos: Yup.mixed()
      .nullable()
      .notRequired()
      .test(
        'FILE_SIZE',
        'Uploaded file is too big.',
        (value) => !changedLogo || !value || (value && value.size <= FILE_SIZE)
      )
      .test(
        'FILE_FORMAT',
        'Uploaded file has unsupported format.',
        (value) =>
          !changedLogo || !value || (value && imgType.includes(value.type))
      ),
  });

  const {
    setFieldValue,
    handleSubmit,
    handleChange,
    values,
    errors,
  } = useFormik({
    initialValues: {
      name: team.name,
      slug: team.slug,
      colors: JSON.parse(team.colors),
      altNames: team.altNames ? JSON.parse(team.altNames) : [],
      logos: team.logos ? JSON.parse(team.logos) : '',
      id: team.id,
      triCode: team.triCode,
      rtnId: team.rtnId,
      _version: team._version,
    },
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit(values) {
      // change out empty array
      let newAltNames =
        values.altNames.length === 0 ? null : JSON.stringify(values.altNames);

      // Check if no change
      if (
        values.name === team.name &&
        values.slug === team.slug &&
        JSON.stringify(values.colors) === team.colors &&
        newAltNames === team.altNames &&
        values.triCode === team.triCode &&
        values.rtnId === team.rtnId &&
        !changedLogo
      ) {
        dispatch(alertActions.success('No changes made.'));
        reset();
        return;
      }

      setIsLoading(true);
      dispatch(alertActions.clear());
      dispatch(adminActions.editTeam(values, changedLogo));
      setChangedLogo(false);
    },
  });

  //console.log(errors)
  //console.log(values);

  useImperativeHandle(ref, () => ({
    submit() {
      handleSubmit();
    },
  }));

  const checkBase64 = (string) => {
    return string.substr(0, 5) === 'data:';
  };

  const colorButton = (index) => {
    return (
      colors && (
        <OverlayTrigger
          placement="bottom"
          //trigger={["click", "focus"]}
          trigger="click"
          rootClose
          overlay={
            <Popover id={`popover-colorpicker-${index}`}>
              <SketchPicker
                disableAlpha
                presetColors={[]}
                color={colors[index]}
                onChange={(value) => {
                  setFieldValue(`colors[${index}]`, value.hex);
                  colors[index] = value.hex;
                  setColors(colors);
                }}
              />
            </Popover>
          }
        >
          <Button
            className="colorSelect"
            disabled={isSaving || !isEditing}
            style={{
              marginLeft: index === 0 ? '0' : null,
              background: colors[index],
            }}
          ></Button>
        </OverlayTrigger>
      )
    );
  };

  const onDrop = useCallback(
    (acceptedFiles) => {
      const handleLogoChange = (files) => {
        const fileUploaded = files;
        if (!fileUploaded) {
          return false;
        }

        if (!alert.clear) {
          dispatch(alertActions.clear());
        }

        let reader = new FileReader();
        reader.onload = (e) => {
          const img = new Image();
          img.onload = () => {
            if (fileUploaded.size > FILE_SIZE) {
              dispatch(alertActions.error('Image exceeds max 5MB.'));
              return false;
            }
            setChangedLogo(true);
            setLogo(reader.result);
          };
          img.onerror = () => {
            dispatch(alertActions.error('Could not load image.'));
            return false;
          };
          img.src = e.target.result;
        };

        setFieldValue(`logos`, fileUploaded);
        reader.readAsDataURL(fileUploaded);
      };

      handleLogoChange(acceptedFiles);
    },
    [alert.clear, dispatch, setFieldValue]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const logoButton = () => {
    const handleClick = (event) => {
      hiddenFileInput.current.click();
    };

    return (
      <>
        <label>Logo</label>
        <Button
          variant="outline-light"
          disabled={isSaving || !isEditing}
          className="logoSelect"
          onClick={handleClick}
          style={{ flexDirection: logo ? 'column' : null }}
        >
          {logo ? (
            <img
              className="img-thumbnail"
              alt={`${team.name} Logo`}
              src={checkBase64(logo) ? logo : `${MEDIAURL}${logo}`}
            />
          ) : isDragActive ? (
            '+'
          ) : (
            '?'
          )}
        </Button>
        <input
          type="file"
          {...getInputProps()}
          ref={hiddenFileInput}
          onChange={(e) => onDrop(e.target.files[0])}
          style={{ display: 'none' }}
          accept="image/png,image/gif,image/jpeg"
        />
      </>
    );
  };

  const onKeyDown = useCallback((e) => {
    if (e.key === 'Enter') {
      setFieldValue('altNames', [...values.altNames, e.target.value]);
      typeaheadRef.clear();
    }
  });

  const setupBody = () => {
    return (
      <>
        <Row>
          <div className="setupFormLine">
            <Col {...getRootProps()}>{logoButton()}</Col>
            <Col sm={8}>
              <Row>
                <label>Colors</label>
                {colorButton(0)}
                {colorButton(1)}
                {colorButton(2)}
                {colorButton(3)}
              </Row>
              <Row>
                <div className="setupFormLine">
                  <Col style={{ padding: '0 2% 0 0' }}>
                    <label>
                      Gender
                      {errors.gender ? (
                        <span className="form-error">{errors.gender}</span>
                      ) : null}
                    </label>
                    <Form.Control
                      plaintext
                      as="select"
                      label="Required"
                      id="gender"
                      defaultValue={team.gender}
                      disabled={true}
                    >
                      <option value="Select..." disabled hidden>
                        Select...
                      </option>
                      {Object.values(genderType).map((type, i) => {
                        return <option key={i}>{type}</option>;
                      })}
                    </Form.Control>
                  </Col>
                  <Col style={{ padding: '0 0 0 2%' }}>
                    <label>
                      Type
                      {errors.type ? (
                        <span className="form-error">{errors.type}</span>
                      ) : null}
                    </label>
                    <Form.Control
                      plaintext
                      as="select"
                      label="Required"
                      id="type"
                      defaultValue={team.type}
                      disabled={true}
                    >
                      <option value="Select..." disabled hidden>
                        Select...
                      </option>
                      {Object.values(leagueType).map((type, i) => {
                        return <option key={i}>{type}</option>;
                      })}
                    </Form.Control>
                  </Col>
                </div>
              </Row>
              <Row>
                <div className="setupFormLine">
                  <Col style={{ padding: '0 2% 0 0' }}>
                    <label>
                      RTN ID
                      {errors.rtnId ? (
                        <span className="form-error">{errors.rtnId}</span>
                      ) : null}
                    </label>
                    <Form.Control
                      plaintext
                      name="rtnId"
                      type="text"
                      autoComplete="off"
                      value={values.rtnId || ''}
                      onChange={handleChange}
                      placeholder="Optional"
                      disabled={isSaving || !isEditing}
                    />
                  </Col>
                  <Col style={{ padding: '0 0 0 2%' }}>
                    <label>
                      Tricode
                      {errors.type ? (
                        <span className="form-error">{errors.triCode}</span>
                      ) : null}
                    </label>
                    <Typeahead
                      onChange={(selected) => {
                        const topTriCode = selected[0];
                        if (topTriCode) {
                          setFieldValue('triCode', topTriCode.triCode);
                        }
                      }}
                      options={[].concat(
                        Object.keys(espnTricodes).map((k) => {
                          return { team: espnTricodes[k], triCode: k };
                        })
                      )}
                      id="triCode"
                      labelKey="triCode"
                      className="typeahead"
                      selected={values.triCode ? [values.triCode] : []}
                      clearButton
                      filterBy={['triCode', 'team']}
                      disabled={isSaving || !isEditing}
                      placeholder="Optional"
                      renderMenuItemChildren={(option) => (
                        <span>
                          {option?.triCode}: {option?.team}
                        </span>
                      )}
                    />
                  </Col>
                </div>
              </Row>
            </Col>
          </div>
        </Row>
        <Row>
          <Col>
            <label>
              Name
              {errors.name ? (
                <span className="form-error">{errors.name}</span>
              ) : null}
            </label>
            <Form.Control
              plaintext
              name="name"
              type="text"
              autoComplete="off"
              value={values.name}
              onChange={handleChange}
              disabled={isSaving || !isEditing}
              placeholder="Enter team name"
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <label>Slug</label>
            <Form.Control
              plaintext
              name="slug"
              type="text"
              autoComplete="off"
              value={values.slug}
              onChange={handleChange}
              disabled={isSaving || !isEditing}
              placeholder="Autogenerated"
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <label>Other Names (Use #hashtag for display name)</label>
            <Typeahead
              onChange={(selected) => {
                setFieldValue('altNames', selected);
              }}
              allowNew
              options={[]}
              id="teamAltNames"
              className="typeahead"
              multiple
              disabled={isSaving || !isEditing}
              placeholder="Optional"
              selected={values.altNames ? values.altNames : []}
              renderMenu={(results, menuProps) => null}
              onKeyDown={onKeyDown}
              ref={(a) => (typeaheadRef = a)}
            />
          </Col>
        </Row>
      </>
    );
  };

  return (
    <Form onSubmit={handleSubmit} className="teamProfile">
      {setupBody()}
    </Form>
  );
});
