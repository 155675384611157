import { useState, useEffect, forwardRef } from 'react';
import { Button, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { streamStatus } from '../../redux/_constants';
import { copyToClipboard } from '../../utilities/export';
import QRCode from 'qrcode.react';

const defaultContent = 'Copy Camera Settings';
const clickedContent = 'Copied';

const ToolTip = forwardRef(({ popper, children, show: _, ...props }, ref) => {
  useEffect(() => {
    popper.scheduleUpdate();
  }, [children, popper]);

  return (
    <Tooltip ref={ref} {...props} disabled>
      {children}
    </Tooltip>
  );
});

function VideoQR(props) {
  const { channel, link, status, logo, sessionKey } = props;
  const defaultLink = `https://virti.us/session?s=${sessionKey}`;
  const [content, setContent] = useState(defaultContent);
  let timerId = null;

  const handleClick = () => {
    clearTimeout(timerId);
    setContent(clickedContent);
    timerId = setTimeout(() => {
      setContent(defaultContent);
    }, 1000);

    const data = link && status !== streamStatus.DELETED ? link : defaultLink;
    copyToClipboard(data);
  };

  const handleToggle = () => {
    if (content === clickedContent) {
      clearTimeout(timerId);
      timerId = setTimeout(() => {
        setContent(defaultContent);
      }, 1000);
    }
  };

  return (
    <OverlayTrigger
      placement="top"
      overlay={<ToolTip id={`popover-contained-${channel}`}>{content}</ToolTip>}
      onToggle={handleToggle}
    >
      <Button
        variant="light"
        onClick={handleClick}
        className="videoQRbutton vCenter"
        disabled={status === streamStatus.DELETED}
        //disabled={!link || status === streamStatus.DELETED}
      >
        <QRCode
          className="QRcode"
          style={{ width: '100%', pointerEvents: link ? 'auto' : 'none' }}
          width="100%"
          height={null}
          value={link && status !== streamStatus.DELETED ? link : defaultLink}
          bgColor="none"
          fgColor="#5E5E5E"
          level="H"
          renderAs="svg"
          imageSettings={{
            src: logo ? logo : `v_logo.svg`,
            x: null,
            y: null,
            height: 48,
            width: 48,
            excavate: true,
            preserveAspectRatio: true,
          }}
        />
      </Button>
    </OverlayTrigger>
  );
}

export default VideoQR;
