import React, { useState } from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { inquiryIcon } from './icons';
import { LAG_THRESHOLD } from '../../utilities/constants';

export function VideoMetricsFile({
  stats = {},
  defaultOn = false,
  handler = null,
}) {
  const [statsBtn, setStatsBtn] = useState(defaultOn);
  const { type, codec, resolution, fps, bitrate, duration, size } = stats || {};

  const clickHandler = () => {
    if (handler) {
      handler();
    }
    setStatsBtn(!statsBtn);
  };

  const btn = (
    <Button variant="dark" onClick={clickHandler}>
      {statsBtn ? (
        <>
          <p>Type: {type ?? '- -'} </p>
          <p>Codec: {codec ?? '- -'} </p>
          <p>Resolution: {resolution ?? '- -'}</p>
          <p>FPS: {fps ?? '- -'}</p>
          <p>
            Bitrate: {bitrate ? (bitrate / 1000000).toFixed(3) : '- -'}
            {bitrate ? 'Mbps' : ''}
          </p>
          <p>
            Duration: {duration ?? '- -'}
            {duration ? 's' : ''}
          </p>
          <p>
            File Size: {size ? (size / 1000000).toFixed(3) : '- -'}
            {size ? 'MB' : ''}
          </p>
        </>
      ) : (
        inquiryIcon
      )}
    </Button>
  );
  const trigger = (show) => {
    return show ? (
      <OverlayTrigger
        placement="top"
        delay={{ show: 700, hide: 0 }}
        overlay={<Tooltip id="popover-contained">File Info</Tooltip>}
      >
        {btn}
      </OverlayTrigger>
    ) : (
      btn
    );
  };

  return (
    <div className={['streamMetrics', statsBtn ? 'max' : null].join(' ')}>
      {trigger(!statsBtn)}
    </div>
  );
}

export function VideoStreamLatency({
  stats = {},
  defaultOn = false,
  handler = null,
}) {
  const [statsBtn, setStatsBtn] = useState(defaultOn);
  const { resolution, lag } = stats || {};

  const clickHandler = () => {
    if (handler) {
      handler();
    }
    setStatsBtn(!statsBtn);
  };

  const btn = (
    <Button
      variant="dark"
      onClick={clickHandler}
      className={lag > LAG_THRESHOLD ? 'critical' : null}
    >
      {statsBtn ? (
        <>
          <p>HD @ FPS: {resolution ? `${resolution} @ 60` : '- -'}</p>
          <p>Latency: {`${lag ?? '- -'}${lag ? 's' : ''}`} </p>
        </>
      ) : (
        inquiryIcon
      )}
    </Button>
  );

  const trigger = (show) => {
    return show ? (
      <OverlayTrigger
        placement="top"
        delay={{ show: 700, hide: 0 }}
        overlay={<Tooltip id="popover-contained">Stream Latency</Tooltip>}
      >
        {btn}
      </OverlayTrigger>
    ) : (
      btn
    );
  };

  return (
    <div className={['streamMetrics', statsBtn ? 'max' : null].join(' ')}>
      {trigger(!statsBtn)}
    </div>
  );
}

export function VideoMetricsStream({
  stats = {},
  defaultOn = false,
  handler = null,
}) {
  const [statsBtn, setStatsBtn] = useState(defaultOn);
  const {
    resolution,
    lag,
    date,
    time,
    sn,
    duration,
    delta,
    bitrate,
    bandwidth,
  } = stats || {};

  const clickHandler = () => {
    if (handler) {
      handler();
    }
    setStatsBtn(!statsBtn);
  };

  const btn = (
    <Button
      variant="dark"
      onClick={clickHandler}
      className={lag > LAG_THRESHOLD ? 'critical' : null}
    >
      {statsBtn ? (
        <>
          <p>Prgm Date: {date ?? '- -'}</p>
          <p>Prgm Time: {time ?? '- -'}</p>
          <p>
            Segm Info:{' '}
            {`${sn ? '#' : ''}${sn ?? '- -'} / ${duration ?? '- -'}${
              duration ? 's' : ''
            }`}{' '}
          </p>
          <p>Segm ΔT: {`${delta ?? '- -'}${delta ? 's' : ''}`} </p>
          <p>HD @ FPS: {resolution ? `${resolution} @ 60` : '- -'} </p>
          <p>Bitrate: {`${bitrate ?? '- -'}${bitrate ? 'Mbps' : ''}`} </p>
          <p>DL Speed: {`${bandwidth ?? '- -'}${bandwidth ? 'Mbps' : ''}`} </p>
          <p>Latency: {`${lag ?? '- -'}${lag ? 's' : ''}`} </p>
        </>
      ) : (
        inquiryIcon
      )}
    </Button>
  );

  const trigger = (show) => {
    return show ? (
      <OverlayTrigger
        placement="top"
        delay={{ show: 700, hide: 0 }}
        overlay={<Tooltip id="popover-contained">Stream Info</Tooltip>}
      >
        {btn}
      </OverlayTrigger>
    ) : (
      btn
    );
  };

  return (
    <div className={['streamMetrics', statsBtn ? 'max' : null].join(' ')}>
      {trigger(!statsBtn)}
    </div>
  );
}

export function VideoMetricsYouTube({
  stats = {},
  defaultOn = false,
  handler = null,
}) {
  const [statsBtn, setStatsBtn] = useState(defaultOn);
  const { type, resolution, fps, duration } = stats || {};

  const clickHandler = () => {
    if (handler) {
      handler();
    }
    setStatsBtn(!statsBtn);
  };

  const btn = (
    <Button variant="dark" onClick={clickHandler}>
      {statsBtn ? (
        <>
          <p>Type: {type ?? '- -'} </p>
          <p>Resolution: {resolution ?? '- -'}</p>
          <p>FPS: {fps ?? '- -'}</p>
          <p>
            Duration: {duration ?? '- -'}
            {duration ? 's' : ''}
          </p>
        </>
      ) : (
        inquiryIcon
      )}
    </Button>
  );
  const trigger = (show) => {
    return show ? (
      <OverlayTrigger
        placement="top"
        delay={{ show: 700, hide: 0 }}
        overlay={<Tooltip id="popover-contained">File Info</Tooltip>}
      >
        {btn}
      </OverlayTrigger>
    ) : (
      btn
    );
  };

  return (
    <div className={['streamMetrics', statsBtn ? 'max' : null].join(' ')}>
      {trigger(!statsBtn)}
    </div>
  );
}
