import React from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { screenshotIcon, downloadIcon } from './icons';
import { captureVideoFrame } from '../../utilities/video';
import { convertMillipointsToDisplay } from '../../utilities/scoring';
import axios from 'axios';

export function FrameCapture({
  player = {},
  playerId = '',
  handler = null,
  disabled = true,
}) {
  const handleFrameCapture = () => {
    const img = captureVideoFrame(playerId);
    const element = document.createElement('a');

    element.setAttribute('href', img.dataUri);
    element.setAttribute(
      'download',
      `${player.clip.id}@${player.currentTime}.${img.format}`
    );
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };

  const btn = (
    <Button variant="dark" onClick={handleFrameCapture} disabled={disabled}>
      {screenshotIcon}
    </Button>
  );

  const trigger = (show) => {
    return show ? (
      <OverlayTrigger
        placement="top"
        delay={{ show: 700, hide: 0 }}
        overlay={<Tooltip id="popover-contained">Capture Frame</Tooltip>}
      >
        {btn}
      </OverlayTrigger>
    ) : (
      btn
    );
  };

  return <div className="frameCapture">{trigger(true)}</div>;
}

export function VideoDownload({
  url = '',
  player = {},
  disabled = true,
  sessionKey = '',
}) {
  const handleDownload = () => {
    const clip = player?.clip;
    const routine = clip?.routine;
    const { rotation, apparatus, order, athlete, score } = routine || {};

    const fileName = `${sessionKey}_R${rotation}_${apparatus}_${String(
      order + 1
    ).padStart(2, '0')}_${athlete.name.replaceAll(
      ' ',
      '_'
    )}_(${convertMillipointsToDisplay(score)}).mp4`;

    axios({
      url: url,
      method: 'GET',
      responseType: 'blob',
    }).then((response) => {
      const urlBlob = window.URL.createObjectURL(new Blob([response.data]));
      const element = document.createElement('a');
      element.setAttribute('href', urlBlob);
      element.setAttribute('download', fileName);
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    });
  };

  const btn = (
    <Button variant="dark" onClick={handleDownload} disabled={disabled}>
      {downloadIcon}
    </Button>
  );

  const trigger = (show) => {
    return show ? (
      <OverlayTrigger
        placement="top"
        delay={{ show: 700, hide: 0 }}
        overlay={<Tooltip id="popover-contained">Download Video</Tooltip>}
      >
        {btn}
      </OverlayTrigger>
    ) : (
      btn
    );
  };

  return <div className="videoDownload">{trigger(true)}</div>;
}
