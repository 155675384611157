import React, { useRef, useCallback, useState } from 'react';
import { Modal, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import { alertActions } from '../../redux/_actions';
import { clipType, MEDIAURL } from '../../utilities/constants';
import { uploadIcon, downloadIcon, linkIcon, checkIcon } from './icons';
import './uploadvideo.css';
import worker from './mediainfo.worker';
import UploadVideoPreview from './uploadvideopreview';
import LinkVideoPreview from './linkvideopreview';
import { useApolloClient } from '@apollo/client';
import { createClip, updateClip, useErrorHandler } from '../../apollo/helpers';
import { Storage } from 'aws-amplify';
import { useMediaInfo } from '../../utilities/clips';
import { ClipStatus, ClipType } from '../../models';

const { wasm } = worker();

export function useUploadClip(setProgress) {
  const apolloClient = useApolloClient();

  const handleCreateClipErrors = useErrorHandler(
    'There was a problem creating the clip'
  );

  function handleUpload(file, input) {
    console.log('file: ', file);
    console.log('input: ', input);
    setProgress(5);

    return new Promise((resolve, reject) => {
      createClip(apolloClient, input).then((res, error) => {
        if (error === undefined) {
          const newClip = res.data.createClip;
          const uploadUrl = `upload/videos/${newClip.id}/${file.name}`;
          console.log(`Clip created with status ${newClip.status}`);
          console.log(newClip);
          setProgress(10);

          Storage.put(uploadUrl, file, {
            progressCallback(progress) {
              const { loaded, total } = progress;
              console.log(
                `Uploaded: ${loaded}/${total} (${(
                  (loaded / total) *
                  100
                ).toFixed(1)}%)`
              );
              setProgress(10 + (loaded / total) * 80);
            },
            contentType: 'video/*',
          })
            .then(() => {
              console.log(`Successfully Uploaded: ${MEDIAURL}${uploadUrl}`);
              const updateInput = {
                status: ClipStatus.ACTIVE,
                originURL: `${MEDIAURL}${uploadUrl}`,
              };
              setProgress(95);
              updateClip(apolloClient, newClip, updateInput)
                .then((res, error) => {
                  console.log(
                    `Clip updated with status ${res.data.updateClip.status}`
                  );
                  setProgress(100);
                })
                .then(() => {
                  setTimeout(() => setProgress(null), 1000);
                });
            })
            .then(resolve)
            .catch(handleCreateClipErrors);
        }
      });
    });
  }

  return handleUpload;
}

export function useLinkClip(setProgress) {
  const apolloClient = useApolloClient();

  const handleCreateClipErrors = useErrorHandler(
    'There was a problem creating the clip'
  );

  function handleLink(link, input) {
    console.log('link: ', link);
    console.log('input: ', input);
    setProgress(5);

    return createClip(apolloClient, input)
      .then((res, error) => {
        if (error === undefined) {
          const newClip = res.data.createClip;
          console.log(`Clip created with status ${newClip.status}`);
          setProgress(100);
        }
      })
      .then(() => {
        setTimeout(() => setProgress(null), 1000);
      })
      .catch(handleCreateClipErrors);
  }

  return handleLink;
}

export function UploadVideoOverlay(props) {
  const {
    mode,
    clear,
    url,
    stats,
    name,
    round,
    position,
    handleUpload,
    progress,
  } = props;

  return (
    <Modal
      show={mode !== null}
      onHide={() => clear()}
      centered
      dialogClassName="uploadVideoPreviewModal"
      animation={false}
      scrollable={false}
      enforceFocus={false}
      size="lg"
    >
      <UploadVideoPreview
        mode={mode}
        clear={clear}
        url={url}
        stats={stats}
        name={name}
        round={round}
        position={position}
        handleUpload={handleUpload}
        progress={progress}
      />
    </Modal>
  );
}

function LinkVideoOverlay({
  mode,
  clear,
  name,
  round,
  position,
  handleSaveLink,
  progress,
  clip,
}) {
  return (
    <Modal
      show={mode}
      onHide={() => clear()}
      centered
      dialogClassName="linkVideoPreviewModal"
      animation={false}
      scrollable={false}
      enforceFocus={false}
      size="lg"
    >
      <LinkVideoPreview
        clear={clear}
        name={name}
        clip={clip}
        round={round}
        position={position}
        handleSaveLink={handleSaveLink}
        progress={progress}
      />
    </Modal>
  );
}

export function UploadVideoButton({
  disabled,
  routine,
  createRoutine,
  clip,
  name,
  round,
  position,
}) {
  const [url, setURL] = useState(null);
  const [mode, setMode] = useState(null);
  const [clipFile, setClipFile] = useState(null);
  const [rawStats, setRawStats] = useState(null);
  const [progress, setProgress] = useState(0);
  const [stats, setStats] = useState(null);
  const hiddenFileInput = useRef(null);
  const dispatch = useDispatch();
  const uploadClip = useUploadClip(setProgress);
  const mediaInfo = useMediaInfo();
  const isFileClip = clip?.type === ClipType.FILE;

  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  const handleClear = () => {
    hiddenFileInput.current.value = null;
    setRawStats({});
    setURL(null);
    setMode(null);
    setClipFile(null);
    setStats(null);
    setProgress(0);
  };

  const handleUpload = () => {
    const routineCheck = () => {
      return new Promise(async (res) => {
        if (!routine) {
          setProgress(1);
          const newRoutine = await createRoutine();
          res(newRoutine?.data?.createRoutine);
        }
        res(routine);
      });
    };

    return routineCheck().then((res, error) => {
      setProgress(5);
      const input = {
        routineId: res?.id,
        sessionId: res?.sessionId,
        duration: stats.duration,
        fileName: clipFile.name,
        fileSize: stats.size,
        resolution: stats.resolution,
        bitrate: stats.bitrate,
        codecs: stats.codec,
        fps: stats.fps,
        type: ClipType.FILE,
        status: ClipStatus.UPLOADING,
        other: JSON.stringify({ mediainfo: rawStats }),
      };

      uploadClip(clipFile, input);
    });
  };

  const onDrop = useCallback(
    (acceptedFiles) => {
      const handleFileChange = (files) => {
        // Drag and drop results in [File], click selection is file only
        const file = Array.isArray(files) && files.length ? files[0] : files;

        if (!file) {
          return false;
        }

        if (!alert.clear) {
          dispatch(alertActions.clear());
        }

        setURL(URL.createObjectURL(file));
        setClipFile(file);
        setMode('PREVIEW');

        wasm(file).then((res) => {
          setRawStats(res.media);
          setStats(mediaInfo(res.media));
        });
      };
      handleFileChange(acceptedFiles);
    },
    [alert.clear, dispatch]
  );

  const buttonText = () => {
    return isFileClip ? (
      <>{uploadIcon}&nbsp;Upload</>
    ) : (
      <>{uploadIcon}&nbsp;Upload</>
    );
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  return (
    <div {...getRootProps()} className="uploadVideoDrop">
      <OverlayTrigger
        placement="top"
        delay={{ show: 700, hide: 0 }}
        overlay={
          <Tooltip id="popover-contained">Drop File or Click to Upload</Tooltip>
        }
      >
        <Button
          variant="outline-light"
          onClick={handleClick}
          className="uploadVideoButton"
          disabled={disabled}
        >
          {buttonText()}
        </Button>
      </OverlayTrigger>
      <input
        type="file"
        {...getInputProps()}
        ref={hiddenFileInput}
        onChange={(e) => onDrop(e.target.files[0])}
        style={{ display: 'none' }}
        accept={clipType}
      />
      <UploadVideoOverlay
        mode={mode}
        clear={handleClear}
        url={url}
        stats={stats}
        name={name}
        round={round}
        position={position}
        handleUpload={handleUpload}
        progress={progress}
      />
      {isFileClip ? <span className="hasClip">{checkIcon}</span> : null}
    </div>
  );
}

export function LinkVideoButton({
  disabled,
  routine,
  createRoutine,
  clip,
  name,
  round,
  position,
}) {
  const [mode, setMode] = useState(false);
  const [progress, setProgress] = useState(0);
  const linkClip = useLinkClip(setProgress);
  const isLinkClip = clip?.type === ClipType.YOUTUBE;

  const handleClick = () => {
    setMode(true);
  };

  const handleClear = () => {
    setMode(false);
    setProgress(0);
  };

  const handleSaveLink = (url, stats) => {
    const routineCheck = () => {
      return new Promise(async (res) => {
        if (!routine) {
          setProgress(1);
          const newRoutine = await createRoutine();
          res(newRoutine?.data?.createRoutine);
        }
        res(routine);
      });
    };

    return routineCheck().then((res, error) => {
      setProgress(5);
      const input = {
        routineId: res?.id,
        sessionId: res?.sessionId,
        duration: stats.duration,
        type: ClipType.YOUTUBE,
        status: ClipStatus.ACTIVE,
        originURL: url,
      };

      linkClip(url, input);
    });
  };

  return (
    <div className="linkVideoContainer">
      <OverlayTrigger
        placement="top"
        delay={{ show: 700, hide: 0 }}
        overlay={
          <Tooltip id="popover-contained">Click to Add Video Link</Tooltip>
        }
      >
        <Button
          variant="outline-light"
          onClick={handleClick}
          className="linkVideoButton"
          disabled={disabled}
        >
          {linkIcon} Add Link
        </Button>
      </OverlayTrigger>
      <LinkVideoOverlay
        mode={mode}
        clear={handleClear}
        clip={clip}
        name={name}
        round={round}
        position={position}
        handleSaveLink={handleSaveLink}
        progress={progress}
      />
      {isLinkClip ? <span className="hasClip">{checkIcon}</span> : null}
    </div>
  );
}

export function DownloadVideoButton({ disabled }) {
  const handleClick = () => {};

  return (
    <OverlayTrigger
      placement="top"
      delay={{ show: 700, hide: 0 }}
      overlay={<Tooltip id="popover-contained">Click to Download</Tooltip>}
    >
      <Button
        variant="outline-secondary"
        onClick={handleClick}
        className="downloadVideoButton"
        disabled={disabled}
      >
        {downloadIcon}
      </Button>
    </OverlayTrigger>
  );
}
