import React, { useState, useEffect } from 'react';
import { Modal, Button, Row, Form, Alert, Spinner } from 'react-bootstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { eye, eyeSlash } from '../helpers/icons';
import { uiState } from '../../redux/_constants';
import { alertActions, userActions } from '../../redux/_actions';
import InputAutofocus from '../helpers/inputautofocus';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useQueryParams } from '../../utilities/query';

function ResetPWCode() {
  const [showPW, setShowPW] = useState(false);
  const alert = useSelector((state) => state.alert);
  const [isVerifying, setIsVerifying] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const query = useQueryParams();

  useEffect(() => {
    if (!alert.clear) {
      setIsVerifying(false);
    }
  }, [alert.clear]);

  const togglePW = () => {
    setShowPW(!showPW);
  };

  const handleLogin = () => {
    const emailParam = values.email ? '?email=' + values.email : null;
    history.push(`${uiState}${emailParam}`);
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Required'),
    code: Yup.string()
      .required('Required')
      .matches(/^[0-9]{6}$/, 'Must be 6 digits'),
    password: Yup.string()
      .required('Required')
      .matches(
        /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
        'Invalid password'
      ),
  });

  const {
    setFieldValue,
    handleSubmit,
    handleChange,
    values,
    errors,
  } = useFormik({
    initialValues: {
      code: '',
      password: '',
      email: '',
    },
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit(values) {
      setIsVerifying(true);
      dispatch(alertActions.clear());
      dispatch(
        userActions.resetPassword(values.email, values.password, values.code)
      );
    },
  });

  // If comes with email parameter then means no user credentials
  useEffect(() => {
    if (query.get('email')) {
      setFieldValue('email', query.get('email'));
    }
  }, [setFieldValue, query]);

  const enterCodeBody = () => {
    return (
      <Form onSubmit={handleSubmit}>
        {!alert.clear ? (
          <Row>
            <Alert
              dismissible
              onClose={() => dispatch(alertActions.clear())}
              variant={alert.type === 'alert-danger' ? 'danger' : 'success'}
            >
              {alert.message}
            </Alert>
          </Row>
        ) : null}
        <Row>
          <label>
            Email
            {errors.email ? (
              <span className="form-error">{errors.email}</span>
            ) : null}
          </label>
          {InputAutofocus({
            name: 'email',
            value: values.email,
            handler: handleChange,
            disabled: isVerifying,
          })}
        </Row>
        <Row>
          <label>
            New Password
            {errors.password ? (
              <span className="form-error">{errors.password}</span>
            ) : null}
          </label>
          <div className="password-container">
            <input
              name="password"
              type={showPW ? 'text' : 'password'}
              autoComplete="off"
              onChange={handleChange}
              values={values.password}
              disabled={isVerifying}
            />
            <i onClick={togglePW}>{showPW ? eye : eyeSlash}</i>
          </div>
          <div className="passwordSpecs">
            Requires minimum 8 characters, uppercase letter, special character,
            and a number.
          </div>
        </Row>
        <Row>
          <label>
            Code
            {errors.code ? (
              <span className="form-error">{errors.code}</span>
            ) : null}
          </label>
          <input
            name="code"
            type="text"
            autoComplete="off"
            onChange={handleChange}
            values={values.code}
            disabled={isVerifying}
            maxLength="6"
          />
          <div className="passwordSpecs">Sent to your email.</div>
        </Row>
        <Row>
          <Button
            variant="outline-primary"
            className="loginButton"
            type="submit"
            disabled={isVerifying}
          >
            {isVerifying ? (
              <>
                {'Resetting password... '}
                <Spinner
                  variant="primary"
                  role="status"
                  animation="border"
                  size="sm"
                />
              </>
            ) : (
              'Reset Password'
            )}
          </Button>
        </Row>
        <Row>
          <div className="newToVirtius">
            <span>Back to</span>
            <button
              type="button"
              className="signUp"
              onClick={handleLogin}
              disabled={isVerifying}
            >
              Log in
            </button>
          </div>
        </Row>
      </Form>
    );
  };

  return (
    <>
      <Modal.Header className="loginHeader">
        <span>Reset password</span>
      </Modal.Header>
      <Modal.Body className="loginForm">{enterCodeBody()}</Modal.Body>
    </>
  );
}

export default ResetPWCode;
