/* global Rollbar */

export function configureErrorHandling() {
  Rollbar.global({
    itemsPerMinute: 5,
    maxItems: 10,
    ignoredMessages: [
      // This error means that ResizeObserver was not able to deliver all observations within a single animation frame
      'ResizeObserver loop≤ limit exceeded',
    ],
  });
}
