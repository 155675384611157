import React, { useState, useCallback, useEffect, useRef } from 'react';
import { useQuery, useApolloClient } from '@apollo/client';
import {
  Container,
  Row,
  Col,
  Button,
  ButtonGroup,
  OverlayTrigger,
  Tooltip,
  Popover,
  Accordion,
  Card,
  Modal,
  FormControl,
  Form,
  InputGroup,
  Spinner,
} from 'react-bootstrap';
import { RoutineStatus, GenderType, ClipStatus, ClipType } from '../../models';
import { scissorsIcon, downloadIcon } from '../helpers/icons';
import { numToApparatusM, numToApparatusW } from '../../utilities/conversions';
import { wGymEvent, mGymEvent, copyToClipboard } from '../../utilities/export';
import { clipType, POLLING_INTERVAL } from '../../utilities/constants';
import { SessionSubscriptionManager } from './hooks';
import { convertMillipointsToPoints } from '../../utilities/scoring';
import { useDropzone } from 'react-dropzone';
import { dualTeamBRotation } from '../../utilities/session';
import GetSession from '../../apollo/queries/GetFullSession.graphql';
import GetLineupWithRoutines from '../../apollo/queries/GetLineupWithRoutines.graphql';
import CreateClip from '../../apollo/mutations/CreateClip.graphql';
import { useUploadClip } from '../helpers/uploadvideo';
import { useMediaInfo } from '../../utilities/clips';
import { useInterval } from '../../utilities/hooks';
import ReactPlayer from 'react-player';
import worker from '../helpers/mediainfo.worker';
import Hls from 'hls.js';
import axios from 'axios';
import styles from './clipconverter.module.css';

const defaultContent = 'Copy Data';
const clickedContent = 'Copied';

const testServer = 'http://192.168.111.135:5000/intake';
//const clipServer = 'http://192.168.111.140:5000';
//const clipServer = 'http://44.224.151.66:8000';
const clipServer = 'https://2i6tjear2e.execute-api.us-west-2.amazonaws.com/v2';
//const clipServer = 'https://vstack.virti.us/v2/v2'

const { wasm } = worker();

const s3_conn = {
  s3_url: 's3.int.byeung.com:443',
  access_id: 'test',
  secret_key: 'test',
  input_bucket: 'input',
  output_bucket: 'output',
};

const ToolTip = React.forwardRef(
  ({ popper, children, show: _, ...props }, ref) => {
    useEffect(() => {
      popper.scheduleUpdate();
    }, [children, popper]);

    return (
      <Tooltip ref={ref} {...props}>
        {children}
      </Tooltip>
    );
  }
);

const apparatus = (num, gender, abbv) => {
  if (gender === 'MALE') {
    return numToApparatusM(num, abbv);
  }

  if (gender === 'FEMALE') {
    return numToApparatusW(num, abbv);
  }
};

function sortRoutines(routines) {
  const sorted = [];

  // Need to filter deleted routines
  const filteredRoutines = routines.filter((s) => s.status !== 'DELETED');

  filteredRoutines.forEach((routine) => {
    if (!sorted[routine.rotation]) {
      sorted[routine.rotation] = [];
    }

    sorted[routine.rotation][routine.order] = routine;
  });

  return sorted;
}

function parseHLS(url) {
  let hls = new Hls();
  let attrs = null;
  let toplevel = null;

  return new Promise((resolve) => {
    hls.loadSource(url);
    hls.config.startLevel = 10;
    hls.on(Hls.Events.MANIFEST_LOADED, (event, data) => {
      toplevel = data;
    });
    hls.on(Hls.Events.LEVEL_SWITCHING, (event, data) => {
      attrs = data;
    });
    hls.on(Hls.Events.LEVEL_UPDATED, (event, data) =>
      resolve({ toplevel, level: data, attrs })
    );
  });
}

function createClip({
  apolloClient,
  routineId,
  sessionId,
  streamId,
  seekStartSeconds,
  seekEndSeconds,
  startTime,
  endTime,
  pgmStartTime,
  pgmEndTime,
  duration,
  originURL,
  type,
  status,
  resolution,
  bitrate,
  codecs,
  fps,
}) {
  return apolloClient
    .mutate({
      mutation: CreateClip,
      variables: {
        input: {
          routineId,
          sessionId,
          streamId,
          seekStartSeconds,
          seekEndSeconds,
          startTime,
          endTime,
          pgmStartTime,
          pgmEndTime,
          duration,
          originURL,
          type,
          status,
          resolution,
          bitrate,
          codecs,
          fps,
        },
      },
    })
    .then(({ data, error }) => {
      if (error) {
        throw error;
      }
      return data.createClip;
    });
}

function LiveClipButton({ payload, streams, index }) {
  const [isClipping, setIsClipping] = useState(false);
  const [clipTime, setClipTime] = useState(0);
  const [isDownloading, setIsDownloading] = useState(false);
  const [dLProgress, setDLProgress] = useState(0);
  const [downloadLink, setDownloadLink] = useState(null);
  const [previewLink, setPreviewLink] = useState(null);
  const [startOffset, setStartOffset] = useState(0);
  const [endOffset, setEndOffset] = useState(0);
  const [fileName, setFileName] = useState('default');
  const [start, setStart] = useState(0);
  const [end, setEnd] = useState(0);
  const playerRef = useRef();

  const filteredStreams = streams
    .filter((s) => s.status !== 'DELETED' && s.recordingId)
    .sort((a, b) => a.index - b.index);

  const [selectedStream, setSelectedStream] = useState(
    index < filteredStreams.length ? filteredStreams[index].recordingId : null
  );

  useInterval(
    async () => {
      console.log('Polling live clip service for status...');
      // need axios polling call to server and follow up with adding file clip
    },
    POLLING_INTERVAL.MED,
    isClipping
  );

  const handleLiveClipProcess = () => {
    const postCall = `${clipServer}/streams/${selectedStream}/requests`;
    const input = {
      start: payload.start + payload.offset + startOffset + start,
      end: payload.end + payload.offset + endOffset + end,
      //offset: payload.offset,
      mode: 'default',
      //mode: 'timecode',
      async_mode: false,
      transcode: false,
      ref_id: payload.routineId,
    };
    console.log(
      `Requesting live clip for ${payload.name} on ${payload.apparatus}`,
      input
    );

    setIsClipping(true);
    const startClipTime = Date.now();

    axios
      .post(postCall, JSON.stringify(input, null, 2), {
        headers: { 'Content-Type': 'application/json' },
      })
      .then((response) => {
        const url = response?.data?.download_link;

        if (url) {
          console.log(`Download: ${url}`);
          setDownloadLink(url);
        }
        setIsClipping(false);
        setClipTime(Date.now() - startClipTime);
      })
      .catch((error) => {
        console.log(error);
        setIsClipping(false);
        setClipTime(0);
      });
  };

  const handlePreview = () => {
    const input = {
      start: payload.start + payload.offset + startOffset,
      end: payload.end + payload.offset + endOffset,
    };

    const url = `${clipServer}/streams/${selectedStream}/preview?start=${input.start}&end=${input.end}&timemode=default`;
    setPreviewLink(url);
  };

  const handleDownload = () => {
    setIsDownloading(true);
    const score =
      (payload?.score &&
        ` (${convertMillipointsToPoints(payload?.score).toFixed(3)})`) ||
      '';

    const filename =
      fileName === 'default'
        ? `${payload.name} - ${payload.apparatus} - ${payload.team}${score}.mp4`
        : `${payload.routineId}.mp4`;

    console.log(downloadLink);

    axios({
      url: downloadLink,
      method: 'GET',
      responseType: 'blob',
      onDownloadProgress: (e) => {
        setDLProgress(((e.loaded / e.total) * 100).toFixed(1));
      },
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.addEventListener('click', (e) => {
        e.stopPropagation();
      });
      link.click();
      setIsDownloading(false);
      setDLProgress(0);
    });
  };

  useInterval(
    async () => {
      setClipTime(clipTime + 1);
    },
    POLLING_INTERVAL.SECOND,
    isClipping
  );

  const handleChangeStream = (e) => {
    const stream = e.target.value.split(': ')[1];
    setSelectedStream(stream);
  };

  const handlePreviewOffset = (offset) => {
    if (offset > 0) {
      setEndOffset(endOffset + offset);
    }
    if (offset < 0) {
      setStartOffset(startOffset + offset);
    }
    if (offset === 0) {
      setEndOffset(0);
      setStartOffset(0);
    }
  };

  const handleStart = () => {
    const currentTime = playerRef.current.getCurrentTime();
    const duration = playerRef.current.getDuration();
    if (currentTime < duration + end) {
      setStart(currentTime);
    }
  };

  const handleReset = () => {
    setStart(0);
    setEnd(0);
  };

  const handleEnd = () => {
    const currentTime = playerRef.current.getCurrentTime();
    const duration = playerRef.current.getDuration();
    if (currentTime > start) {
      setEnd(currentTime - duration);
    }
  };

  const handleScrubStart = () => {
    playerRef.current.seekTo(start);
  };

  const handleScrubEnd = () => {
    playerRef.current.seekTo(playerRef.current.getDuration() + end);
  };

  const handleFileName = (e) => {
    setFileName(e.target.value);
  };

  useEffect(() => {
    handlePreview();
  }, [startOffset, endOffset, selectedStream]);

  const processLiveClip = (
    <Popover
      placement="auto"
      id="popover-live-clip"
      container="body"
      style={{ minWidth: previewLink ? '50%' : 'null' }}
    >
      <Popover.Content>
        <Row className={styles.clipFormRow}>
          <Col>
            <Row className={styles.innerRow}>
              <h6>
                Status:
                <span
                  className={styles.statusLabel}
                  style={{ padding: '0 1rem', fontWeight: '400' }}
                >
                  <i>
                    {downloadLink && !isClipping && !isDownloading
                      ? `Clipped (${(clipTime / 1000).toFixed(1)}s)`
                      : isClipping
                      ? `Clipping... (${clipTime}s)`
                      : isDownloading
                      ? `Downloading... (${dLProgress}%)`
                      : 'Ready'}
                  </i>
                </span>
                {isDownloading || isClipping ? (
                  <Spinner
                    variant="secondary"
                    role="status"
                    animation="border"
                    size="sm"
                  />
                ) : null}
              </h6>
            </Row>
            <Row className={styles.innerRow}>
              <Form style={{ cursor: 'pointer' }}>
                <Form.Check
                  className={styles.clipForm}
                  type="radio"
                  value="default"
                  onChange={handleFileName}
                  checked={fileName === 'default'}
                  inline
                  id="inline-radio-1"
                  label={`${payload.name} - ${payload.apparatus} - ${
                    payload.team
                  } (${convertMillipointsToPoints(payload.score).toFixed(
                    3
                  )}).mp4`} //"Name - Apparatus - Team (Score)"
                  style={{ cursor: 'pointer', fontSize: '0.75rem' }}
                />
                <Form.Check
                  type="radio"
                  value="uuid"
                  className={styles.clipForm}
                  onChange={handleFileName}
                  checked={fileName === 'uuid'}
                  inline
                  id="inline-radio-2"
                  label={`${payload.routineId}.mp4`}
                  style={{ cursor: 'pointer', fontSize: '0.75rem' }}
                />
              </Form>
            </Row>
          </Col>
          <Col>
            <Row>
              <h6>Stream:</h6>
            </Row>
            <Row>
              <Form.Control
                plaintext
                as="select"
                label="Required"
                style={{ cursor: 'pointer', fontSize: '0.75rem', width: '90%' }}
                defaultValue={
                  (selectedStream && `${index + 1}: ${selectedStream}`) ||
                  (index < filteredStreams.length
                    ? `${filteredStreams[index].index + 1}: ${
                        filteredStreams[index].recordingId
                      }`
                    : 'Select...')
                }
                onChange={handleChangeStream}
              >
                <option value="Select..." disabled hidden>
                  Select...
                </option>
                {filteredStreams.map((s) => {
                  return (
                    <option key={s.id}>
                      {s.index + 1}: {s.recordingId || 'Missing recordingId'}
                    </option>
                  );
                })}
              </Form.Control>
            </Row>
          </Col>
        </Row>
        <hr />
        <ReactPlayer
          ref={playerRef}
          className={styles.videoPlayer}
          style={{ width: '100%' }}
          url={previewLink}
          controls={true}
          width="100%"
          height="100%"
          muted={false}
          progressInterval={1000}
          autoPlay={false}
          config={{ file: { forceHLS: true } }}
        />
        <hr />
        <Row>
          <Col>
            <h6>Payload:</h6>
            <code>
              <pre>
                {`{`} <br />
                &emsp;start: {payload.start + startOffset + start}
                <br />
                &emsp;end: {payload.end + endOffset + end}
                <br />
                &emsp;offset: {payload.offset}
                <br />
                &emsp;session: {selectedStream}
                <br />
                {`}`} <br />
              </pre>
            </code>
          </Col>
          <Col>
            <h6>
              {`Tune: `}{' '}
              <Button
                style={{ padding: '0 0.25rem' }}
                variant="secondary"
                onClick={handleScrubStart}
              >
                ⇤
              </Button>{' '}
              {`[${start.toFixed(1)}s - ${(
                payload.end -
                payload.start +
                end +
                endOffset -
                startOffset
              ).toFixed(1)}s]`}{' '}
              <Button
                style={{ padding: '0 0.25rem' }}
                variant="secondary"
                onClick={handleScrubEnd}
              >
                ⇥
              </Button>
            </h6>
            <ButtonGroup
              style={{ padding: '0.25rem', width: '100%', display: 'flex' }}
            >
              <Button
                style={{ width: '33%', fontSize: '0.75rem' }}
                variant="secondary"
                onClick={handleStart}
              >
                Set Start
              </Button>
              <Button
                style={{ width: '33%', fontSize: '0.75rem' }}
                variant="secondary"
                onClick={handleReset}
              >
                Reset
              </Button>
              <Button
                style={{ width: '33%', fontSize: '0.75rem' }}
                variant="secondary"
                onClick={handleEnd}
              >
                Set End
              </Button>
            </ButtonGroup>
            <ButtonGroup
              style={{ padding: '0.25rem', width: '100%', display: 'flex' }}
            >
              <Button
                style={{ width: '33%', fontSize: '0.75rem' }}
                variant="secondary"
                onClick={() => handlePreviewOffset(-5)}
              >
                -5s
              </Button>
              <Button
                style={{ width: '33%', fontSize: '0.75rem' }}
                variant="secondary"
                onClick={() => handlePreviewOffset(0)}
              >
                Reset
              </Button>
              <Button
                style={{ width: '33%', fontSize: '0.75rem' }}
                variant="secondary"
                onClick={() => handlePreviewOffset(+5)}
              >
                +5s
              </Button>
            </ButtonGroup>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col>
            <Button
              variant="secondary"
              style={{ width: '100%' }}
              onClick={handlePreview}
              disabled={
                selectedStream === 'Missing recordingId' ||
                selectedStream === null
              }
            >
              Preview
            </Button>
          </Col>
          <Col>
            <Button
              variant={downloadLink ? 'success' : 'primary'}
              style={{ width: '100%' }}
              onClick={downloadLink ? handleDownload : handleLiveClipProcess}
              disabled={
                selectedStream === 'Missing recordingId' ||
                selectedStream === null ||
                isClipping ||
                isDownloading
              }
            >
              <span style={{ padding: '0 1rem' }}>
                {downloadLink ? 'Download' : 'Clip'}
              </span>
              {isClipping || isDownloading ? (
                <Spinner
                  variant="secondary"
                  role="status"
                  animation="border"
                  size="sm"
                />
              ) : null}
            </Button>
          </Col>
        </Row>
      </Popover.Content>
    </Popover>
  );

  return (
    <OverlayTrigger
      //placement="right"
      placement="auto"
      delay={{ show: 700, hide: 0 }}
      overlay={processLiveClip}
      trigger="click"
      rootClose
    >
      <Button
        variant={downloadLink ? 'light' : 'outline-success'}
        style={{
          borderRadius: '5rem',
          width: '1.8rem',
          height: '1.8rem',
          position: 'relative',
          //border: 'none',
        }}
      >
        <span
          style={{
            position: 'absolute',
            left: '0',
            top: '0',
            width: '100%',
            height: '100%',
            transform: 'translate (-50%, -50%)',
          }}
        >
          {downloadLink ? downloadIcon : scissorsIcon}
        </span>
      </Button>
    </OverlayTrigger>
  );
}

function UploadVideosButton({ files, routines, setUpload }) {
  const setProgress = () => {};
  const uploadClip = useUploadClip(setProgress);
  const mediaInfo = useMediaInfo();

  const handleUpload = (routine, file) => {
    const setInput = (stats, rawStats) => {
      return {
        routineId: routine.id,
        sessionId: routine.sessionId,
        duration: stats.duration,
        fileName: file.name,
        fileSize: stats.size,
        resolution: stats.resolution,
        bitrate: stats.bitrate,
        codecs: stats.codec,
        fps: stats.fps,
        type: ClipType.FILE,
        status: ClipStatus.UPLOADING,
        other: JSON.stringify({ mediainfo: rawStats }),
      };
    };

    return new Promise((resolve, reject) => {
      wasm(file)
        .then((res) => {
          return { stats: mediaInfo(res.media), rawStats: res.media };
        })
        .then(async (res) => {
          const input = setInput(res.stats, res.rawStats);
          const upload = new Promise((res) => {
            res(uploadClip(file, input));
          });
          return upload;
        })
        .then(resolve);
    });
  };

  const handleClick = async () => {
    console.log('Upload Requested...');
    const batch = [];
    let newFiles = Object.assign({}, files);

    for (const rotation in files) {
      for (const order in files[rotation]) {
        const file = files[rotation][order];
        const routine = routines[rotation][order];
        batch.push({ routine, file });
      }
    }

    await batch.reduce(async (accu, current) => {
      const accumulator = await accu;
      await handleUpload(current.routine, current.file);
      newFiles = {
        ...newFiles,
        [current.routine.rotation]: {
          ...newFiles[current.routine.rotation],
          [current.routine.order]: null,
        },
      };
      setUpload(newFiles);
      return accumulator;
    }, []);
    console.log('Upload Completed.');
  };

  return (
    <Button
      variant="secondary"
      style={{ marginLeft: '0.5rem' }}
      onClick={handleClick}
    >
      Upload
    </Button>
  );
}

function ChooseFilesButton({ routines, setUpload }) {
  const hiddenFileInput = useRef(null);

  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  const onDrop = useCallback((acceptedFiles) => {
    const handleFileChange = (files) => {
      // Drag and drop results in [File], click selection is file only
      let result = {};
      const hasFiles = files && files.length > 0;

      if (!hasFiles) {
        return false;
      }

      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        let routine = null;

        const hasRoutine = routines.find((r) => {
          // Filename may either be clip id or routine id
          const ids = [r.id, ...r?.clips?.items.map((c) => c.id)];

          for (let j = 0; j < ids.length; j++) {
            if (file.name.toLowerCase().includes(ids[j])) {
              routine = r;
              return true;
            }
          }
          return false;
        });
        if (hasRoutine) {
          result = {
            ...result,
            [routine.rotation]: {
              ...result?.[routine.rotation],
              [routine.order]: file,
            },
          };
        }

        setUpload(result);
      }
    };
    handleFileChange(acceptedFiles);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  return (
    <span {...getRootProps()}>
      <Button
        variant="secondary"
        style={{ marginLeft: '0.5rem' }}
        onClick={handleClick}
      >
        Choose Files
      </Button>
      <input
        type="file"
        {...getInputProps()}
        ref={hiddenFileInput}
        onChange={(e) => onDrop(e.target.files)}
        style={{ display: 'none' }}
        accept={clipType}
        multiple
      />
    </span>
  );
}

export default function ClipConverter({ sessionId }) {
  const [showModal, setShowModal] = useState(null);
  const [modalIndex, setModalIndex] = useState(null);
  const [title, setTitle] = useState(null);
  const [offset, setOffset] = useState('');
  const [trimOffset, setTrimOffset] = useState(0);
  const [content, setContent] = useState(defaultContent);
  const [requestedCopy, setRequestedCopy] = useState(false);
  const { data } = useQuery(GetSession, { variables: { id: sessionId } });
  let timerId;

  useEffect(() => {
    if (requestedCopy) {
      setRequestedCopy(false);
      setContent(clickedContent);
      setTimeout(() => {
        setContent(defaultContent);
      }, 2000);
    }
  }, [requestedCopy, showModal]);

  if (!data) {
    return <>Loading...</>;
  }

  const session = data.getSession;
  const lineups = [...session.lineups.items];
  const streams = [...session.streams.items];
  const isAlternating = session?.alternating;

  const handleCopy = () => {
    setRequestedCopy(true);
    copyToClipboard(showModal);
  };

  const handleProcess = () => {
    const payload = JSON.parse(showModal);

    if (showModal && payload.length > 0) {
      payload.forEach((job) => {
        console.log(`Sending job: ${job.run_id}`);
        axios
          .post(testServer, JSON.stringify(job, null, 2), {
            headers: { 'Content-Type': 'application/json' },
          })
          .then((response) => console.log(response));
      });
    }
  };

  const handleToggle = () => {
    if (content === clickedContent) {
      clearTimeout(timerId);
      timerId = setTimeout(() => {
        setContent(defaultContent);
      }, 1000);
    }
  };

  const hideModal = () => {
    setShowModal(null);
    setTitle(null);
    setModalIndex(null);
  };

  const jsonModal = (
    <Modal show={showModal !== null} onHide={hideModal} centered size="xl">
      <Modal.Header style={{ display: 'flex', alignItems: 'center' }}>
        <Row style={{ width: '100%', margin: '0' }}>
          <Col xs={3}>
            <h3>{title}</h3>
          </Col>
          <Col xs={9}>
            <Button
              variant="secondary"
              style={{ float: 'right', marginLeft: '1rem' }}
              onClick={handleProcess}
            >
              Process
            </Button>
            <OverlayTrigger
              placement="top"
              delay={{ show: 700, hide: 0 }}
              overlay={<ToolTip id="popover-contained">{content}</ToolTip>}
              onToggle={handleToggle}
            >
              <Button
                variant="secondary"
                style={{ float: 'right', marginLeft: '1rem' }}
                onClick={handleCopy}
              >
                Copy
              </Button>
            </OverlayTrigger>
            {title?.includes('Trim') ? (
              <InputGroup
                style={{ minWidth: '140px', width: '33%', float: 'right' }}
              >
                <FormControl
                  placeholder="Trim Offset"
                  type="number"
                  value={offset}
                  onChange={(e) => setOffset(e.target.value ?? 0)}
                  onKeyPress={(e) => {
                    if (e.charCode === 13 && e.target.value !== '') {
                      setTrimOffset(e.target.value);
                    }
                  }}
                />
                <InputGroup.Append>
                  <Button
                    variant="secondary"
                    onClick={() => setTrimOffset(offset)}
                  >
                    Apply
                  </Button>
                </InputGroup.Append>
              </InputGroup>
            ) : null}
          </Col>
        </Row>
      </Modal.Header>
      <Modal.Body>
        <pre style={{ whiteSpace: 'break-spaces', overflowWrap: 'anywhere' }}>
          <FormControl
            as="textarea"
            style={{ minHeight: '80vh' }}
            value={showModal ?? ''}
            onChange={(e) => setShowModal(e.target.value)}
          />
        </pre>
      </Modal.Body>
    </Modal>
  );

  const sortedLineups = lineups.sort((a, b) => {
    return a.order - b.order;
  });

  const sortedStreams = streams
    .filter((a) => {
      return a.status !== 'DELETED';
    })
    .sort((a, b) => {
      return a.index - b.index;
    });

  return (
    <Container>
      <h1>
        {session.name}
        <span style={{ float: 'right', margin: '0.5rem' }}>
          <h4>{isAlternating ? `Alternating: ✅` : null}</h4>
        </span>
      </h1>
      {sortedLineups.map((lineup, i) => {
        return (
          <LineupStatus
            key={i}
            lineupId={lineup.id}
            session={session}
            streams={sortedStreams}
            setShowModal={setShowModal}
            setTitle={setTitle}
            isAlternating={isAlternating}
            index={i}
            trimOffset={trimOffset}
            modalIndex={modalIndex}
            setModalIndex={setModalIndex}
            title={title}
          />
        );
      })}
      {jsonModal}
      <SessionSubscriptionManager sessionId={sessionId} />
    </Container>
  );
}

function LineupStatus({
  lineupId,
  session,
  streams,
  setShowModal,
  title,
  setTitle,
  isAlternating,
  index,
  trimOffset,
  modalIndex,
  setModalIndex,
}) {
  const [converting, setConverting] = useState(false);
  const [liveClipping, setLiveClipping] = useState(false);
  const [filesToUpload, setFilesToUpload] = useState({});
  const apolloClient = useApolloClient();

  useEffect(() => {
    if (trimOffset !== 0 && index === modalIndex) {
      if (title === 'Trim By Clips') {
        handleTrimJSON();
      }
      if (title === 'Trim By Routines') {
        handleTrimJSONByRoutines();
      }
    }
  }, [trimOffset, index, modalIndex, title]);

  const { data } = useQuery(GetLineupWithRoutines, {
    variables: { id: lineupId },
  });

  function rotationNumberToEventName(rotation) {
    return session.gender === GenderType.FEMALE
      ? wGymEvent(rotation)
      : mGymEvent(rotation);
  }

  let lineup, oldData, sortedNewRoutines;

  const objectTrigger = (obj, green = true) => {
    const objPopover = obj && (
      <Popover
        id="popover-clip"
        container="body"
        style={{ maxWidth: '35%', minWidth: '20%' }}
      >
        <Popover.Content>
          <ul style={{ listStyle: 'none', padding: '0' }}>
            {Object.entries(obj).map((pairs, i) => {
              return (
                <li key={`${obj.id}-${i}`}>
                  <Row>
                    <Col xs={3}>{pairs[0]}</Col>
                    <Col xs={9}>{JSON.stringify(pairs[1])}</Col>
                  </Row>
                </li>
              );
            })}
          </ul>
        </Popover.Content>
      </Popover>
    );

    return (
      <OverlayTrigger
        trigger="click"
        key="top"
        placement="left-start"
        overlay={objPopover}
        rootClose
        disabled={!obj}
      >
        <Button
          variant="outline-light"
          disabled={!obj}
          style={{
            background: 'transparent',
            border: 'none',
          }}
        >
          {!obj ? '🔴' : green ? '🟢' : '🟡'}
        </Button>
      </OverlayTrigger>
    );
  };

  const handleDLStream = () => {
    setShowModal(generateDLStream());
    setTitle('Download Stream');
    setModalIndex(index);
  };

  const handleTrimJSON = () => {
    setShowModal(generateTrimJSON());
    setTitle('Trim By Clips');
    setModalIndex(index);
  };

  const handleTrimJSONByRoutines = () => {
    setShowModal(generateTrimJSONByRoutine());
    setTitle('Trim By Routines');
    setModalIndex(index);
  };

  const handleTranscodeJSON = () => {
    setShowModal(generateTranscodeJSON());
    setTitle('Transcode');
    setModalIndex(index);
  };

  const generateDLStream = useCallback(() => {
    const input = streams[index].vodURL;
    const output = streams[index].recordingURL;
    return 'ffmpeg -i ' + input + ' -acodec copy -vcodec copy ' + output;
  });

  const generateTrimJSON = useCallback(() => {
    const result = [];

    streams.forEach((stream, i) => {
      if (!isAlternating && index !== i) {
        return;
      }

      const input = {
        s3_conn,
        input_path: `virtius/2022/${session.sessionKey}/${stream?.recordingURL}`,
        run_id: `${session.sessionKey}_team_${index}_stream_${
          stream.index
        }_${Date.now()}`,
        requests: [],
      };

      Object.keys(oldData).forEach((roundNumber) => {
        if (isAlternating && (parseInt(roundNumber) + stream.index) % 2 === 0) {
          return;
        }
        oldData[roundNumber].forEach((competitor, order) => {
          const newRoutine =
            sortedNewRoutines[
              isAlternating && lineup.order === 1
                ? dualTeamBRotation(parseInt(roundNumber))
                : roundNumber
            ]?.[order];
          // Need to find and use the stream clip from VOD
          const newClip =
            newRoutine?.clips.items.length > 0
              ? newRoutine?.clips.items.find((c) => c.type === ClipType.VOD)
              : null;
          const isEmptyClip =
            !newClip ||
            newClip?.seekStartSeconds === null ||
            newClip?.seekEndSeconds === null;

          if (newRoutine && newClip && !isEmptyClip) {
            input.requests.push({
              mode: 'trim',
              output_id: newClip?.id,
              start: `${
                Number.parseFloat(newClip?.seekStartSeconds) +
                Number.parseFloat(trimOffset)
              }`,
              end: `${
                Number.parseFloat(newClip?.seekEndSeconds) +
                Number.parseFloat(trimOffset)
              }`,
            });
          }
        });
      });

      result.push(input);
    });

    console.log(JSON.stringify(result, null, 2));
    return JSON.stringify(result, null, 2);
  }, [oldData, sortedNewRoutines, streams, trimOffset]);

  const generateTrimJSONByRoutine = useCallback(() => {
    const result = [];

    console.log(trimOffset);

    streams.forEach((stream, i) => {
      if (!isAlternating && index !== i) {
        return;
      }

      const sessionStartTime = new Date(session.startAt).getTime();

      const input = {
        s3_conn,
        input_path: `virtius/2022/${session.sessionKey}/${stream?.recordingURL}`,
        run_id: `${session.sessionKey}_team_${index}_stream_${
          stream.index
        }_${Date.now()}`,
        requests: [],
      };

      Object.keys(oldData).forEach((roundNumber) => {
        if (isAlternating && (parseInt(roundNumber) + stream.index) % 2 === 0) {
          return;
        }
        oldData[roundNumber].forEach((competitor, order) => {
          const newRoutine =
            sortedNewRoutines[
              isAlternating && lineup.order === 1
                ? dualTeamBRotation(parseInt(roundNumber))
                : roundNumber
            ]?.[order];

          const startTime =
            newRoutine &&
            (new Date(newRoutine.createdAt).getTime() - sessionStartTime) /
              1000;
          const endTime =
            newRoutine &&
            (new Date(newRoutine.updatedAt).getTime() - sessionStartTime) /
              1000;

          if (newRoutine) {
            input.requests.push({
              mode: 'trim',
              output_id: newRoutine?.id,
              start: `${
                Number.parseFloat(startTime) + Number.parseFloat(trimOffset)
              }`,
              end: `${
                Number.parseFloat(endTime) + Number.parseFloat(trimOffset)
              }`,
            });
          }
        });
      });

      result.push(input);
    });

    console.log(JSON.stringify(result, null, 2));
    return JSON.stringify(result, null, 2);
  }, [oldData, sortedNewRoutines, streams, trimOffset]);

  const generateTranscodeJSON = useCallback(() => {
    const result = {
      s3_conn,
      input_path: '5-mins.sample.mp4',
      requests: [],
    };

    const test1 = {
      mode: 'transcode',
      format: 'mp4',
      video_bitrate: '6000k',
    };

    const test2 = {
      mode: 'transcode',
      format: 'mp4',
      video_bitrate: '3000k',
    };

    result.requests.push(test1);
    result.requests.push(test2);

    /*
    const { streamId, recordingURL } = stream;

    Object.keys(oldData).forEach((roundNumber) => {
      oldData[roundNumber].forEach((competitor, order) => {
        const newRoutine = sortedNewRoutines[roundNumber]?.[order];
        const newClip =
          newRoutine?.clips.items.length > 0
            ? newRoutine?.clips.items[0]
            : null;

        if (newRoutine && newClip) {
          result.requests.push({
            mode: 'trim',
            file:
              (streamId && recordingURL && `${'input'}/${recordingURL}`) ??
              'placeholder.mp4',
            start: newClip?.seekStartSeconds,
            end: newClip?.seekEndSeconds,
          });
        }
      });
    });
    */

    console.log(JSON.stringify(result, null, 2));
    return JSON.stringify(result, null, 2);
  }, [oldData, sortedNewRoutines, streams]);

  const handleConvert = useCallback(() => {
    streams.forEach((stream, i) => {
      if (!isAlternating && index !== i) {
        return;
      }

      if (!converting) {
        setConverting(true);

        let chain = Promise.resolve();
        let hlsData = null;

        // Get stream information
        chain = chain
          .then(() => {
            return parseHLS(stream.vodURL);
          })
          .then((res) => {
            console.log(res);
            hlsData = res;
          });

        Object.keys(oldData).forEach((roundNumber) => {
          if (
            isAlternating &&
            (parseInt(roundNumber) + stream.index) % 2 === 0
          ) {
            return;
          }
          oldData[roundNumber].forEach((competitor, order) => {
            const newRoutine =
              sortedNewRoutines[
                isAlternating && lineup.order === 1
                  ? dualTeamBRotation(parseInt(roundNumber))
                  : roundNumber
              ]?.[order];
            const newClip =
              newRoutine?.clips.items.length > 0
                ? newRoutine?.clips.items[0]
                : null;

            if (newRoutine && !newClip) {
              chain = chain.then(() => {
                const segments = hlsData?.level?.details?.fragments;
                const numSegments = segments.length;
                const start =
                  numSegments > 0 ? segments[0].programDateTime : null;
                const end =
                  (numSegments > 0) & segments[numSegments - 1].programDateTime
                    ? segments[numSegments - 1].programDateTime +
                      segments[numSegments - 1].duration * 1000
                    : null;
                const duration = competitor.endTime - competitor.startTime;
                const startSeg = segments.find((seg) => {
                  if (
                    competitor.startTime > seg.start &&
                    competitor.startTime < seg.start + seg.duration
                  ) {
                    return true;
                  }
                  return false;
                });
                const startTime =
                  startSeg && startSeg.programDateTime
                    ? startSeg.programDateTime +
                      (competitor.startTime - startSeg.start) * 1000
                    : null;
                const endSeg = segments.find((seg) => {
                  if (
                    competitor.endTime > seg.start &&
                    competitor.endTime < seg.start + seg.duration
                  ) {
                    return true;
                  }
                  return false;
                });
                const endTime =
                  endSeg && endSeg.programDateTime
                    ? endSeg.programDateTime +
                      (competitor.endTime - endSeg.start) * 1000
                    : null;

                //console.log(newRoutine.id);
                //console.log(session.id);
                //console.log(newRoutine);
                console.log(competitor.name);
                //console.log('Start: ', new Date(start));
                //console.log('End: ', new Date(end));
                console.log('StartTime: ', new Date(startTime));
                console.log('EndTime: ', new Date(endTime));
                console.log('Duration: ', duration);
                console.log('\n');
                //console.log(hlsData);
                //console.log(stream.id);
                //console.log(stream.vodURL);

                return (
                  newClip ||
                  createClip({
                    apolloClient,
                    routineId: newRoutine.id,
                    sessionId: session.id,
                    streamId: stream.id,
                    seekStartSeconds: competitor.startTime,
                    seekEndSeconds: competitor.endTime,
                    startTime: startTime
                      ? new Date(startTime).toISOString()
                      : null,
                    endTime: endTime ? new Date(endTime).toISOString() : null,
                    pgmStartTime: start ? new Date(start).toISOString() : null,
                    pgmEndTime: end ? new Date(end).toISOString() : null,
                    duration: duration,
                    originURL: stream.vodURL,
                    type: ClipType.VOD,
                    status: ClipStatus.ACTIVE,
                    resolution: hlsData?.attrs?.attrs?.RESOLUTION,
                    bitrate: hlsData?.attrs?.attrs.BANDWIDTH,
                    codecs: hlsData?.attrs?.attrs.CODECS,
                    fps: 60,
                  })
                );
              });
            }

            return chain
              .catch((error) => {
                console.error(error);
              })
              .then(() => {
                setConverting(false);
              });
          });
        });
      }
    });
  }, [apolloClient, session, converting, oldData, sortedNewRoutines, streams]);

  if (!data) {
    return null;
  }

  lineup = data.getLineup;
  oldData = JSON.parse(lineup.lineupData);
  sortedNewRoutines = sortRoutines(lineup.routines.items);

  //console.log(lineup);
  //console.log(sortedNewRoutines);
  //console.log(oldData);
  //console.log(filesToUpload);

  return (
    <>
      <h3>{lineup.team.name}</h3>
      <Button
        variant={liveClipping ? 'primary' : 'secondary'}
        onClick={() => setLiveClipping(!liveClipping)}
        style={{ marginLeft: '0.5rem' }}
      >
        Live Clips
      </Button>
      <Button
        variant="secondary"
        disabled={converting}
        onClick={handleConvert}
        style={{ marginLeft: '0.5rem' }}
      >
        Create Clips
      </Button>
      <Button
        variant="secondary"
        style={{ marginLeft: '0.5rem' }}
        onClick={handleDLStream}
      >
        Download Stream
      </Button>
      <Button
        variant="secondary"
        style={{ marginLeft: '0.5rem' }}
        onClick={handleTrimJSON}
      >
        Trim by Clips
      </Button>
      <Button
        variant="secondary"
        style={{ marginLeft: '0.5rem' }}
        onClick={handleTrimJSONByRoutines}
      >
        Trim by Routines
      </Button>
      <Button
        variant="secondary"
        style={{ marginLeft: '0.5rem' }}
        onClick={handleTranscodeJSON}
      >
        Transcode
      </Button>
      <ChooseFilesButton
        routines={lineup?.routines?.items}
        setUpload={setFilesToUpload}
      />
      <UploadVideosButton
        files={filesToUpload}
        routines={sortedNewRoutines}
        setUpload={setFilesToUpload}
      />
      <ul style={{ padding: '1rem', listStyle: 'none' }}>
        {oldData &&
          Object.keys(oldData).map((roundNumber) => {
            const sublineup = oldData[roundNumber];
            return (
              <li key={`${lineupId}-round-${roundNumber}`}>
                <Accordion style={{ margin: '0.5rem 0' }}>
                  <Card>
                    <Accordion.Toggle
                      as={Card.Header}
                      eventKey={roundNumber}
                      style={{ borderBottom: 'none', cursor: 'pointer' }}
                    >
                      <b>{`${rotationNumberToEventName(roundNumber)}`}</b>
                      <span>
                        {' '}
                        {`(${sortedNewRoutines[roundNumber]?.length ?? 0})`}
                      </span>
                      <span style={{ float: 'right' }}>
                        {' '}
                        {sortedNewRoutines[roundNumber]?.find((r) =>
                          [
                            RoutineStatus.ON_EVAL,
                            RoutineStatus.ON_AIR,
                          ].includes(r?.status)
                        )
                          ? '🟢'
                          : null}
                      </span>
                    </Accordion.Toggle>

                    <Accordion.Collapse eventKey={roundNumber}>
                      <ul>
                        {sublineup.map((competitor, order) => {
                          const newRoutine =
                            //isAlternating && lineup.order === 1
                            //? dualTeamBRotation(parseInt(roundNumber))
                            /*: */ sortedNewRoutines[roundNumber]?.[order];
                          const liveClip = {
                            start: Math.round(
                              new Date(newRoutine?.startedAt).getTime() / 1000
                            ),
                            end: Math.round(
                              new Date(newRoutine?.completedAt).getTime() / 1000
                            ),
                            offset: Number.parseFloat(trimOffset),
                            recordingId: null,
                            ready: [
                              RoutineStatus.COMPLETE,
                              RoutineStatus.ON_EVAL,
                            ].includes(newRoutine?.status),
                            complete: false,
                            name: newRoutine?.athlete?.name,
                            apparatus: apparatus(
                              Number.parseInt(roundNumber),
                              session.gender,
                              true
                            ),
                            team: lineup?.team?.name,
                            score: newRoutine?.score,
                            routineId: newRoutine?.id,
                          };

                          const vodClip =
                            newRoutine?.clips.items.length > 0
                              ? newRoutine?.clips.items.find(
                                  (el) => el.type === ClipType.VOD
                                )
                              : null;
                          const fileClip =
                            newRoutine?.clips.items.length > 0
                              ? newRoutine?.clips.items.find(
                                  (el) =>
                                    el.type === ClipType.FILE &&
                                    el.status === ClipStatus.ACTIVE
                                )
                              : null;
                          const fileUpload = filesToUpload?.[roundNumber]?.[
                            order
                          ] && {
                            name: filesToUpload?.[roundNumber]?.[order]?.name,
                            type: filesToUpload?.[roundNumber]?.[order]?.type,
                            size: filesToUpload?.[roundNumber]?.[order]?.size,
                            lastModified:
                              filesToUpload?.[roundNumber]?.[order]
                                ?.lastModified,
                            lastModifiedDate:
                              filesToUpload?.[roundNumber]?.[order]
                                ?.lastModifiedDate,
                            webkitRelativePath:
                              filesToUpload?.[roundNumber]?.[order]
                                ?.webkitRelativePath,
                          };

                          return competitor.evaluation || newRoutine ? (
                            <li
                              key={`${lineupId}-round-${roundNumber}-competitor-${order}`}
                              style={{ display: 'flex' }}
                            >
                              <Row style={{ width: '100%' }}>
                                <Col
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    flexGrow: '1.5',
                                  }}
                                >
                                  {order + 1}. {competitor.name || '<No name>'}
                                </Col>
                                <Col
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                  }}
                                >
                                  {'Score: '}
                                  {competitor?.evaluation?.score ||
                                    convertMillipointsToPoints(
                                      newRoutine?.score
                                    ).toFixed(3)}
                                </Col>
                                <Col
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                  }}
                                >
                                  {'Routine: '}
                                  {newRoutine
                                    ? objectTrigger(
                                        newRoutine,
                                        newRoutine.status ===
                                          RoutineStatus.COMPLETE
                                      )
                                    : '🔴'}
                                </Col>
                                <Col>
                                  {'Live Clip: '}
                                  {!liveClipping ? (
                                    liveClip ? (
                                      objectTrigger(
                                        liveClip?.ready && liveClip,
                                        liveClip.complete
                                      )
                                    ) : (
                                      '🔴'
                                    )
                                  ) : liveClip?.ready ? (
                                    <LiveClipButton
                                      payload={liveClip}
                                      streams={session?.streams?.items || []}
                                      index={lineup?.order}
                                    />
                                  ) : null}
                                </Col>
                                <Col>
                                  {'VOD Clip: '}
                                  {objectTrigger(vodClip)}
                                </Col>
                                <Col>
                                  {'File Clip: '}
                                  {objectTrigger(
                                    fileClip || !!fileUpload,
                                    !!fileClip &&
                                      fileClip.status === ClipStatus.ACTIVE &&
                                      !fileUpload
                                  )}
                                </Col>
                              </Row>
                            </li>
                          ) : null;
                        })}
                      </ul>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
              </li>
            );
          })}
      </ul>
    </>
  );
}
