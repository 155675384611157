import { Button } from 'react-bootstrap';
import { GenderType } from '../../models';
import selectGenderStyles from './selectgender.module.css';

function SelectGender(props) {
  const { setGender, gender } = props;
  const { MALE, FEMALE } = GenderType;
  const { genderSelect, male, female } = selectGenderStyles;

  return (
    <div className={genderSelect}>
      <Button
        variant="light"
        onClick={() => setGender(FEMALE)}
        className={[gender === FEMALE ? 'active' : null, female].join(' ')}
      >
        <span className="vBlue">W</span>
        <span className="vGray">omens</span>
      </Button>
      <Button
        variant="light"
        onClick={() => setGender(MALE)}
        className={[gender === MALE ? 'active' : null, male].join(' ')}
        style={{ float: 'right' }}
      >
        <span className="vBlue">M</span>
        <span className="vGray">ens</span>
      </Button>
    </div>
  );
}

export default SelectGender;
