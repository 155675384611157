import CreateScore from '../mutations/CreateScore.graphql';
import GetFullRoutine from '../queries/GetFullRoutine.graphql';

export function makeScore({ id, createdAt, value, type }) {
  const score = newOptimisticScore();

  score.id = id ?? score.id;
  score.createdAt = createdAt ?? score.createdAt;
  score.value = value ?? score.value;
  score.type = type ?? score.type;

  return score;
}

const newOptimisticScore = () => ({
  __typename: 'Score',
  id: 'FAKE-SCORE-ID',
  createdAt: new Date(),
  _version: 0,
});

export function createScore(apolloClient, input) {
  const { getRoutine } = apolloClient.readQuery({
    query: GetFullRoutine,
    variables: {
      id: input.routineId,
    },
  });
  // console.log('createScore');
  return apolloClient.mutate({
    mutation: CreateScore,
    variables: {
      input,
    },
    optimisticResponse: {
      __typename: 'Mutation',
      createScore: {
        ...newOptimisticScore(),
        routine: getRoutine,
        ...input,
      },
    },
    update: (apolloProxy, { data: { createScore } }) => {
      // console.log('updateScore', { createScore });
      apolloProxy.writeQuery({
        query: GetFullRoutine,
        variables: {
          id: input.routineId,
        },
        data: {
          getRoutine: {
            ...getRoutine,
            scores: {
              ...getRoutine.scores,
              items: [...getRoutine.scores.items, createScore],
            },
          },
        },
      });
    },
  });
}
