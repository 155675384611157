/* global Rollbar, rollbar */
import { useDispatch } from 'react-redux';
import { alertActions } from '../../redux/_actions';
import { useErrorHandler as rebHandler } from 'react-error-boundary';

export function useErrorHandler(message) {
  const dispatch = useDispatch();
  const criticalHandler = rebHandler();

  return (error, critical = false) => {
    if (Rollbar.options.enabled && !error.networkError) {
      rollbar.error(error);
    }
    if (critical) {
      criticalHandler(error);
    }
    dispatch(alertActions.error(message));
  };
}
