import { evaluatorConstants } from '../_constants';

export const evaluatorActions = {
  openInquiry,
  clearInquiry,
  openEdit,
  clearEdit,
};

function openInquiry(lineupId, inquiry) {
  return {
    type: evaluatorConstants.OPEN_INQUIRY,
    data: { lineupId, inquiry },
  };
}

function clearInquiry(lineupId) {
  return {
    type: evaluatorConstants.CLEAR_INQUIRY,
    data: { lineupId },
  };
}

function openEdit(lineupId, routine) {
  return {
    type: evaluatorConstants.OPEN_EDIT,
    data: { lineupId, routine },
  };
}

function clearEdit(lineupId) {
  return {
    type: evaluatorConstants.CLEAR_EDIT,
    data: { lineupId },
  };
}
