import { useEffect, forwardRef } from 'react';
import { Button, Tooltip, OverlayTrigger, Row, Modal } from 'react-bootstrap';
import { scoreboardIcon } from '../helpers/icons';
import Leaderboard from './leaderboard';
import { useSelector, useDispatch } from 'react-redux';
import { producerActions } from '../../redux/_actions';
import { useHotkeys } from 'react-hotkeys-hook';
import { sessionViewType } from '../../redux/_constants';
import { useSession, useSortedLineups } from './hooks';

const defaultContent = 'Leaderboard';

const scoreboardType = {
  LEADERBOARD: 'LEADERBOARD',
  ROTATION: 'ROTATION',
  APPARATUS: 'APPARATUS',
};

const ToolTip = forwardRef(({ popper, children, show: _, ...props }, ref) => {
  useEffect(() => {
    popper.scheduleUpdate();
  }, [children, popper]);

  return (
    <Tooltip ref={ref} {...props}>
      {children}
    </Tooltip>
  );
});

function ScoreboardOverlay(props) {
  const { mode, lineups, session, disabled, isLeaderBoard, type } = props;
  const dispatch = useDispatch();
  const { LEADERBOARD, ROTATION, APPARATUS } = scoreboardType;
  const isSBFullscreen =
    useSelector((state) => state.session.view === sessionViewType.SCOREBOARD) ||
    isLeaderBoard;

  const handleHide = () => {
    dispatch(producerActions.leaderboard(false));
  };

  const formType = () => {
    switch (mode) {
      case LEADERBOARD:
        return (
          <Leaderboard
            mode={mode}
            onHide={handleHide}
            session={session}
            lineups={lineups}
            type={type}
          />
        );
      case ROTATION:
      case APPARATUS:
      default:
        return null;
    }
  };

  return (
    !disabled && (
      <Modal
        show={mode !== null}
        onHide={handleHide}
        centered
        dialogClassName={[
          'scoreboardModal',
          isSBFullscreen ? 'scoreboardFullscreen' : null,
        ].join(' ')}
        animation={false}
        scrollable={true}
        enforceFocus={false}
        size="xl"
      >
        {formType()}
      </Modal>
    )
  );
}

export function ScoreboardButton({ sessionId, defaultMode = null }) {
  const dispatch = useDispatch();
  const showLeaderboard = useSelector((state) => state.producer.leaderboard);
  const content = defaultContent;

  const handleClick = () => {
    dispatch(producerActions.leaderboard(!showLeaderboard));
  };

  useHotkeys(
    'l',
    (e) => {
      e.preventDefault();
      dispatch(producerActions.leaderboard(!showLeaderboard));
    },
    [showLeaderboard]
  );

  return (
    <div className="scoreboard vCenter">
      <Row>
        <OverlayTrigger
          placement="top"
          delay={{ show: 700, hide: 0 }}
          overlay={<ToolTip id="popover-contained">{content}</ToolTip>}
        >
          <Button variant="light" onClick={handleClick} className="vCenter">
            {scoreboardIcon}
          </Button>
        </OverlayTrigger>
      </Row>
    </div>
  );
}

export function Scoreboard({
  sessionId,
  defaultMode = scoreboardType.LEADERBOARD,
  isLeaderBoard,
  type,
}) {
  const showLeaderboard = useSelector((state) => state.producer.leaderboard);
  const session = useSession(sessionId);
  const lineups = useSortedLineups(sessionId);

  return (
    <ScoreboardOverlay
      disabled={!showLeaderboard && !isLeaderBoard}
      mode={defaultMode}
      session={session}
      lineups={lineups}
      isLeaderBoard={isLeaderBoard}
      type={type}
    />
  );
}
