import { useEffect, useState } from 'react';
import {
  Modal,
  Button,
  Row,
  Col,
  Spinner,
  Alert,
  Container,
  Form,
} from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { sessionActions, alertActions } from '../../redux/_actions';
import { streamStatus } from '../../redux/_constants';
import { useInterval } from '../../utilities/hooks';
import { useSideScrollMenu } from '../helpers/sidescrollmenu';
import {
  POLLING_INTERVAL,
  MEDIAURL,
  CAMERA_720_60_SETTINGS,
  CAMERA_1080_30_SETTINGS,
  CAMERA_1080_60_SETTINGS,
  CAMERA_1440_60_SETTINGS,
  CAMERA_4K_60_SETTINGS,
  STREAM_DEFAULT_CONFIG,
  STREAM_LOCATION_CONFIG,
} from '../../utilities/constants';
import { larixURL } from '../../utilities/conversions';
import VideoQR from './videoqr';
import { videoCameraIcon } from '../helpers/icons';
import { StreamType } from '../../models';
import './videosetup.css';

const {
  OFF,
  STARTING,
  STARTED,
  STOPPING,
  STOPPED,
  RESETTING,
  DELETING,
  DELETED,
  ERROR,
  CREATING,
} = streamStatus;

function VideoSelector({ menu, streams, handler, selected, addHandler }) {
  const streamButton = (i) => {
    return (
      <span className="camIconNum">
        {videoCameraIcon}
        <span className="camIndex">{i + 1}</span>
      </span>
    );
  };

  const addButton = (
    <span className="camIconNum">
      {videoCameraIcon}
      <span className="camIndex">+</span>
    </span>
  );

  return menu({
    keys: streams.map((s, i) => {
      return {
        value: streamButton(i),
      };
    }),
    handler,
    selectedIndex: selected,
    addButton: { value: addButton },
    addHandler,
  });
}

const emptyStream = {
  status: streamStatus.OFF,
  id: 'FAKE-STREAM-ID',
  _version: 0,
};

function VideoSetup() {
  const alert = useSelector((state) => state.alert);
  const sessionStreams = useSelector((state) => state.session.streams.items);
  const teams = useSelector((state) => state.producer.teams);
  const sessionName = useSelector((state) => state.session.name);
  const sessionKey = useSelector((state) => state.session.sessionKey);
  const dispatch = useDispatch();
  const [selectedStreamIndex, setSelectedStreamIndex] = useState(0);
  const [resolution, setResolution] = useState('720/60');
  const [location, setLocation] = useState(null);
  const sideScrollMenu = useSideScrollMenu();
  const { sideScrollMenu: menu } = sideScrollMenu;
  const [streams, setStreams] = useState([]);
  const [type, setType] = useState(
    sessionStreams?.[selectedStreamIndex]?.type || StreamType.CAMERA
  );

  useEffect(() => {
    setStreams(
      sessionStreams.length === 0 ? [emptyStream] : [...sessionStreams]
    );
  }, [sessionStreams]);

  useEffect(() => {
    if (selectedStreamIndex >= streams.length && streams.length > 0) {
      setSelectedStreamIndex(streams.length - 1);
    }
    setType(streams?.[selectedStreamIndex]?.type || StreamType.CAMERA);
  }, [streams.length, selectedStreamIndex]);

  const stream = streams?.[selectedStreamIndex];
  const channel = stream?.status || streamStatus.OFF;
  const numProdStreams = streams.filter((s, index) => {
    return s.type === StreamType.PRODUCTION && index <= selectedStreamIndex;
  }).length;

  const teamLogo =
    selectedStreamIndex - numProdStreams < teams.length &&
    type === StreamType.CAMERA
      ? MEDIAURL + teams[selectedStreamIndex - numProdStreams]?.logo
      : null;

  const addEmptyStream = () => {
    setStreams([...streams, emptyStream]);
    setSelectedStreamIndex(streams.length);
    setType(StreamType.CAMERA);
  };

  const initialCheck = () => {
    console.log('Load check Wowza API for status...');
    if (
      stream &&
      channel !== DELETED &&
      stream.id !== 'FAKE-STREAM-ID' &&
      stream.type !== StreamType.PRODUCTION
    ) {
      dispatch(sessionActions.checkStream(selectedStreamIndex));
    }
  };

  // On first render update the stream status
  useEffect(initialCheck, []);

  // handle polling for channel for status update
  useInterval(
    async () => {
      console.log('Polling Stream API for status...');
      dispatch(sessionActions.checkStream(selectedStreamIndex));
    },
    channel === STOPPING ? POLLING_INTERVAL.FAST : POLLING_INTERVAL.MED,
    [STARTING, STOPPING, RESETTING].includes(channel)
  );

  const videoOptions = (option) => {
    switch (option) {
      case '2160/60':
        return CAMERA_4K_60_SETTINGS;
      case '1440/60':
        return CAMERA_1440_60_SETTINGS;
      case '1080/60':
        return CAMERA_1080_60_SETTINGS;
      case '1080/30':
        return CAMERA_1080_30_SETTINGS;
      case '720/60':
      default:
        return CAMERA_720_60_SETTINGS;
    }
  };

  const handleChannel = (index) => {
    const streamState = channel;
    const createStreamCfg = {
      live_stream: {
        ...STREAM_DEFAULT_CONFIG.live_stream,
        aspect_ratio_width: videoOptions(resolution)?.width,
        aspect_ratio_height: videoOptions(resolution)?.height,
        broadcast_location:
          location ?? STREAM_DEFAULT_CONFIG.live_stream.broadcast_location,
      },
    };

    const createStreamNoCamCfg = {
      type: StreamType.PRODUCTION,
      status: streamStatus.STARTED,
    };

    // Need to fix this as the

    dispatch(alertActions.clear());

    switch (streamState) {
      case OFF:
      case DELETED:
        if (type === StreamType.CAMERA) {
          dispatch(sessionActions.createStream(index, createStreamCfg));
        }
        if (type === StreamType.PRODUCTION) {
          dispatch(
            sessionActions.createStreamNoCam(index, createStreamNoCamCfg)
          );
        }
        break;
      case STOPPED:
        dispatch(sessionActions.startStream(index));
        break;
      case STARTED:
        dispatch(sessionActions.stopStream(index));
        break;
      default:
        break;
    }
  };

  const showSpinner = (channelState) => {
    return [STARTING, STOPPING, CREATING, RESETTING, DELETING].includes(
      channelState
    ) ? (
      <Spinner variant="primary" role="status" animation="border" size="sm" />
    ) : null;
  };

  const deleteChannel = (index) => {
    dispatch(alertActions.clear());
    dispatch(sessionActions.deleteStream(index));
  };

  const resetChannel = (index) => {
    dispatch(alertActions.clear());
    dispatch(sessionActions.resetStream(index));
  };

  const buttonMsg = (state) => {
    switch (state) {
      case OFF:
      case DELETED:
        return 'Create';
      case STARTING:
        return 'Starting';
      case RESETTING:
        return 'Resetting';
      case STOPPED:
        return 'Start';
      case STARTED:
        return 'Stop';
      case STOPPING:
        return 'Stopping';
      case ERROR:
        return 'Error';
      case CREATING:
        return 'Creating';
      case DELETING:
        return 'Deleting';
      default:
        return '';
    }
  };

  const videoSetupBody = () => {
    return (
      <>
        {!alert.clear ? (
          <Row style={{ padding: '0 15px', margin: '1rem -15px' }}>
            <Alert
              dismissible
              onClose={() => dispatch(alertActions.clear())}
              variant={alert.type === 'alert-danger' ? 'danger' : 'success'}
            >
              {alert.message}
            </Alert>
          </Row>
        ) : null}
        <Container className="videoSetupSelector">
          <VideoSelector
            menu={menu}
            streams={streams}
            handler={setSelectedStreamIndex}
            selected={selectedStreamIndex}
            addHandler={addEmptyStream}
          />
        </Container>
        <Container className="videoSetupContainer">
          <Row>
            <Col style={{ display: 'flex' }}>
              <VideoQR
                channel={selectedStreamIndex + 1}
                link={
                  stream &&
                  channel !== DELETED &&
                  larixURL({
                    url: stream.ingestURL || stream.outputURL,
                    name: `${sessionName} (${selectedStreamIndex + 1})`,
                    ...videoOptions(resolution),
                  })
                }
                status={channel}
                logo={teamLogo}
                sessionKey={sessionKey}
              />
            </Col>
          </Row>
          <Row
            style={{
              border: '1px solid #ccc',
              padding: '1rem 0',
              borderRadius: '0.5rem',
            }}
          >
            <Col xs={2}>
              <Form.Label>
                <i>Type:</i>
              </Form.Label>
            </Col>
            <Col xs={3}>
              <Form.Control
                as="select"
                value={type}
                disabled={stream && stream.id !== 'FAKE-STREAM-ID'}
                onClick={(e) => {
                  e.stopPropagation();
                }}
                onChange={(e) => {
                  setType(StreamType[e.target.value]);
                }}
              >
                {Object.keys(StreamType).map((t) => {
                  return (
                    <option key={t} value={t}>
                      {t}
                    </option>
                  );
                })}
              </Form.Control>
            </Col>
            <Col xs={2}>
              <Form.Label>
                <i>Location:</i>
              </Form.Label>
            </Col>
            <Col xs={5}>
              <Form.Control
                as="select"
                disabled={type === StreamType.PRODUCTION}
                onClick={(e) => {
                  e.stopPropagation();
                }}
                onChange={(e) => {
                  setLocation(STREAM_LOCATION_CONFIG[e.target.value]);
                }}
              >
                {Object.keys(STREAM_LOCATION_CONFIG).map((loc) => {
                  return (
                    <option key={loc} value={loc}>
                      {loc}
                    </option>
                  );
                })}
              </Form.Control>
            </Col>
          </Row>
          <Row
            style={{
              border: '1px solid #ccc',
              padding: '1rem 0',
              borderRadius: '0.5rem',
            }}
          >
            <Col xs={2}>
              <Form.Label>
                <i>Quality:</i>
              </Form.Label>
            </Col>
            <Col xs={5}>
              <Form.Check
                inline
                label="720p/60fps (4500kbps)"
                name="group1"
                type="radio"
                id="inline-radio-1"
                defaultChecked={true}
                onChange={(e) => setResolution('720/60')}
                disabled={type === StreamType.PRODUCTION}
              />
              <Form.Check
                inline
                label="1080p/30fps (4800kbps)"
                name="group1"
                type="radio"
                id="inline-radio-2"
                onChange={(e) => setResolution('1080/30')}
                disabled={type === StreamType.PRODUCTION}
              />
              <Form.Check
                inline
                label="1080p/60fps (6000kbps)"
                name="group1"
                type="radio"
                id="inline-radio-2"
                //checked={resolution === 1080}
                disabled={type === StreamType.PRODUCTION}
                onChange={(e) => setResolution('1080/60')}
              />
            </Col>
            <Col xs={5}>
              <Form.Check
                inline
                label="1440p/60fps (10000kbps)"
                name="group1"
                type="radio"
                id="inline-radio-3"
                //checked={resolution === 1440}
                disabled={type === StreamType.PRODUCTION}
                onChange={(e) => setResolution('1440/60')}
              />
              <Form.Check
                inline
                label="4K/60fps (24000kbps)"
                name="group1"
                type="radio"
                id="inline-radio-4"
                //checked={resolution === 2160}
                disabled={type === StreamType.PRODUCTION}
                onChange={(e) => setResolution('2160/60')}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={6}>
              <Button
                variant="outline-primary"
                className="streamButtonLG"
                onClick={() => handleChannel(selectedStreamIndex)}
                disabled={
                  [STARTING, STOPPING, CREATING, RESETTING, DELETING].includes(
                    channel
                  ) ||
                  (type === StreamType.PRODUCTION && channel === STARTED)
                }
              >
                <span>{`${buttonMsg(channel)} `}</span>
                {showSpinner(channel)}
              </Button>
            </Col>
            <Col xs={3}>
              <Button
                variant="outline-secondary"
                className="streamButtonSM"
                onClick={() => resetChannel(selectedStreamIndex)}
                disabled={channel !== STARTED}
              >
                <span>Reset</span>
              </Button>
            </Col>
            <Col xs={3}>
              <Button
                variant="outline-danger"
                className="streamButtonSM"
                onClick={() => deleteChannel(selectedStreamIndex)}
                disabled={channel !== STOPPED}
              >
                <span>Delete</span>
              </Button>
            </Col>
          </Row>
        </Container>
      </>
    );
  };

  const footer = () => {
    return (
      <Col className="vCenter">
        <span className="sessionDesc">{`Stream ${
          selectedStreamIndex + 1
        }: ${channel}`}</span>
      </Col>
    );
  };

  const headerMsg = () => {
    return <span>Video streaming setup</span>;
  };

  return (
    <>
      <Modal.Header className="setupHeader">{headerMsg()}</Modal.Header>
      <Modal.Body className="setupForm">{videoSetupBody()}</Modal.Body>
      <Modal.Footer className="setupFooter">{footer()}</Modal.Footer>
    </>
  );
}

export default VideoSetup;
