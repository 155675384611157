import { useState } from 'react';
import { useQuery } from '@apollo/client';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { SearchFullTeams } from '../../apollo/queries/SearchFullTeams.graphql';

function TeamTypeahead({
  gender,
  onSelected,
  prompt,
  disabled = false,
  clearOnSelect = true,
}) {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedTeams, setSelectedTeams] = useState([]);

  const { data, loading } = useQuery(SearchFullTeams, {
    variables: {
      filter: {
        name: { matchPhrasePrefix: searchTerm.trim() },
        gender: { eq: gender },
      },
      limit: 25,
      sort: {
        direction: 'asc',
        field: 'name',
      },
    },
  });

  return (
    <AsyncTypeahead
      id="team-typeahead"
      clearButton
      disabled={disabled}
      dropup
      filterBy={['name']}
      isLoading={loading}
      onChange={(selectedTeams) => {
        setSelectedTeams(selectedTeams);
        if (selectedTeams.length !== 0) {
          onSelected(selectedTeams[0]).then(() => {
            if (clearOnSelect) {
              setSelectedTeams([]);
            }
          });
        } else {
          setSelectedTeams([]);
        }
      }}
      onSearch={setSearchTerm}
      selected={selectedTeams}
      highlightOnlyResult={true}
      labelKey="name"
      className="editing"
      placeholder={prompt ?? 'Search for team...'}
      options={data ? data.searchTeams.items : []}
      onKeyDown={(e) => e.stopPropagation()}
      renderMenuItemChildren={(option) => {
        return <div style={{ fontWeight: '500' }}>{option.name}</div>;
      }}
    />
  );
}

export default TeamTypeahead;
