import { useContext } from 'react';
import { Row, Col, Button, OverlayTrigger, Popover } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { evaluatorActions } from '../../redux/_actions';
import {
  caretUpIcon,
  caretDownIcon,
  inquiryIcon,
  sCircle,
  nCircle,
  editIcon,
} from '../helpers/icons';
import { sessionRoleType, sessionViewType } from '../../redux/_constants';
import {
  convertMillipointsToDisplay,
  useRoutineScore,
} from '../../utilities/scoring';
import { InquiryStatus } from '../../models';
import { useApolloClient } from '@apollo/client';
import {
  createInquiry,
  updateInquiry,
  useErrorHandler,
} from '../../apollo/helpers';
import { useEvalConfig, useDisplayConfig } from './hooks';
import producerStyles from './producer.module.css';
import './scorecard.css';
import { SessionContext } from './multiexperimental';

function InquiryIndicator({ openInquiry }) {
  return (
    <div className={`inquiryIndicator ${(openInquiry && 'unresolved') || ''}`}>
      {inquiryIcon}
    </div>
  );
}

function EditIndicator({ openEdit }) {
  return (
    <div className={`inquiryIndicator ${(openEdit && 'unresolved') || ''}`}>
      {editIcon}
    </div>
  );
}

const stickIndicator = (
  <div
    className={['stickIndicator green', producerStyles.stickIndicator].join(
      ' '
    )}
  >
    {sCircle}
  </div>
);
const penaltyIndicator = (
  <div
    className={['penaltyIndicator red', producerStyles.penaltyIndicator].join(
      ' '
    )}
  >
    {nCircle}
  </div>
);
const dScoreIndicator = (score) => {
  return (
    <div
      className={['dScoreIndicator', producerStyles.dScoreIndicator].join(' ')}
    >
      {score}
    </div>
  );
};

const { ADMIN, JUDGE } = sessionRoleType;

function ScoreCard({ routine, home }) {
  const live = useSelector((state) => state.producer.live);
  const role = useSelector((state) => state.session.role);
  const view = useSelector((state) => state.session.view);
  const activeInquiryId = useSelector(
    (state) => state.evaluator.activeInquiryByLineupId[routine.lineupId]?.id
  );
  const activeEditId = useSelector(
    (state) => state.evaluator.activeEditByLineupId[routine.lineupId]?.id
  );

  const dispatch = useDispatch();
  const apolloClient = useApolloClient();
  const evalConfig = useEvalConfig();
  const displayConfig = useDisplayConfig();
  const routineScore = useRoutineScore();
  const sessionContext = useContext(SessionContext);
  const setEvalRoutine = sessionContext?.setEvalRoutine || null;

  const {
    scoresObj,
    scoresJ,
    scoresE,
    scoresSV,
    scoreD,
    scoresD,
    scoreDType,
    scoreND,
    scoreSB,
    scoreEDelta,
    scoreEAverage,
    scoreJAverage,
    scoreJDelta,
  } = routineScore(routine);

  const handleCreateInquiryErrors = useErrorHandler(
    'There was a problem creating the inquiry'
  );

  const openInquiry = routine.inquiries.items.find(
    (inquiry) => inquiry.status === InquiryStatus.OPEN
  );

  const openEdit = activeEditId === routine?.id;

  const isStickBonus = evalConfig?.stickBonus;
  const isNeutralDeduction = evalConfig?.nDeduct;
  const isExecution = evalConfig?.ePanel?.length > 0;
  const isJudging = evalConfig?.jPanel?.length > 0;
  const isStartValue = !!evalConfig?.startValue;

  const {
    jPrecision,
    avgPrecision,
    svPrecision,
    dPrecision,
    ePrecision,
    scorePrecision,
  } = displayConfig;

  const dScorePrecision = isStartValue ? svPrecision : dPrecision;

  const scoreCardButton = () => {
    let buttonText = 'Inquiry';
    let buttonVariant = 'warning';
    let enabled = true;
    if (openInquiry) {
      if ([ADMIN, JUDGE].includes(role)) {
        buttonText = openInquiry.id === activeInquiryId ? 'Cancel' : 'Resolve';
        enabled = openInquiry.id !== 'FAKE-INQUIRY-ID';
        if (buttonText === 'Cancel') {
          buttonVariant = 'outline-secondary';
        }
      } else {
        buttonText = 'Pending';
        enabled = false;
      }
    } else if (openEdit) {
      if ([ADMIN, JUDGE].includes(role)) {
        buttonText = 'Cancel';
        enabled = true;
        buttonVariant = 'outline-secondary';
      } else {
        buttonText = 'Pending';
        enabled = false;
      }
    } else {
      if ([ADMIN, JUDGE].includes(role)) {
        buttonText = 'Inquiry';
      }
    }

    return (
      <Button
        variant={buttonVariant}
        disabled={!enabled}
        onClick={submitInquiryOrEdit}
      >
        {buttonText}
      </Button>
    );
  };

  const scoreCardPlacement = () => {
    if (view === sessionViewType.SCOREBOARD) {
      return home ? 'right' : 'left';
    } else if (view === sessionViewType.MOBILE) {
      return 'auto';
    } else {
      //return home ? 'top-end' : 'top-start';
      return home ? 'auto-end' : 'auto-start';
    }
  };

  const inquiryHeader = () => {
    const classes = ['inquiryHeader'];
    if (
      routine.inquiries.items.filter((i) => i.status !== InquiryStatus.CANCELED)
        .length === 0
    ) {
      return null;
    }

    if (openInquiry) {
      classes.push('unresolved');
    }

    return <span className={classes.join(' ')}>Inquiry</span>;
  };

  const editHeader = () => {
    const classes = ['inquiryHeader'];
    return <span className={classes.join(' ')}>Edit</span>;
  };

  const header = () => {
    if (openInquiry) {
      return inquiryHeader();
    } else if (openEdit) {
      return editHeader();
    } else {
      return null;
    }
  };

  const submitInquiryOrEdit = (e) => {
    if (openInquiry) {
      if (openInquiry.id === activeInquiryId) {
        // Option 1: Cancels an existing inquiry
        return updateInquiry(apolloClient, openInquiry, {
          status: InquiryStatus.CANCELED,
        }).then(() => {
          dispatch(evaluatorActions.clearInquiry(routine.lineupId));
          if (setEvalRoutine) {
            setEvalRoutine(null);
          }
        });
      } else {
        // Option 2: Takes control of an open inquiry
        dispatch(evaluatorActions.openInquiry(routine.lineupId, openInquiry));
        if (setEvalRoutine) {
          setEvalRoutine(routine);
        }
      }
    } else if (openEdit) {
      dispatch(evaluatorActions.clearEdit(routine.lineupId));
      if (setEvalRoutine) {
        setEvalRoutine(null);
      }
    } else {
      createInquiry(apolloClient, {
        routineId: routine.id,
        sessionId: routine.sessionId,
        status: InquiryStatus.OPEN,
      }).catch(handleCreateInquiryErrors);
    }
  };

  const isHiE = (index, scores) => {
    const val = scores[index];
    if (Math.max(...scores) === Math.min(...scores)) {
      return index === scores.length - 1;
    }
    return val === Math.max(...scores) && index === scores.indexOf(val);
  };

  const isLoE = (index, scores) => {
    const val = scores[index];
    if (Math.max(...scores) === Math.min(...scores)) {
      return index === 0;
    }
    return val === Math.min(...scores) && index === scores.indexOf(val);
  };

  const handleEditScore = () => {
    dispatch(evaluatorActions.openEdit(routine.lineupId, routine));
    if (setEvalRoutine) {
      setEvalRoutine(routine);
    }
  };

  const editScoreButton = () => {
    return [ADMIN, JUDGE].includes(role) && !(openInquiry || openEdit) ? (
      <Button
        variant="light"
        className="scoreEdit"
        onClick={handleEditScore}
        disabled={openInquiry || openEdit}
      >
        {editIcon}&nbsp;Edit
      </Button>
    ) : null;
  };

  const inquiryOrEditIndicator = () => {
    if (openEdit) {
      return <EditIndicator openEdit={openEdit} />;
    } else {
      return (
        routine.inquiries.items.filter(
          (i) => i.status !== InquiryStatus.CANCELED
        ).length !== 0 && <InquiryIndicator openInquiry={openInquiry} />
      );
    }
  };

  const popover = (
    <Popover id="popover-scorecard">
      <Popover.Title as="h3">
        <Row style={{ margin: '0', minHeight: '40px' }}>
          <Col xs={6} className="vCenter score">
            Score Card:
          </Col>
          <Col xs={3} className="vCenter score">
            {editScoreButton()}
          </Col>
          <Col xs={3} className="vCenter">
            {header()}
          </Col>
        </Row>
        <Row style={{ margin: '0' }}>
          <Col xs={12} className="vCenter">
            <span style={{ fontStyle: 'italic', fontWeight: '400' }}>
              {routine?.athlete?.name}
            </span>
          </Col>
        </Row>
      </Popover.Title>
      <Popover.Content>
        {scoreDType &&
          evalConfig.dPanel.map(({ type, name }, i) => {
            return (
              <Row key={`${routine.id}_${type}_${i}`} style={{ margin: '0' }}>
                <Col xs={6} className="vCenter" style={{ fontWeight: '600' }}>
                  {name}:
                </Col>
                <Col xs={3} className="vCenter" style={{ fontWeight: '600' }}>
                  {convertMillipointsToDisplay(scoresD[i], dScorePrecision)}
                </Col>
                <Col xs={3} className="vCenter green"></Col>
              </Row>
            );
          })}
        {isJudging && (
          <>
            <Row style={{ margin: '0' }}>
              <Col xs={6} className="vCenter" style={{ fontWeight: '600' }}>
                Judging:
              </Col>
              <Col xs={3} className="vCenter" style={{ fontWeight: '600' }}>
                {scoreJAverage || scoreJAverage === 0
                  ? convertMillipointsToDisplay(scoreJAverage, avgPrecision)
                  : '--'}
              </Col>
              <Col
                xs={3}
                className="vCenter"
                style={{ textAlign: 'center', fontStyle: 'italic' }}
              >
                average
              </Col>
            </Row>
            {evalConfig.jPanel.map(({ type }, i) => {
              const svPanel = evalConfig?.svPanel?.[i];

              return (
                <Row
                  key={`${routine.id}_${type}_${i}`}
                  style={{ margin: '0', padding: '0' }}
                >
                  {svPanel ? (
                    <>
                      <Col
                        xs={2}
                        style={{
                          fontVariantNumeric: 'tabular-nums',
                          paddingRight: '0',
                          whiteSpace: 'nowrap',
                          textAlign: 'right',
                        }}
                      >
                        <span style={{ fontStyle: 'italic' }}>
                          {svPanel.name}:&nbsp;
                        </span>
                      </Col>
                      <Col className="vCenter" xs={2}>
                        <span style={{ float: 'right', textAlign: 'right' }}>
                          {scoresSV[i] || scoresSV[i] === 0
                            ? convertMillipointsToDisplay(
                                scoresSV[i],
                                svPrecision
                              )
                            : '--'}
                        </span>
                      </Col>
                    </>
                  ) : null}
                  <Col
                    xs={2}
                    style={{
                      fontVariantNumeric: 'tabular-nums',
                      whiteSpace: 'nowrap',
                      textAlign: 'right',
                      paddingLeft: '5px',
                      paddingRight: '0',
                    }}
                  >
                    <span style={{ fontStyle: 'italic' }}>{type}:&nbsp;</span>
                  </Col>
                  <Col className="vCenter" xs={2}>
                    <span
                      style={{
                        float: 'right',
                        paddingRight: '0',
                        textAlign: 'right',
                      }}
                    >
                      {scoresJ[i] || scoresJ[i] === 0
                        ? convertMillipointsToDisplay(scoresJ[i], jPrecision)
                        : '--'}
                    </span>
                  </Col>
                  {svPanel ? null : <Col xs={4} />}
                  {i === parseInt(evalConfig.jPanel.length / 2) ? (
                    <Col
                      xs={4}
                      className="vCenter scoreDelta"
                      style={{
                        textAlign: 'center',
                        fontStyle: 'italic',
                        marginTop: '-1.8rem',
                      }}
                    >
                      {scoreJDelta && scoreJDelta !== 0
                        ? 'Δ ' +
                          (scoreJDelta || scoreJDelta === 0
                            ? convertMillipointsToDisplay(
                                scoreJDelta,
                                jPrecision
                              )
                            : '--')
                        : null}
                    </Col>
                  ) : (
                    <Col xs={4} />
                  )}
                </Row>
              );
            })}
          </>
        )}
        {isExecution && (
          <>
            <Row style={{ margin: '0' }}>
              <Col xs={6} className="vCenter" style={{ fontWeight: '600' }}>
                Execution:
              </Col>
              <Col xs={3} className="vCenter" style={{ fontWeight: '600' }}>
                {scoreEAverage || scoreEAverage === 0
                  ? convertMillipointsToDisplay(scoreEAverage, scorePrecision)
                  : '--'}
              </Col>
              <Col
                xs={3}
                className="vCenter"
                style={{ textAlign: 'center', fontStyle: 'italic' }}
              >
                average
              </Col>
            </Row>
            {evalConfig.ePanel.map(({ type }, i) => {
              return (
                <Row
                  key={`${routine.id}_${type}_${i}`}
                  style={{ margin: '0', padding: '0' }}
                >
                  <Col
                    xs={2}
                    style={{ fontStyle: 'italic', padding: '0 0 0 15px' }}
                  >
                    {type}:
                  </Col>
                  <Col
                    xs={1}
                    className="vCenter"
                    style={{
                      whiteSpace: 'nowrap',
                      textDecoration:
                        scoresE.length >= 4 &&
                        (isHiE(i, scoresE) || isLoE(i, scoresE))
                          ? 'line-through'
                          : 'none',
                    }}
                  >
                    {scoresObj[type] || scoresObj[type] === 0
                      ? convertMillipointsToDisplay(scoresObj[type], ePrecision)
                      : '--'}
                  </Col>
                  <Col
                    xs={1}
                    style={{
                      whiteSpace: 'nowrap',
                      textDecoration:
                        scoresE.length >= 4 &&
                        (isHiE(i, scoresE) || isLoE(i, scoresE))
                          ? 'line-through'
                          : 'none',
                    }}
                  >
                    (
                    {scoresE[i] || scoresE[i] === 0
                      ? convertMillipointsToDisplay(scoresE[i], ePrecision)
                      : '--'}
                    )
                  </Col>
                  <Col xs={5} />
                  {i === parseInt(evalConfig.ePanel.length / 2) ? (
                    <Col
                      xs={2}
                      className="vCenter scoreDelta"
                      style={{
                        textAlign: 'center',
                        fontStyle: 'italic',
                        marginTop: '-0.8rem',
                      }}
                    >
                      {scoreEDelta !== 0
                        ? 'Δ ' +
                          (scoreEDelta || scoreEDelta === 0
                            ? convertMillipointsToDisplay(
                                scoreEDelta,
                                ePrecision
                              )
                            : '--')
                        : null}
                    </Col>
                  ) : (
                    <Col xs={3} />
                  )}
                </Row>
              );
            })}
          </>
        )}
        {isNeutralDeduction && (
          <Row style={{ margin: '0' }}>
            <Col xs={6} className="vCenter">
              Neutral Deduction:
            </Col>
            <Col xs={3} className="vCenter">
              {scoreND ? convertMillipointsToDisplay(scoreND, 1) : '--'}
            </Col>
            <Col xs={3} className="vCenter red">
              {!!scoreND && caretDownIcon}
            </Col>
          </Row>
        )}
        {isStickBonus && (
          <Row style={{ margin: '0' }}>
            <Col xs={6} className="vCenter">
              Stick Bonus:
            </Col>
            <Col xs={3} className="vCenter">
              {scoreSB ? convertMillipointsToDisplay(scoreSB, 1) : '--'}
            </Col>
            <Col xs={3} className="vCenter green">
              {!!scoreSB && caretUpIcon}
            </Col>
          </Row>
        )}
        <hr style={{ width: '100%' }} />
        <Row style={{ margin: '0' }}>
          <Col xs={6} className="vCenter score">
            Final Score:
          </Col>
          <Col xs={3} className="vCenter score">
            {routine.score || routine.score === 0
              ? convertMillipointsToDisplay(routine.score, scorePrecision)
              : '--'}
          </Col>
          <Col xs={3} className="vCenter" style={{ textAlign: 'center' }}>
            {(live || openEdit) && [ADMIN, JUDGE].includes(role)
              ? scoreCardButton()
              : null}
          </Col>
        </Row>
      </Popover.Content>
    </Popover>
  );

  return (
    <OverlayTrigger
      trigger="click"
      placement={scoreCardPlacement()}
      key="top"
      overlay={popover}
      rootClose
    >
      <Button
        className="scorecard"
        variant={openInquiry ? 'outline-warning' : 'outline'}
      >
        {!isJudging &&
          dScoreIndicator(convertMillipointsToDisplay(scoreD, dScorePrecision))}
        {routine.score || routine.score === 0
          ? convertMillipointsToDisplay(routine.score, scorePrecision)
          : '--'}
        {inquiryOrEditIndicator()}
        {!!scoreSB && stickIndicator}
        {!!scoreND && penaltyIndicator}
      </Button>
    </OverlayTrigger>
  );
}

export default ScoreCard;
