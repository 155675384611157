import ScoringPanel, { DisabledScoringPanel } from './scoringpanel';
import { useSelector } from 'react-redux';
import { RoutineStatus } from '../../models';
import { useJudgingEnabled } from './hooks';
import { dualTeamBRotation } from '../../utilities/session';
import { useRoutines } from '../../utilities/clips';

export default function Evaluator({ team, sessionId, judgeType }) {
  const alternating = useSelector((state) => state.session.alternating);
  const rotation = useSelector((state) => state.producer.round);
  const round =
    !team.home && alternating ? dualTeamBRotation(rotation) : rotation;
  const judgingEnabled = useJudgingEnabled();
  const routines = useRoutines(team.lineupId, round);

  const inquiry = useSelector(
    (state) => state.evaluator.activeInquiryByLineupId[team.lineupId]
  );

  const edit = useSelector(
    (state) => state.evaluator.activeEditByLineupId[team.lineupId]
  );

  const evalRoutine = routines.find(
    (routine) => routine?.status === RoutineStatus.ON_EVAL
  );

  const airRoutine = routines.find(
    (routine) => routine?.status === RoutineStatus.ON_AIR
  );

  const standby =
    !evalRoutine &&
    !airRoutine &&
    routines.find(
      (routine) => !routine || routine.status === RoutineStatus.STANDBY
    );

  if (judgingEnabled && (inquiry || edit || evalRoutine)) {
    return (
      <ScoringPanel
        team={team}
        sessionId={sessionId}
        routineId={inquiry?.routineId || edit?.id || evalRoutine?.id}
        judgeType={judgeType}
      />
    );
  } else {
    return (
      <DisabledScoringPanel
        onAir={airRoutine}
        onEval={evalRoutine}
        onStandby={standby}
        routineId={evalRoutine ? evalRoutine.id : 'FAKE-ROUTINE-ID'}
        judgeType={judgeType}
      />
    );
  }
}
