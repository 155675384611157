// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const JudgeType = {
  "FIG_MENS": "FIG_MENS",
  "FIG_WOMENS": "FIG_WOMENS",
  "NCAA_MENS": "NCAA_MENS",
  "NCAA_WOMENS": "NCAA_WOMENS",
  "JO_MENS": "JO_MENS",
  "JO_WOMENS": "JO_WOMENS"
};

const UserStatus = {
  "CREATED": "CREATED",
  "VERIFIED": "VERIFIED",
  "WAITLIST": "WAITLIST",
  "ACTIVE": "ACTIVE",
  "DELETED": "DELETED"
};

const SessionHostType = {
  "NONE": "NONE",
  "TEAM": "TEAM",
  "USER": "USER"
};

const SessionAccess = {
  "PRIVATE": "PRIVATE",
  "PUBLIC": "PUBLIC",
  "KEY": "KEY",
  "GUESTLIST": "GUESTLIST"
};

const SessionStatus = {
  "CREATED": "CREATED",
  "PREGAME": "PREGAME",
  "LIVE": "LIVE",
  "POSTGAME": "POSTGAME",
  "ARCHIVED": "ARCHIVED",
  "CANCELED": "CANCELED",
  "DELETED": "DELETED"
};

const SessionType = {
  "SOLO": "SOLO",
  "DUAL": "DUAL",
  "MULTI": "MULTI"
};

const SessionTeamScoreType = {
  "TOP5_OF6": "TOP5OF6",
  "TOP6_HL": "TOP6HL",
  "TOP6": "TOP6",
  "TOP5": "TOP5",
  "TOP4": "TOP4",
  "TOP3": "TOP3",
  "BEST5": "BEST5",
  "BEST4": "BEST4",
  "BEST3": "BEST3",
  "ALL": "ALL",
  "NONE": "NONE"
};

const JudgePanelType = {
  "NCAAM": "NCAAM",
  "NCAAM4": "NCAAM4",
  "NCAAW": "NCAAW",
  "NCAAW2": "NCAAW2",
  "NCAAW2_SV": "NCAAW2SV",
  "NCAAW4": "NCAAW4",
  "NCAAW6": "NCAAW6",
  "FIG4": "FIG4",
  "FIG5": "FIG5",
  "FIG6": "FIG6",
  "FAN": "FAN",
  "SINGLE": "SINGLE",
  "J4": "J4",
  "OPEN": "OPEN"
};

const RotationStatus = {
  "CREATED": "CREATED",
  "ACTIVE": "ACTIVE",
  "COMPLETE": "COMPLETE"
};

const StageStatus = {
  "CREATED": "CREATED",
  "ACTIVE": "ACTIVE",
  "COMPLETE": "COMPLETE"
};

const AthleteContextLevel = {
  "JO": "JO",
  "ELITE": "ELITE",
  "COLLEGE": "COLLEGE"
};

const AthleteContextDivision = {
  "C_FR": "C_FR",
  "C_RFR": "C_RFR",
  "C_SO": "C_SO",
  "C_RSO": "C_RSO",
  "C_JR": "C_JR",
  "C_RJR": "C_RJR",
  "C_SR": "C_SR",
  "C_RSR": "C_RSR",
  "JO_10": "JO_10",
  "JO_9": "JO_9",
  "JO_8": "JO_8",
  "JO_7": "JO_7",
  "JO_6": "JO_6",
  "JO_5": "JO_5",
  "JO_4": "JO_4",
  "JO_3": "JO_3",
  "JO_2": "JO_2",
  "JO_1": "JO_1"
};

const AthleteStatus = {
  "ACTIVE": "ACTIVE",
  "INACTIVE": "INACTIVE",
  "DELETED": "DELETED"
};

const GenderType = {
  "MALE": "MALE",
  "FEMALE": "FEMALE",
  "COED": "COED"
};

const AffiliationStatus = {
  "ACTIVE": "ACTIVE",
  "INACTIVE": "INACTIVE"
};

const TeamStatus = {
  "ACTIVE": "ACTIVE",
  "INACTIVE": "INACTIVE",
  "DELETED": "DELETED"
};

const LeagueStatus = {
  "ACTIVE": "ACTIVE",
  "INACTIVE": "INACTIVE",
  "DELETED": "DELETED"
};

const LeagueType = {
  "FIG": "FIG",
  "JO": "JO",
  "NCAA": "NCAA",
  "CLUB": "CLUB",
  "OTHER": "OTHER"
};

const ScoreType = {
  "D": "D",
  "D1": "D1",
  "D2": "D2",
  "E": "E",
  "E1": "E1",
  "E2": "E2",
  "E3": "E3",
  "E4": "E4",
  "E5": "E5",
  "E6": "E6",
  "F": "F",
  "SB": "SB",
  "ND": "ND",
  "SV": "SV",
  "SV1": "SV1",
  "SV2": "SV2",
  "SV3": "SV3",
  "SV4": "SV4",
  "SV5": "SV5",
  "SV6": "SV6",
  "J": "J",
  "J1": "J1",
  "J2": "J2",
  "J3": "J3",
  "J4": "J4",
  "J5": "J5",
  "J6": "J6",
  "OPEN": "OPEN"
};

const ClassYear = {
  "RFR": "RFR",
  "RSO": "RSO",
  "RJR": "RJR",
  "RSR": "RSR",
  "FR": "FR",
  "SO": "SO",
  "JR": "JR",
  "SR": "SR",
  "ELT": "ELT",
  "PFR": "PFR",
  "RET": "RET"
};

const PenaltyType = {
  "NEUTRAL": "NEUTRAL",
  "YELLOWCARD": "YELLOWCARD",
  "REDCARD": "REDCARD"
};

const PenaltyStatus = {
  "ISSUED": "ISSUED",
  "OVERRULED": "OVERRULED",
  "CANCELED": "CANCELED"
};

const ScoreValueType = {
  "MILLIPOINT": "MILLIPOINT",
  "INTEGER": "INTEGER"
};

const StreamType = {
  "CAMERA": "CAMERA",
  "PRODUCTION": "PRODUCTION"
};

const StreamStatus = {
  "OFF": "OFF",
  "STARTING": "STARTING",
  "STARTED": "STARTED",
  "STOPPING": "STOPPING",
  "STOPPED": "STOPPED",
  "RESETTING": "RESETTING",
  "DELETING": "DELETING",
  "DELETED": "DELETED",
  "CREATING": "CREATING",
  "ERROR": "ERROR",
  "ARCHIVED": "ARCHIVED"
};

const ClipType = {
  "LIVE": "LIVE",
  "VOD": "VOD",
  "FILE": "FILE",
  "LINK": "LINK",
  "YOUTUBE": "YOUTUBE",
  "OTHER": "OTHER"
};

const ClipStatus = {
  "ACTIVE": "ACTIVE",
  "INACTIVE": "INACTIVE",
  "ARCHIVED": "ARCHIVED",
  "DELETED": "DELETED",
  "PROCESSING": "PROCESSING",
  "UPLOADING": "UPLOADING"
};

const RoutineStatus = {
  "ON_AIR": "ON_AIR",
  "ON_EVAL": "ON_EVAL",
  "COMPLETE": "COMPLETE",
  "STANDBY": "STANDBY",
  "DELETED": "DELETED"
};

const InquiryStatus = {
  "OPEN": "OPEN",
  "CLOSED": "CLOSED",
  "CANCELED": "CANCELED"
};

const GymApparatus = {
  "FX": "FX",
  "PH": "PH",
  "SR": "SR",
  "VT": "VT",
  "PB": "PB",
  "HB": "HB",
  "UB": "UB",
  "BB": "BB",
  "WU": "WU",
  "WUFX": "WUFX",
  "WUPH": "WUPH",
  "WUSR": "WUSR",
  "WUVT": "WUVT",
  "WUPB": "WUPB",
  "WUHB": "WUHB",
  "WUUB": "WUUB",
  "WUBB": "WUBB",
  "BYE1": "BYE1",
  "BYE2": "BYE2",
  "BYE3": "BYE3",
  "BYE4": "BYE4",
  "BYE5": "BYE5",
  "BYE6": "BYE6",
  "BYE7": "BYE7"
};

const { User, Session, Rotation, Stage, StageStream, StageJudge, Squad, LineupMember, AthleteContext, RosterLink, Athlete, TeamCoach, Judge, SessionJudgeAssignment, SessionJudge, Team, SessionTeam, League, LeagueTeam, Score, Penalty, Roster, RosterAthleteContext, SessionRoster, Lineup, Stream, Overlay, Clip, Routine, Inquiry } = initSchema(schema);

export {
  User,
  Session,
  Rotation,
  Stage,
  StageStream,
  StageJudge,
  Squad,
  LineupMember,
  AthleteContext,
  RosterLink,
  Athlete,
  TeamCoach,
  Judge,
  SessionJudgeAssignment,
  SessionJudge,
  Team,
  SessionTeam,
  League,
  LeagueTeam,
  Score,
  Penalty,
  Roster,
  RosterAthleteContext,
  SessionRoster,
  Lineup,
  Stream,
  Overlay,
  Clip,
  Routine,
  Inquiry,
  JudgeType,
  UserStatus,
  SessionHostType,
  SessionAccess,
  SessionStatus,
  SessionType,
  SessionTeamScoreType,
  JudgePanelType,
  RotationStatus,
  StageStatus,
  AthleteContextLevel,
  AthleteContextDivision,
  AthleteStatus,
  GenderType,
  AffiliationStatus,
  TeamStatus,
  LeagueStatus,
  LeagueType,
  ScoreType,
  ClassYear,
  PenaltyType,
  PenaltyStatus,
  ScoreValueType,
  StreamType,
  StreamStatus,
  ClipType,
  ClipStatus,
  RoutineStatus,
  InquiryStatus,
  GymApparatus
};