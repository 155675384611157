import { SessionSubscriptionManager } from './hooks';
import { useQuery } from '@apollo/client';
import GetRoutines from '../../apollo/queries/GetRoutines.graphql';

function LiveClipsJSON({ sessionId }) {
  const { data: routineData } = useQuery(GetRoutines, {
    variables: { sessionId },
  });

  const routines =
    (routineData && [...routineData?.SessionRoutines?.items]) || [];

  const filteredRoutines = routines
    .filter((r) => {
      return !!r.completedAt && !!r.startedAt;
    })
    .sort((a, b) => new Date(a.completedAt) - new Date(b.completedAt));

  console.log(routines);

  const liveClips = filteredRoutines.map((r) => {
    return {
      id: r.id,
      completedAt: r.completedAt,
      startedAt: r.startedAt,
      status: r.status,
    };
  });

  return (
    <>
      {routineData && <pre>{JSON.stringify(liveClips, null, 2)}</pre>}
      <SessionSubscriptionManager sessionId={sessionId} />
    </>
  );
}

export default LiveClipsJSON;
