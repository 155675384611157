import { forwardRef, useState, useEffect } from 'react';
import { Button, Tooltip, OverlayTrigger, Row } from 'react-bootstrap';
import { exportIcon } from '../helpers/icons';
import { exportAllDataApollo, copyToClipboard } from '../../utilities/export';
import { useQuery, NetworkStatus } from '@apollo/client';
import { useDispatch } from 'react-redux';
import { alertActions } from '../../redux/_actions';
import { useSelector } from 'react-redux';
import { sessionRoleType } from '../../redux/_constants';

import GetFullSession from '../../apollo/queries/GetFullSession.graphql';

const defaultContent = 'Copy Data';
const clickedContent = 'Copied Data to Clipboard';

const ToolTip = forwardRef(({ popper, children, show: _, ...props }, ref) => {
  useEffect(() => {
    popper.scheduleUpdate();
  }, [children, popper]);

  return (
    <Tooltip ref={ref} {...props}>
      {children}
    </Tooltip>
  );
});

export function ExportData({ sessionId }) {
  const [content, setContent] = useState(defaultContent);
  const [requestedCopy, setRequestedCopy] = useState(false);
  const { data, refetch, networkStatus } = useQuery(GetFullSession, {
    variables: { id: sessionId },
  });
  let timerId;
  const dispatch = useDispatch();
  const isAdmin = useSelector(
    (state) => state.session.role === sessionRoleType.ADMIN
  );

  useEffect(() => {
    if (data && requestedCopy && networkStatus !== NetworkStatus.refetch) {
      setRequestedCopy(false);
      dispatch(alertActions.notification(clickedContent));
      setContent(clickedContent);
      setTimeout(() => {
        setContent(defaultContent);
      }, 2000);
    }
  }, [requestedCopy, networkStatus, data, dispatch]);

  const handleClick = () => {
    refetch();
    setRequestedCopy(true);
    copyToClipboard(
      exportAllDataApollo(data.getSession, isAdmin)
        .map((row) => row.join('\t'))
        .join('\n')
    );
  };

  const handleToggle = () => {
    if (content === clickedContent) {
      clearTimeout(timerId);
      timerId = setTimeout(() => {
        setContent(defaultContent);
        dispatch(alertActions.clear());
      }, 1000);
    }
  };

  return (
    <div className="exportData vCenter">
      <Row>
        <OverlayTrigger
          placement="top"
          delay={{ show: 700, hide: 0 }}
          overlay={<ToolTip id="popover-contained">{content}</ToolTip>}
          onToggle={handleToggle}
        >
          <Button variant="light" onClick={handleClick} className="vCenter">
            {exportIcon}
          </Button>
        </OverlayTrigger>
      </Row>
    </div>
  );
}
