import { eventConstants, initialEventState } from '../_constants';

export function event(state = initialEventState, action) {
  switch (action.type) {
    case eventConstants.CREATE_ROUTINE_SUCCESS:
      return {
        routine: action.event,
      };
    default:
      return state;
  }
}
