import React from 'react';
import { DELTA_SENSITIVITY } from '../../utilities/constants';

function DeltaIndicator({
  scoreA,
  scoreB,
  colorA,
  colorB,
  sensitivity = 'DEFAULT',
}) {
  let styleLeft = null;
  let styleRight = null;
  const sensitivityFactor =
    DELTA_SENSITIVITY[sensitivity] ?? DELTA_SENSITIVITY['DEFAULT'];
  let offset = 50 - ((scoreA - scoreB) / 1000) * sensitivityFactor;

  // 5 offset gives blowouts at least some visual indication of color
  if (offset < 5) {
    offset = 5;
  }
  if (offset > 95) {
    offset = 95;
  }

  styleLeft = { background: colorA, left: '0', width: 100 - offset + '%' };
  styleRight = { background: colorB, right: '0', width: offset + '%' };

  return (
    <div className="deltaIndicator vCenter">
      <div className="deltaBarLeft" style={styleLeft} />
      <div className="deltaBarRight" style={styleRight} />
    </div>
  );
}

export default DeltaIndicator;
