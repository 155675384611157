import React, { useImperativeHandle } from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { adminActions, alertActions } from '../../redux/_actions';
import { streamRequestType } from '../../redux/_constants';
import { StreamType } from '../../models';
import * as Yup from 'yup';
import { dateTime } from '../../utilities/conversions';

export const SessionStreams = React.forwardRef((props, ref) => {
  const { sessionId, isEditing, isSaving, reset, setIsLoading } = props;
  const { sessions, streams } = useSelector((state) => state.admin);
  const { CHECKVOD } = streamRequestType;
  const session = sessions.byId[sessionId];
  const sessionStreams = session.streams.items
    .map((id) => streams.byId[id])
    .sort((a, b) => a.index - b.index);
  const dispatch = useDispatch();

  useImperativeHandle(ref, () => ({
    refresh() {
      sessionStreams.forEach((stream) => {
        dispatch(adminActions.checkStreamPostgame(stream.id, CHECKVOD));
      });
    },
    submit() {
      handleSubmit();
    },
  }));

  const emptyList = () => {
    return (
      <Row className="vCenter emptyList">
        <span>No streams created.</span>
      </Row>
    );
  };

  const validationSchema = Yup.object().shape({
    indices: Yup.array().of(Yup.number()),
    outputURLs: Yup.array().of(Yup.string()),
    vodURLs: Yup.array().of(Yup.string()),
    recordingURLs: Yup.array().of(Yup.string()),
    recordingIds: Yup.array().of(Yup.string()),
    types: Yup.array().of(Yup.string().nullable()),
    titles: Yup.array().of(Yup.string()),
  });

  const initialValues = {
    indices: sessionStreams.map((stream) => stream.index ?? ''),
    outputURLs: sessionStreams.map((stream) => stream.outputURL ?? ''),
    vodURLs: sessionStreams.map((stream) => stream.vodURL ?? ''),
    recordingURLs: sessionStreams.map((stream) => stream.recordingURL ?? ''),
    recordingIds: sessionStreams.map((stream) => stream.recordingId ?? ''),
    types: sessionStreams.map((stream) => stream.type ?? null),
    titles: sessionStreams.map((stream) => stream.title ?? ''),
  };

  const { setFieldValue, handleSubmit, values, errors } = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit(values) {
      setIsLoading(true);
      dispatch(alertActions.clear());

      const payload = sessionStreams.map((stream, i) => {
        return initialValues.outputURLs[i] !== values.outputURLs[i] ||
          initialValues.indices[i] !== values.indices[i] ||
          initialValues.vodURLs[i] !== values.vodURLs[i] ||
          initialValues.recordingURLs[i] !== values.recordingURLs[i] ||
          initialValues.recordingIds[i] !== values.recordingIds[i] ||
          initialValues.types[i] !== values.types[i] ||
          initialValues.titles[i] !== values.titles[i]
          ? {
              id: stream.id,
              index: stream.index,
              _version: stream._version,
              vodURL: values.vodURLs[i],
              recordingURL: values.recordingURLs[i],
              outputURL: values.outputURLs[i],
              recordingId: values.recordingIds[i],
              type: values.types[i],
              title: values.titles[i],
            }
          : false;
      });

      if (payload.every((el) => el === false)) {
        dispatch(alertActions.success('No changes made.'));
        reset();
        return;
      }

      console.log(payload);

      dispatch(adminActions.updateStreamURLs(payload));
    },
  });

  if (errors && errors.size === 0) {
    console.log(errors);
  }

  //console.log(values);

  const setupBody = () => {
    if (sessionStreams.length === 0) {
      // no such thing yet
      return emptyList();
    } else {
      return (
        <ul>
          {sessionStreams.map((stream, i) => {
            return (
              <li key={stream.id}>
                <Row>
                  <Col className="title" sm={2}>
                    Index:
                  </Col>
                  <Col sm={3}>
                    <Form.Control
                      plaintext
                      name="index"
                      type="number"
                      step="1"
                      min="0"
                      autoComplete="off"
                      disabled={isSaving || !isEditing}
                      value={values.indices[i] ?? ''}
                      onChange={(e) =>
                        setFieldValue(
                          'indices',
                          Object.assign([...values.indices], {
                            [i]: e.target.value,
                          })
                        )
                      }
                    />
                  </Col>
                  <Col className="title" sm={2}>
                    Id:
                  </Col>
                  <Col sm={5}>{stream.streamId}</Col>
                </Row>
                <Row>
                  <Col className="title" sm={2}>
                    Type:
                  </Col>
                  <Col sm={3}>
                    <Form.Control
                      as="select"
                      label="Required"
                      id="type"
                      disabled={isSaving || !isEditing}
                      value={values.types[i] || 'Select...'}
                      onChange={(e) =>
                        setFieldValue(
                          'types',
                          Object.assign([...values.types], {
                            [i]: e.target.value,
                          })
                        )
                      }
                    >
                      <option
                        value="Select..."
                        disabled={values.types[i]}
                        hidden
                      >
                        Select...
                      </option>
                      {Object.values(StreamType).map((type, i) => {
                        return <option key={i}>{type}</option>;
                      })}
                    </Form.Control>
                  </Col>
                  <Col className="title" sm={2}>
                    Updated:
                  </Col>
                  <Col sm={5}>{dateTime(stream.updatedAt, true)}</Col>
                </Row>
                <Row>
                  <Col className="title" sm={2}>
                    Status:
                  </Col>
                  <Col sm={3}>{stream.status}</Col>
                </Row>
                <Row>
                  <Col className="title" sm={2}>
                    Ingest:
                  </Col>
                  <Col>{stream.ingestURL}</Col>
                </Row>
                <Row>
                  <Col className="title" sm={2}>
                    Output:
                  </Col>
                  <Col>
                    <Form.Control
                      plaintext
                      name="outputURL"
                      type="text"
                      autoComplete="off"
                      disabled={isSaving || !isEditing}
                      value={values.outputURLs[i] ?? ''}
                      onChange={(e) =>
                        setFieldValue(
                          'outputURLs',
                          Object.assign([...values.outputURLs], {
                            [i]: e.target.value,
                          })
                        )
                      }
                      placeholder="Edit to add URL..."
                    />
                  </Col>
                </Row>
                <Row>
                  <Col className="title" sm={2}>
                    VOD:
                  </Col>
                  <Col>
                    <Form.Control
                      plaintext
                      name="vodURL"
                      type="text"
                      autoComplete="off"
                      disabled={isSaving || !isEditing}
                      value={values.vodURLs[i] ?? ''}
                      onChange={(e) =>
                        setFieldValue(
                          'vodURLs',
                          Object.assign([...values.vodURLs], {
                            [i]: e.target.value,
                          })
                        )
                      }
                      placeholder="Edit to add URL..."
                    />
                  </Col>
                </Row>
                <Row>
                  <Col className="title" sm={2}>
                    Recording:
                  </Col>
                  <Col>
                    <Form.Control
                      plaintext
                      name="recordingURL"
                      type="text"
                      autoComplete="off"
                      disabled={isSaving || !isEditing}
                      value={values.recordingURLs[i] ?? ''}
                      onChange={(e) =>
                        setFieldValue(
                          'recordingURLs',
                          Object.assign([...values.recordingURLs], {
                            [i]: e.target.value,
                          })
                        )
                      }
                      placeholder="Edit to add URL..."
                    />
                  </Col>
                </Row>
                <Row>
                  <Col className="title" sm={2}>
                    Recording Id:
                  </Col>
                  <Col>
                    <Form.Control
                      plaintext
                      name="recordingId"
                      type="text"
                      autoComplete="off"
                      disabled={isSaving || !isEditing}
                      value={values.recordingIds[i] ?? ''}
                      onChange={(e) =>
                        setFieldValue(
                          'recordingIds',
                          Object.assign([...values.recordingIds], {
                            [i]: e.target.value,
                          })
                        )
                      }
                      placeholder="Edit to add recording id..."
                    />
                  </Col>
                </Row>
                <Row>
                  <Col className="title" sm={2}>
                    Title:
                  </Col>
                  <Col>
                    <Form.Control
                      plaintext
                      name="title"
                      type="text"
                      autoComplete="off"
                      disabled={isSaving || !isEditing}
                      value={values.titles[i] ?? ''}
                      onChange={(e) =>
                        setFieldValue(
                          'titles',
                          Object.assign([...values.titles], {
                            [i]: e.target.value,
                          })
                        )
                      }
                      placeholder="Edit to add stream title..."
                    />
                  </Col>
                </Row>
              </li>
            );
          })}
        </ul>
      );
    }
  };

  return (
    <Form onSubmit={null} className="sessionStreams">
      {setupBody()}
    </Form>
  );
});
