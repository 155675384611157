export const sessionConstants = {
  CREATE_REQUEST: 'CREATE_SESSION_REQUEST',
  CREATE_SUCCESS: 'CREATE_SESSION_SUCCESS',
  CREATE_FAILURE: 'CREATE_SESSION_FAILURE',
  UPDATE_REQUEST: 'UPDATE_SESSION_REQUEST',
  UPDATE_SUCCESS: 'UPDATE_SESSION_SUCCESS',
  UPDATE_FAILURE: 'UPDATE_SESSION_FAILURE',
  JOIN_REQUEST: 'JOIN_SESSION_REQUEST',
  JOIN_SUCCESS: 'JOIN_SESSION_SUCCESS',
  JOIN_FAILURE: 'JOIN_SESSION_FAILURE',
  LOAD_REQUEST: 'LOAD_SESSION_REQUEST',
  LOAD_SUCCESS: 'LOAD_SESSION_SUCCESS',
  LOAD_FAILURE: 'LOAD_SESSION_FAILURE',
  UNLOAD_REQUEST: 'UNLOAD_SESSION_REQUEST',
  START_STREAM_REQUEST: 'START_STREAM_SESSION_REQUEST',
  START_STREAM_SUCCESS: 'START_STREAM_SESSION_SUCCESS',
  START_STREAM_FAILURE: 'START_STREAM_SESSION_FAILURE',
  CREATE_STREAM_REQUEST: 'CREATE_STREAM_SESSION_REQUEST',
  CREATE_STREAM_SUCCESS: 'CREATE_STREAM_SESSION_SUCCESS',
  CREATE_STREAM_FAILURE: 'CREATE_STREAM_SESSION_FAILURE',
  CREATE_STREAM_NOCAM_REQUEST: 'CREATE_STREAM_NOCAM_SESSION_REQUEST',
  CREATE_STREAM_NOCAM_SUCCESS: 'CREATE_STREAM_NOCAM_SESSION_SUCCESS',
  CREATE_STREAM_NOCAM_FAILURE: 'CREATE_STREAM_NOCAM_SESSION_FAILURE',
  DELETE_STREAM_REQUEST: 'DELETE_STREAM_SESSION_REQUEST',
  DELETE_STREAM_SUCCESS: 'DELETE_STREAM_SESSION_SUCCESS',
  DELETE_STREAM_FAILURE: 'DELETE_STREAM_SESSION_FAILURE',
  RESET_STREAM_REQUEST: 'RESET_STREAM_SESSION_REQUEST',
  RESET_STREAM_SUCCESS: 'RESET_STREAM_SESSION_SUCCESS',
  RESET_STREAM_FAILURE: 'RESET_STREAM_SESSION_FAILURE',
  STOP_STREAM_REQUEST: 'STOP_STREAM_SESSION_REQUEST',
  STOP_STREAM_SUCCESS: 'STOP_STREAM_SESSION_SUCCESS',
  STOP_STREAM_FAILURE: 'STOP_STREAM_SESSION_FAILURE',
  CHECK_STREAM_REQUEST: 'CHECK_STREAM_SESSION_REQUEST',
  CHECK_STREAM_SUCCESS: 'CHECK_STREAM_SESSION_SUCCESS',
  CHECK_STREAM_FAILURE: 'CHECK_STREAM_SESSION_FAILURE',
  SUBSCRIBE_REQUEST: 'SUBSCRIBE_SESSION_REQUEST',
  SUBSCRIBE_SUCCESS: 'SUBSCRIBE_SESSION_SUCCESS',
  SUBSCRIBE_FAILURE: 'SUBSCRIBE_SESSION_FAILURE',
  UNSUBSCRIBE_REQUEST: 'UNSUBSCRIBE_SESSION_REQUEST',
  UNSUBSCRIBE_SUCCESS: 'UNSUBSCRIBE_SESSION_SUCCESS',
  UNSUBSCRIBE_FAILURE: 'UNSUBSCRIBE_SESSION_FAILURE',
  SUBSCRIBE_STREAM_REQUEST: 'SUBSCRIBE_STREAM_SESSION_REQUEST',
  SUBSCRIBE_STREAM_SUCCESS: 'SUBSCRIBE_STREAM_SESSION_SUCCESS',
  SUBSCRIBE_STREAM_FAILURE: 'SUBSCRIBE_STREAM_SESSION_FAILURE',
  UNSUBSCRIBE_STREAM_REQUEST: 'UNSUBSCRIBE_STREAM_SESSION_REQUEST',
  UNSUBSCRIBE_STREAM_SUCCESS: 'UNSUBSCRIBE_STREAM_SESSION_SUCCESS',
  UNSUBSCRIBE_STREAM_FAILURE: 'UNSUBSCRIBE_STREAM_SESSION_FAILURE',
  SUBSCRIBE_NEW_STREAM_REQUEST: 'SUBSCRIBE_NEW_STREAM_SESSION_REQUEST',
  SUBSCRIBE_NEW_STREAM_SUCCESS: 'SUBSCRIBE_NEW_STREAM_SESSION_SUCCESS',
  SUBSCRIBE_NEW_STREAM_FAILURE: 'SUBSCRIBE_NEW_STREAM_SESSION_FAILURE',
  UNSUBSCRIBE_NEW_STREAM_REQUEST: 'UNSUBSCRIBE_NEW_STREAM_SESSION_REQUEST',
  UNSUBSCRIBE_NEW_STREAM_SUCCESS: 'UNSUBSCRIBE_NEW_STREAM_SESSION_SUCCESS',
  UNSUBSCRIBE_NEW_STREAM_FAILURE: 'UNSUBSCRIBE_NEW_STREAM_SESSION_FAILURE',
  SYNC_STREAM_STATUS_SUCCESS: 'SYNC_STREAM_STATUS_SESSION_SUCCESS',
  SYNC_NEW_STREAM_SUCCESS: 'SYNC_NEW_STREAM_SESSION_SUCCESS',
  SYNC_NEW_STREAM_BYPASS: 'SYNC_NEW_STREAM_SESSION_BYPASS',
  SUBSCRIBE_ALL_STREAMS_REQUEST: 'SUBSCRIBE_ALL_STREAMS_SESSION_REQUEST',
  SUBSCRIBE_ALL_STREAMS_SUCCESS: 'SUBSCRIBE_ALL_STREAMS_SESSION_SUCCESS',
  SUBSCRIBE_ALL_STREAMS_FAILURE: 'SUBSCRIBE_ALL_STREAMS_SESSION_FAILURE',
  UNSUBSCRIBE_ALL_STREAMS_REQUEST: 'UNSUBSCRIBE_ALL_STREAMS_SESSION_REQUEST',
  UNSUBSCRIBE_ALL_STREAMS_SUCCESS: 'UNSUBSCRIBE_ALL_STREAMS_SESSION_SUCCESS',
  UNSUBSCRIBE_ALL_STREAMS_FAILURE: 'UNSUBSCRIBE_ALL_STREAMS_SESSION_FAILURE',
  GET_TEAMS_REQUEST: 'GET_TEAMS_SESSION_REQUEST',
  GET_TEAMS_SUCCESS: 'GET_TEAMS_SESSION_SUCCESS',
  GET_TEAMS_FAILURE: 'GET_TEAMS_SESSION_FAILURE',
  CREATE_LINEUP_REQUEST: 'CREATE_LINEUP_SESSION_REQUEST',
  CREATE_LINEUP_SUCCESS: 'CREATE_LINEUP_SESSION_SUCCESS',
  CREATE_LINEUP_FAILURE: 'CREATE_LINEUP_SESSION_FAILURE',
  UPDATE_LINEUP_REQUEST: 'UPDATE_LINEUP_SESSION_REQUEST',
  UPDATE_LINEUP_SUCCESS: 'UPDATE_LINEUP_SESSION_SUCCESS',
  UPDATE_LINEUP_FAILURE: 'UPDATE_LINEUP_SESSION_FAILURE',
  SUBSCRIBE_LINEUP_REQUEST: 'SUBSCRIBE_LINEUP_SESSION_REQUEST',
  SUBSCRIBE_LINEUP_SUCCESS: 'SUBSCRIBE_LINEUP_SESSION_SUCCESS',
  SUBSCRIBE_LINEUP_FAILURE: 'SUBSCRIBE_LINEUP_SESSION_FAILURE',
  UNSUBSCRIBE_LINEUP_REQUEST: 'UNSUBSCRIBE_LINEUP_SESSION_REQUEST',
  UNSUBSCRIBE_LINEUP_SUCCESS: 'UNSUBSCRIBE_LINEUP_SESSION_SUCCESS',
  UNSUBSCRIBE_LINEUP_FAILURE: 'UNSUBSCRIBE_LINEUP_SESSION_FAILURE',
  SYNC_LINEUP_SUCCESS: 'SYNC_LINEUP_SESSION_SUCCESS',
  SYNC_SESSION_SUCCESS: 'SYNC_SESSION_SESSION_SUCCESS',
  UPDATE_PRODUCER_REQUEST: 'UPDATE_PRODUCER_SESSION_REQUEST',
  UPDATE_PRODUCER_SUCCESS: 'UPDATE_PRODUCER_SESSION_SUCCESS',
  UPDATE_PRODUCER_FAILURE: 'UPDATE_PRODUCER_SESSION_FAILURE',
  CHANGE_VIEW: 'CHANGE_VIEW_SESSION',
  UPDATE_STATUS_REQUEST: 'UPDATE_STATUS_SESSION_REQUEST',
  UPDATE_STATUS_SUCCESS: 'UPDATE_STATUS_SESSION_SUCCESS',
  UPDATE_STATUS_FAILURE: 'UPDATE_STATUS_SESSION_FAILURE',
  SUBSCRIBE_ALL_LINEUPS_REQUEST: 'SUBSCRIBE_ALL_LINEUPS_SESSION_REQUEST',
  SUBSCRIBE_ALL_LINEUPS_SUCCESS: 'SUBSCRIBE_ALL_LINEUPS_SESSION_SUCCESS',
  SUBSCRIBE_ALL_LINEUPS_FAILURE: 'SUBSCRIBE_ALL_LINEUPS_SESSION_FAILURE',
  UNSUBSCRIBE_ALL_LINEUPS_REQUEST: 'UNSUBSCRIBE_ALL_LINEUPS_SESSION_REQUEST',
  UNSUBSCRIBE_ALL_LINEUPS_SUCCESS: 'UNSUBSCRIBE_ALL_LINEUPS_SESSION_SUCCESS',
  UNSUBSCRIBE_ALL_LINEUPS_FAILURE: 'UNSUBSCRIBE_ALL_LINEUPS_SESSION_FAILURE',
};

export const sessionType = {
  SOLO: 'SOLO',
  DUAL: 'DUAL',
  MULTI: 'MULTI',
};

export const streamStatus = {
  OFF: 'OFF',
  STARTING: 'STARTING',
  STARTED: 'STARTED',
  STOPPING: 'STOPPING',
  STOPPED: 'STOPPED',
  RESETTING: 'RESETTING',
  DELETING: 'DELETING',
  DELETED: 'DELETED',
  CREATING: 'CREATING',
  ERROR: 'ERROR',
  ARCHIVED: 'ARCHIVED',
};

export const streamRequestType = {
  CREATE: 'CREATE',
  START: 'START',
  RESET: 'RESET',
  DELETE: 'DELETE',
  STOP: 'STOP',
  CHECK: 'CHECK',
  METRICS: 'METRICS',
  CHECKVOD: 'CHECKVOD',
  CHECKRECORDING: 'CHECKRECORDING',
  ARCHIVE: 'ARCHIVE',
};

export const genderType = {
  FEMALE: 'FEMALE',
  MALE: 'MALE',
  COED: 'COED',
};

export const sessionRoleType = {
  ADMIN: 'ADMIN',
  JUDGE: 'JUDGE',
  PRODUCER: 'PRODUCER',
  FAN: 'FAN',
  COACH: 'COACH',
  ANNOUNCER: 'ANNOUNCER',
};

export const accessType = {
  PRIVATE: 'PRIVATE',
  PUBLIC: 'PUBLIC',
  KEY: 'KEY',
  GUESTLIST: 'GUESTLIST',
};

export const sessionStatus = {
  CREATED: 'CREATED',
  PREGAME: 'PREGAME',
  LIVE: 'LIVE',
  POSTGAME: 'POSTGAME',
  ARCHIVED: 'ARCHIVED',
  CANCELED: 'CANCELED',
  DELETED: 'DELETED',
};

export const sessionViewType = {
  FULLSCREEN: 'FULLSCREEN',
  FULLSCREENA: 'FULLSCREENA',
  FULLSCREENB: 'FULLSCREENB',
  SCOREBOARD: 'SCOREBOARD',
  LEADERBOARD: 'LEADERBOARD',
  DEFAULT: 'DEFAULT',
  THEATER: 'THEATER',
  MOBILE: 'MOBILE',
  MIRROR: 'MIRROR',
  MIRRORA: 'MIRRORA',
  MIRRORB: 'MIRRORB',
  SOLOPLAYER: 'SOLOPLAYER',
  DUALPLAYER: 'DUALPLAYER',
  DUALLINEUP: 'DUALLINEUP',
  SOLOLINEUP: 'SOLOLINEUP',
  PLAYERDUAL: 'PLAYERDUAL',
  PLAYERMULTI: 'PLAYERMULTI',
  MULTIONLY: 'MULTIONLY',
};

export const sessionLoadingState = {
  NOT_FOUND: 'NOT_FOUND',
  UNAUTHORIZED: 'UNAUTHORIZED',
  LOADING: 'LOADING',
  LOADED: 'LOADED',
  NOT_LOADED: 'NOT_LOADED',
};

export const teamScoreType = {
  TOP5OF6: 'TOP5OF6',
  TOP6HL: 'TOP6HL',
  TOP6: 'TOP6',
  TOP5: 'TOP5',
  TOP4: 'TOP4',
  TOP3: 'TOP3',
  BEST5: 'BEST5',
  BEST4: 'BEST4',
  BEST3: 'BEST3',
  ALL: 'ALL',
  NONE: 'NONE',
};

export const judgePanelType = {
  NCAAM: 'NCAAM',
  NCAAM4: 'NCAAM4',
  NCAAW: 'NCAAW',
  NCAAW2: 'NCAAW2',
  NCAAW2SV: 'NCAAW2SV',
  NCAAW4: 'NCAAW4',
  NCAAW6: 'NCAAW6',
  FIG4: 'FIG4',
  FIG5: 'FIG5',
  FIG6: 'FIG6',
  FAN: 'FAN',
  SINGLE: 'SINGLE',
  J4: 'J4',
  OPEN: 'OPEN',
};

export const initialSessionState = {
  type: null,
  startAt: null,
  endAt: null,
  access: accessType.PRIVATE,
  now: null,
  name: null,
  competition: null,
  gender: genderType.MALE,
  status: null,
  streamSubs: null,
  judging: null,
  judgingRequired: null,
  roster: null,
  apparatus: null,
  alternating: false,
  id: null,
  sessionKey: null,
  load: null,
  request: null,
  newStreamSub: null,
  sessionData: null,
  streamData: null,
  lineupSubs: null,
  role: sessionRoleType.FAN,
  producer: null,
  view: sessionViewType.PLAYERDUAL,
  teamScoring: teamScoreType.TOP5OF6,
  judgePanel: judgePanelType.NCAAM,
  loadingState: null,
};

export const RotationStatus = {
  CREATED: 'CREATED',
  ACTIVE: 'ACTIVE',
  COMPLETE: 'COMPLETE',
};

export const StageStatus = {
  CREATED: 'CREATED',
  ACTIVE: 'ACTIVE',
  COMPLETE: 'COMPLETE',
};

export const GymApparatus = {
  FX: 'FX',
  PH: 'PH',
  SR: 'SR',
  VT: 'VT',
  PB: 'PB',
  HB: 'HB',
  UB: 'UB',
  BB: 'BB',
  WU: 'WU',
  WUFX: 'WUFX',
  WUPH: 'WUPH',
  WUSR: 'WUSR',
  WUVT: 'WUVT',
  WUPB: 'WUPB',
  WUHB: 'WUHB',
  WUUB: 'WUUB',
  WUBB: 'WUBB',
  BYE: 'BYE',
};
