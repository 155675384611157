/*global Rollbar */
import { useCallback, useContext } from 'react';
import { Button } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { evaluatorActions, alertActions } from '../../redux/_actions';
import { useApolloClient } from '@apollo/client';
import { useRoutineScore } from '../../utilities/scoring';
import { RoutineStatus, InquiryStatus } from '../../models';
import {
  updateRoutine,
  updateInquiry,
  useErrorHandler,
} from '../../apollo/helpers';
import { SessionContext } from './multiexperimental';

export default function EvaluatorSlabFinalize({ team, scores, routineData }) {
  const dispatch = useDispatch();
  const apolloClient = useApolloClient();
  const routineScore = useRoutineScore();
  const sessionContext = useContext(SessionContext);
  const setEvalRoutine = sessionContext?.setEvalRoutine || null;

  const inquiry = useSelector(
    (state) => state.evaluator.activeInquiryByLineupId[team.lineupId]
  );

  const edit = useSelector(
    (state) => state.evaluator.activeEditByLineupId[team.lineupId]
  );

  const handleUpdateInquiryErrors = useErrorHandler(
    'There was a problem updating the inquiry'
  );
  const handleUpdateRoutineErrors = useErrorHandler(
    'There was a problem updating the routine'
  );

  const { scoreTotal, subScoresComplete, scoresJ } = routineScore({
    scores: { items: scores },
  });

  if (!subScoresComplete) {
    console.error(
      'EvaluatorFinalize is open without complete subscores 😦',
      scores
    );
  }

  const submitScore = useCallback(() => {
    //const { score, id, _version, apparatus, order, athlete } = routineData;

    // Debug check here to see if score mismatch
    const {
      scoreTotal: finalScoreCheck,
      scoresJ: judgeScores,
      // scoresD: svScores,
    } = routineScore({
      scores: { items: routineData.scores.items },
    });

    judgeScores.forEach((j, i) => {
      // console.log(j, scoresJ[i]);
      if (j !== scoresJ[i]) {
        Rollbar.error(
          `Score (J${i + 1}) mismatch: ${
            scoresJ[i]
          } attempted vs ${j} recalculated.`,
          {}
        );
      }
    });

    if (finalScoreCheck !== scoreTotal) {
      Rollbar.error(
        `Score total mismatch: ${scoreTotal} attempted vs ${finalScoreCheck} recalculated.`,
        {}
      );
      console.error(
        `Score total mismatch: ${scoreTotal} attempted vs ${finalScoreCheck} recalculated.`
      );
      return dispatch(
        alertActions.error(`Score mismatch: Resubmit score component.`)
      );
    }

    const isEdited = routineData.score && routineData.score !== scoreTotal; // might want to make this any change not just manifested final score change
    updateRoutine(apolloClient, routineData, {
      score: scoreTotal,
      isEdited,
      status: RoutineStatus.COMPLETE,
    }).then((res) => {
      if (inquiry) {
        return updateInquiry(apolloClient, inquiry, {
          status: InquiryStatus.CLOSED,
        }).then(() => {
          dispatch(evaluatorActions.clearInquiry(team.lineupId));
        }, handleUpdateInquiryErrors);
      }
      if (edit) {
        return dispatch(evaluatorActions.clearEdit(team.lineupId));
      }
      if (sessionContext) {
        setEvalRoutine(null);
      }
    }, handleUpdateRoutineErrors);
  }, [
    routineData,
    routineData?.scores?.items?.length,
    scoreTotal,
    apolloClient,
    inquiry,
    scores,
    edit,
    dispatch,
    team.lineupId,
    handleUpdateRoutineErrors,
    handleUpdateInquiryErrors,
  ]);

  return (
    <Button
      className={['vCenter', 'submit'].join(' ')}
      variant="success"
      disabled={!subScoresComplete}
      onClick={submitScore}
    >
      <span>SUBMIT</span>
    </Button>
  );
}
