import { useEffect, useRef } from 'react';

export function useInterval(callback, delay, enable = false) {
  const savedCallback = useRef();

  useEffect(() => {
    if (!enable) {
      return;
    }
    savedCallback.current = callback;
  }, [callback, enable]);

  useEffect(() => {
    if (!enable) {
      return;
    }
    function tick() {
      savedCallback.current();
    }

    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => {
        clearInterval(id);
      };
    }
  }, [callback, delay, enable]);
}

export function useDocumentTitle() {
  function setTitle(title) {
    return (document.title = title);
  }

  return setTitle;
}
