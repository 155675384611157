import { useMemo } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

export function useQueryParams() {
  const location = useLocation();
  return useMemo(() => new URLSearchParams(location.search), [location]);
}

export function useQueryParamsAdv(options, replace = false) {
  const { search } = useLocation();
  const history = useHistory();

  // get query params
  const queryParams = useMemo(() => queryStringToObject(search, options), [
    search,
  ]);

  // updates the query params
  function setQueryParams(queryObj) {
    if (replace) {
      history.replace({
        search: createQueryString(queryObj),
      });
    } else {
      history.push({
        search: createQueryString(queryObj),
      });
    }
  }

  return { queryParams, setQueryParams };
}

function queryStringToObject(queryString = '', options = {}) {
  let queryObject = {};
  queryString &&
    decodeURIComponent(queryString.replace('?', ''))
      .split('&')
      .map((itemString) => {
        let [itemKey, itemValue] = itemString.split('=');
        if (options.hasOwnProperty(itemKey)) {
          if (!queryObject[itemKey] && Array.isArray(options[itemKey])) {
            queryObject[itemKey] = [];
          }
          Array.isArray(options[itemKey])
            ? queryObject[itemKey].push(itemValue)
            : (queryObject[itemKey] =
                typeof options[itemKey] === 'number'
                  ? parseInt(itemValue)
                  : itemValue || '');
        }
      });
  return queryObject;
}

function createQueryString(queryObject = {}) {
  let queryString = Object.keys(queryObject)
    .filter(
      (key) =>
        queryObject[key] !== undefined &&
        queryObject[key] !== null &&
        !(Array.isArray(queryObject[key]) && !queryObject[key].length)
    )
    .map((key) => {
      if (Array.isArray(queryObject[key])) {
        return queryObject[key]
          .map((item) => {
            if (item === true) return encodeURIComponent(key);
            return item ? `${encodeURIComponent(key)}=${encodeURIComponent(item)}` : '';
          })
          .filter(Boolean)
          .join('&');
      }
      
      if (queryObject[key] === true) return encodeURIComponent(key);
      return queryObject[key] ? 
        `${encodeURIComponent(key)}=${encodeURIComponent(queryObject[key])}` : 
        encodeURIComponent(key);
    })
    .filter(Boolean)
    .join('&');
    
  return queryString ? `?${queryString}` : '';
}
