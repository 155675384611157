import { nanoid } from 'nanoid';
import { store } from '../redux/store';
import { Storage } from 'aws-amplify';

// Utility function to get image file given either a flat or array image list

export function useImage() {
  // Input is as an AWSJSON string and can be as a metaData
  function parseImageFile(imgData) {
    const parsed = (imgData && JSON.parse(imgData)) || [];
    const isArray = Array.isArray(parsed);
    let metaData = null;
    let fileList = [];
    let metaList = [];
    let filename = null;

    if (isArray && parsed.length > 0) {
      metaData = parsed[0].metaData;
      fileList = parsed.map((m) => m.metaData.filename);
      metaList = parsed.map((m) => m.metaData);
      filename = metaData?.filename;
    } else if (!isArray && !!parsed?.metaData) {
      metaData = parsed?.metaData;
      filename = metaData?.filename;
    }

    return { filename, metaData, fileList, metaList };
  }

  function addImageFile(newData, prevData = []) {
    // Takes old JSON and adds the newData to the beginning and returns JSONified
    let imgMeta = [];
    let newMeta = null;
    const prevImg = prevData ? JSON.parse(prevData) : [];
    let oldMeta = Array.isArray(prevImg) ? prevImg : [prevImg];
    const { username } = store.getState().user;

    if (newData !== null) {
      let imgId = nanoid();

      newMeta = {
        metaData: {
          type: 'athlete',
          typeid: newData?.id,
          id: imgId,
          owner: username,
          originalname: newData.name,
          size: newData.size,
          imgtype: newData.type,
          filename: `upload/images/athlete/${imgId}`,
        },
      };
    }

    imgMeta = [newMeta, ...oldMeta];
    return JSON.stringify(imgMeta);
  }

  async function uploadImage(file, meta) {
    return await Storage.put(meta.metaData.filename, file, meta);
  }

  return { parseImageFile, addImageFile, uploadImage };
}
