import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Container } from 'react-bootstrap';

function SessionNotFoundPage() {
  return (
    <Container>
      <Row>
        <Col
          style={{
            textAlign: 'center',
            marginTop: '3em',
            fontSize: '50px',
            fontWeight: 600,
          }}
        >
          404
        </Col>
      </Row>
      <Row>
        <Col style={{ textAlign: 'center', marginBottom: '1em' }}>
          The session you are looking for was not found.
        </Col>
      </Row>
      <Row>
        <Col style={{ textAlign: 'center' }}>
          <Link to="/">Back to Home</Link>
        </Col>
      </Row>
    </Container>
  );
}

export default SessionNotFoundPage;
