import React, { useRef, useEffect, useState } from 'react';
import ReactPlayer from 'react-player';
import { Button, Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { videoPlayerActions } from '../../redux/_actions';
import {
  resolutionType,
  sessionStatus,
  sessionRoleType,
  playerType,
} from '../../redux/_constants';
import { ClipType, StreamType } from '../../models';
import VideoSetup from '../setup/videosetup';
import { POLLING_INTERVAL } from '../../utilities/constants';
import { useInterval } from '../../utilities/hooks';
import { useVideoConfig } from './hooks';
import { gearIcon } from '../helpers/icons';
import { VideoMetricsStream, VideoMetricsFile } from '../helpers/videometrics';
import { FrameCapture, VideoDownload } from '../helpers/videotools';
import { useHLSStats } from '../../utilities/video';
import { useMediaInfo } from '../../utilities/clips';

const { CREATED, PREGAME, LIVE, POSTGAME } = sessionStatus;

const LAG_THRESHOLD = 15;

function HlsPlayer({
  playerRef,
  refId,
  url,
  play,
  playerReady,
  handlePlay,
  handlePause,
  handleSeek,
}) {
  const dispatch = useDispatch();

  return (
    <ReactPlayer
      ref={playerRef}
      className="videoPlayer"
      config={{
        file: {
          attributes: {
            id: `videoPlayer${refId}`,
            crossOrigin: 'Anonymous',
            playsInline: true,
          },
        },
      }}
      //url={thisPlayer.url}
      url={url}
      /*url={
          'https://moctobpltc-i.akamaihd.net/hls/live/571329/eight/playlist.m3u8'
        }*/
      controls={true}
      width="100%"
      height="100%"
      playing={play}
      onReady={playerReady}
      onStart={handlePlay}
      onPlay={handlePlay}
      muted={false}
      onPause={handlePause}
      progressInterval={1000}
      autoPlay={true}
      onProgress={(e) =>
        dispatch(videoPlayerActions.currentTime(refId, e.playedSeconds))
      }
      onSeek={handleSeek}
    />
  );
}

function VideoPlayer(props) {
  const { REACT_PLAYER } = playerType;
  const { team, mirror, clips, streamType } = props;
  const dispatch = useDispatch();
  const status = useSelector((state) => state.session.status);
  const sessionKey = useSelector((state) => state.session.sessionKey);
  const videoPlayer = useSelector((state) => state.videoPlayer);
  const producer = useSelector((state) => state.producer);
  const round = useSelector((state) => state.producer.round);
  const sessionType = useSelector((state) => state.session.type);
  const role = useSelector((state) => state.session.role);
  const alternating = useSelector((state) => state.session.alternating);
  const streams = useSelector((state) => state.session.streams.items);
  const streamA =
    streams.find((s) => s.index === parseInt(streamType)) ||
    streams.find((s) => s.index === 0);
  const streamB =
    streams.find((s) => s.index === parseInt(streamType)) ||
    streams.find((s) => s.index === 1);
  // const streamC = useSelector((state) =>
  //   state.session.streams.items.find((s) => s.index === 2)
  // );
  const view = useSelector((state) => state.session.view);
  const [isReady, setIsReady] = useState(false);
  const [statsBtn, setStatsBtn] = useState(false);
  const [setupBtn, setSetupBtn] = useState(false);
  const [setup, setSetup] = useState(false);
  const { ADMIN, JUDGE, PRODUCER, /*FAN,*/ COACH } = sessionRoleType;
  const [statsTimeout, setStatsTimeout] = useState(0);
  const [currentPlayerType] = useState(REACT_PLAYER);
  const videoConfig = useVideoConfig();
  const hasProductionStream = !!videoConfig?.hasProductionStream;

  const [stats, setStats] = useState({
    pgmtime: null,
    date: null,
    time: null,
    sn: null,
    duration: null,
    resolution: null,
    bitrate: null,
    bandwidth: null,
    now: Date.now(),
    delta: null,
    lastTime: null,
    lag: null,
  });
  const id = team && team.home ? 'A' : 'B';
  const refId =
    team && ((team.home && !mirror) || (!team.home && mirror)) ? 'A' : 'B';

  const thisPlayer = videoPlayer[`player${refId}`];
  const playerRef = useRef();
  const isLive = [LIVE, PREGAME, CREATED].includes(status);
  const hlsStats = useHLSStats();
  const mediaInfo = useMediaInfo();
  const isClipEmpty = !(thisPlayer && thisPlayer.clip && thisPlayer.url);
  const clipType = thisPlayer?.clip?.type;

  useEffect(() => {
    if (isReady && playerRef.current && thisPlayer.seekTime !== null) {
      if (currentPlayerType === REACT_PLAYER) {
        if (playerRef.current.player?.isReady) {
          if (clipType === ClipType.YOUTUBE) {
            if (playerRef.current.player?.isPlaying) {
              playerRef.current.seekTo(thisPlayer.seekTime, 'seconds');
            }
          } else {
            playerRef.current.seekTo(thisPlayer.seekTime, 'seconds');
          }
        }
      }

      if (thisPlayer.autoplay) {
        if (currentPlayerType === REACT_PLAYER) {
          playerRef.current.playing = true;
          dispatch(videoPlayerActions.play(refId));
        }
      }
      // Need to reset seekTime for next time
      dispatch(videoPlayerActions.seek(null, refId));
    }
  }, [thisPlayer.seekTime, thisPlayer.autoplay, isReady, dispatch, id]);

  // Put the ref into Redux state for access?
  useEffect(() => {
    if (isReady && playerRef.current) {
      dispatch(videoPlayerActions.setRef(playerRef.current, refId));
    }

    return () => {
      dispatch(videoPlayerActions.setRef(null, refId));
    };
  }, [isReady, refId, dispatch]);

  // Load file URL for new session, round, phase
  useEffect(() => {
    if (status === POSTGAME && !producer.clip) {
      const clipURL =
        clips && clips.length && `${clips[0]?.originURL}`
          ? `${clips[0]?.originURL}`
          : url();
      const clipStart =
        (clips && clips.length && clips[0]?.seekStartSeconds) ?? 0;
      const clipMeta = mediaInfo(
        JSON.parse((clips && clips.length && clips[0]?.other) ?? null)
          ?.mediainfo
      );

      if (clipURL && !hasProductionStream) {
        dispatch(videoPlayerActions.load(clipURL, refId, clipMeta, clips[0]));
        dispatch(videoPlayerActions.seek(clipStart, refId));
      }
    }
  }, [status, dispatch, refId, clips]);

  const updateStats = () => {
    let newStats = null;
    newStats = hlsStats({
      hls: playerRef?.current?.getInternalPlayer('hls'),
      resolutionType,
      live: isLive,
    });

    if (newStats) {
      if (stats.lastTime === newStats?.lastTime) {
        if (statsTimeout < 2) {
          setStatsTimeout(statsTimeout + 1);
        } else {
          setStatsTimeout(-1);
        }
      }
      setStats(newStats);
    }
  };

  useInterval(
    async () => {
      updateStats();
    },
    POLLING_INTERVAL.SECOND,
    currentPlayerType === REACT_PLAYER &&
      thisPlayer?.ref?.getInternalPlayer &&
      statsBtn &&
      (statsTimeout !== -1 || thisPlayer.play)
  );

  const handlePlay = () => {
    dispatch(videoPlayerActions.play(refId));
  };

  const handlePause = () => {
    dispatch(videoPlayerActions.pause(refId));
  };

  const handleSeek = (seconds) => {
    updateStats();
    setStatsTimeout(0);
  };

  // Probably needs to get pulled out of the videoplayer component and become a utility function?
  const url = () => {
    const stream = id === 'A' ? streamA : streamB;

    // For live streaming

    // if ([FAN].includes(role) && status === 'LIVE' && streamC?.outputURL) {
    //   return streamC.outputURL;
    // }

    if (sessionType === 'SOLO' && status === 'LIVE') {
      if (streamA && streamA.outputURL) {
        return streamA.outputURL;
      }
      return null;
    }

    if (sessionType === 'DUAL' && status === 'LIVE') {
      if (stream && stream.outputURL) {
        return stream.outputURL;
      }
      return null;
    }

    if (sessionType === 'MULTI' && status === 'LIVE') {
      if (stream && stream.outputURL) {
        return stream.outputURL;
      }
      return null;
    }

    if (status === 'POSTGAME') {
      if (stream && streamType) {
        return stream.outputURL;
      }

      if (hasProductionStream) {
        return videoConfig.productionStream;
      }

      // Use Clips if available
      if (clips && clips.length > 0 && thisPlayer?.url) {
        return thisPlayer.url;
      }

      if (['MIRROR'].includes(view) && refId === 'B' && thisPlayer?.url) {
        return thisPlayer.url;
      }

      if (
        clips &&
        clips.length === 0 &&
        alternating &&
        stream.type === StreamType.CAMERA
      ) {
        // For postgame VOD classic aternating
        // Stream A contains FX / SR / PB
        // Stream B contains PH / VT / HB

        if (round % 2 === 1) {
          return streamA?.vodURL;
        } else {
          return streamB?.vodURL;
        }
      } else {
        // For postgame VOD head to head vs
        // Team A on Stream A
        // Team B on Stream B
        if (stream && stream.vodURL && videoPlayer.controller.vod) {
          return stream.vodURL;
        }
      }

      // For SOLO mirroring A to B
      if (id === 'B' && !stream && videoPlayer.controller.vod) {
        return streamA?.vodURL;
      }
    }

    return null;
  };

  const playerReady = () => {
    setIsReady(true);
  };

  const handleToggleStats = () => {
    // Update immediately
    if (!statsBtn) {
      updateStats();
      setStatsTimeout(0);
    }

    setStatsBtn(!statsBtn);
  };

  const handleToggleSetup = () => {
    setSetup(true);
    setSetupBtn(!setupBtn);
  };

  const handleGoLive = () => {
    const player = playerRef?.current?.getInternalPlayer('hls');
    player.media.currentTime = player.media.duration - 4;
  };

  const latencyAlert = () => {
    return (
      <div className={['streamAlert', statsBtn ? 'max' : null].join(' ')}>
        <Button variant="dark" onClick={handleGoLive}>
          Go 'Live'
        </Button>
      </div>
    );
  };

  const streamSetup = () => {
    return (
      <div className="streamSetup">
        <Button variant="dark" onClick={handleToggleSetup}>
          {gearIcon}
        </Button>
      </div>
    );
  };

  //console.log('re-rendering videoPlayer');
  //console.log(stats.lag);

  const overlayIndicators = () => {
    return (
      <>
        {!statsBtn && stats?.lag && stats.lag > LAG_THRESHOLD
          ? latencyAlert()
          : null}
        {[ADMIN].includes(role)
          ? thisPlayer.meta &&
            Object.keys(thisPlayer.meta).length !== 0 &&
            clipType !== ClipType.YOUTUBE
            ? VideoMetricsFile({ stats: thisPlayer.meta })
            : VideoMetricsStream({ stats, handler: handleToggleStats })
          : null}
        {/*[JUDGE, COACH, FAN].includes(role) && isLive
          ? VideoStreamLatency({ stats, handler: handleToggleStats })
          : null*/}
        {role === ADMIN && status !== POSTGAME ? streamSetup() : null}
      </>
    );
  };

  return (
    <div className="videoWrapper">
      {[ADMIN, COACH, PRODUCER, JUDGE].includes(role) &&
      status === POSTGAME &&
      clipType === ClipType.FILE ? (
        <VideoDownload
          player={thisPlayer}
          disabled={isClipEmpty || clipType !== ClipType.FILE}
          url={thisPlayer.url}
          sessionKey={sessionKey}
        />
      ) : null}
      {[ADMIN, COACH, PRODUCER, JUDGE].includes(role) &&
      status === POSTGAME &&
      clipType === ClipType.FILE ? (
        <FrameCapture
          player={thisPlayer}
          playerId={`videoPlayer${refId}`}
          disabled={isClipEmpty}
        />
      ) : null}
      <HlsPlayer
        playerRef={playerRef}
        refId={refId}
        url={url()}
        play={thisPlayer.play}
        playerReady={playerReady}
        handlePlay={handlePlay}
        handlePause={handlePause}
        handleSeek={handleSeek}
      />
      {overlayIndicators()}
      <Modal
        show={setup}
        onHide={() => setSetup(false)}
        centered
        dialogClassName="videoSetupModal"
      >
        <VideoSetup />
      </Modal>
    </div>
  );
}

export default VideoPlayer;
