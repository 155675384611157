import { useQuery } from '@apollo/client';
import GetMatch from '../apollo/queries/GetMatch.graphql';
const logoPath = 'upload/images/team/';

export const matchA = {
  sessionId: 'nUltZ7LgSH',
  teamA: 'Illinois',
  teamALogo: logoPath + 't9wWLsOPF2bpVZHqjsogl',
  teamBLogo: logoPath + 'U7yGlq6CiOy02Wqqwj9Sb',
  teamB: 'NIU',
  gameTime: '2021-01-15T23:30:00.000Z',
};

export const matchB = {
  sessionId: 'al5TXaUSVa',
  teamA: 'Army',
  teamALogo: logoPath + 'wmICCck438BshXvBlPHV_',
  teamBLogo: logoPath + '6Ju2q59cmDgc7NU-VCdEg',
  teamB: 'Penn State',
  gameTime: '2021-01-16T18:00:00.000Z',
};

export const matchC = {
  sessionId: 'Ah4x-2OZzm',
  teamA: 'Michigan',
  teamALogo: logoPath + '5UacbwAbMk1Md_YfRP801',
  teamBLogo: logoPath + 'G1Vglwz4LRM2Yo1pc9NiT',
  teamB: 'UIC',
  gameTime: '2021-01-17T18:00:00.000Z',
};

export const matchD = {
  sessionId: 'eg2wDJN-YB',
  teamA: 'Ohio State',
  teamALogo: logoPath + '1zvZAutg_lGASowMXuhnY',
  teamBLogo: logoPath + 'QtMgsTKjUwl0sEQwnlwNT',
  teamB: 'Navy',
  gameTime: '2021-01-30T17:00:00.000Z',
};

export const matchE = {
  sessionId: 'PEvQ6AddE1',
  teamA: 'Oklahoma',
  teamALogo: logoPath + 'hqph8HiyQRcSV8QF_G78t',
  teamBLogo: logoPath + 'wmICCck438BshXvBlPHV_',
  teamB: 'Army',
  gameTime: '2021-01-30T21:00:00.000Z',
};

export const matchF = {
  sessionId: 'OI5mO6SqsO',
  teamA: 'Cal',
  teamALogo: logoPath + 'cFqM7q75rvGOTzliayNOC',
  teamBLogo: logoPath + 'du0YPpvxVn6sDVlayCUX0',
  teamB: 'ASU',
  gameTime: '2021-01-31T00:30:00.000Z',
};

export const matchG = {
  sessionId: '3yMhVIxZSJ',
  teamA: 'Cal',
  teamALogo: logoPath + 'cFqM7q75rvGOTzliayNOC',
  teamBLogo: logoPath + 'wmICCck438BshXvBlPHV_',
  teamB: 'Army',
  gameTime: '2021-02-06T22:00:00.000Z',
};

export const matchH = {
  sessionId: 'vw_njNuOuF',
  teamB: 'Stanford',
  teamBLogo: logoPath + 'TxEa6Awt5quagSXQIhqtk',
  teamALogo: logoPath + 'KHVR7ujdqBH7LXG57CuO8',
  teamA: 'William & Mary',
  gameTime: '2021-02-10T19:00:00.000Z',
};

export const matchI = {
  sessionId: 'E4gyXXKIam',
  teamA: 'Cal',
  teamALogo: logoPath + 'cFqM7q75rvGOTzliayNOC',
  teamBLogo: logoPath + 'TxEa6Awt5quagSXQIhqtk',
  teamB: 'Stanford',
  gameTime: '2021-02-14T00:00:00.000Z',
};

export const matchJ = {
  sessionId: 'Vsq5DKXecb',
  teamA: 'Cal',
  teamALogo: logoPath + 'cFqM7q75rvGOTzliayNOC',
  teamBLogo: logoPath + 'uGniv3T8oFPiagZqgWXwv',
  teamB: 'Springfield',
  gameTime: '2021-02-17T18:00:00.000Z',
};

export const matchK = {
  sessionId: 'n7AvD4q1XU',
  teamA: 'Navy',
  teamALogo: logoPath + 'QtMgsTKjUwl0sEQwnlwNT',
  teamBLogo: logoPath + 'TxEa6Awt5quagSXQIhqtk',
  teamB: 'Stanford',
  gameTime: '2021-02-17T22:00:00.000Z',
};

export const matchL = {
  sessionId: 'xxvFZYTt5y',
  teamA: 'Springfield',
  teamALogo: logoPath + 'uGniv3T8oFPiagZqgWXwv',
  teamB: 'Army',
  teamBLogo: logoPath + 'wmICCck438BshXvBlPHV_',
  gameTime: '2021-02-20T18:00:00.000Z',
};

export const matchM = {
  sessionId: 'gTfaGUPtkd',
  teamALogo: logoPath + 'KHVR7ujdqBH7LXG57CuO8',
  teamA: 'William & Mary',
  teamB: 'Cal',
  teamBLogo: logoPath + 'cFqM7q75rvGOTzliayNOC',
  gameTime: '2021-02-24T19:00:00.000Z',
};

export const matchN = {
  sessionId: 'RH9ifh7ZFy',
  teamB: 'Navy',
  teamBLogo: logoPath + 'QtMgsTKjUwl0sEQwnlwNT',
  teamA: 'Penn State',
  teamALogo: logoPath + '6Ju2q59cmDgc7NU-VCdEg',
  gameTime: '2021-02-27T18:00:00.000Z',
};

export const matchO = {
  sessionId: 'Gd38Bi8E4S',
  teamB: 'Springfield',
  teamBLogo: logoPath + 'uGniv3T8oFPiagZqgWXwv',
  teamALogo: logoPath + 'KHVR7ujdqBH7LXG57CuO8',
  teamA: 'William & Mary',
  gameTime: '2021-02-28T20:00:00.000Z',
};

export const matchP = {
  sessionId: 'CSP43NTxRu',
  teamA: 'Springfield',
  teamALogo: logoPath + 'uGniv3T8oFPiagZqgWXwv',
  teamBLogo: logoPath + 'TxEa6Awt5quagSXQIhqtk',
  teamB: 'Stanford',
  gameTime: '2021-03-05T19:00:00.000Z',
};

export const matchQ = {
  sessionId: 'ij1qKrQt6V',
  teamALogo: logoPath + 'TxEa6Awt5quagSXQIhqtk',
  teamA: 'Stanford',
  teamBLogo: logoPath + 'cFqM7q75rvGOTzliayNOC',
  teamB: 'Cal',
  gameTime: '2021-03-07T21:00:00.000Z',
};

export const matchR = {
  sessionId: '26yOZYMQZt',
  teamA: 'Cal',
  teamALogo: logoPath + 'cFqM7q75rvGOTzliayNOC',
  teamB: 'Air Force',
  teamBLogo: logoPath + 'AAz9Ikazg9k-YNOWvNnLa',
  gameTime: '2021-03-13T23:00:00.000Z',
};

export const matchS = {
  sessionId: 'DkOCqc3x6j',
  teamA: 'Michigan',
  teamALogo: logoPath + '5UacbwAbMk1Md_YfRP801',
  teamB: 'Ohio State',
  teamBLogo: logoPath + '1zvZAutg_lGASowMXuhnY',
  gameTime: '2021-03-17T20:30:00.000Z',
};

export const matchT = {
  sessionId: 'tSjbPf3mvj',
  teamA: 'Army',
  teamALogo: logoPath + 'wmICCck438BshXvBlPHV_',
  teamB: 'Niigata UM 新潟経営大学',
  teamBLogo: logoPath + 'krOzxIcuasRLcch5DrneQ',
  gameTime: '2021-03-18T00:00:00.000Z',
};

export const matchU = {
  sessionId: 'XTdGkn98ix',
  teamA: 'Air Force',
  teamALogo: logoPath + 'AAz9Ikazg9k-YNOWvNnLa',
  teamB: 'Army',
  teamBLogo: logoPath + 'wmICCck438BshXvBlPHV_',
  gameTime: '2021-03-19T22:00:00.000Z',
};

export const matchV = {
  sessionId: 'YV_pwawePD',
  teamA: 'Army',
  teamALogo: logoPath + 'wmICCck438BshXvBlPHV_',
  teamB: 'Springfield',
  teamBLogo: logoPath + 'uGniv3T8oFPiagZqgWXwv',
  gameTime: '2021-03-21T16:00:00.000Z',
};

export const matchW = {
  sessionId: '_VOtc_ctbg',
  teamA: 'Stanford',
  teamALogo: logoPath + 'TxEa6Awt5quagSXQIhqtk',
  teamB: 'Army',
  teamBLogo: logoPath + 'wmICCck438BshXvBlPHV_',
  gameTime: '2021-03-27T18:00:00.000Z',
};

export const matchX = {
  sessionId: 'HoG9C96KrB',
  teamB: 'Air Force',
  teamBLogo: logoPath + 'AAz9Ikazg9k-YNOWvNnLa',
  teamA: 'William & Mary',
  teamALogo: logoPath + 'KHVR7ujdqBH7LXG57CuO8',
  gameTime: '2021-03-27T23:00:00.000Z',
};

export const matchY = {
  sessionId: '07rSlDc0eB',
  teamB: 'Team GBR',
  teamBLogo: logoPath + 'b71yEXmvjNdpkVpizpbad',
  teamA: 'Team USOPTC',
  teamALogo: logoPath + 'oYKpmpRm0iK4b1AnlTCfC',
  gameTime: '2021-04-13T16:00:00.000Z',
};

export const matchZ = {
  sessionId: 'DB7D60OIit',
  teamB: 'Team ESP',
  teamBLogo: logoPath + 'RqY93FUEJOjLyegGWiXZl',
  teamA: 'Team GBR',
  teamALogo: logoPath + 'b71yEXmvjNdpkVpizpbad',
  gameTime: '2021-06-17T14:00:00.000Z',
};

export const matchAA = {
  sessionId: '-wC65OQZQw',
  teamB: 'W&M Gold',
  teamBLogo: logoPath + 'KHVR7ujdqBH7LXG57CuO8',
  teamA: 'W&M Green',
  teamALogo: logoPath + 'KHVR7ujdqBH7LXG57CuO8',
  gameTime: '2021-12-10T23:30:00.000Z',
};

export const matchAB = {
  sessionId: 'piRX6HyXQb',
  teamB: 'Cal',
  teamBLogo: logoPath + 'cFqM7q75rvGOTzliayNOC',
  teamA: 'Stanford',
  teamALogo: logoPath + 'TxEa6Awt5quagSXQIhqtk',
  gameTime: '2022-02-13T21:00:00.000Z',
};

export const matchAC = {
  sessionId: 'g9EYT3poME',
  teamA: 'Cal',
  teamALogo: logoPath + 'cFqM7q75rvGOTzliayNOC',
  teamB: 'Air Force',
  teamBLogo: logoPath + 'AAz9Ikazg9k-YNOWvNnLa',
  gameTime: '2022-02-20T22:00:00.000Z',
};

export const matchAD = {
  sessionId: 'PFJ2QXjYsp',
  teamA: 'William & Mary',
  teamALogo: logoPath + 'KHVR7ujdqBH7LXG57CuO8',
  teamB: 'Springfield',
  teamBLogo: logoPath + 'uGniv3T8oFPiagZqgWXwv',
  gameTime: '2022-02-26T00:00:00.000Z',
};

export const matchAE = {
  sessionId: 'IvnHfOPDJi',
  teamA: 'Stanford',
  teamALogo: logoPath + 'TxEa6Awt5quagSXQIhqtk',
  teamB: 'Cal',
  teamBLogo: logoPath + 'cFqM7q75rvGOTzliayNOC',
  teamC: 'Air Force',
  teamCLogo: logoPath + 'AAz9Ikazg9k-YNOWvNnLa',
  gameTime: '2022-03-06T00:00:00.000Z',
};

export const matchAF = {
  sessionId: 'zKqT3d-Rxk',
  teamA: 'William & Mary',
  teamALogo: logoPath + 'KHVR7ujdqBH7LXG57CuO8',
  teamBLogo: logoPath + 'QtMgsTKjUwl0sEQwnlwNT',
  teamB: 'Navy',
  gameTime: '2022-03-12T19:00:00.000Z',
};

export const matchAG = {
  sessionId: '3eFDrkjq-7',
  teamB: 'Stanford',
  teamBLogo: logoPath + 'TxEa6Awt5quagSXQIhqtk',
  teamA: 'Cal',
  teamALogo: logoPath + 'cFqM7q75rvGOTzliayNOC',
  teamC: 'Exhibition',
  teamCLogo: logoPath + '6UR3LYSxDqBvbkpw_5mpf',
  gameTime: '2022-03-12T22:00:00.000Z',
};

export const matchAH = {
  sessionId: 'DCIw5PJGWO',
  teamA: 'William & Mary',
  teamALogo: logoPath + 'KHVR7ujdqBH7LXG57CuO8',
  teamB: 'Springfield',
  teamBLogo: logoPath + 'uGniv3T8oFPiagZqgWXwv',
  teamCLogo: logoPath + 'QtMgsTKjUwl0sEQwnlwNT',
  teamC: 'Navy',
  teamD: 'Army',
  teamDLogo: logoPath + 'wmICCck438BshXvBlPHV_',
  gameTime: '2022-04-02T18:00:00.000Z',
  title: 'ECAC Championships @ William & Mary',
};

export const matchAI = {
  sessionId: 'vSYiSoaY7Y',
  teamA: 'Stanford',
  teamALogo: logoPath + 'TxEa6Awt5quagSXQIhqtk',
  teamB: 'Cal',
  teamBLogo: logoPath + 'cFqM7q75rvGOTzliayNOC',
  teamC: 'Air Force',
  teamCLogo: logoPath + 'AAz9Ikazg9k-YNOWvNnLa',
  teamD: 'Oklahoma',
  teamDLogo: logoPath + 'hqph8HiyQRcSV8QF_G78t',
  gameTime: '2022-04-02T23:00:00.000Z',
  title: 'MPSF Championships @ Stanford',
};

export const matchAJ = {
  sessionId: 'JQYjKl5-AA',
  teamB: 'W&M Gold',
  teamBLogo: logoPath + 'KHVR7ujdqBH7LXG57CuO8',
  teamA: 'W&M Green',
  teamALogo: logoPath + 'KHVR7ujdqBH7LXG57CuO8',
  gameTime: '2022-12-09T23:30:00.000Z',
};

export const matchAK = {
  sessionId: 'BaUnU7ditc',
  teamA: 'Team West',
  teamALogo: logoPath + 'zcoCCYBpS0S8SJVBd1d_D',
  teamB: 'Team East',
  teamBLogo: logoPath + 'rAKOnIsH9NcK7FmMjYiKq',
  gameTime: '2022-12-23T00:00:00.000Z',
  title: 'CGA All Stars 2022',
  matchLogo: '',
};

export const matchAL = {
  sessionId: 'vO_Vc1fxDw',
  teamA: 'Stanford',
  teamALogo: logoPath + 'TxEa6Awt5quagSXQIhqtk',
  gameTime: '2023-01-15T03:00:00.000Z',
  title: 'Stanford @ Cal Benefit Cup',
  matchLogo: '',
};

export const matchAM = {
  sessionId: 'pINlu1EYcd',
  teamA: 'William & Mary',
  teamALogo: logoPath + 'KHVR7ujdqBH7LXG57CuO8',
  teamB: 'Simpson',
  teamBLogo: logoPath + '5iJiCZuUmn0V7FPYx6-Oh',
  gameTime: '2023-01-15T19:00:00.000Z',
  title: 'William & Mary vs Simpson',
  matchLogo: '',
};

export const matchAN = {
  sessionId: 'TZb7htNeNp',
  teamALogo: logoPath + 'TxEa6Awt5quagSXQIhqtk',
  teamA: 'Stanford',
  teamB: 'Cal',
  teamBLogo: logoPath + 'cFqM7q75rvGOTzliayNOC',
  teamC: 'Team USA',
  teamCLogo: logoPath + 'E5hyh2M2uDgtpZLxr5-6o',
  teamD: 'Grand Prix',
  teamDLogo: logoPath + 'jgiLKKl54XFRorCVH4-E3',
  gameTime: '2023-01-29T03:00:00.000Z',
  title: 'Stanford Open: Grand Prix',
  matchLogo: '',
};

export const matchAO = {
  sessionId: 'eCegNClKQU',
  teamA: 'Springfield',
  teamALogo: logoPath + 'uGniv3T8oFPiagZqgWXwv',
  teamB: 'Army',
  teamBLogo: logoPath + 'wmICCck438BshXvBlPHV_',
  gameTime: '2023-01-29T18:00:00.000Z',
  title: 'Springfield vs Army',
  matchLogo: '',
};

export const matchAP = {
  sessionId: 'LIi818EOxC',
  teamA: 'Army',
  teamALogo: logoPath + 'wmICCck438BshXvBlPHV_',
  teamB: 'Navy',
  teamBLogo: logoPath + 'QtMgsTKjUwl0sEQwnlwNT',
  gameTime: '2023-02-04T18:00:00.000Z',
  title: 'Army vs Navy',
  matchLogo: '',
};

export const matchAQ = {
  sessionId: '8g01IPBdWX',
  teamA: 'Springfield',
  teamALogo: logoPath + 'uGniv3T8oFPiagZqgWXwv',
  teamB: 'William & Mary',
  teamBLogo: logoPath + 'KHVR7ujdqBH7LXG57CuO8',
  gameTime: '2023-02-05T18:00:00.000Z',
  title: 'Springfield vs William & Mary',
  matchLogo: '',
};

export const matchAR = {
  sessionId: '4JxXc7VBrT',
  teamA: 'Simpson',
  teamALogo: logoPath + 'e4ReYbsesszxOxrprmRfI',
  teamBLogo: logoPath + 'DvpQ8aJqMsi2aXyHkqWpb',
  teamB: 'Greenville',
  gameTime: '2023-02-11T17:00:00.000Z',
  title: 'Simpson vs Greenville (M)',
};

export const matchAS = {
  sessionId: 'fwxxv8AmLj',
  teamB: 'Cal',
  teamBLogo: logoPath + 'cFqM7q75rvGOTzliayNOC',
  teamALogo: logoPath + 'uGniv3T8oFPiagZqgWXwv',
  teamA: 'Springfield',
  gameTime: '2023-02-17T20:00:00.000Z',
  title: 'Springfield vs Cal',
};

export const matchAT = {
  sessionId: 'QFEA-BxU64',
  teamA: 'Stanford',
  teamALogo: logoPath + 'TxEa6Awt5quagSXQIhqtk',
  teamBLogo: logoPath + 'cFFAbpy0KKyUf-ryB9z5c',
  teamB: 'Bay Area Bandits (Norcal)',
  gameTime: '2023-02-19T00:00:00.000Z',
  title: 'Stanford vs Bandits (Norcal)',
};

export const matchAU = {
  sessionId: 'unmQCxsZp4',
  teamA: 'Stanford',
  teamALogo: logoPath + 'TxEa6Awt5quagSXQIhqtk',
  teamBLogo: logoPath + 'cFqM7q75rvGOTzliayNOC',
  teamB: 'Cal',
  teamCLogo: logoPath + 't9wWLsOPF2bpVZHqjsogl',
  teamC: 'Illinois',
  gameTime: '2023-03-05T00:00:00.000Z',
  title: 'Stanford vs Cal, Illinois (Senior Night)',
};

export const matchAV = {
  sessionId: 'Yeq26is6yS',
  teamA: 'Simpson',
  teamALogo: logoPath + '5iJiCZuUmn0V7FPYx6-Oh',
  teamBLogo: logoPath + 'uGniv3T8oFPiagZqgWXwv',
  teamB: 'Springfield',
  teamC: 'Greenville',
  teamCLogo: logoPath + 'DvpQ8aJqMsi2aXyHkqWpb',
  gameTime: '2023-03-05T19:00:00.000Z',
  title: 'Simpson vs Springfield, Greenville',
};

export const matchAW = {
  sessionId: 'DgwUvWxYIx',
  teamA: 'Simpson',
  teamALogo: logoPath + '5iJiCZuUmn0V7FPYx6-Oh',
  teamBLogo: logoPath + 'QtMgsTKjUwl0sEQwnlwNT',
  teamB: 'Navy',
  gameTime: '2023-03-16T23:00:00.000Z',
  title: 'Simpson vs Navy',
};

export const matchAX = {
  sessionId: 'ApATXLVE0X',
  teamA: 'William & Mary',
  teamALogo: logoPath + 'KHVR7ujdqBH7LXG57CuO8',
  teamBLogo: logoPath + '6Ju2q59cmDgc7NU-VCdEg',
  teamB: 'Penn State',
  gameTime: '2023-03-19T17:00:00.000Z',
  title: 'William & Mary vs Penn State',
};

export const matchAY = {
  sessionId: 'oaH0N1WfAP',
  teamA: 'William & Mary',
  teamALogo: logoPath + 'KHVR7ujdqBH7LXG57CuO8',
  teamB: 'Simpson',
  teamBLogo: logoPath + '5iJiCZuUmn0V7FPYx6-Oh',
  teamC: 'Air Force',
  teamCLogo: logoPath + 'AAz9Ikazg9k-YNOWvNnLa',
  teamD: 'Navy',
  teamDLogo: logoPath + 'QtMgsTKjUwl0sEQwnlwNT',
  teamE: 'Springfield',
  teamELogo: logoPath + 'uGniv3T8oFPiagZqgWXwv',
  gameTime: '2023-03-24T17:00:00.000Z',
  title: 'USAG Collegiate Championships (Team Finals)',
};

export const matchAZ = {
  sessionId: '-Dhm9X55Zq',
  teamA: 'William & Mary',
  teamALogo: logoPath + 'KHVR7ujdqBH7LXG57CuO8',
  teamB: 'Simpson',
  teamBLogo: logoPath + '5iJiCZuUmn0V7FPYx6-Oh',
  teamC: 'Air Force',
  teamCLogo: logoPath + 'AAz9Ikazg9k-YNOWvNnLa',
  teamD: 'Navy',
  teamDLogo: logoPath + 'QtMgsTKjUwl0sEQwnlwNT',
  teamE: 'Springfield',
  teamELogo: logoPath + 'uGniv3T8oFPiagZqgWXwv',
  gameTime: '2023-03-25T17:00:00.000Z',
  title: 'USAG Collegiate Championships (Event Finals)',
};

export const matchA24 = {
  sessionId: 'DbzeVot0Zr',
  teamA: 'Michigan',
  teamALogo: logoPath + '_pStX-aYWTvumVvf_BPYe',
  teamB: 'Simpson',
  teamBLogo: logoPath + '5iJiCZuUmn0V7FPYx6-Oh',
  teamC: 'Greenville',
  teamCLogo: logoPath + 'DvpQ8aJqMsi2aXyHkqWpb',
  gameTime: '2024-01-06T18:00:00.000Z',
};

export const matchB24 = {
  sessionId: '0gxtvSN6rc',
  teamA: 'Michigan',
  teamALogo: logoPath + '_pStX-aYWTvumVvf_BPYe',
  teamB: 'Army',
  teamBLogo: logoPath + 'wmICCck438BshXvBlPHV_',
  gameTime: '2024-01-20T18:00:00.000Z',
};

export const matchC24 = {
  sessionId: 'bcLqSq4o3I',
  teamA: 'Greenville',
  teamALogo: logoPath + 'DvpQ8aJqMsi2aXyHkqWpb',
  teamB: 'Illinois',
  teamBLogo: logoPath + 't9wWLsOPF2bpVZHqjsogl',
  gameTime: '2024-01-20T20:00:00.000Z',
};

export const matchD24 = {
  sessionId: '8s-iyLHTl8',
  teamA: 'Navy',
  teamALogo: logoPath + 'QtMgsTKjUwl0sEQwnlwNT',
  teamB: 'William & Mary',
  teamBLogo: logoPath + 'MG4yA_-sLEqBckLyfy71-',
  gameTime: '2024-01-27T20:00:00.000Z',
};

export const matchE24 = {
  sessionId: '01aIGrasK8',
  teamA: 'Stanford',
  teamALogo: logoPath + 'TxEa6Awt5quagSXQIhqtk',
  teamB: 'Cal',
  teamBLogo: logoPath + 'cFqM7q75rvGOTzliayNOC',
  teamC: 'Team USA',
  teamCLogo: logoPath + 'E5hyh2M2uDgtpZLxr5-6o',
  gameTime: '2024-01-28T03:00:00.000Z',
  title: 'Stanford Open',
};

const matchF24 = {
  sessionId: 'hT4zpKsJuL',
  gameTime: '2024-02-03T19:00:00.000Z',
  title: 'Navy vs Army ',
  teamA: 'Navy',
  teamALogo: 'upload/images/team/QtMgsTKjUwl0sEQwnlwNT',
  teamB: 'Army',
  teamBLogo: 'upload/images/team/wmICCck438BshXvBlPHV_',
};

const matchG24 = {
  sessionId: '9Ad2fMlD-K',
  gameTime: '2024-02-03T19:00:00.000Z',
  title: 'Simpson vs Greenville ',
  teamA: 'Simpson',
  teamALogo: 'upload/images/team/5iJiCZuUmn0V7FPYx6-Oh',
  teamB: 'Greenville',
  teamBLogo: 'upload/images/team/ENm2b6qi96JA3DRXNyvt9',
};

const matchH24 = {
  sessionId: 'yVrG4vssSA',
  gameTime: '2024-02-04T00:00:00.000Z',
  title: 'Stanford vs California ',
  teamA: 'Stanford',
  teamALogo: 'upload/images/team/TxEa6Awt5quagSXQIhqtk',
  teamB: 'California',
  teamBLogo: 'upload/images/team/cFqM7q75rvGOTzliayNOC',
};

const matchI24 = {
  sessionId: '7nBm4hm00d',
  gameTime: '2024-02-10T18:00:00.000Z',
  title: 'Michigan vs Nebraska ',
  teamA: 'Michigan',
  teamALogo: 'upload/images/team/OlF6zJbX8C_3TY7v_vL5S',
  teamB: 'Nebraska',
  teamBLogo: 'upload/images/team/vgpxEJbZU4AIfAjSJ6-ru',
};

const matchJ24 = {
  sessionId: 'vgsJI4Rw96',
  gameTime: '2024-02-11T00:00:00.000Z',
  title: 'Stanford vs Team USA, Bay Area Bandits',
  teamA: 'Stanford',
  teamALogo: 'upload/images/team/TxEa6Awt5quagSXQIhqtk',
  teamB: 'USA',
  teamBLogo: 'upload/images/team/E5hyh2M2uDgtpZLxr5-6o',
  teamC: 'Bandits',
  teamCLogo: 'upload/images/team/cFFAbpy0KKyUf-ryB9z5c',
};

const matchK24 = {
  sessionId: 'guMkwbpe40',
  gameTime: '2024-02-11T18:00:00.000Z',
  title: 'Springfield vs William & Mary',
  teamA: 'Springfield',
  teamALogo: 'upload/images/team/uGniv3T8oFPiagZqgWXwv',
  teamB: 'William & Mary',
  teamBLogo: 'upload/images/team/MG4yA_-sLEqBckLyfy71-',
};

const matchL24 = {
  sessionId: 'N5VwAjON3T',
  gameTime: '2024-02-17T18:00:00.000Z',
  title: 'Michigan vs Springfield, Arizona State',
  teamA: 'Michigan',
  teamALogo: 'upload/images/team/OlF6zJbX8C_3TY7v_vL5S',
  teamB: 'Springfield',
  teamBLogo: 'upload/images/team/uGniv3T8oFPiagZqgWXwv',
  teamC: 'Arizona State',
  teamCLogo: 'upload/images/team/izSAKpk7iFF9mstqq6Dbb',
};

const matchM24 = {
  sessionId: 'QokWtWZhn1',
  gameTime: '2024-02-17T19:00:00.000Z',
  title: 'Simpson vs Oklahoma',
  teamA: 'Simpson',
  teamALogo: 'upload/images/team/5iJiCZuUmn0V7FPYx6-Oh',
  teamB: 'Oklahoma',
  teamBLogo: 'upload/images/team/hqph8HiyQRcSV8QF_G78t',
};

const matchN24 = {
  sessionId: 'dDI7oPH-S9',
  gameTime: '2024-02-18T20:00:00.000Z',
  title: 'William & Mary vs California',
  teamA: 'William & Mary',
  teamALogo: 'upload/images/team/MG4yA_-sLEqBckLyfy71-',
  teamB: 'California',
  teamBLogo: 'upload/images/team/cFqM7q75rvGOTzliayNOC',
};

const matchO24 = {
  sessionId: 'lxkhhp_ZY7',
  gameTime: '2024-02-10T00:30:00.534Z',
  title: "EVO Elite Collegiate Open '24",
  teamA: 'Evo Athletics',
  teamALogo: 'upload/images/team/c2UvHSqg_fSNBp3SWx3yF',
  teamB: 'Illinois',
  teamBLogo: 'upload/images/team/t9wWLsOPF2bpVZHqjsogl',
  teamC: 'Simpson',
  teamCLogo: 'upload/images/team/5iJiCZuUmn0V7FPYx6-Oh',
  teamD: 'Florida Storm',
  teamDLogo: 'upload/images/team/xl9LOJKpyrSivEzpZRGpf',
  teamE: 'Georgia United',
  teamELogo: 'upload/images/team/r_Hk7su649BxkqFf33jVY',
};

const matchP24 = {
  sessionId: 'n5yNzW5pVj',
  gameTime: '2024-03-02T18:00:00.000Z',
  title: 'Michigan vs Oklahoma',
  teamA: 'Michigan',
  teamALogo: 'upload/images/team/OlF6zJbX8C_3TY7v_vL5S',
  teamB: 'Oklahoma',
  teamBLogo: 'upload/images/team/hqph8HiyQRcSV8QF_G78t',
};

const matchQ24 = {
  sessionId: 'CmyyxjD94C',
  gameTime: '2024-03-03T00:00:00.000Z',
  title: 'Greenville vs William & Mary',
  teamA: 'Greenville',
  teamALogo: 'upload/images/team/ENm2b6qi96JA3DRXNyvt9',
  teamB: 'William & Mary',
  teamBLogo: 'upload/images/team/MG4yA_-sLEqBckLyfy71-',
};

const matchR24 = {
  sessionId: 'CD8NMJ7Arl',
  gameTime: '2024-03-03T20:00:00.000Z',
  title: 'California vs Nebraska, Air Force, Stanford',
  teamA: 'California',
  teamALogo: 'upload/images/team/cFqM7q75rvGOTzliayNOC',
  teamB: 'Nebraska',
  teamBLogo: 'upload/images/team/vgpxEJbZU4AIfAjSJ6-ru',
  teamC: 'Air Force',
  teamCLogo: 'upload/images/team/AAz9Ikazg9k-YNOWvNnLa',
  teamD: 'Stanford',
  teamDLogo: 'upload/images/team/TxEa6Awt5quagSXQIhqtk',
};

const matchS24 = {
  sessionId: 'a-TlzL_0TN',
  gameTime: '2024-03-09T19:00:00.000Z',
  title: 'Greenville vs Simpson',
  teamA: 'Greenville',
  teamALogo: 'upload/images/team/ENm2b6qi96JA3DRXNyvt9',
  teamB: 'Simpson',
  teamBLogo: 'upload/images/team/5iJiCZuUmn0V7FPYx6-Oh',
};

const matchT24 = {
  sessionId: 'Q8F6RIEMqx',
  gameTime: '2024-03-09T21:00:00.000Z',
  title: 'Air Force vs Stanford',
  teamA: 'Air Force',
  teamALogo: 'upload/images/team/AAz9Ikazg9k-YNOWvNnLa',
  teamB: 'Stanford',
  teamBLogo: 'upload/images/team/TxEa6Awt5quagSXQIhqtk',
};

const matchU24 = {
  sessionId: 'GKNv-VCQ1c',
  gameTime: '2024-03-16T17:00:00.000Z',
  title: 'Springfield vs Army',
  teamA: 'Springfield',
  teamALogo: 'upload/images/team/uGniv3T8oFPiagZqgWXwv',
  teamB: 'Army',
  teamBLogo: 'upload/images/team/wmICCck438BshXvBlPHV_',
};

const matchV24 = {
  sessionId: 'k9SRiSm8PK',
  gameTime: '2024-03-16T18:00:00.000Z',
  title: 'William & Mary vs Navy',
  teamA: 'William & Mary',
  teamALogo: 'upload/images/team/MG4yA_-sLEqBckLyfy71-',
  teamB: 'Navy',
  teamBLogo: 'upload/images/team/QtMgsTKjUwl0sEQwnlwNT',
};

const matchW24 = {
  sessionId: 'DkL-dCgX-9',
  gameTime: '2024-03-16T20:00:00.000Z',
  title: 'Air Force vs Simpson',
  teamA: 'Air Force',
  teamALogo: 'upload/images/team/AAz9Ikazg9k-YNOWvNnLa',
  teamB: 'Simpson',
  teamBLogo: 'upload/images/team/5iJiCZuUmn0V7FPYx6-Oh',
};

const matchX24 = {
  sessionId: 'QuSfiSlwuK',
  gameTime: '2024-03-16T23:00:00.000Z',
  title: 'Stanford vs California, Oklahoma',
  teamA: 'Stanford',
  teamALogo: 'upload/images/team/TxEa6Awt5quagSXQIhqtk',
  teamB: 'California',
  teamBLogo: 'upload/images/team/cFqM7q75rvGOTzliayNOC',
  teamC: 'Oklahoma',
  teamCLogo: 'upload/images/team/hqph8HiyQRcSV8QF_G78t',
};

const matchY24 = {
  sessionId: 'D9YEazNnWv',
  gameTime: '2024-03-17T20:00:00.000Z',
  title: 'Michigan vs Penn State',
  teamA: 'Michigan',
  teamALogo: 'upload/images/team/OlF6zJbX8C_3TY7v_vL5S',
  teamB: 'Penn State',
  teamBLogo: 'upload/images/team/6Ju2q59cmDgc7NU-VCdEg',
};

const matchZ24 = {
  sessionId: 'zZgp53Fv8d',
  gameTime: '2024-03-22T20:00:00.000Z',
  title: "USAG Collegiate Championships '24 (Team & AA)",
  teamA: 'Springfield',
  teamALogo: 'upload/images/team/uGniv3T8oFPiagZqgWXwv',
  teamB: 'Greenville',
  teamBLogo: 'upload/images/team/ENm2b6qi96JA3DRXNyvt9',
  teamC: 'William & Mary',
  teamCLogo: 'upload/images/team/MG4yA_-sLEqBckLyfy71-',
  teamD: 'Simpson',
  teamDLogo: 'upload/images/team/5iJiCZuUmn0V7FPYx6-Oh',
  teamE: 'Navy',
  teamELogo: 'upload/images/team/QtMgsTKjUwl0sEQwnlwNT',
  teamF: 'Air Force',
  teamFLogo: 'upload/images/team/AAz9Ikazg9k-YNOWvNnLa',
};

const matchAA24 = {
  sessionId: 'lW_UvTyk80',
  gameTime: '2024-03-23T19:00:00.000Z',
  title: "USAG Collegiate Championships '24 (Event Finals)",
  teamA: 'USAG Collegiate Event Finalists',
  teamALogo: 'upload/images/team/Na0lT0sy5rOcenjVimkba',
};

const matchAB24 = {
  sessionId: 'nYxWA4GxY4',
  gameTime: '2024-04-06T17:00:00.000Z',
  title: "ECAC Championship '24",
  teamA: 'Greenville',
  teamALogo: 'upload/images/team/ENm2b6qi96JA3DRXNyvt9',
  teamB: 'Navy',
  teamBLogo: 'upload/images/team/QtMgsTKjUwl0sEQwnlwNT',
  teamC: 'Army',
  teamCLogo: 'upload/images/team/wmICCck438BshXvBlPHV_',
  teamD: 'Simpson',
  teamDLogo: 'upload/images/team/5iJiCZuUmn0V7FPYx6-Oh',
  teamE: 'Springfield',
  teamELogo: 'upload/images/team/uGniv3T8oFPiagZqgWXwv',
  teamF: 'William & Mary',
  teamFLogo: 'upload/images/team/MG4yA_-sLEqBckLyfy71-',
};

export const matches2024 = [
  [matchAB24],
  [],
  [],
  [],
  [matchZ24, matchAA24],
  [matchU24, matchV24, matchW24, matchX24, matchY24],
  [matchS24, matchT24],
  [matchP24, matchQ24, matchR24],
  [matchL24, matchM24, matchN24],
  [matchI24, matchJ24, matchK24],
  [matchO24],
  [matchF24, matchG24, matchH24],
  [],
  [matchD24, matchE24],
  [matchB24, matchC24],
  [matchA24],
];

export const matches2023 = [
  [matchAY, matchAZ],
  [matchAW, matchAX],
  [matchAU, matchAV],
  [matchAS, matchAT],
  [matchAR],
  [matchAP, matchAQ],
  [matchAN, matchAO],
  [matchAL, matchAM],
  [matchAK],
];

export const matches2022 = [
  [matchAJ],
  [matchAH, matchAI],
  [matchAF, matchAG],
  [matchAE],
  [matchAD],
  [matchAC],
  [matchAB],
  [matchAA],
];

export const matches2021 = [
  [matchZ],
  [matchY],
  [matchW, matchX],
  [matchU, matchV],
  [matchR, matchS, matchT],
  [matchP, matchQ],
  [matchM, matchN, matchO],
  [matchJ, matchK, matchL],
  [matchG, matchH, matchI],
  [matchD, matchE, matchF],
  [matchA, matchB, matchC],
];

export function useMatch(key) {
  const { data } = useQuery(GetMatch, {
    variables: {
      sessionKey: key,
    },
  });

  return data;
}
