import React, { useImperativeHandle } from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { adminActions, alertActions } from '../../redux/_actions';
import * as Yup from 'yup';
import { dateTime } from '../../utilities/conversions';

export const SessionLineups = React.forwardRef((props, ref) => {
  const { sessionId, isEditing, isSaving, reset, setIsLoading } = props;
  const { sessions, lineups, teams } = useSelector((state) => state.admin);
  const session = sessions.byId[sessionId];
  const sessionLineups = session.lineups.items
    .map((id) => lineups.byId[id])
    .sort((a, b) => a.order - b.order);
  const dispatch = useDispatch();

  useImperativeHandle(ref, () => ({
    submit() {
      handleSubmit();
    },
  }));

  const emptyList = () => {
    return (
      <Row className="vCenter emptyList">
        <span>No lineups created.</span>
      </Row>
    );
  };

  const validationSchema = Yup.object().shape({
    titles: Yup.array().of(Yup.string()),
    orders: Yup.array().of(Yup.number()),
    lineupDatas: Yup.array().of(Yup.string()),
    forceTitles: Yup.array().of(Yup.boolean()),
    _deleted: Yup.array().of(Yup.boolean()),
    individuals: Yup.array().of(Yup.boolean()),
  });

  const initialValues = {
    titles: sessionLineups.map((lineup) => lineup.title ?? ''),
    orders: sessionLineups.map((lineup, i) => lineup.order ?? i),
    lineupDatas: sessionLineups.map((lineup) => lineup?.lineupData ?? ''),
    forceTitles: sessionLineups.map((lineup) => !!lineup?.forceTitle),
    _deleted: sessionLineups.map((lineup) => !!lineup?._deleted),
    individuals: sessionLineups.map((lineup) => !!lineup?.individuals),
  };

  const { setFieldValue, handleSubmit, values } = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit(values) {
      setIsLoading(true);
      dispatch(alertActions.clear());

      const payload = sessionLineups.map((lineup, i) => {
        return initialValues.titles[i] !== values.titles[i] ||
          initialValues.orders[i] !== values.orders[i] ||
          initialValues.lineupDatas[i] !== values.lineupDatas[i] ||
          initialValues.forceTitles[i] !== values.forceTitles[i] ||
          initialValues._deleted[i] !== values._deleted[i] ||
          initialValues.individuals[i] !== values.individuals[i]
          ? {
              id: lineup.id,
              _version: lineup._version,
              title: values.titles[i],
              order: values.orders[i],
              lineupData:
                values.lineupDatas[i] === '' ? null : values.lineupDatas[i],
              forceTitle: values.forceTitles[i],
              _deleted: values._deleted[i],
              individuals: values.individuals[i],
            }
          : false;
      });

      if (payload.every((el) => el === false)) {
        dispatch(alertActions.success('No changes made.'));
        reset();
        return;
      }

      dispatch(adminActions.updateLineupTitles(payload));
    },
  });

  const setupBody = () => {
    if (sessionLineups.length === 0) {
      return emptyList();
    } else {
      return (
        <ul>
          {sessionLineups.map((lineup, i) => {
            return (
              <li key={lineup.id}>
                <Row>
                  <Col className="title" sm={2}>
                    Order:
                  </Col>
                  <Col sm={2}>
                    <Form.Control
                      plaintext
                      name="order"
                      type="number"
                      step="1"
                      min="0"
                      autoComplete="off"
                      disabled={isSaving || !isEditing}
                      value={values.orders[i] ?? ''}
                      onChange={(e) =>
                        setFieldValue(
                          'orders',
                          Object.assign([...values.orders], {
                            [i]: e.target.value,
                          })
                        )
                      }
                    />
                  </Col>
                  <Col className="title" sm={2}>
                    Updated:
                  </Col>
                  <Col sm={3}>{dateTime(lineup.updatedAt, true)}</Col>
                  <Col className="title" sm={2}>
                    Deleted:
                  </Col>
                  <Col sm={1}>
                    <Form.Check
                      type="checkbox"
                      custom
                      id={`deleted${i}`}
                      disabled={isSaving || !isEditing}
                      checked={values?._deleted[i]}
                      onChange={(e) =>
                        setFieldValue(
                          '_deleted',
                          Object.assign([...values._deleted], {
                            [i]: !values._deleted[i],
                          })
                        )
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col className="title" sm={2}>
                    ID:
                  </Col>
                  <Col>{lineup.id}</Col>
                </Row>
                <Row>
                  <Col className="title" sm={2}>
                    Team:
                  </Col>
                  <Col sm={5}>{teams.byId[lineup.teamId]?.name}</Col>
                  <Col className="title" sm={1}>
                    Alt:
                  </Col>
                  <Col sm={4}>
                    {teams.byId[lineup.teamId]?.altNames &&
                      JSON.parse(teams.byId[lineup.teamId]?.altNames).join(
                        ', '
                      )}
                  </Col>
                </Row>
                <Row>
                  <Col className="title" sm={2}>
                    Title:
                  </Col>
                  <Col>
                    <Form.Control
                      plaintext
                      name="title"
                      type="text"
                      autoComplete="off"
                      disabled={isSaving || !isEditing}
                      value={values.titles[i] ?? ''}
                      onChange={(e) =>
                        setFieldValue(
                          'titles',
                          Object.assign([...values.titles], {
                            [i]: e.target.value,
                          })
                        )
                      }
                      placeholder="Edit to add title..."
                    />
                  </Col>
                </Row>
                <Row>
                  <Col className="title" sm={2}>
                    Force Title:
                  </Col>
                  <Col sm={7}>
                    <Form.Check
                      type="checkbox"
                      label="(Force title to be displayed instead of team name)"
                      custom
                      id={`forceTitle${i}`}
                      disabled={isSaving || !isEditing}
                      checked={values?.forceTitles[i]}
                      onChange={(e) =>
                        setFieldValue(
                          'forceTitles',
                          Object.assign([...values.forceTitles], {
                            [i]: !values.forceTitles[i],
                          })
                        )
                      }
                    />
                  </Col>
                  <Col className="title" sm={2}>
                    Individuals:
                  </Col>
                  <Col sm={1}>
                    <Form.Check
                      type="checkbox"
                      custom
                      id={`individuals${i}`}
                      disabled={isSaving || !isEditing}
                      checked={values?.individuals[i]}
                      onChange={(e) =>
                        setFieldValue(
                          'individuals',
                          Object.assign([...values.individuals], {
                            [i]: !values.individuals[i],
                          })
                        )
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col className="title" sm={2}>
                    Data:
                  </Col>
                  <Col>
                    <Form.Control
                      plaintext
                      name="lineupData"
                      type="text"
                      autoComplete="off"
                      disabled={isSaving || !isEditing}
                      value={values.lineupDatas[i] ?? ''}
                      onChange={(e) =>
                        setFieldValue(
                          'lineupDatas',
                          Object.assign([...values.lineupDatas], {
                            [i]: e.target.value,
                          })
                        )
                      }
                      placeholder="Edit to add lineup data..."
                    />
                  </Col>
                </Row>
              </li>
            );
          })}
        </ul>
      );
    }
  };

  return (
    <Form onSubmit={null} className="sessionLineups">
      {setupBody()}
    </Form>
  );
});
