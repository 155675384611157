import { forwardRef, useState, useImperativeHandle, useEffect } from 'react';
import { Row, Col, Form, Accordion, Card, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { adminActions, alertActions } from '../../redux/_actions';
import * as Yup from 'yup';
import './sessionroutines.css';
import { wGymAbbv, mGymAbbv } from '../../utilities/export';
import { GenderType, RoutineStatus } from '../../models';
import { useApolloClient, useQuery } from '@apollo/client';
import { updateRoutine, useErrorHandler } from '../../apollo/helpers';
import GetRoutine from '../../apollo/queries/GetRoutine.graphql';
import { convertMillipointsToDisplay } from '../../utilities/scoring';
import { useRoutinesByApparatusEC } from '../../utilities/clips';

function rotationNumberToEventName(rotation, gender) {
  return gender === GenderType.FEMALE ? wGymAbbv(rotation) : mGymAbbv(rotation);
}

function ApparatusCard({ lineupId, apparatus, setErrors }) {
  const apolloClient = useApolloClient();
  const dispatch = useDispatch();
  const {
    routines: allRoutines,
    duplicates,
    errors,
  } = useRoutinesByApparatusEC(lineupId);
  const routines = (allRoutines && allRoutines[apparatus]) || [];

  const handleUpdateRoutineErrors = useErrorHandler(
    'There was a problem updating the routine'
  );

  useEffect(() => {
    if (
      errors.length > 0 &&
      duplicates.some((d) => d.apparatus === apparatus)
    ) {
      dispatch(alertActions.error(errors[0]));
      console.error(errors[0], { errors, duplicates });
      setErrors({ lineupId, msg: '⚠ Duplicates Detected' });
    }

    if (errors.length === 0) {
      setErrors(null);
      dispatch(alertActions.clear());
    }
  }, [errors.length]);

  const handleDeleteRoutine = (routine) => {
    updateRoutine(apolloClient, routine, {
      status: RoutineStatus.DELETED,
    }).catch(handleUpdateRoutineErrors);
  };

  const handleEditRoutine = (routine, edit = {}) => {
    updateRoutine(apolloClient, routine, {
      ...edit,
    }).catch(handleUpdateRoutineErrors);
  };

  function RoutineRow({ routineId }) {
    const { data } = useQuery(GetRoutine, {
      variables: { id: routineId },
      skip: !routineId,
    });
    const routine = data?.getRoutine;
    const [editing, setEditing] = useState(false);
    const [newOrder, setNewOrder] = useState(routine.order + 1);
    const [newScore, setNewScore] = useState(routine.score);
    const [newApparatus, setNewApparatus] = useState(routine.apparatus);
    const isDeleted = routine.status === RoutineStatus.DELETED;
    const isDuplicate = duplicates.some(
      (r) => r.id === routineId || r.order === routine.order
    );

    useEffect(() => {
      cancelEditing();
      return () => {
        setEditing(false);
        setNewApparatus(null);
        setNewOrder(null);
        setNewScore(null);
      };
    }, []);

    const cancelEditing = () => {
      setNewApparatus(routine.apparatus);
      setNewOrder(routine.order + 1);
      setEditing(false);
    };

    return (
      <Row
        style={{
          color: isDeleted ? '#aaa' : isDuplicate ? 'red' : null,
          fontWeight: isDuplicate ? 'bold' : null,
          textDecoration: isDeleted ? 'line-through' : null,
        }}
      >
        <Col xs={1} style={{ padding: '0', maxWidth: '3%' }}>
          {editing ? (
            <Form.Control
              value={newOrder}
              onChange={(e) => setNewOrder(e.target.value)}
            />
          ) : (
            routine.order + 1
          )}
        </Col>
        <Col xs={3} style={{ color: editing ? '#aaa' : null }}>
          {routine?.athlete?.name}
        </Col>
        <Col xs={1}>
          {editing ? (
            <Form.Control
              value={newApparatus}
              onChange={(e) => setNewApparatus(e.target.value)}
            />
          ) : (
            routine.apparatus
          )}
        </Col>
        <Col
          style={{
            fontVariantNumeric: 'tabular-nums',
            color: editing ? '#aaa' : null,
          }}
        >
          {' '}
          {editing ? (
            <Form.Control
              value={newScore}
              onChange={(e) => setNewScore(e.target.value)}
            />
          ) : (
            convertMillipointsToDisplay(routine.score)
          )}
        </Col>
        <Col
          style={{ fontStyle: 'italic', color: isDuplicate ? 'red' : '#aaa' }}
        >
          {routine.status.toLowerCase()}
        </Col>
        <Col xs={2}>
          <Button
            variant="light"
            onClick={() =>
              editing
                ? handleEditRoutine(routine, {
                    apparatus: newApparatus,
                    order: newOrder - 1,
                    score: newScore,
                  })
                : setEditing(true)
            }
            style={{ color: editing ? '#007bff' : null, width: '100%' }}
          >
            {editing ? 'Save' : 'Edit'}
          </Button>
        </Col>
        <Col xs={2}>
          <Button
            variant="light"
            onClick={() =>
              editing ? cancelEditing() : handleDeleteRoutine(routine)
            }
            disabled={isDeleted && !editing}
            style={{
              color: isDeleted ? '#aaa' : editing ? null : '#dc3545',
              width: '100%',
            }}
          >
            {editing ? 'Cancel' : 'Delete'}
          </Button>
        </Col>
      </Row>
    );
  }

  return (
    <Card key={`${lineupId}-${apparatus}`}>
      <Accordion.Toggle as={Card.Header} eventKey={`${lineupId}-${apparatus}`}>
        <p style={{ float: 'left' }}>
          {`${apparatus} (${routines?.length ?? 0})`}
        </p>
        <p style={{ float: 'right', color: 'red', fontWeight: 'bold' }}>
          {duplicates.some(
            (d) => d.apparatus === apparatus && d.lineupId === lineupId
          )
            ? ` ⚠ Duplicates Detected`
            : ''}
        </p>
      </Accordion.Toggle>
      <Accordion.Collapse eventKey={`${lineupId}-${apparatus}`}>
        <Card.Body>
          {routines?.map((routine) => {
            return <RoutineRow routineId={routine.id} key={routine.id} />;
          })}
        </Card.Body>
      </Accordion.Collapse>
    </Card>
  );
}

function LineupRoutines({ lineupId, gender, apparatus, setErrors }) {
  return (
    <Accordion>
      {Array.from(apparatus.replace(/0+$/, '')).map((num, i) => {
        // filters trailing 0s

        if (num === 0) {
          return null;
        }

        const apparatusAbbv = rotationNumberToEventName(i + 1, gender);

        return (
          lineupId && (
            <ApparatusCard
              lineupId={lineupId}
              apparatus={apparatusAbbv}
              key={i}
              setErrors={setErrors}
            />
          )
        );
      })}
    </Accordion>
  );
}

function LineupButtons({ lineupId }) {
  const apolloClient = useApolloClient();
  const [editing, setEditing] = useState(false);
  const allRoutines = useRoutinesByApparatusEC(lineupId).routines;
  const routines = Object.keys(allRoutines)
    .map((app) => allRoutines[app])
    .flat();
  const rCount = routines.length || 0;
  const [msg, setMsg] = useState('');

  const handleUpdateRoutineErrors = useErrorHandler(
    'There was a problem deleting the routine'
  );

  const handleDeleteAllRoutines = async () => {
    const handleDeleteRoutine = async (routine, index) => {
      await updateRoutine(apolloClient, routine, {
        status: RoutineStatus.DELETED,
      })
        .then(() => {
          return console.log('Finish Delete: ', index + 1);
        })
        .catch(handleUpdateRoutineErrors);
    };

    try {
      const serial = (funcs) =>
        funcs.reduce(
          (promise, func) =>
            promise.then((result) =>
              func().then(Array.prototype.concat.bind(result))
            ),
          Promise.resolve([])
        );

      const batch = routines.map((r, i) => () => {
        return new Promise((res, rej) => {
          return handleDeleteRoutine(r, i).then(() => {
            setTimeout(() => {
              setMsg(`Deleted ${i + 1} routines.`);
              return res();
            }, 100);
          });
        });
      });

      serial(batch)
        .then((res) => {
          //console.log.bind(console);
          console.log('Delete complete.');
          setEditing(false);
        })
        .catch((e) => console.log(e));
    } catch (e) {
      console.log(e);
      setMsg(e);
      setEditing(false);
    }
  };

  const handleStandbyDelete = () => {
    if (!editing) {
      setMsg(`Are you sure you want to delete ${rCount} routines?`);
    } else {
      setMsg('');
    }
    setEditing(!editing);
  };

  return (
    <Row>
      <Col xs={8} style={{ color: editing ? '#dc3545' : '#007bff' }}>
        {msg}
      </Col>
      <Col xs={2}>
        <Button
          variant="light"
          onClick={handleStandbyDelete}
          style={{
            color: editing ? '#007bff' : null,
            width: '100%',
          }}
        >
          {editing ? 'Cancel' : 'Edit'}
        </Button>
      </Col>
      <Col xs={2}>
        <Button
          variant="light"
          onClick={() => handleDeleteAllRoutines()}
          disabled={!editing}
          style={{
            color: '#dc3545',
            width: '100%',
            paddingLeft: '0',
            paddingRight: '0',
          }}
        >
          Delete All
        </Button>
      </Col>
    </Row>
  );
}

export const SessionRoutines = forwardRef((props, ref) => {
  const { sessionId, reset, setIsLoading } = props;
  const { sessions, lineups, teams } = useSelector((state) => state.admin);
  const session = sessions.byId[sessionId];
  const gender = session.gender;
  const apparatus = session.apparatus;
  const [errors, setErrors] = useState(null);
  const sessionLineups = session.lineups.items
    .map((id) => lineups.byId[id])
    .filter((l) => !l?._deleted)
    .sort((a, b) => a.order - b.order);
  const dispatch = useDispatch();

  useImperativeHandle(ref, () => ({
    submit() {
      handleSubmit();
    },
  }));

  const emptyList = () => {
    return (
      <Row className="vCenter emptyList">
        <span>No routines created.</span>
      </Row>
    );
  };

  const validationSchema = Yup.object().shape({
    titles: Yup.array().of(Yup.string()),
    orders: Yup.array().of(Yup.number()),
  });

  const initialValues = {
    titles: sessionLineups.map((lineup) => lineup.title ?? ''),
    orders: sessionLineups.map((lineup, i) => lineup.order ?? i),
  };

  const { handleSubmit } = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit(values) {
      setIsLoading(true);
      dispatch(alertActions.clear());

      const payload = sessionLineups.map((lineup, i) => {
        return initialValues.titles[i] !== values.titles[i] ||
          initialValues.orders[i] !== values.orders[i]
          ? {
              id: lineup.id,
              _version: lineup._version,
              title: values.titles[i],
              order: values.orders[i],
            }
          : false;
      });

      if (payload.every((el) => el === false)) {
        dispatch(alertActions.success('No changes made.'));
        reset();
        return;
      }

      dispatch(adminActions.updateLineupTitles(payload));
    },
  });

  const setupBody = () => {
    if (sessionLineups.length === 0) {
      return emptyList();
    } else {
      return (
        <>
          {sessionLineups.map((lineup, i) => {
            return (
              <Accordion key={`${lineup.id}_${i}`}>
                <Card key={lineup.id}>
                  <Accordion.Toggle
                    as={Card.Header}
                    eventKey={`${lineup.id}_${i}`}
                  >
                    <p style={{ float: 'left' }}>
                      {teams.byId[lineup.teamId]?.name || lineup?.title}
                      {`${lineup?.forceTitle ? ': ' + lineup?.title : ''}`}
                      {`${lineup?.individuals ? ' (Individuals)' : ''}`}
                    </p>
                    <p
                      style={{
                        float: 'right',
                        color: 'red',
                        fontWeight: 'bold',
                      }}
                    >
                      {errors && errors.lineupId === lineup.id && errors.msg}
                    </p>
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey={`${lineup.id}_${i}`}>
                    <Card.Body>
                      <LineupButtons lineupId={lineup.id} />
                      <LineupRoutines
                        lineupId={lineup.id}
                        gender={gender}
                        apparatus={apparatus}
                        setErrors={setErrors}
                      ></LineupRoutines>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                {/*});*/}
              </Accordion>
            );
          })}
        </>
      );
    }
  };

  return (
    <Form onSubmit={null} className="sessionRoutines">
      {setupBody()}
    </Form>
  );
});
