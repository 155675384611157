import CreateClip from '../mutations/CreateClip.graphql';
import UpdateClip from '../mutations/UpdateClip.graphql';
import GetFullRoutine from '../queries/GetFullRoutine.graphql';

const newOptimisticClip = () => ({
  __typename: 'Clip',
  id: 'FAKE-CLIP-ID',
  _version: 0,
  routine: null,
  streamId: null,
  seekStartSeconds: null,
  seekEndSeconds: null,
  startTime: null,
  endTime: null,
  pgmStartTime: null,
  pgmEndTime: null,
  originURL: null,
  location: null,
  resolution: null,
  fileName: null,
  fps: null,
  bitrate: null,
  fileSize: null,
  source: null,
  other: null,
  updatedAt: new Date(),
});

export function createClip(apolloClient, input) {
  const { getRoutine } = apolloClient.readQuery({
    query: GetFullRoutine,
    variables: {
      id: input.routineId,
    },
  });
  return apolloClient.mutate({
    mutation: CreateClip,
    variables: {
      input,
    },
    optimisticResponse: {
      __typename: 'Mutation',
      createClip: {
        ...newOptimisticClip(),
        ...input,
        routine: getRoutine,
      },
    },
    update: (apolloProxy, { data: { createClip } }) => {
      apolloProxy.writeQuery({
        query: GetFullRoutine,
        variables: {
          id: input.routineId,
        },
        data: {
          getRoutine: {
            ...getRoutine,
            clips: {
              ...getRoutine.clips,
              items: [...getRoutine.clips.items, createClip],
            },
          },
        },
      });
    },
  });
}

export function updateClip(apolloClient, currentData, newData) {
  return apolloClient.mutate({
    mutation: UpdateClip,
    variables: {
      input: {
        id: currentData.id,
        _version: currentData._version,
        ...newData,
      },
    },
    optimisticResponse: {
      __typename: 'Mutation',
      updateClip: {
        ...currentData,
        updatedAt: new Date(),
        ...newData,
      },
    },
  });
}
