export const producerConstants = {
  RESET: 'PRODUCER_RESET',
  INITIALIZE: 'PRODUCER_INITIALIZE',
  SET_END: 'PRODUCER_SET_END',
  EXPORT_DATA: 'PRODUCER_EXPORT_DATA',
  LIVE: 'PRODUCER_LIVE',
  CHANGE_ROUND: 'PRODUCER_CHANGE_ROUND',
  EDIT: 'PRODUCER_EDIT',
  JUDGE: 'PRODUCER_JUDGE',
  CLIP: 'PRODUCER_CLIP',
  UPLOAD: 'PRODUCER_UPLOAD',
  UPDATE_LINEUP_SUCCESS: 'PRODUCER_UPDATE_LINEUP_SUCCESS',
  UPDATE_LINEUP_FAILURE: 'PRODUCER_UPDATE_LINEUP_FAILURE',
  SHOW_SCORES: 'PRODUCER_SHOW_SCORES',
  SORT_LINEUP: 'PRODUCER_SORT_LINEUP',
  SYNC_PRODUCER: 'PRODUCER_SYNC_PRODUCER',
  RESET_LINEUP: 'PRODUCER_RESET_LINEUP',
  FORCE: 'PRODUCER_FORCE',
  FORCE_SELECT: 'PRODUCER_FORCE_SELECT',
  UNDO: 'PRODUCER_UNDO',
  LEADERBOARD: 'PRODUCER_SHOW_LEADERBOARD',
  TEAM_BANNER_CHANGE: 'PRODUCER_TEAM_BANNER_CHANGE',
};

export const exportDataHeaders = [
  'Round',
  'Order',
  /*'ID',*/
  'Name',
  'Team',
  'Rotation',
  'Score',
  'Difficulty',
  'Execution',
  'Neutral Deduction',
  'Stick Bonus',
  'Inquiry',
  'Date',
  'Event',
  'Start Time',
  'End Time',
];

// Key used to identify in drag and drop, cannot change with data, could use athleteId, but that is not assigned in open session cases
export const emptyLineup = [
  {
    name: '',
    evaluation: null,
    score: null,
    order: 1,
    athleteId: null,
    key: null,
    startTime: null,
    endTime: null,
  },
  {
    name: '',
    evaluation: null,
    score: null,
    order: 2,
    athleteId: null,
    key: null,
    startTime: null,
    endTime: null,
  },
  {
    name: '',
    evaluation: null,
    score: null,
    order: 3,
    athleteId: null,
    key: null,
    startTime: null,
    endTime: null,
  },
  {
    name: '',
    evaluation: null,
    score: null,
    order: 4,
    athleteId: null,
    key: null,
    startTime: null,
    endTime: null,
  },
  {
    name: '',
    evaluation: null,
    score: null,
    order: 5,
    athleteId: null,
    key: null,
    startTime: null,
    endTime: null,
  },
  {
    name: '',
    evaluation: null,
    score: null,
    order: 6,
    athleteId: null,
    key: null,
    startTime: null,
    endTime: null,
  },
];

export const emptyTeam = {
  index: 0,
  home: false,
  lineup: null,
  score: '0.000',
  logo: null,
  name: null,
  color: '#00A2FF',
};

export const initialProducerState = {
  exportData: null,
  teamA: Object.assign({}, emptyTeam),
  teamB: Object.assign({}, emptyTeam),
  round: 1,
  loaded: false,
  live: false,
  type: null,
  edit: false,
  upload: false,
  clip: false,
  judge: false,
  scores: false,
  force: false,
  undo: false,
  leaderboard: false,
  completedRounds: [],
  teams: [],
};
