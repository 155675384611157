import { useContext, useState } from 'react';
import { Dropdown, Table, Button } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { producerActions } from '../../redux/_actions';
import { mApparatusAbbv, wApparatusAbbv } from '../../utilities/constants';
import { MEDIAURL } from '../../utilities/constants';
import {
  genderType,
  sessionRoleType,
  sessionViewType,
  sessionType,
} from '../../redux/_constants';
import { useHistory, useLocation } from 'react-router-dom';
import * as Icons from '../helpers/icons';
import { useHotkeys } from 'react-hotkeys-hook';
import './roundcount.css';
import { useRotationsByTeam, useRotationsByLineup } from './hooks';
import { SessionContext } from './multiexperimental';

const ALIGN = {
  LEFT: 'LEFT',
  CENTER: 'CENTER',
  RIGHT: 'RIGHT',
};

function RoundCount({ align, judgeLock }) {
  const [show, setShow] = useState(false);
  const gender = useSelector((state) => state.session.gender);
  const view = useSelector((state) => state.session.view);
  const sessionId = useSelector((state) => state.session.id);
  const sessionKey = useSelector((state) => state.session.sessionKey);
  const sessionApparatus = useSelector((state) => state.session.apparatus);
  const round = useSelector((state) => state.producer.round);
  const isLive = useSelector((state) => state.producer.live);
  const isCompetition = useSelector((state) => state.session.competition);
  const dispatch = useDispatch();
  const role = useSelector((state) => state.session.role);
  const type = useSelector((state) => state.session.type);
  const isAdmin = role === sessionRoleType.ADMIN;
  const isJudge = role === sessionRoleType.JUDGE;
  const isSoloSession = type === sessionType.SOLO;
  const isSoloView =
    [sessionViewType.SOLOLINEUP, sessionViewType.SOLOPLAYER].includes(view) ||
    (isJudge &&
      [sessionViewType.DEFAULT, sessionViewType.PLAYERDUAL].includes(view)) ||
    isSoloSession ||
    align === ALIGN.LEFT;
  const history = useHistory();
  const location = useLocation();
  const sessionContext = useContext(SessionContext);

  const isExperimental = sessionContext?.isExperimental || false;
  const setActiveLineup = sessionContext?.setActiveLineup || null;
  const setActiveApparatus = sessionContext?.setActiveApparatus || null;
  const activeApparatus = sessionContext?.activeApparatus || null;
  const activeLineup = sessionContext?.activeLineup || null;
  const ignoreKeys = isLive;

  const rotations = useRotationsByTeam(sessionId);
  const rotationsByLU = useRotationsByLineup(sessionId);
  const notRotationConfigured = Object.keys(rotations).length === 0;
  const notSquadConfigured = !Object.keys(rotations).reduce(
    (acc, val) =>
      acc +
      Object.keys(val).length *
        Object.keys(rotations[val]).reduce(
          (a, v) => a + (rotations[val][v] === undefined ? 0 : 1),
          0
        ),
    0
  );

  const maxRound =
    Object.keys(rotations).length ||
    String(sessionApparatus).replace(/0+$/, '').length;

  const hotKeyOptions = [...Array(maxRound).keys()].map((x) => x + 1).join();

  const handleChangeRound = (selectedRound) => {
    if (round !== selectedRound && selectedRound <= maxRound) {
      dispatch(producerActions.changeRound(selectedRound));
    }
  };

  const handleChangeRoundOnly = (selectedRound) => {
    if (isExperimental) {
      setActiveApparatus(null);
      setActiveLineup(null);
    }
    handleChangeRound(selectedRound);
  };

  const handleChangeApparatusOnly = (apparatus) => {
    if (isExperimental) {
      setActiveApparatus(apparatus);
      setActiveLineup(null);
    } else {
      handleChangeRound(options.indexOf(apparatus) + 1);
    }
  };

  useHotkeys(
    hotKeyOptions,
    (e) => {
      e.preventDefault();
      if (!ignoreKeys) {
        handleChangeRound(Number.parseInt(e.key));
      }
    },
    [round, maxRound, ignoreKeys]
  );

  let options = [];

  switch (gender) {
    case genderType.MALE:
      options = mApparatusAbbv;
      break;
    case genderType.FEMALE:
      options = wApparatusAbbv;
      break;
    default:
      break;
  }

  const handleConfigure = (e) => {
    history.push(`${location.pathname}/${sessionKey}/setup`);
  };

  const handleChangeRoundAndSquad = (e, rotation, lineup, apparatus) => {
    handleChangeRound(rotation);
    setActiveLineup(lineup);
    setActiveApparatus(apparatus);
  };

  const showNotConfigured = (
    <td
      className="notConfigured"
      colSpan={options.length}
      rowSpan={options.length}
    >
      {isAdmin ? (
        <Button
          variant="link"
          className="cfgRotations"
          onClick={handleConfigure}
        >
          {`Configure ${notSquadConfigured ? 'Squads' : 'Rotations'}`}
        </Button>
      ) : (
        'Rotations Not Configured'
      )}
    </td>
  );

  const showRoundOptionsByRotation = () => {
    const cellWidth = `${100 / (options.length + 1)}%`;
    const roundOptions = [];

    if (notRotationConfigured) {
      roundOptions.push(...options);
    } else {
      roundOptions.push(...Object.values(rotations));
    }

    return (
      <Table>
        <thead>
          <tr>
            <th style={{ width: cellWidth, verticalAlign: 'middle' }}>
              {isAdmin ? (
                <Dropdown.Item
                  as="button"
                  variant="outline"
                  key="setup"
                  onSelect={handleConfigure}
                  className="configureButton"
                >
                  {Icons?.gearIcon}
                </Dropdown.Item>
              ) : null}
            </th>
            {options.map((apparatus, i) => {
              const selectedColumn =
                apparatus === activeApparatus
                  ? // (notRotationConfigured &&
                    //   isExperimental &&
                    //   options.indexOf(apparatus) === round - 1)
                    'selectedApparatus'
                  : null;

              return (
                <th
                  style={{
                    width: cellWidth,
                  }}
                  key={apparatus}
                  className={selectedColumn}
                >
                  <Dropdown.Item
                    as="button"
                    variant="outline"
                    key={apparatus}
                    //onSelect={() => handleChangeRound(i + 1)}
                    onSelect={() => handleChangeApparatusOnly(apparatus)}
                    disabled={
                      notRotationConfigured || !isExperimental || judgeLock
                    }
                    className={selectedColumn}
                  >
                    {Icons?.[apparatus] ?? apparatus.toLowerCase()}
                  </Dropdown.Item>
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {roundOptions.map((apparatus, i) => {
            const selectedRow = isCompetition
              ? round === i + 1
                ? 'selectedRound'
                : null // parseInt(sessionApparatus[i]) === 0
              : //  ? 'notAvailable'
                null;
            //: 'notAvailable';

            return (
              <tr
                style={{ flexWrap: 'nowrap' }}
                className={selectedRow}
                key={i}
              >
                <td
                  style={{
                    width: cellWidth,
                  }}
                  key={apparatus}
                >
                  <Dropdown.Item
                    as="button"
                    variant="outline"
                    key={apparatus}
                    onSelect={() => handleChangeRoundOnly(i + 1)}
                    disabled={
                      !isCompetition || judgeLock // || round === i + 1 // ||
                      //parseInt(sessionApparatus[i]) === 0
                    }
                    className={selectedRow}
                  >
                    {`R${i + 1}`}
                  </Dropdown.Item>
                </td>

                {!notRotationConfigured && !notSquadConfigured
                  ? options.map((apparatus, j) => {
                      return (
                        <td
                          style={{
                            width: cellWidth,
                          }}
                          key={apparatus}
                          className={
                            apparatus === activeApparatus
                              ? 'selectedApparatus'
                              : null
                          }
                        >
                          {isExperimental
                            ? rotationsByLU[i + 1]?.[apparatus]?.map(
                                (l, index) => {
                                  if (l?.individuals && index > 0) {
                                    return null;
                                  }

                                  const logos = l?.team?.logos;
                                  const logo =
                                    (logos &&
                                      JSON.parse(logos)?.metaData?.filename) ||
                                    null;
                                  const name = l?.team?.name;

                                  return logo ? (
                                    <Button
                                      key={apparatus + '_' + (i + 1) + l.id}
                                      variant="light"
                                      className={
                                        apparatus === activeApparatus &&
                                        l?.id === activeLineup?.id
                                          ? 'selectedLogo'
                                          : null
                                      }
                                      onClick={(e) =>
                                        handleChangeRoundAndSquad(
                                          e,
                                          i + 1,
                                          l,
                                          apparatus
                                        )
                                      }
                                    >
                                      <img
                                        src={`${MEDIAURL}${logo}`}
                                        alt={name ? `${name} logo` : ''}
                                      />
                                    </Button>
                                  ) : null;
                                }
                              )
                            : rotations[i + 1]?.[apparatus]?.map((t) => {
                                const logos = t?.logos;
                                const logo =
                                  (logos &&
                                    JSON.parse(logos)?.metaData?.filename) ||
                                  null;
                                const name = t?.name;

                                return logo ? (
                                  <img
                                    key={apparatus + '_' + (i + 1) + t.id}
                                    src={`${MEDIAURL}${logo}`}
                                    alt={name ? `${name} logo` : ''}
                                  />
                                ) : null;
                              })}
                        </td>
                      );
                    })
                  : i === 0
                  ? showNotConfigured
                  : null}
              </tr>
            );
          })}
        </tbody>
      </Table>
    );
  };

  return (
    <div className="roundcount vCenter">
      <Dropdown
        className="roundBubble"
        show={show}
        onToggle={(isOpen, event, metadata) => {
          if (metadata.source !== 'select') {
            setShow(isOpen);
          }
        }}
      >
        <Dropdown.Toggle id="dropdown-roundBubble" variant="outline">
          {isCompetition ? `R${round}` : 'AA'}
        </Dropdown.Toggle>
        <Dropdown.Menu
          className={[
            'roundBubble-menu',
            !isSoloView ? 'centerMenu' : null,
          ].join(' ')}
          data-display="static"
          style={{ minWidth: `${options.length * 5}rem` }}
        >
          {showRoundOptionsByRotation()}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}

export default RoundCount;
