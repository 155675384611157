import React, { useEffect, useState, useMemo } from 'react';
import { Modal, Button, Row, Spinner, Alert } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { alertActions, userActions } from '../../redux/_actions';
import TableComponent from '../helpers/tablecomponent';
import { dateTime } from '../../utilities/conversions';
import { useHistory } from 'react-router-dom';
import { uiState } from '../../redux/_constants';

function Sessions(props) {
  const [isLoading, setIsLoading] = useState(true);
  const { alert } = useSelector((state) => state);
  const { sessions } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (sessions.allIds.length === 0) {
      setIsLoading(true);
      dispatch(userActions.getSessions());
    }
  }, [sessions, dispatch]);

  useEffect(() => {
    if (sessions.allIds.length) {
      setIsLoading(false);
    }
  }, [sessions]);

  useEffect(() => {
    if (!alert.clear) {
      setIsLoading(false);
    }
  }, [alert.clear]);

  useEffect(() => {
    dispatch(alertActions.clear());
  }, [dispatch]);

  const sessionDetail = (e, row) => {
    if (!alert.clear) {
      dispatch(alertActions.clear());
    }
    history.push(`${uiState.SESSION}?s=${row.col5}`);
  };

  const loading = () => {
    return (
      <Row
        style={{ margin: '1rem -15px', minHeight: '20vh', textAlign: 'center' }}
        className="vCenter"
      >
        <div className="vCenter" style={{ margin: '0 auto' }}>
          <Spinner
            variant="secondary"
            role="status"
            animation="border"
            size="md"
          />
        </div>
      </Row>
    );
  };

  const emptyList = () => {
    return (
      <Row
        style={{ margin: '1rem -15px', minHeight: '20vh', textAlign: 'center' }}
        className="vCenter"
      >
        <span>No sessions available.</span>
      </Row>
    );
  };

  const headers = useMemo(
    () => [
      { Header: '', accessor: 'col1' },
      { Header: 'Name', accessor: 'col2', maxWidth: 50 },
      { Header: 'Type', accessor: 'col3' },
      { Header: '', accessor: 'col4' },
      {
        Header: 'Key',
        accessor: 'col5',
        Cell: ({ row }) => (
          <Link
            to={{
              pathname: uiState.SESSION,
              search: `?s=${row.values.col5}`,
              state: { fromAdmin: true },
            }}
          >
            {row.values.col5}
          </Link>
        ),
      },
      { Header: 'When', accessor: 'col6' },
      { Header: 'Status', accessor: 'status' },
    ],
    [sessions]
  );

  const data = useMemo(() => {
    return sessions.allIds.map((id, i) => {
      const {
        name,
        type,
        gender,
        sessionKey,
        startAt,
        status /*athletes, league*/,
      } = sessions.byId[id];

      return {
        col1: i + 1,
        col2: name,
        col3: type,
        col4:
          gender === 'MALE' ? 'MENS' : gender === 'FEMALE' ? 'WOMENS' : 'COED',
        col5: sessionKey,
        col6: dateTime(startAt),
        status: status,
        id: id,
      };
    });
  }, [sessions]);

  const table = () => {
    const initialState = {
      sortBy: [{ id: 'col6', desc: true }],
    };

    return sessions.allIds.length === 0 ? (
      emptyList()
    ) : (
      <TableComponent
        columns={headers}
        data={data}
        onClick={sessionDetail}
        initialState={initialState}
        textSort={false}
      />
    );
  };

  const sessionsBody = () => {
    return (
      <>
        {!alert.clear ? (
          <Row style={{ padding: '0 15px', margin: '1rem -15px' }}>
            <Alert
              dismissible
              onClose={() => dispatch(alertActions.clear())}
              variant={alert.type === 'alert-danger' ? 'danger' : 'success'}
              style={{ textAlign: 'center', width: '100%' }}
            >
              {alert.message}
            </Alert>
          </Row>
        ) : null}
        {isLoading ? loading() : table()}
      </>
    );
  };

  const footer = () => {
    return (
      <>
        <Row className="vCenter" style={{ margin: '0 auto' }}>
          <Button
            variant="outline-primary"
            className="formButton"
            onClick={() => history.push(uiState.CREATE)}
            disabled={isLoading}
          >
            Create session
          </Button>
        </Row>
        <Row />
      </>
    );
  };

  const headerMsg = () => {
    return <span>Sessions</span>;
  };

  return (
    <>
      <Modal.Header className="sessionsHeader">{headerMsg()}</Modal.Header>
      <Modal.Body className="sessionsBody" style={{ width: '100%' }}>
        {sessionsBody()}
      </Modal.Body>
      <Modal.Footer className="sessionsFooter">{footer()}</Modal.Footer>
    </>
  );
}

export default Sessions;
