import { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Button,
  OverlayTrigger,
  Popover,
  ButtonGroup,
} from 'react-bootstrap';
import { /* useDispatch, */ useSelector } from 'react-redux';
import {
  caretUpIcon,
  caretDownIcon,
  nCircle,
  editIcon,
  cardIcon,
} from '../helpers/icons';
import { sessionRoleType, sessionViewType } from '../../redux/_constants';
import { convertMillipointsToDisplay } from '../../utilities/scoring';
import { teamBrand } from '../../utilities/conversions';
import { useApolloClient } from '@apollo/client';
import {
  createPenalty,
  updatePenalty,
  useErrorHandler,
} from '../../apollo/helpers';
import {
  PenaltyStatus,
  PenaltyType,
  ScoreValueType,
  SessionStatus,
} from '../../models';
import { useDisplayConfig } from './hooks';
import producerStyles from './producer.module.css';
import './scorecard.css';

function EditIndicator({ openEdit }) {
  return (
    <div className={`inquiryIndicator ${(openEdit && 'unresolved') || ''}`}>
      {editIcon}
    </div>
  );
}

const penaltyIndicator = (
  <div
    className={['penaltyIndicator red', producerStyles.penaltyIndicator].join(
      ' '
    )}
  >
    {nCircle}
  </div>
);

const conductCardInput = (value, handler) => {
  return (
    <ButtonGroup
      aria-label="Conduct Card Buttons"
      className="teamScoreCardUpDown"
    >
      <Button
        variant="secondary"
        className="green"
        style={{
          minWidth: 'unset',
          borderTopRightRadius: 0,
          borderBottomRightRadius: 0,
        }}
        onClick={() => handler((value) => value + 1)}
      >
        {caretUpIcon}
      </Button>
      <Button
        variant="secondary"
        className="red"
        style={{
          minWidth: 'unset',
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: 0,
        }}
        onClick={() => handler((value) => (value === 0 ? 0 : value - 1))}
        disabled={value === 0}
      >
        {caretDownIcon}
      </Button>
    </ButtonGroup>
  );
};

const teamDeductionInput = (value, handler) => {
  return (
    <ButtonGroup aria-label="Team Deduction Buttons">
      <Button
        variant="secondary"
        style={{
          minWidth: 'unset',
          borderTopRightRadius: 0,
          borderBottomRightRadius: 0,
        }}
        onClick={() => handler((value) => value + 100)}
      >
        {caretUpIcon}
      </Button>
      <Button
        variant="secondary"
        style={{
          minWidth: 'unset',
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: 0,
        }}
        onClick={() => handler((value) => (value === 0 ? 0 : value - 100))}
        disabled={value === 0}
      >
        {caretDownIcon}
      </Button>
    </ButtonGroup>
  );
};

const { ADMIN, JUDGE } = sessionRoleType;

function TeamScoreCard({ team, lineup }) {
  const {
    conductCard,
    events,
    neutral,
    finalScore,
    baseScore,
    lineupId,
  } = team;
  const { name, colors } = teamBrand(team);
  const { teamId, penalties } = lineup;
  const [newConductCard, setNewConductCard] = useState(conductCard);
  const [newTeamDeduction, setNewTeamDeduction] = useState(neutral);
  const live = useSelector(
    (state) => state.session.status === SessionStatus.LIVE
  );
  const role = useSelector((state) => state.session.role);
  const view = useSelector((state) => state.session.view);
  const userId = useSelector((state) => state.user.profile?.id);
  const sessionId = useSelector((state) => state.session.id);
  const [openEdit, setOpenEdit] = useState(false);
  const apolloClient = useApolloClient();
  const displayConfig = useDisplayConfig();
  const { scorePrecision } = displayConfig;
  const changeConductCard = newConductCard !== conductCard;
  const changeTeamDeduction = newTeamDeduction !== neutral;
  const newFinalScore = changeTeamDeduction
    ? convertMillipointsToDisplay(baseScore - newTeamDeduction, scorePrecision)
    : finalScore;

  useEffect(() => {
    setNewConductCard(conductCard);
  }, [conductCard]);

  useEffect(() => {
    setNewTeamDeduction(neutral);
  }, [neutral]);

  const teamPenaltyDeduction = penalties.items.find(
    (penalty) =>
      penalty.status === PenaltyStatus.ISSUED &&
      penalty.type === PenaltyType.NEUTRAL
  );

  const teamPenaltyConduct = penalties.items.find(
    (penalty) =>
      penalty.status === PenaltyStatus.ISSUED &&
      penalty.type === PenaltyType.YELLOWCARD
  );

  const handleCreatePenaltyErrors = useErrorHandler(
    'There was a problem creating the penalty'
  );

  const scoreCardButton = () => {
    let buttonVariant = 'outline-secondary';
    let buttonText = '';
    if (openEdit) {
      if (changeConductCard || changeTeamDeduction) {
        buttonText = 'Save';
        buttonVariant = 'outline-primary';
      } else {
        buttonText = 'Cancel';
      }
    }

    return (
      <Button
        id="teamScoreCardButton"
        variant={buttonVariant}
        onClick={handleScoreCardButton}
      >
        {buttonText}
      </Button>
    );
  };

  const scoreCardPlacement = () => {
    if (view === sessionViewType.SCOREBOARD) {
      return 'left';
    } else if (view === sessionViewType.MOBILE) {
      return 'auto';
    } else {
      return 'auto-start';
    }
  };

  const editHeader = () => {
    const classes = ['inquiryHeader'];
    return <span className={classes.join(' ')}>Edit</span>;
  };

  const header = () => {
    if (openEdit) {
      return editHeader();
    } else {
      return null;
    }
  };

  const handleScoreCardButton = () => {
    if (changeConductCard || changeTeamDeduction) {
      if (changeTeamDeduction) {
        if (teamPenaltyDeduction) {
          updatePenalty(apolloClient, teamPenaltyDeduction, {
            value: newTeamDeduction,
            userId,
          });
        } else {
          createPenalty(apolloClient, {
            sessionId: sessionId,
            lineupId: lineupId,
            value: newTeamDeduction,
            status: PenaltyStatus.ISSUED,
            valueType: ScoreValueType.MILLIPOINT,
            type: PenaltyType.NEUTRAL,
            userId,
            teamId,
          }).catch(handleCreatePenaltyErrors);
        }
      }

      if (changeConductCard) {
        if (teamPenaltyConduct) {
          updatePenalty(apolloClient, teamPenaltyConduct, {
            value: newConductCard,
            userId,
          });
        } else {
          createPenalty(apolloClient, {
            sessionId: sessionId,
            lineupId: lineupId,
            value: newConductCard,
            status: PenaltyStatus.ISSUED,
            valueType: ScoreValueType.INTEGER,
            type: PenaltyType.YELLOWCARD,
            userId,
            teamId,
          }).catch(handleCreatePenaltyErrors);
        }
      }
    }
    setOpenEdit(false);
  };

  const handleEditScore = () => {
    setOpenEdit(true);
  };

  const editScoreButton = () => {
    return [ADMIN, JUDGE].includes(role) && !openEdit ? (
      <Button
        variant="light"
        className="scoreEdit"
        onClick={handleEditScore}
        disabled={openEdit}
      >
        {editIcon}&nbsp;Edit
      </Button>
    ) : null;
  };

  const editIndicator = () => {
    if (openEdit) {
      return <EditIndicator openEdit={openEdit} />;
    }
  };

  const headerBkg = `linear-gradient(90deg, var(--background-haze) 0% 97%, ${colors[0]} 97% 100%)`;

  const popover = (
    <Popover id="popover-scorecard">
      <Popover.Title as="h3">
        <Row style={{ margin: '0', minHeight: '40px', background: headerBkg }}>
          <Col xs={6} className="vCenter score">
            Score Card: <span>{name}</span>
          </Col>
          <Col xs={3} className="vCenter score">
            {editScoreButton()}
          </Col>
          <Col xs={3} className="vCenter">
            {header()}
          </Col>
        </Row>
      </Popover.Title>
      <Popover.Content>
        <Row style={{ margin: '0' }}>
          <Col xs={6} className="vCenter" style={{ fontWeight: '600' }}>
            Base Score:
          </Col>
          <Col
            xs={3}
            className="vCenter"
            style={{ fontWeight: '600', fontVariantNumeric: 'tabular-nums' }}
          >
            {baseScore
              ? convertMillipointsToDisplay(baseScore, scorePrecision)
              : '--'}
          </Col>
          <Col xs={3} className="vCenter green"></Col>
        </Row>
        <Row style={{ margin: '0' }}>
          <Col xs={9} className="vCenter" style={{ fontWeight: '600' }}>
            Apparatus Scores:
          </Col>
          <Col
            xs={3}
            className="vCenter"
            style={{ textAlign: 'center', fontStyle: 'italic' }}
          >
            average
          </Col>
        </Row>
        {events.map(({ apparatus, eventScore, eventAverage }, i) => {
          return (
            <Row
              key={`${apparatus}_${i}`}
              style={{ margin: '0', padding: '0' }}
            >
              <Col
                xs={2}
                style={{
                  fontStyle: 'italic',
                  padding: '0 0 0 15px',
                }}
              >
                {apparatus}:
              </Col>
              <Col
                xs={7}
                className="vCenter"
                style={{
                  whiteSpace: 'nowrap',
                  fontVariantNumeric: 'tabular-nums',
                }}
              >
                {eventScore || '--'}
              </Col>
              <Col
                xs={3}
                style={{
                  whiteSpace: 'nowrap',
                  fontVariantNumeric: 'tabular-nums',
                  fontStyle: 'italic',
                }}
              >
                {eventAverage}
              </Col>
            </Row>
          );
        })}
        <Row style={{ margin: '0' }}>
          <Col xs={6} className="vCenter">
            Team Deduction:
          </Col>
          <Col
            xs={2}
            className="vCenter"
            style={{ fontVariantNumeric: 'tabular-nums' }}
          >
            {newTeamDeduction
              ? convertMillipointsToDisplay(newTeamDeduction, 1)
              : '--'}
          </Col>
          <Col xs={4} className="vCenter red">
            {openEdit
              ? teamDeductionInput(newTeamDeduction, setNewTeamDeduction)
              : null}
            {!!neutral && !openEdit && caretDownIcon}
          </Col>
        </Row>
        <Row style={{ margin: '0' }}>
          <Col xs={6} className="vCenter">
            Yellow Card:
          </Col>
          <Col
            xs={2}
            className="vCenter"
            style={{ fontVariantNumeric: 'tabular-nums' }}
          >
            {newConductCard ? newConductCard : '--'}
          </Col>
          <Col xs={4} className="vCenter ">
            {openEdit ? (
              conductCardInput(newConductCard, setNewConductCard)
            ) : (
              <span className="yellow" style={{ textAlign: 'center' }}>
                {!!newConductCard ? cardIcon : null}
              </span>
            )}
          </Col>
        </Row>

        <hr style={{ width: '100%' }} />
        <Row style={{ margin: '0' }}>
          <Col xs={6} className="vCenter score">
            Final Score:
          </Col>
          <Col
            xs={3}
            className="vCenter score"
            style={{ fontVariantNumeric: 'tabular-nums' }}
          >
            {newFinalScore || '--'}
          </Col>
          <Col xs={3} className="vCenter" style={{ textAlign: 'center' }}>
            {(live && openEdit && [JUDGE].includes(role)) ||
            (openEdit && [ADMIN].includes(role))
              ? scoreCardButton()
              : null}
          </Col>
        </Row>
      </Popover.Content>
    </Popover>
  );

  return (
    <OverlayTrigger
      trigger="click"
      placement={scoreCardPlacement()}
      key="top"
      overlay={popover}
      rootClose
    >
      <Button className="scorecard" variant="outline">
        {finalScore || convertMillipointsToDisplay(0, scorePrecision)}
        {editIndicator()}
        {!!neutral && penaltyIndicator}
      </Button>
    </OverlayTrigger>
  );
}

export default TeamScoreCard;
