import { Row, Col } from 'react-bootstrap';
import { useEvalConfig, useDisplayConfig } from './hooks';
import {
  newestScoreOfType,
  convertMillipointsToDisplay,
} from '../../utilities/scoring';
import competitorlistscoresheetStyles from './competitorlistscoresheet.module.css';

export function CompetitorListScoresheetRow({ routine }) {
  const evalConfig = useEvalConfig();
  const displayConfig = useDisplayConfig();

  const { nDeduct, stickBonus, dPanel, ePanel, jPanel, svPanel } = evalConfig;
  const {
    scorePrecision,
    jPrecision,
    ePrecision,
    dPrecision,
    svPrecision,
  } = displayConfig;

  const scores = (routine && routine?.scores?.items) || [];

  const dHeadings = (dPanel && dPanel.map((d) => d.type)) || [];
  const eHeadings = (ePanel && ePanel.map((e) => e.type)) || [];
  const jHeadings = (jPanel && jPanel.map((j) => j.type)) || [];
  const svHeadings = (svPanel && svPanel.map((sv) => sv.type)) || [];
  const svjHeadings = [];

  svHeadings.forEach((sv, i) => {
    svjHeadings.push(sv);
    svjHeadings.push(jHeadings?.[i]);
  });

  const renderHeadings = (headings, precision) => {
    return headings.map((h) => {
      const scoreObj = (scores && newestScoreOfType(scores, h)) || null;
      const scoreVal = h === 'Total' ? routine?.score : scoreObj?.value;

      return (
        <Col key={h}>
          <span>{h}: </span>
          <div className={competitorlistscoresheetStyles.score}>
            {(scoreVal || scoreVal === 0) &&
              convertMillipointsToDisplay(scoreVal, precision)}
          </div>
        </Col>
      );
    });
  };

  return (
    <Row className="scoreSheetSpaces">
      {renderHeadings(dHeadings, svPrecision || dPrecision)}
      {renderHeadings(svjHeadings, svPrecision)}
      {renderHeadings(eHeadings, ePrecision)}
      {!svPanel && renderHeadings(jHeadings, jPrecision)}
      {nDeduct ? renderHeadings(['ND'], 1) : null}
      {stickBonus ? renderHeadings(['SB'], 1) : null}
      {renderHeadings(['Total'], scorePrecision)}
    </Row>
  );
}
