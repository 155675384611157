import { Row, Spinner } from 'react-bootstrap';

function Loading() {
  return (
    <Row style={{ minHeight: '100%' }} className="vCenter">
      <div className="vCenter" style={{ margin: '0 auto' }}>
        <Spinner
          variant="secondary"
          role="status"
          animation="border"
          size="md"
        />
      </div>
    </Row>
  );
}

function ScrollLoading() {
  return (
    <Row
      style={{
        margin: '0',
        textAlign: 'center',
      }}
      className="vCenter"
    >
      <div className="vCenter" style={{ margin: '0 auto' }}>
        <Spinner
          variant="secondary"
          role="status"
          animation="border"
          size="md"
        />
      </div>
    </Row>
  );
}

export { Loading, ScrollLoading };
