export const eventConstants = {
  CREATE_ROUTINE_REQUEST: 'CREATE_ROUTINE_EVENT_REQUEST',
  CREATE_ROUTINE_SUCCESS: 'CREATE_ROUTINE_EVENT_SUCCESS',
  CREATE_ROUTINE_FAILURE: 'CREATE_ROUTINE_EVENT_FAILURE',
};

export const routineStatus = {
  ON_AIR: 'ON_AIR',
  ON_EVAL: 'ON_EVAL',
  COMPLETE: 'COMPLETE',
};

export const initialEventState = {
  routines: null,
  scores: null,
  clips: null,
};
