import { videoPlayerConstants, initialVideoPlayerState } from '../_constants';

export function videoPlayer(state = initialVideoPlayerState, action) {
  switch (action.type) {
    case videoPlayerConstants.VOD:
      return {
        ...state,
        controller: {
          ...state.controller,
          vod: action.vod,
        },
      };
    case videoPlayerConstants.INITIALIZE:
      return {
        ...state,
        controller: action.data.controller,
        playerA: action.data.playerA,
        playerB: action.data.playerB,
      };
    case videoPlayerConstants.SYNC:
      return {
        ...state,
        controller: {
          ...state.controller,
          sync: !state.controller.sync,
        },
      };
    case videoPlayerConstants.PLAY:
    case videoPlayerConstants.PAUSE:
    case videoPlayerConstants.SEEK:
    case videoPlayerConstants.SET_REF:
    case videoPlayerConstants.LOAD:
      return {
        ...state,
        playerA: {
          ...state.playerA,
          ...action.data.playerA,
        },
        playerB: {
          ...state.playerB,
          ...action.data.playerB,
        },
      };
    case videoPlayerConstants.STOP:
      return state;
    case videoPlayerConstants.CURRENT_TIME:
      return {
        ...state,
        [`player${action.data.player}`]: {
          ...state[`player${action.data.player}`],
          currentTime: action.data.time,
        },
      };
    case videoPlayerConstants.TEST:
      return {
        ...state,
        controller: {
          ...state.controller,
          test: action.test,
        },
      };
    default:
      return state;
  }
}
