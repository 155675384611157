import React, { useEffect, useState, useRef } from 'react';
import { Modal, Button, Row, Alert, ProgressBar } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { alertActions } from '../../redux/_actions';
import ReactPlayer from 'react-player';
import { Loading } from './loading';
import { VideoMetricsFile } from './videometrics';
import './uploadvideopreview.css';

function UploadVideoPreview(props) {
  const {
    clear,
    url,
    stats,
    name,
    round,
    position,
    handleUpload,
    progress,
  } = props;
  const [isLoading, setIsLoading] = useState(false);
  const alert = useSelector((state) => state.alert);
  const dispatch = useDispatch();
  const playerRef = useRef();

  useEffect(() => {
    if (!alert.clear) {
      setIsLoading(false);
    }
  }, [alert.clear]);

  useEffect(() => {
    dispatch(alertActions.clear());
  }, [dispatch]);

  const close = () => {
    if (!alert.clear) {
      dispatch(alertActions.clear());
    }
    clear();
  };

  const showPreview = () => {
    return (
      <div className="videoWrapper">
        <ReactPlayer
          ref={playerRef}
          className="videoPlayer"
          url={url}
          controls={true}
          width="100%"
          height="100%"
          muted={false}
          progressInterval={1000}
          autoPlay={false}
        />
        <VideoMetricsFile stats={stats} defaultOn={true} />
      </div>
    );
  };

  const body = () => {
    return (
      <>
        {!alert.clear ? (
          <Row style={{ padding: '0 15px', margin: '1rem -15px' }}>
            <Alert
              dismissible
              onClose={() => dispatch(alertActions.clear())}
              variant={alert.type === 'alert-danger' ? 'danger' : 'success'}
              style={{ textAlign: 'center', width: '100%' }}
            >
              {alert.message}
            </Alert>
          </Row>
        ) : null}
        {isLoading ? <Loading /> : showPreview()}
      </>
    );
  };

  const closeButton = (
    <Button variant="outline-secondary" className="closeButton" onClick={close}>
      Close
    </Button>
  );

  const uploadButton = (
    <Button
      variant="outline-success"
      className="uploadButton"
      onClick={handleUpload}
      disabled={progress > 0 || stats === null}
    >
      {progress > 0
        ? progress === 100
          ? 'Success!'
          : 'Uploading...'
        : 'Upload'}
    </Button>
  );

  const footer = () => {
    return (
      <>
        {progress ? <ProgressBar now={progress} variant="success" /> : null}
        <Row className="vCenter" style={{ margin: '0 auto' }}>
          {progress === null ? closeButton : uploadButton}
        </Row>
        <Row />
      </>
    );
  };

  const headerMsg = () => {
    return (
      <>
        <span>
          Upload Video Preview (R{round} » {position})
        </span>
        <span className="headerName">{name}</span>
      </>
    );
  };

  return (
    <>
      <Modal.Header className="uploadVideoPreviewHeader">
        {headerMsg()}
      </Modal.Header>
      <Modal.Body className="uploadVideoPreviewBody">{body()}</Modal.Body>
      <Modal.Footer className="uploadVideoPreviewFooter">
        {footer()}
      </Modal.Footer>
    </>
  );
}

export default UploadVideoPreview;
