import React from 'react';
import { Toast, Row } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { alertActions } from '../../redux/_actions';
import { alertType } from '../../redux/_constants';

function ToastStack(props) {
  const alert = useSelector((state) => state.alert);
  const dispatch = useDispatch();
  const { SUCCESS, ERROR, NOTIFICATION, SYNC, OFFLINE } = alertType;

  // No show cases
  let show = true;
  switch (alert.message) {
    case 'Not Authenticated.':
      show = false;
      break;
    default:
      break;
  }

  const header = () => {
    switch (alert.type) {
      case ERROR:
        return 'Error';
      case SUCCESS:
        return 'Success';
      case NOTIFICATION:
        return 'Notification';
      case SYNC:
        return 'Sync';
      case OFFLINE:
        return 'Offline';
      default:
        return 'Notification';
    }
  };

  const toastClass = () => {
    switch (alert.type) {
      case ERROR:
        return 'error';
      case SUCCESS:
        return 'success';
      case NOTIFICATION:
        return 'warning';
      case OFFLINE:
        return 'offline';
      default:
        return 'notification';
    }
  };

  return (
    <div className="toastStack">
      <Row className="toastRow">
        <Toast
          onClose={() => dispatch(alertActions.clear())}
          show={!alert.clear && show}
          animation={false}
          className={toastClass()}
          delay={5000}
          autohide={alert.autohide}
        >
          <Toast.Header>
            <strong className="mr-auto">{header()}:&nbsp;</strong>
            <span className="mr-auto">{alert.message}</span>
          </Toast.Header>
        </Toast>
      </Row>
    </div>
  );
}

export default ToastStack;
