import {
  sessionConstants,
  uiState,
  streamRequestType,
  streamStatus,
  sessionLoadingState,
  sessionViewType,
} from '../_constants';
import { SessionStatus } from '../../models';
import {
  alertActions,
  userActions,
  producerActions,
  videoPlayerActions,
} from '../_actions';
import { sessionService, analyticsService } from '../_services';
import { store } from '../store';
import { normalize, schema } from 'normalizr';
import { checkRole, dualTeamBRotation } from '../../utilities/session';

export const sessionActions = {
  create,
  join,
  load,
  unload,
  update,
  createStream,
  createStreamNoCam,
  startStream,
  stopStream,
  resetStream,
  deleteStream,
  checkStream,
  subscribe,
  unsubscribe,
  subscribeStream,
  unsubscribeStream,
  subscribeNewStream,
  unsubscribeNewStream,
  subscribeAllStreams,
  unsubscribeAllStreams,
  syncStreamStatus,
  syncNewStream,
  createLineup,
  updateLineup,
  subscribeLineup,
  unsubscribeLineup,
  subscribeAllLineups,
  unsubscribeAllLineups,
  syncLineup,
  syncSession,
  updateProducer,
  changeView,
  updateStatus,
};

// Normalized data schema (TODO: might want to pull this out since same as admin)
const athlete = new schema.Entity('athletes');
const rosterLink = new schema.Entity('rosterLinks', {
  athlete: athlete,
});
const roster = new schema.Entity('rosters', {
  athletes: {
    items: [rosterLink],
  },
});
const lineup = new schema.Entity('lineups');
const sessionTeam = new schema.Entity('sessionTeams');
const sessionJudge = new schema.Entity('sessionJudges');
const session = new schema.Entity('sessions', {
  sessionTeams: {
    items: [sessionTeam],
  },
  sessionJudges: {
    items: [sessionJudge],
  },
  lineups: {
    items: [lineup],
  },
});
const coach = new schema.Entity('coaches');
const teamLeague = new schema.Entity('leagues');
const league = new schema.Entity('leagues', {});
const team = new schema.Entity('teams', {
  rosters: {
    items: [roster],
  },
  coaches: {
    items: [coach],
  },
});

league.define({
  teams: {
    items: [team],
  },
});

function checkStream(index) {
  const stream = store.getState().session?.streams?.items?.[index];
  const { streamId, id, _version, status } = stream;

  const req = {
    type: streamRequestType.CHECK,
    id: id,
    _version: _version,
    streamId: streamId,
    status: status,
  };

  return (dispatch) => {
    dispatch(request());
    sessionService
      .streamRequest(req)
      .then((res) => {
        console.log(res);
        const data = res.data.createStreamWowza;
        //if (data.error) { throw JSON.parse(data.error); }

        if (data.status === status) {
          // no change but need to increment _version
          dispatch(success(data, index));
        } else {
          let newStatus = '';
          switch (data.status) {
            case streamStatus.STARTED:
              if (status === streamStatus.RESETTING) {
                newStatus = 'reset';
              }
              if (status === streamStatus.STARTING) {
                newStatus = 'started';
              }
              break;
            case streamStatus.STOPPED:
              newStatus = 'stopped';
              break;
            default:
              break;
          }

          dispatch(success(data, index));
          dispatch(alertActions.success(`Stream ${index + 1} ${newStatus}.`));
        }
      })
      .catch((error) => {
        console.log(error);
        const errorDetail =
          error && error.errors && error.errors.length > 0
            ? error.errors[0].message
            : null;
        let newStatus = null;

        switch (errorDetail) {
          case 'ERR-404-RecordNotFound':
            error.message = 'Stream was not found.';
            newStatus = streamStatus.OFF;
            break;
          case 'ERR-410-RecordDeleted':
            error.message = 'Stream was deleted.';
            newStatus = streamStatus.OFF;
            break;
          case 'ERR-000-NoStatusChange':
            return dispatch(success(stream, index)); // Hack way of handling no change
          default:
            error.message = 'Check stream error.';
            break;
        }
        dispatch(failure(newStatus, index));
        dispatch(alertActions.error(error.message));
      });
  };

  function request() {
    return { type: sessionConstants.CHECK_STREAM_REQUEST };
  }
  function success(stream, index) {
    return { type: sessionConstants.CHECK_STREAM_SUCCESS, stream, index };
  }
  function failure(status, index) {
    return { type: sessionConstants.CHECK_STREAM_FAILURE, status, index };
  }
}

function startStream(index) {
  const stream = store.getState().session?.streams?.items?.[index];
  const { streamId, id, _version } = stream;

  const req = {
    type: streamRequestType.START,
    id: id,
    _version: _version,
    streamId: streamId,
  };

  return (dispatch) => {
    dispatch(request(streamStatus.STARTING, index));
    sessionService
      .streamRequest(req)
      .then((res) => {
        console.log(res);
        const data = res.data.createStreamWowza;
        if (data.error) {
          throw JSON.parse(data.error);
        }
        dispatch(success(data.status, index));
      })
      .catch((error) => {
        dispatch(failure(index));
        console.log(error);
        switch (error.code) {
          default:
            error.message = 'Start stream error.';
            break;
        }
        dispatch(alertActions.error(error.message));
      });
  };

  function request(status, index) {
    return { type: sessionConstants.START_STREAM_REQUEST, status, index };
  }
  function success(status, index) {
    return { type: sessionConstants.START_STREAM_SUCCESS, status, index };
  }
  function failure(index) {
    return { type: sessionConstants.START_STREAM_FAILURE, index };
  }
}

function stopStream(index) {
  const stream = store.getState().session?.streams?.items?.[index];
  const { streamId, id, _version } = stream;

  const req = {
    type: streamRequestType.STOP,
    id: id,
    _version: _version,
    streamId: streamId,
  };

  return (dispatch) => {
    dispatch(request(streamStatus.STOPPING, index));
    sessionService
      .streamRequest(req)
      .then((res) => {
        console.log(res);
        const data = res.data.createStreamWowza;
        if (data.error) {
          throw JSON.parse(data.error);
        }
        dispatch(success(data.status, index));
      })
      .catch((error) => {
        dispatch(failure(index));
        console.log(error);
        switch (error.code) {
          default:
            error.message = 'Stop stream error.';
            break;
        }
        dispatch(alertActions.error(error.message));
      });
  };

  function request(status, index) {
    return { type: sessionConstants.STOP_STREAM_REQUEST, status, index };
  }
  function success(status, index) {
    return { type: sessionConstants.STOP_STREAM_SUCCESS, status, index };
  }
  function failure(index) {
    return { type: sessionConstants.STOP_STREAM_FAILURE, index };
  }
}

function resetStream(index) {
  const stream = store.getState().session?.streams?.items?.[index];
  const { streamId, id, _version } = stream;

  const req = {
    type: streamRequestType.RESET,
    id: id,
    _version: _version,
    streamId: streamId,
  };

  return (dispatch) => {
    dispatch(request(streamStatus.RESETTING, index));
    sessionService
      .streamRequest(req)
      .then((res) => {
        console.log(res);
        const data = res.data.createStreamWowza;
        if (data.error) {
          throw JSON.parse(data.error);
        }
        dispatch(success(data.status, index));
      })
      .catch((error) => {
        dispatch(failure(index));
        console.log(error);
        switch (error.code) {
          default:
            error.message = 'Reset stream error.';
            break;
        }
        dispatch(alertActions.error(error.message));
      });
  };

  function request(status, index) {
    return { type: sessionConstants.RESET_STREAM_REQUEST, status, index };
  }
  function success(status, index) {
    return { type: sessionConstants.RESET_STREAM_SUCCESS, status, index };
  }
  function failure(index) {
    return { type: sessionConstants.RESET_STREAM_FAILURE, index };
  }
}

function deleteStream(index) {
  const stream = store.getState().session?.streams?.items?.[index];
  const { streamId, id, _version } = stream;

  const session = store.getState().session;

  const req = {
    sessionId: session.id,
    sessionKey: session.sessionKey,
    sessionVersion: session._version,
    type: streamRequestType.DELETE,
    id: id,
    _version: _version,
    streamId: streamId,
  };

  return (dispatch) => {
    dispatch(request(streamStatus.DELETING, index));
    sessionService
      .streamRequest(req)
      .then((res) => {
        console.log(res);
        const data = res.data.createStreamWowza;
        if (data.error) {
          throw JSON.parse(data.error);
        }
        dispatch(success(data, index));
        dispatch(alertActions.success(`Stream ${index + 1} deleted.`));
      })
      .catch((error) => {
        dispatch(failure(index));
        switch (error.code) {
          default:
            error.message = 'Delete stream error.';
            break;
        }
        dispatch(alertActions.error(error.message));
      });
  };

  function request(status, index) {
    return { type: sessionConstants.DELETE_STREAM_REQUEST, status, index };
  }
  function success(stream, index) {
    return { type: sessionConstants.DELETE_STREAM_SUCCESS, stream, index };
  }
  function failure(index) {
    return { type: sessionConstants.DELETE_STREAM_FAILURE, index };
  }
}

function createStream(index, config = null) {
  const { id, sessionKey, _version } = store.getState().session;

  return (dispatch) => {
    const req = {
      sessionId: id,
      sessionKey: sessionKey,
      sessionVersion: _version,
      type: streamRequestType.CREATE,
      config: config,
      index,
    };

    dispatch(request(streamStatus.CREATING, index));
    sessionService
      .streamRequest(req)
      .then((res) => {
        console.log(res);
        const data = res.data.createStreamWowza;
        if (data.error) {
          throw JSON.parse(data.error);
        }
        data.meta = JSON.parse(data.meta);
        dispatch(success(data, index));
        dispatch(alertActions.success(`Stream ${index + 1} created.`));
      })
      .catch((error) => {
        dispatch(failure(index));
        console.log(error);
        switch (error.code) {
          default:
            error.message = 'Create stream error.';
            break;
        }
        dispatch(alertActions.error(error.message));
      });
  };

  function request(status, index) {
    return { type: sessionConstants.CREATE_STREAM_REQUEST, status, index };
  }
  function success(stream, index) {
    return { type: sessionConstants.CREATE_STREAM_SUCCESS, stream, index };
  }
  function failure(index) {
    return { type: sessionConstants.CREATE_STREAM_FAILURE, index };
  }
}

function createStreamNoCam(index, config = null) {
  const { id, sessionKey } = store.getState().session;

  return (dispatch) => {
    const req = {
      sessionId: id,
      sessionKey: sessionKey,
      ...config,
      index,
    };

    dispatch(request(streamStatus.CREATING, index));
    sessionService
      .streamRequestNoCam(req)
      .then((res) => {
        console.log(res);
        const data = res.data.createStream;
        if (data.error) {
          throw JSON.parse(data.error);
        }
        dispatch(success(data, index));
        dispatch(alertActions.success(`Stream ${index + 1} created.`));
      })
      .catch((error) => {
        dispatch(failure(index));
        console.log(error);
        switch (error.code) {
          default:
            error.message = 'Create stream error.';
            break;
        }
        dispatch(alertActions.error(error.message));
      });
  };

  function request(status, index) {
    return {
      type: sessionConstants.CREATE_STREAM_NOCAM_REQUEST,
      status,
      index,
    };
  }
  function success(stream, index) {
    return {
      type: sessionConstants.CREATE_STREAM_NOCAM_SUCCESS,
      stream,
      index,
    };
  }
  function failure(index) {
    return { type: sessionConstants.CREATE_STREAM_NOCAM_FAILURE, index };
  }
}

function load(key) {
  const user = store.getState().user;

  return (dispatch) => {
    dispatch(request(key));
    sessionService
      .load(key)
      .then((res) => {
        //console.log(res);
        if (res.data.SessionByKey.items.length === 0) {
          throw new Error('SessionKeyNotFound');
        }

        // Get teams in the right place
        const sessionData = res.data.SessionByKey.items[0];
        const sortedLineups = sessionData?.lineups?.items.sort(
          (a, b) => a.order - b.order
        );
        sessionData.lineups.items = sortedLineups;

        const sortedStreams = sessionData?.streams?.items.sort(
          (a, b) => a.index - b.index
        );
        sessionData.streams.items = sortedStreams;

        // Sorting might not be needed since Query already sorts by order
        const sortedSessionTeams = sessionData.sessionTeams.items
          .filter((st) => !st._deleted)
          .sort((a, b) => {
            return a.order - b.order;
          });
        sessionData.sessionTeams.items = sortedSessionTeams;
        const teamData = sortedSessionTeams.map((e) => e.team);

        const normalizedSession = normalize(sessionData, session);
        const {
          lineups,
          sessionTeams,
          sessionJudges,
        } = normalizedSession.entities;
        const normalizedLineups = lineups ? normalize(lineups, [lineup]) : null;
        const normalizedSessionTeams = sessionTeams
          ? normalize(sessionTeams, [sessionTeam])
          : null;
        const normalizedSessionJudges = sessionJudges
          ? normalize(sessionJudges, [sessionJudge])
          : null;

        // Normalize team data (teams, rosters, athletes, lineups, rosterlinks?)
        const normalizedTeams = normalize(teamData, [team]);
        const {
          rosters,
          coaches,
          leagues,
          rosterLinks,
          athletes,
        } = normalizedTeams.entities;

        //const normalizedJudges = normalize(sessionData.sessionJudges.items, [judge])

        const normalizedRosters = rosters ? normalize(rosters, [roster]) : null;
        const normalizedLeagues = leagues
          ? normalize(leagues, [teamLeague])
          : null;
        const normalizedCoaches = coaches ? normalize(coaches, [coach]) : null;
        const normalizedRosterLinks = rosterLinks
          ? normalize(rosterLinks, [rosterLink])
          : null;
        const normalizedAthletes = athletes
          ? normalize(athletes, [athlete])
          : null;

        // Get user role in session, evaluator config
        const role = checkRole(user, sessionData);
        //const role = 'JUDGE';
        // const evalConfig = getEvalConfig(
        //   role,
        //   sessionData.judgeType,
        //   sessionData.gender
        // );
        //const evalConfig = undefined;

        dispatch(
          success(
            sessionData,
            {
              teams: {
                byId: normalizedTeams.entities.teams,
                allIds: normalizedTeams.result,
              },
              lineups: lineups
                ? {
                    byId: normalizedLineups.entities.lineups,
                    allIds: normalizedLineups.result,
                  }
                : null,
              rosters: rosters
                ? {
                    byId: normalizedRosters.entities.rosters,
                    allIds: normalizedRosters.result,
                  }
                : null,
              leagues: leagues
                ? {
                    byId: normalizedLeagues.entities.leagues,
                    allIds: normalizedLeagues.result,
                  }
                : null,
              coaches: coaches
                ? {
                    byId: normalizedCoaches.entities.coaches,
                    allIds: normalizedCoaches.result,
                  }
                : null,
              rosterLinks: rosterLinks
                ? {
                    byId: normalizedRosterLinks.entities.rosterLinks,
                    allIds: normalizedRosterLinks.result,
                  }
                : null, // is this needed?
              athletes: athletes
                ? {
                    byId: normalizedAthletes.entities.athletes,
                    allIds: normalizedAthletes.result,
                  }
                : null,
              sessionTeams: sessionTeams
                ? {
                    byId: normalizedSessionTeams.entities.sessionTeams,
                    allIds: normalizedSessionTeams.result,
                  }
                : null,
              sessionJudges: sessionJudges
                ? {
                    byId: normalizedSessionJudges.entities.sessionJudges,
                    allIds: normalizedSessionJudges.result,
                  }
                : null,
            },
            role
          )
        );

        // Makes lineup(s) on first load (should be part of create sequence)
        // Needs to be able to upgrade a Solo to add a lineup for new Dual
        if (
          !lineups ||
          normalizedLineups.result.length < normalizedTeams.result.length
        ) {
          dispatch(
            sessionActions.createLineup(
              teamData
                .map((el, i) => {
                  if (
                    normalizedLineups?.entities?.lineups[
                      normalizedLineups.result[i]
                    ]?.teamId === el.id
                  ) {
                    return null;
                  }
                  return {
                    sessionId: sessionData.id,
                    title: sessionData.name,
                    teamId: el.id,
                    sessionTeamId: sortedSessionTeams[i].id,
                    order: i,
                  };
                })
                .filter((el) => el !== null)
            )
          ); // solo or dual create 1 or 2 lineups
        }

        dispatch(
          producerActions.initialize(
            null,
            teamData,
            sessionData.producer ? JSON.parse(sessionData.producer) : null
          )
        );
        dispatch(videoPlayerActions.vod(sessionData.status === 'POSTGAME')); // for vod or not
      })
      .catch((error) => {
        let loadingStatus = sessionLoadingState.NOT_LOADED;

        if (error?.message === 'SessionKeyNotFound') {
          loadingStatus = sessionLoadingState.NOT_FOUND;
        } else if (
          error?.errors &&
          error?.errors[0]?.errorType === 'Unauthorized'
        ) {
          loadingStatus = sessionLoadingState.UNAUTHORIZED;
        } else {
          console.log(error);
        }

        dispatch(failure(loadingStatus));
      });
  };

  function request(key) {
    return { type: sessionConstants.LOAD_REQUEST, key };
  }
  function success(session, sessionData, role) {
    return { type: sessionConstants.LOAD_SUCCESS, session, sessionData, role };
  }
  function failure(loadingState) {
    return { type: sessionConstants.LOAD_FAILURE, loadingState };
  }
}

function unload() {
  return (dispatch) => {
    dispatch(request());
    dispatch(videoPlayerActions.load('', 'AB'));
  };
  function request() {
    return { type: sessionConstants.UNLOAD_REQUEST };
  }
}

function create(input, teamChange, history) {
  return (dispatch) => {
    dispatch(request(input));

    sessionService
      .create(input, teamChange)
      .then((sessionData) => {
        dispatch(success());
        dispatch(userActions.addSession(sessionData));
        history.push(`${uiState.SESSION}?s=${sessionData.sessionKey}`);
      })
      .catch((error) => {
        dispatch(failure());
        console.log(error);
        switch (error.code) {
          default:
            error.message = 'Create session error.';
            break;
        }
        dispatch(alertActions.error(error.message));
      });
  };

  function request() {
    return { type: sessionConstants.CREATE_REQUEST };
  }
  function success() {
    return { type: sessionConstants.CREATE_SUCCESS };
  }
  function failure() {
    return { type: sessionConstants.CREATE_FAILURE };
  }
}

function join(key, history) {
  const { profile } = store.getState().user;

  return (dispatch) => {
    dispatch(request());
    sessionService
      .join(key)
      .then((res) => {
        console.log(res);
        analyticsService.join(profile.id, key);
        if (res.data.SessionByKey.items.length === 0) {
          throw new Error({ code: 'SessionKeyNotFound' });
        }
        dispatch(success()); // throw away graphQL since will load with redirect
        history.push(`${uiState.SESSION}?s=${key}`);
      })
      .catch((error) => {
        dispatch(failure());
        switch (error.code) {
          case 'SessionKeyNotFound':
            error.message = 'Session not found.';
            break;
          default:
            error.message = 'Join session error.';
            break;
        }
        dispatch(alertActions.error(error.message));
      });
  };

  function request() {
    return { type: sessionConstants.JOIN_REQUEST };
  }
  function success() {
    return { type: sessionConstants.JOIN_SUCCESS };
  }
  function failure(error) {
    return { type: sessionConstants.JOIN_FAILURE };
  }
}

function update(input) {
  return (dispatch) => {
    dispatch(request());
    sessionService
      .update(input)
      .then((res) => {
        console.log(res);
        dispatch(success(res.data.updateSession));
        dispatch(alertActions.success('Session updated successfully.'));
      })
      .catch((error) => {
        dispatch(failure());
        console.log(error);
        switch (error.code) {
          default:
            error.message = 'Update session error.';
            break;
        }
        dispatch(alertActions.error(error.message));
      });
  };

  function request() {
    return { type: sessionConstants.UPDATE_REQUEST };
  }
  function success(session) {
    return { type: sessionConstants.UPDATE_SUCCESS, session };
  }
  function failure() {
    return { type: sessionConstants.UPDATE_FAILURE };
  }
}

function subscribe(id) {
  return (dispatch) => {
    dispatch(request());
    sessionService
      .subscribe(id, dispatch) // subscribes to session changes & new streams
      .then((res) => {
        dispatch(success(res));
        //console.log(res);
      })
      .catch((error) => {
        dispatch(failure());
        console.log(error);
        dispatch(alertActions.error(error.message));
      });
  };

  function request() {
    return { type: sessionConstants.SUBSCRIBE_REQUEST };
  }
  function success(sub) {
    return { type: sessionConstants.SUBSCRIBE_SUCCESS, sub };
  }
  function failure(error) {
    return { type: sessionConstants.SUBSCRIBE_FAILURE };
  }
}

function unsubscribe() {
  const { session } = store.getState();

  return (dispatch) => {
    dispatch(request());
    sessionService
      .unsubscribe(session.sub)
      .then((res) => {
        dispatch(success());
        //console.log(res);
      })
      .catch((error) => {
        dispatch(failure());
        console.log(error);
        dispatch(alertActions.error(error.message));
      });
  };

  function request() {
    return { type: sessionConstants.UNSUBSCRIBE_REQUEST };
  }
  function success() {
    return { type: sessionConstants.UNSUBSCRIBE_SUCCESS };
  }
  function failure(error) {
    return { type: sessionConstants.UNSUBSCRIBE_FAILURE };
  }
}

function subscribeStream(id, index) {
  return (dispatch) => {
    dispatch(request());
    sessionService
      .subscribeStream(id, index, dispatch)
      .then((res) => {
        dispatch(success(res, index));
        //console.log(res);
      })
      .catch((error) => {
        dispatch(failure());
        console.log(error);
        dispatch(alertActions.error(error.message));
      });
  };

  function request() {
    return { type: sessionConstants.SUBSCRIBE_STREAM_REQUEST };
  }
  function success(sub, index) {
    return { type: sessionConstants.SUBSCRIBE_STREAM_SUCCESS, sub, index };
  }
  function failure(error) {
    return { type: sessionConstants.SUBSCRIBE_STREAM_FAILURE };
  }
}

function unsubscribeStream(index) {
  const { session } = store.getState();

  return (dispatch) => {
    dispatch(request());
    sessionService
      .unsubscribe(session[`streamSubs`][index])
      .then((res) => {
        dispatch(success());
      })
      .catch((error) => {
        dispatch(failure());
        dispatch(alertActions.error(error.message));
      });
  };

  function request() {
    return { type: sessionConstants.UNSUBSCRIBE_STREAM_REQUEST };
  }
  function success() {
    return { type: sessionConstants.UNSUBSCRIBE_STREAM_SUCCESS };
  }
  function failure(error) {
    return { type: sessionConstants.UNSUBSCRIBE_STREAM_FAILURE };
  }
}

function subscribeNewStream(id) {
  return (dispatch) => {
    dispatch(request());
    sessionService
      .subscribeNewStream(id, dispatch)
      .then((res) => {
        if (res) dispatch(success(res));
      })
      .catch((error) => {
        dispatch(failure());
        console.log(error);
        dispatch(alertActions.error(error.message));
      });
  };

  function request() {
    return { type: sessionConstants.SUBSCRIBE_NEW_STREAM_REQUEST };
  }
  function success(sub) {
    return {
      type: sessionConstants.SUBSCRIBE_NEW_STREAM_SUCCESS,
      sub,
    };
  }
  function failure(error) {
    return { type: sessionConstants.SUBSCRIBE_NEW_STREAM_FAILURE };
  }
}

function unsubscribeNewStream() {
  const { session } = store.getState();

  return (dispatch) => {
    dispatch(request());
    sessionService
      .unsubscribe(session.newStreamSub)
      .then((res) => {
        dispatch(success());
      })
      .catch((error) => {
        dispatch(failure());
        dispatch(alertActions.error(error.message));
      });
  };

  function request() {
    return { type: sessionConstants.UNSUBSCRIBE_NEW_STREAM_REQUEST };
  }
  function success() {
    return { type: sessionConstants.UNSUBSCRIBE_NEW_STREAM_SUCCESS };
  }
  function failure(error) {
    return { type: sessionConstants.UNSUBSCRIBE_NEW_STREAM_FAILURE };
  }
}

function syncStreamStatus(stream, index) {
  return { type: sessionConstants.SYNC_STREAM_STATUS_SUCCESS, stream, index };
}

function syncNewStream(stream) {
  const { session } = store.getState();

  return (dispatch) => {
    // Check if it was this session that launched the stream create request
    if (session?.streams?.items[stream?.index] === streamStatus.STOPPED) {
      dispatch(bypass());
    } else {
      // Need to parse the stream meta data
      stream.meta = JSON.parse(stream.meta);
      dispatch(success(stream, stream?.index));
      dispatch(alertActions.success(`Stream ${stream?.index + 1} created.`));
    }
  };

  function success(stream, index) {
    return { type: sessionConstants.SYNC_NEW_STREAM_SUCCESS, stream, index };
  }
  function bypass() {
    return { type: sessionConstants.SYNC_NEW_STREAM_BYPASS };
  }
}

function subscribeAllStreams() {
  const streams = store.getState().session?.streams.items;

  return (dispatch) => {
    dispatch(request());
    sessionService
      .subscribeAllStreams(streams, dispatch)
      .then((res) => {
        dispatch(success(res));
      })
      .catch((error) => {
        dispatch(failure());
        console.log(error);
        dispatch(alertActions.error(error.message));
      });
  };
  function request() {
    return { type: sessionConstants.SUBSCRIBE_ALL_STREAMS_REQUEST };
  }
  function success(sub) {
    return { type: sessionConstants.SUBSCRIBE_ALL_STREAMS_SUCCESS, sub };
  }
  function failure(error) {
    return { type: sessionConstants.SUBSCRIBE_ALL_STREAMS_FAILURE };
  }
}

function unsubscribeAllStreams() {
  const subs = store.getState().session.streamSubs;

  return (dispatch) => {
    dispatch(request());
    sessionService
      .unsubscribeAllStreams(subs)
      .then((res) => {
        dispatch(success(res));
      })
      .catch((error) => {
        dispatch(failure());
        console.log(error);
        dispatch(alertActions.error(error.message));
      });
  };

  function request() {
    return { type: sessionConstants.UNSUBSCRIBE_ALL_STREAMS_REQUEST };
  }
  function success() {
    return { type: sessionConstants.UNSUBSCRIBE_ALL_STREAMS_SUCCESS };
  }
  function failure(error) {
    return { type: sessionConstants.UNSUBSCRIBE_ALL_STREAMS_FAILURE };
  }
}

function createLineup(input) {
  return (dispatch) => {
    dispatch(request());
    sessionService
      .addLineup(input)
      .then((res) => {
        // Need to add it back into the Session data structure, receives array of added lineups
        console.log(res);
        const data = res.map((el) => el.data.createLineup);
        const normalizedLineups = normalize(data, [lineup]);

        console.log(normalizedLineups);

        dispatch(
          success({
            byId: normalizedLineups.entities.lineups,
            allIds: normalizedLineups.result,
            lineups: data,
          })
        );
      })
      .catch((error) => {
        dispatch(failure());
        dispatch(alertActions.error(error.message));
      });
  };

  function request() {
    return { type: sessionConstants.CREATE_LINEUP_REQUEST };
  }
  function success(data) {
    return { type: sessionConstants.CREATE_LINEUP_SUCCESS, data };
  }
  function failure(error) {
    return { type: sessionConstants.CREATE_LINEUP_FAILURE };
  }
}

function updateLineup(input, round = null) {
  return (dispatch) => {
    // Since changes can be fast need to handle the double send with the same version (preventing automerge)
    // strategy make it optimistic update so next request is next version and then overwrite when data arrives
    dispatch(request(input));
    sessionService
      .editLineup(input)
      .then((res) => {
        //console.log(res);
        const data = res.data.updateLineup;
        dispatch(success(data));
        dispatch(producerActions.updateLineup(data, round));
      })
      .catch((error) => {
        console.log(error);

        const topError = error?.errors?.[0].errorType;

        switch (topError) {
          case 'ConditionalCheckFailedException':
            error.message = 'Update lineup permission denied.';
            break;
          default:
            error.message = error?.errors?.[0].message;
            break;
        }

        dispatch(failure());
        dispatch(alertActions.error(error.message));
      });
  };

  function request(data) {
    return { type: sessionConstants.UPDATE_LINEUP_REQUEST, data };
  }
  function success(data) {
    return { type: sessionConstants.UPDATE_LINEUP_SUCCESS, data };
  }
  function failure(error) {
    return { type: sessionConstants.UPDATE_LINEUP_FAILURE };
  }
}

function syncLineup(lineup) {
  // Determine if lineup is Team A / Team B
  const alternating = store.getState().session.alternating;
  const view = store.getState().session.view;
  const { DUALLINEUP, DUALPLAYER, PLAYERDUAL } = sessionViewType;
  const status = store.getState().session.status;
  const { LIVE, PREGAME } = SessionStatus;
  const round = store.getState().producer.round;
  const teams = store.getState().producer.teams;
  const teamB = store.getState().producer.teamB;
  const teamA = store.getState().producer.teamA;
  const team = teams.find((t) => t.lineupId === lineup.id);
  const altRound =
    [PLAYERDUAL, DUALLINEUP, DUALPLAYER].includes(view) &&
    team?.index === 1 &&
    teamB?.lineupId === lineup.id &&
    teamA?.lineupId !== lineup.id &&
    alternating &&
    [LIVE, PREGAME].includes(status)
      ? dualTeamBRotation(round)
      : round;

  const msg = team?.name ? team?.name : `${lineup?.title}`;

  return (dispatch) => {
    dispatch(success(lineup));
    dispatch(producerActions.updateLineup(lineup, altRound));
    dispatch(alertActions.sync(`${msg} lineup updated.`));
  };

  function success(data) {
    return { type: sessionConstants.SYNC_LINEUP_SUCCESS, data };
  }
}

function subscribeLineup(id, channel) {
  return (dispatch) => {
    dispatch(request());
    sessionService
      .subscribeLineup(id, dispatch)
      .then((res) => {
        dispatch(success(res, channel));
      })
      .catch((error) => {
        dispatch(failure());
        console.log(error);
        dispatch(alertActions.error(error.message));
      });
  };

  function request() {
    return { type: sessionConstants.SUBSCRIBE_LINEUP_REQUEST };
  }
  function success(sub, channel) {
    return { type: sessionConstants.SUBSCRIBE_LINEUP_SUCCESS, sub, channel };
  }
  function failure(error) {
    return { type: sessionConstants.SUBSCRIBE_LINEUP_FAILURE };
  }
}

function unsubscribeLineup(channel) {
  const { session } = store.getState();

  return (dispatch) => {
    dispatch(request());
    sessionService
      .unsubscribe(session[`lineup${channel}sub`])
      .then((res) => {
        dispatch(success());
      })
      .catch((error) => {
        dispatch(failure());
        dispatch(alertActions.error(error.message));
      });
  };

  function request() {
    return { type: sessionConstants.UNSUBSCRIBE_LINEUP_REQUEST };
  }
  function success() {
    return { type: sessionConstants.UNSUBSCRIBE_LINEUP_SUCCESS };
  }
  function failure(error) {
    return { type: sessionConstants.UNSUBSCRIBE_LINEUP_FAILURE };
  }
}

function subscribeAllLineups() {
  const lineups = store.getState().session?.lineups.items;

  return (dispatch) => {
    dispatch(request());
    sessionService
      .subscribeAllLineups(lineups, dispatch)
      .then((res) => {
        dispatch(success(res));
      })
      .catch((error) => {
        dispatch(failure());
        console.log(error);
        dispatch(alertActions.error(error.message));
      });
  };
  function request() {
    return { type: sessionConstants.SUBSCRIBE_ALL_LINEUPS_REQUEST };
  }
  function success(sub) {
    return { type: sessionConstants.SUBSCRIBE_ALL_LINEUPS_SUCCESS, sub };
  }
  function failure(error) {
    return { type: sessionConstants.SUBSCRIBE_ALL_LINEUPS_FAILURE };
  }
}

function unsubscribeAllLineups() {
  const subs = store.getState().session.lineupSubs;

  return (dispatch) => {
    dispatch(request());
    sessionService
      .unsubscribeAllLineups(subs)
      .then((res) => {
        dispatch(success(res));
      })
      .catch((error) => {
        dispatch(failure());
        console.log(error);
        dispatch(alertActions.error(error.message));
      });
  };

  function request() {
    return { type: sessionConstants.UNSUBSCRIBE_ALL_LINEUPS_REQUEST };
  }
  function success() {
    return { type: sessionConstants.UNSUBSCRIBE_ALL_LINEUPS_SUCCESS };
  }
  function failure(error) {
    return { type: sessionConstants.UNSUBSCRIBE_ALL_LINEUPS_FAILURE };
  }
}

function syncSession(session) {
  const { session: prevSession } = store.getState();
  const reload =
    store.getState().session.alternating !== session.alternating ||
    session.alternating;
  const isStreamUpdate =
    !!session.streams?.items &&
    session.streams?.items?.length >= prevSession.streams?.items?.length;
  const streams = !session.streams ? prevSession.streams : session.streams;

  const updatedFields = Object.keys(session).filter(
    (key) =>
      session[key] !== prevSession[key] &&
      !['_version', '__typename', isStreamUpdate ? '' : 'streams'].includes(key)
  );

  const newData = {
    ...session,
    streams,
  };

  return (dispatch) => {
    dispatch(success(newData));
    if (session?.producer || reload) {
      dispatch(
        producerActions.syncProducer(JSON.parse(session.producer), reload)
      );
    }
    dispatch(alertActions.sync(`Session ${updatedFields?.[0]} updated.`));
  };

  function success(data) {
    return { type: sessionConstants.SYNC_SESSION_SUCCESS, data };
  }
}

function updateProducer(payload) {
  const { round, live, completedRounds } = store.getState().producer;
  const { id, _version } = store.getState().session;

  const producerSync = {
    round,
    live,
    completedRounds,
    ...payload,
  };

  //console.log(payload);
  //console.log(producerSync);

  return (dispatch) => {
    dispatch(request());

    sessionService
      .updateProducer({ id, _version, producer: producerSync })
      .then((res) => {
        console.log(res);
        dispatch(success(res.data.updateSession));
      })
      .catch((error) => {
        console.log(error);
        dispatch(failure());
        dispatch(alertActions.error(error.message));
      });
  };

  function request() {
    return { type: sessionConstants.UPDATE_PRODUCER_REQUEST };
  }
  function success(data) {
    return { type: sessionConstants.UPDATE_PRODUCER_SUCCESS, data };
  }
  function failure() {
    return { type: sessionConstants.UPDATE_PRODUCER_FAILURE };
  }
}

function changeView(view, side = null) {
  const { playerA, playerB } = store.getState().videoPlayer;
  let newView = view;

  return (dispatch) => {
    if (side && ['A', 'B'].includes(side.toUpperCase())) {
      newView = view + side.toUpperCase();
    }

    if (view !== sessionViewType.DEFAULT) {
      dispatch(producerActions.showScores(true));
    }

    if (view === sessionViewType.MIRROR) {
      if (side && side.toUpperCase() === 'A') {
        dispatch(videoPlayerActions.seek(playerA.seekTime, 'B', false));
      }
      if (side && side.toUpperCase() === 'B') {
        dispatch(videoPlayerActions.seek(playerB.seekTime, 'A', false));
      }
    }
    dispatch(success());
  };

  function success() {
    return { type: sessionConstants.CHANGE_VIEW, view: newView };
  }
}

function updateStatus(input) {
  return (dispatch) => {
    dispatch(request());
    sessionService
      .updateStatus(input)
      .then((res) => {
        dispatch(success(res.data.updateSession));
      })
      .catch((error) => {
        dispatch(failure());
        dispatch(alertActions.error(error.message));
      });
  };

  function request() {
    return { type: sessionConstants.UPDATE_STATUS_REQUEST };
  }
  function success(data) {
    return { type: sessionConstants.UPDATE_STATUS_SUCCESS, data };
  }
  function failure(error) {
    return { type: sessionConstants.UPDATE_STATUS_FAILURE };
  }
}
