import ScoreCard from './scorecard';
import { RoutineStatus } from '../../models';

export default function CompetitorListScore({ team, index, routine }) {
  if (routine?.status === RoutineStatus.COMPLETE) {
    return <ScoreCard routine={routine} home={team.home} />;
  } else if (routine?.status === RoutineStatus.ON_AIR) {
    return <span className="pending">On Air</span>;
  } else if (routine?.status === RoutineStatus.ON_EVAL) {
    return <span className="pending">On Eval</span>;
  } else if (routine?.status === RoutineStatus.STANDBY) {
    return <span className="pending">Standby</span>;
  } else {
    return '--';
  }
}
