import React from 'react';
import { Row, Col, Button, OverlayTrigger, Popover } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { producerActions, evaluatorActions } from '../../redux/_actions';
import {
  caretUpIcon,
  caretDownIcon,
  inquiryIcon,
  sCircle,
  nCircle,
} from '../helpers/icons';
import { sessionRoleType, sessionViewType } from '../../redux/_constants';

const inquiryIndicator = <div className="inquiryIndicator">{inquiryIcon}</div>;
const stickIndicator = <div className="stickIndicator green">{sCircle}</div>;
const penaltyIndicator = <div className="penaltyIndicator red">{nCircle}</div>;
const dScoreIndicator = (score) => {
  return <div className="dScoreIndicator">{score}</div>;
};

function ScoreCardLegacy({ team, index, lineup }) {
  //const [inquiry, setInquiry] = useState(false);
  const live = useSelector((state) => state.producer.live);
  const role = useSelector((state) => state.session.role);
  const view = useSelector((state) => state.session.view);
  const stickBonus = useSelector((state) => state.evaluator.config.stickBonus);
  const startValue = useSelector((state) => state.evaluator.config.startValue);
  const dispatch = useDispatch();
  const fs = lineup[index]?.evaluation;
  const subScores = lineup[index]?.evaluation?.evalPanel?.subScores;
  const { inquiry, resolved } = fs?.evalPanel?.flags || {};
  const { ADMIN, JUDGE } = sessionRoleType;

  const scoreCardButton = () => {
    if (inquiry && resolved) {
      const finalScore = fs.evalPanel.finalScore;
      const i = finalScore.length - 1;
      let previousScore = null;

      for (let j = i - 1; j >= 0; j--) {
        if (finalScore[j].locked) {
          previousScore = finalScore[j].score;
          break;
        }
      }

      if (previousScore === null) {
        return null;
      }

      const scoreDelta =
        Number(finalScore[i].score.score) - Number(previousScore.score);

      if (scoreDelta === 0) {
        return '--';
      } else {
        return (
          <span
            style={{
              textDecoration: 'line-through',
              fontSize: '1.2rem',
              fontStyle: 'italic',
            }}
          >
            {previousScore.score}
          </span>
        );
      }
    }

    const buttonText = inquiry ? 'Resolve' : 'Inquiry';

    return (
      <Button variant="warning" onClick={submitInquiry}>
        {' '}
        {buttonText}{' '}
      </Button>
    );
  };

  const scoreCardPlacement = () => {
    if (view === sessionViewType.SCOREBOARD) {
      return team.home ? 'right' : 'left';
    } else if (view === sessionViewType.MOBILE) {
      return 'auto';
    } else {
      //return team.home ? 'top-end' : 'top-start';
      return team.home ? 'auto-end' : 'auto-start';
    }
  };

  const inquiryHeader = () => {
    let resolvedClass = '';
    if (!inquiry) {
      return null;
    }

    if (inquiry && !resolved) {
      resolvedClass = 'unresolved';
    }

    return (
      <span className={['inquiryHeader', resolvedClass].join(' ')}>
        Inquiry
      </span>
    );
  };

  const submitInquiry = (e) => {
    // Request Inquiry
    if (!inquiry) {
      dispatch(producerActions.requestInquiry(team.home, index));
    } else {
      // Move to resolve inquiry phase
      dispatch(
        evaluatorActions.resolveInquiry(
          team.home,
          index,
          lineup[index].evaluation.evalPanel
        )
      );
      dispatch(producerActions.resolveInquiry(team.home, index));
    }
  };

  const popover = (
    <Popover id="popover-scorecard">
      <Popover.Title as="h3">
        <Row style={{ margin: '0' }}>
          <Col xs={6} className="vCenter score">
            Score Card
          </Col>
          <Col xs={3} className="vCenter score"></Col>
          <Col xs={3} className="vCenter">
            {inquiryHeader()}
          </Col>
        </Row>
      </Popover.Title>
      <Popover.Content>
        <Row style={{ margin: '0' }}>
          <Col xs={6} className="vCenter" style={{ fontWeight: '600' }}>
            {startValue ? 'Start Value:' : 'Difficulty:'}
          </Col>
          <Col xs={3} className="vCenter" style={{ fontWeight: '600' }}>
            {fs.dScore}
          </Col>
          <Col xs={3} className="vCenter green"></Col>
        </Row>
        <Row style={{ margin: '0' }}>
          <Col xs={6} className="vCenter" style={{ fontWeight: '600' }}>
            Execution:
          </Col>
          <Col xs={3} className="vCenter" style={{ fontWeight: '600' }}>
            {fs.eScore}
          </Col>
          <Col
            xs={3}
            className="vCenter"
            style={{ textAlign: 'center', fontStyle: 'italic' }}
          >
            average
          </Col>
        </Row>
        <Row style={{ margin: '0', padding: '0' }}>
          <Col xs={2} style={{ fontStyle: 'italic', padding: '0 0 0 15px' }}>
            E1:
          </Col>
          <Col xs={1} className="vCenter" style={{ whiteSpace: 'nowrap' }}>
            {startValue
              ? (
                  fs?.dScore - subScores['E1'][subScores['E1'].length - 1].score
                ).toFixed(2)
              : `${(
                  10 - subScores['E1'][subScores['E1'].length - 1].score
                ).toFixed(1)}`}
          </Col>
          <Col xs={1} style={{ whiteSpace: 'nowrap' }}>
            {`(${subScores['E1'][subScores['E1'].length - 1].score.toFixed(
              startValue ? 2 : 1
            )})`}
          </Col>
          <Col xs={5} />
          <Col xs={3} />
        </Row>
        <Row style={{ margin: '0', padding: '0' }}>
          <Col xs={2} style={{ fontStyle: 'italic', padding: '0 0 0 15px' }}>
            E2:
          </Col>
          <Col xs={1} className="vCenter" style={{ whiteSpace: 'nowrap' }}>
            {startValue
              ? (
                  fs?.dScore - subScores['E2'][subScores['E2'].length - 1].score
                ).toFixed(2)
              : `${(
                  10 - subScores['E2'][subScores['E2'].length - 1].score
                ).toFixed(1)}`}
          </Col>
          <Col xs={1} style={{ whiteSpace: 'nowrap' }}>
            {`(${subScores['E2'][subScores['E2'].length - 1].score.toFixed(
              startValue ? 2 : 1
            )})`}
          </Col>
          <Col xs={5} />
          <Col
            xs={2}
            className="vCenter"
            style={{
              textAlign: 'center',
              fontStyle: 'italic',
              marginTop: '-0.8rem',
              whiteSpace: 'nowrap',
            }}
          >
            {Math.abs(
              subScores['E1'][subScores['E1'].length - 1].score -
                subScores['E2'][subScores['E2'].length - 1].score
            ) !== 0
              ? 'Δ ' +
                Math.abs(
                  subScores['E1'][subScores['E1'].length - 1].score -
                    subScores['E2'][subScores['E2'].length - 1].score
                ).toFixed(startValue ? 2 : 1)
              : null}
          </Col>
        </Row>
        <Row style={{ margin: '0' }}>
          <Col xs={6} className="vCenter">
            Neutral Deduction:
          </Col>
          <Col xs={3} className="vCenter">
            {fs.ND === '0.0' ? '--' : fs.ND}
          </Col>
          <Col xs={3} className="vCenter red">
            {fs.ND === '0.0' ? '' : caretDownIcon}
          </Col>
        </Row>
        {stickBonus ? (
          <Row style={{ margin: '0' }}>
            <Col xs={6} className="vCenter">
              Stick Bonus:
            </Col>
            <Col xs={3} className="vCenter">
              {fs.SB === '0.0' ? '--' : fs.SB}
            </Col>
            <Col xs={3} className="vCenter green">
              {fs.SB === '0.0' ? '' : caretUpIcon}
            </Col>
          </Row>
        ) : null}
        <hr style={{ width: '100%' }} />
        <Row style={{ margin: '0' }}>
          <Col xs={6} className="vCenter score">
            Final Score:
          </Col>
          <Col xs={3} className="vCenter score">
            {fs.score}
          </Col>
          <Col xs={3} className="vCenter" style={{ textAlign: 'center' }}>
            {/*live ? scoreCardButton() : null */}
            {live && [ADMIN, JUDGE].includes(role) ? scoreCardButton() : null}
          </Col>
        </Row>
      </Popover.Content>
    </Popover>
  );

  return (
    <OverlayTrigger
      trigger="click"
      placement={scoreCardPlacement()}
      key="top"
      overlay={popover}
      rootClose
    >
      <Button
        className="scorecard"
        variant={inquiry && !resolved ? 'outline-warning' : 'outline'}
      >
        {dScoreIndicator(fs.dScore)}
        {fs.score}
        {inquiry ? inquiryIndicator : null}
        {fs.SB === '0.0' ? null : stickIndicator}
        {fs.ND === '0.0' ? null : penaltyIndicator}
      </Button>
    </OverlayTrigger>
  );
}

export default ScoreCardLegacy;
