import { evaluatorConstants, initialEvaluatorState } from '../_constants';

export function evaluator(state = initialEvaluatorState, action) {
  switch (action.type) {
    case evaluatorConstants.OPEN_INQUIRY:
      return {
        ...state,
        activeInquiryByLineupId: {
          ...state.activeInquiryByLineupId,
          [action.data.lineupId]: { ...action.data.inquiry },
        },
      };
    case evaluatorConstants.CLEAR_INQUIRY:
      const activeInquiryByLineupId = { ...state.activeInquiryByLineupId };
      delete activeInquiryByLineupId[action.data.lineupId];
      return {
        ...state,
        activeInquiryByLineupId,
      };
    case evaluatorConstants.OPEN_EDIT:
      return {
        ...state,
        activeEditByLineupId: {
          ...state.activeEditByLineupId,
          [action.data.lineupId]: { ...action.data.routine },
        },
      };
    case evaluatorConstants.CLEAR_EDIT:
      const activeEditByLineupId = { ...state.activeEditByLineupId };
      delete activeEditByLineupId[action.data.lineupId];
      return {
        ...state,
        activeEditByLineupId,
      };
    default:
      return state;
  }
}
