import { dateTime, timeWithSeconds } from './conversions';

// Used to update the data for various video players

export function useHLSStats() {
  function compileStats({ hls, resolutionType, live }) {
    const streamCtlr = hls?.streamController;
    const frag = streamCtlr?.fragPlaying;
    const pgmTime = (frag && new Date(frag?.programDateTime)) ?? null;
    const lastTime = streamCtlr?.lastCurrentTime;
    const delta = (lastTime && lastTime - frag?.start) ?? null; // in seconds
    const duration = frag?.duration;
    const now = Date.now(); // in ms;
    const lag =
      (pgmTime && delta && (now - pgmTime - delta * 1000) / 1000) ?? null;

    return {
      pgmtime: pgmTime,
      date: frag ? dateTime(pgmTime, false) : null,
      time: frag ? timeWithSeconds(pgmTime) : null,
      sn: frag ? frag?.sn : null,
      duration: frag ? duration.toFixed(3) : null,
      resolution: hls
        ? resolutionType[hls?.levels?.[hls?.loadLevel]?.attrs?.RESOLUTION]
        : null,
      bitrate:
        (hls &&
          (hls?.levels?.[hls?.loadLevel]?.bitrate / 1000000).toFixed(3)) ??
        null,
      bandwidth: hls ? (hls?.bandwidthEstimate / 1000000).toFixed(1) : null,
      now: now,
      delta:
        frag && streamCtlr && Math.abs(delta) <= duration
          ? delta.toFixed(3)
          : null,
      lastTime: streamCtlr ? streamCtlr.lastCurrentTime : null,
      lag: live && lag !== null ? lag.toFixed(3) : null,
    };
  }
  return compileStats;
}

export function useTheoStats() {
  function compileStats({ hls, resolutionType, live }) {
    const pgmTime = hls?.currentProgramDateTime ?? null;
    const lastTime = hls?.currentTime;
    const now = Date.now(); // in ms
    const lag =
      hls?.duration === Infinity ? pgmTime && (now - pgmTime) / 1000 : null;

    return {
      pgmtime: pgmTime,
      date: pgmTime ? dateTime(pgmTime, false) : null,
      time: pgmTime ? timeWithSeconds(pgmTime) : null,
      lastTime: pgmTime ? lastTime : null,
      resolution: hls
        ? resolutionType[`${hls?.videoWidth}x${hls?.videoHeight}`]
        : null,
      now: now,
      lag: live && lag !== null ? lag.toFixed(3) : null,
      bitrate:
        (hls &&
          (hls?.videoTracks?.[0]?.activeQuality?.bandwidth / 1000000).toFixed(
            3
          )) ??
        null,
      bandwidth: hls
        ? (hls?.metrics?.currentBandwidthEstimate / 1000000).toFixed(1)
        : null,
    };
  }

  return compileStats;
}

export function useYouTubeStats() {
  function compileStats({ player }) {
    const now = Date.now();
    return {
      now: now,
      duration: player && player.getDuration && player.getDuration(),
      type: 'YouTube',
    };
  }

  return compileStats;
}

export function captureVideoFrame(video, format, quality) {
  if (typeof video === 'string') {
    video = document.getElementById(video);
  }

  format = format || 'jpeg';
  quality = quality || 0.92;

  if (!video || (format !== 'png' && format !== 'jpeg')) {
    return false;
  }

  const canvas = document.createElement('CANVAS');

  canvas.width = video.videoWidth;
  canvas.height = video.videoHeight;

  canvas.getContext('2d').drawImage(video, 0, 0);

  const dataUri = canvas.toDataURL('image/' + format, quality);
  const data = dataUri.split(',')[1];
  const mimeType = dataUri.split(';')[0].slice(5);

  const bytes = window.atob(data);
  const buf = new ArrayBuffer(bytes.length);
  const arr = new Uint8Array(buf);

  for (let i = 0; i < bytes.length; i++) {
    arr[i] = bytes.charCodeAt(i);
  }

  const blob = new Blob([arr], { type: mimeType });
  return { blob: blob, dataUri: dataUri, format: format };
}
