import React from 'react';
import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'react-bootstrap';

function UnauthorizedPage() {
  return (
    <Container>
      <Row>
        <Col
          style={{
            textAlign: 'center',
            marginTop: '3em',
            fontSize: '50px',
            fontWeight: 600,
          }}
        >
          Access Denied
        </Col>
      </Row>
      <Row>
        <Col style={{ textAlign: 'center', marginBottom: '1em' }}>
          You do not have access to this session. Please log in to view the
          session.
        </Col>
      </Row>
      <Row>
        <Col style={{ textAlign: 'center' }}>
          <Link to="/login">Login</Link>
        </Col>
      </Row>
    </Container>
  );
}

export default UnauthorizedPage;
