import { Container, Row, Col } from 'react-bootstrap';
import LoginOrSignup from './login/loginorsignup';
import CreateOrJoin from './setup/createorjoin';
import Header from './helpers/header';
import UserBubble from './helpers/userbubble';
import MenuBubble from './helpers/menububble';
import { uiState } from '../redux/_constants';
import { heroLogo } from './helpers/logos';
import LoginBubble from './helpers/loginbubble';
import MatchupGrid from './helpers/matchupgrid';
import Privacy from './helpers/privacy';
import Terms from './helpers/terms';
import Footer from './helpers/footer';
import { useLocation } from 'react-router-dom';
import { useUser } from '../user-provider';
import CookieBanner from '../utilities/cookie';

function Home() {
  const user = useUser((state) => state.user);
  const location = useLocation();
  const { PRIVACY, TERMS } = uiState;

  const mainContent = () => {
    switch (location.pathname) {
      case PRIVACY:
        return <Privacy />;
      case TERMS:
        return <Terms />;
      default:
        return <MatchupGrid />;
    }
  };

  const mainSubtitle = () => {
    switch (location.pathname) {
      case PRIVACY:
        return 'Privacy policy';
      case TERMS:
        return 'Terms of service';
      default:
        return 'Select match (local time)';
    }
  };

  const header = () => {
    if (
      user &&
      user.signInUserSession &&
      ['/privacy', '/terms'].includes(location.pathname)
    ) {
      return (
        <>
          <Header>
            <UserBubble />
            <MenuBubble />
          </Header>
          <CreateOrJoin />
        </>
      );
    } else {
      return (
        <>
          <Header>
            <MenuBubble />
            <LoginBubble />
          </Header>
          <LoginOrSignup />
        </>
      );
    }
  };

  return (
    <div className="main">
      {header()}
      <Container>
        <Row style={{ height: 'calc(15vh - 72px)' }} />
        <Row
          style={{
            background: '#fff',
            padding: '1rem',
            zIndex: '100',
          }}
        >
          <Col className="center" style={{ display: 'flex', flexWrap: 'wrap' }}>
            <div className="heroLogo"> {heroLogo} </div>
            <div className="heroTagline">Season 4</div>
            <div className="heroItalic" style={{ fontStyle: 'italic' }}>
              {mainSubtitle()}
            </div>
          </Col>
        </Row>
        <Row style={{ height: '50vh' }}>
          <Col className="center" style={{ display: 'flex', flexWrap: 'wrap' }}>
            {mainContent()}
          </Col>
        </Row>
      </Container>
      <Footer />
      <CookieBanner />
    </div>
  );
}

export default Home;
