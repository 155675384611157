import { createContext } from 'react';
import { Container, Row, Col, Card, CardGroup } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import ScoreSheetHeader from './scoresheetheader';
import {
  SessionSubscriptionManager,
  useRotations,
  useExperimental,
  useEvalConfig,
  useJudgesByApparatus,
  useTeamsByAthleteId,
} from './hooks';
import './main.css';
import './scoreslips.css';
import { GYMapparatusAbbv, teamBrand } from '../../utilities/conversions';
import * as Icons from '../helpers/icons';

export const SessionContext = createContext(null);

export default function ScoreSlips({ sessionId }) {
  const evalConfig = useEvalConfig();
  const judgesByApparatus = useJudgesByApparatus(sessionId);
  const { jPanel, ePanel } = evalConfig;
  const experimental = useExperimental({ sessionId });
  const rotations = useRotations(sessionId);
  const { session } = experimental;
  const { gender } = session;
  const apparatusesInOrder = GYMapparatusAbbv(gender);
  const teamA = useSelector((state) => state.producer['teamA']);
  const teamsByAthleteId = useTeamsByAthleteId(sessionId);

  const whichPanel = jPanel || ePanel || [];

  const lineup = useSelector((state) =>
    state.session.lineups.items.find((l) => l.id === teamA.lineupId)
  );
  const fullLineup = JSON.parse(lineup.lineupData) || [];

  function ScoreSlips({ team }) {
    return (
      <section>
        {Object.values(rotations).map((rotation) => {
          const stagesByApparatus = rotation.stages.items.reduce(
            (acc, stage) => {
              if (!stage._deleted) {
                acc[stage.apparatus] = stage;
              }
              return acc;
            },
            {}
          );

          let apparatus = '';

          Object.keys(stagesByApparatus).forEach((key) => {
            if (
              stagesByApparatus[key].squad?.rosters?.items?.some(
                (r) => r?.roster?.teamId === teamA.id
              )
            ) {
              apparatus = key;
            }
          });

          const apparatusIndex = apparatusesInOrder.indexOf(apparatus);
          const currentLineup = fullLineup?.[apparatusIndex + 1];
          const isLineupSet = currentLineup && currentLineup.length > 0;

          return (
            <section key={`${rotation.order}`} className="scoreSlipSection">
              <h3 className="noprint">Rotation {rotation.order + 1}</h3>

              <Row className="headers noprint" style={{ marginBottom: '1rem' }}>
                <Col className="left">
                  <ScoreSheetHeader
                    teamSide="teamA"
                    left={true}
                    index={apparatusIndex + 1}
                  />
                </Col>
              </Row>

              <Row style={{ justifyContent: 'center' }}>
                {isLineupSet &&
                  fullLineup[apparatusIndex + 1].map((l, i) => {
                    const athleteTeam = teamsByAthleteId[l.athleteId];
                    const { name: teamName } = teamBrand(athleteTeam);

                    return whichPanel.map((j) => {
                      return (
                        <Card
                          style={{
                            margin: '1rem',
                            pageBreakAfter: 'auto',
                            pageBreakInside: 'avoid',
                            minWidth: '46%',
                          }}
                          key={`${l.key}_${i}_${j.type}`}
                          className="scoreSlip"
                        >
                          <Card.Header style={{ fontSize: '1rem' }}>
                            <Row>
                              <Col xs={8}>
                                <h4>{session.name}</h4>
                              </Col>
                              <Col xs={4}>
                                <h4 style={{ textAlign: 'center' }}>
                                  R{rotation.order + 1}: #{i + 1} ({j.type})
                                </h4>
                              </Col>
                            </Row>
                          </Card.Header>
                          <Card.Body>
                            <Row>
                              <Col xs={9}>
                                <h2>{l.name}</h2>
                              </Col>
                              <Col xs={3}>
                                <h4 style={{ textAlign: 'center' }}>
                                  {apparatus}
                                </h4>
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={9}>
                                <h4>{teamName}</h4>
                              </Col>
                              <Col xs={3} className="vCenter">
                                <span
                                  style={{
                                    width: '100%',
                                    textAlign: 'center',
                                    padding: '0 2rem',
                                  }}
                                >
                                  {Icons?.[`${apparatus}noText`]}
                                </span>
                              </Col>
                            </Row>
                            <Row>
                              <CardGroup
                                style={{ width: '100%', margin: '1rem' }}
                              >
                                <Card style={{ flexBasis: '25%' }}>
                                  <Card.Header
                                    style={{
                                      fontSize: '1.2rem',
                                      fontWeight: '600',
                                      padding: '0.25rem',
                                    }}
                                  >
                                    SV
                                  </Card.Header>
                                  <Card.Body
                                    style={{ height: '6rem' }}
                                  ></Card.Body>
                                </Card>
                                <Card style={{ flexBasis: '25%' }}>
                                  <Card.Header
                                    style={{
                                      fontSize: '1.2rem',
                                      fontWeight: '600',
                                      padding: '0.25rem',
                                    }}
                                  >
                                    ND
                                  </Card.Header>
                                  <Card.Body></Card.Body>
                                </Card>
                                <Card style={{ flexBasis: '50%' }}>
                                  <Card.Header
                                    style={{
                                      fontSize: '1.2rem',
                                      fontWeight: '600',
                                      padding: '0.25rem',
                                    }}
                                  >
                                    Score ({j.type})
                                  </Card.Header>
                                  <Card.Body></Card.Body>
                                </Card>
                              </CardGroup>
                            </Row>
                            <Row>
                              <CardGroup
                                style={{ width: '100%', margin: '1rem' }}
                              >
                                <Card style={{ flexBasis: '25%' }}>
                                  <Card.Header
                                    style={{
                                      fontSize: '1.2rem',
                                      fontWeight: '600',
                                      padding: '0.25rem',
                                    }}
                                  >
                                    Final Avg.
                                  </Card.Header>
                                  <Card.Body
                                    style={{ height: '6rem' }}
                                  ></Card.Body>
                                </Card>
                                <Card style={{ flexBasis: '25%' }}>
                                  <Card.Header
                                    style={{
                                      fontSize: '1.2rem',
                                      fontWeight: '600',
                                      padding: '0.25rem',
                                    }}
                                  >
                                    ND (Team)
                                  </Card.Header>
                                  <Card.Body></Card.Body>
                                </Card>
                                <Card style={{ flexBasis: '50%' }}>
                                  <Card.Header
                                    style={{
                                      fontSize: '1.2rem',
                                      fontWeight: '600',
                                      padding: '0.25rem',
                                    }}
                                  >
                                    Signature
                                  </Card.Header>
                                  <Card.Body></Card.Body>
                                  <Card.Footer className="judgeSignature">
                                    {
                                      judgesByApparatus[apparatus].component[
                                        j.type
                                      ]
                                    }
                                  </Card.Footer>
                                </Card>
                              </CardGroup>
                            </Row>
                          </Card.Body>
                        </Card>
                      );
                    });
                  })}
              </Row>
            </section>
          );
        })}
      </section>
    );
  }

  return (
    <SessionContext.Provider value={experimental}>
      <div className={['main', 'session'].join(' ')}>
        <Container>
          <ScoreSlips />
        </Container>
        <SessionSubscriptionManager sessionId={sessionId} />
      </div>
    </SessionContext.Provider>
  );
}
