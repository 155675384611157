import React from 'react';
import { exportAllDataApollo } from '../../utilities/export';
import { SessionSubscriptionManager } from './hooks';
import { useQuery } from '@apollo/client';
import { useSelector } from 'react-redux';
import { sessionRoleType } from '../../redux/_constants';
import GetFullSession from '../../apollo/queries/GetFullSession.graphql';

function ScoresJSON({ sessionId }) {
  const isAdmin = useSelector((state) => state.session.role);
  const { data: sessionData } = useQuery(GetFullSession, {
    variables: { id: sessionId },
  });

  return (
    <>
      {' '}
      {sessionData && (
        <pre>
          {JSON.stringify(
            exportAllDataApollo(
              sessionData?.getSession,
              isAdmin === sessionRoleType.ADMIN
            ),
            null,
            2
          )}
        </pre>
      )}
      <SessionSubscriptionManager sessionId={sessionId} />
    </>
  );
}

export default ScoresJSON;
