import React, { useEffect, useState, useMemo } from 'react';
import { Modal, Button, Row, Alert } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { alertActions, adminActions } from '../../redux/_actions';
import TableComponent from '../helpers/tablecomponent';
import { modeType } from '../../utilities/constants';
import { judgeType } from '../../redux/_constants';
import UserDetail from './userdetail';
import { Loading, ScrollLoading } from '../helpers/loading';
import { SearchInput, addBold, noResults } from '../helpers/search';

function JudgeAdmin(props) {
  const { setIsForm, handleMode } = props;
  const [isLoading, setIsLoading] = useState(true);
  const [isScrollLoading, setIsScrollLoading] = useState(false);
  const { LIST, DETAIL, EDIT, DELETE } = modeType;
  const [mode, setMode] = useState(LIST);
  const [userId, setUserId] = useState(null);
  const alert = useSelector((state) => state.alert);
  const judges = useSelector((state) => state.admin.judges);
  const nextToken = useSelector((state) => state.admin.judges.tokens.nextToken);
  const searchQuery = useSelector(
    (state) => state.admin.judges.tokens.searchQuery
  );
  const searchToken = useSelector(
    (state) => state.admin.judges.tokens.searchToken
  );

  const [searchTerm, setSearchTerm] = useState(searchQuery || '');
  const [tempSearchTerm, setTempSearchTerm] = useState(searchQuery || ''); // used for async holding of query
  const dispatch = useDispatch();
  const numJudges = judges?.allIds?.length || 0;
  const isSearch = searchTerm !== '';
  let lastScrollTop = 0;

  const {
    FIG_MENS,
    FIG_WOMENS,
    NCAA_MENS,
    NCAA_WOMENS,
    JO_MENS,
    JO_WOMENS,
  } = judgeType;

  useEffect(() => {
    if (!alert.clear) {
      setIsLoading(false);
    }
  }, [alert.clear]);

  useEffect(() => {
    dispatch(alertActions.clear());
  }, [dispatch]);

  useEffect(() => {
    if (numJudges === 0) {
      setIsLoading(true);
      dispatch(adminActions.getJudges());
    } else {
      setIsLoading(false);
      setIsScrollLoading(false);
      setSearchTerm(tempSearchTerm);
    }
  }, [numJudges, judges, dispatch]);

  const userDetail = (e, row) => {
    if (!alert.clear) {
      dispatch(alertActions.clear());
    }
    setUserId(row.id);
    setMode(DETAIL);
    setIsForm(true);
  };

  const back = () => {
    if (!alert.clear) {
      dispatch(alertActions.clear());
    }
    setMode(LIST);
    setIsForm(false);
  };

  const headers = useMemo(
    () => [
      { Header: '#', accessor: 'order' },
      {
        Header: 'Name',
        accessor: 'name',
        Cell: (r) => addBold(r.cell.value, searchTerm),
      },
      {
        Header: 'Email',
        accessor: 'email',
        Cell: (r) => addBold(r.cell.value, searchTerm),
      },
      { Header: 'FIG (M)', accessor: 'figm' },
      { Header: 'NCAA (M)', accessor: 'ncaam' },
      { Header: 'JO (M)', accessor: 'jom' },
      { Header: 'FIG (W)', accessor: 'figw' },
      { Header: 'NCAA (W)', accessor: 'ncaaw' },
      { Header: 'JO (W)', accessor: 'jow' },
    ],
    [searchTerm]
  );

  const data = useMemo(() => {
    return judges.allIds.map((id, i) => {
      const { name, email, judgeType } = judges.byId[id];

      return {
        order: i + 1,
        name: name ? name : '',
        email: email,
        figm: judgeType && judgeType.includes(FIG_MENS) ? 'Y' : '-',
        ncaam: judgeType && judgeType.includes(NCAA_MENS) ? 'Y' : '-',
        jom: judgeType && judgeType.includes(JO_MENS) ? 'Y' : '-',
        figw: judgeType && judgeType.includes(FIG_WOMENS) ? 'Y' : '-',
        ncaaw: judgeType && judgeType.includes(NCAA_WOMENS) ? 'Y' : '-',
        jow: judgeType && judgeType.includes(JO_WOMENS) ? 'Y' : '-',
        id: id,
      };
    });
  }, [
    judges,
    FIG_MENS,
    FIG_WOMENS,
    NCAA_WOMENS,
    NCAA_MENS,
    JO_WOMENS,
    JO_MENS,
  ]);

  const table = () => {
    const initialState = {
      sortBy: [{ id: 'name', desc: false }],
    };

    const empty = numJudges === 0 || data.length === 0;

    return empty ? (
      noResults('judges')
    ) : (
      <TableComponent
        columns={headers}
        data={data}
        textSort={true}
        onClick={userDetail}
        initialState={initialState}
        searchTerm={searchTerm}
        showFooter={true}
        filterColumns={['name', 'email']}
      />
    );
  };

  const handleScroll = (e) => {
    const { scrollHeight, scrollTop, scrollLeft, clientHeight } = e.target;
    const bottom = scrollHeight - scrollTop === clientHeight;
    const nextPage =
      (isSearch && searchToken !== null) ||
      (!isSearch && nextToken !== null && numJudges !== 0);
    const vertScroll =
      scrollLeft === 0 && scrollTop !== lastScrollTop && lastScrollTop !== 0;

    lastScrollTop = scrollTop <= 0 ? 0 : scrollTop;

    if (bottom && nextPage && vertScroll && !isScrollLoading) {
      setIsScrollLoading(true);
      dispatch(
        adminActions.getJudges({
          searchQuery: searchTerm,
          nextToken: isSearch ? searchToken : nextToken,
        })
      );
    }
  };

  const handleSearch = (query) => {
    setTempSearchTerm(query);

    if (query && query !== '') {
      dispatch(
        adminActions.getJudges({
          searchQuery: query,
          nextToken: null,
        })
      );
    } else {
      // clear search
      setSearchTerm(query);
    }
  };

  const adminBody = () => {
    return (
      <>
        {!alert.clear ? (
          <Row style={{ padding: '0 15px', margin: '1rem -15px' }}>
            <Alert
              dismissible
              onClose={() => dispatch(alertActions.clear())}
              variant={alert.type === 'alert-danger' ? 'danger' : 'success'}
              style={{ textAlign: 'center', width: '100%' }}
            >
              {alert.message}
            </Alert>
          </Row>
        ) : null}
        {isLoading ? <Loading /> : table()}
      </>
    );
  };

  const footer = () => {
    return (
      <>
        <Row className="vCenter" style={{ margin: '0 auto' }}>
          <Button
            variant="outline-primary"
            className="formButton"
            onClick={() => handleMode(null)}
            disabled={isLoading}
          >
            Close
          </Button>
        </Row>
        <Row />
      </>
    );
  };

  const headerMsg = () => {
    return <span>Judge Admin</span>;
  };

  const listModal = () => {
    return (
      <>
        <Modal.Header className="searchHeader">
          {headerMsg()}
          {numJudges !== 0 && data?.length !== 0 ? (
            <SearchInput searchTerm={searchTerm} search={handleSearch} />
          ) : null}
        </Modal.Header>
        <Modal.Body className="setupForm" onScroll={handleScroll}>
          {adminBody()}
          {isScrollLoading ? <ScrollLoading /> : null}
        </Modal.Body>
        <Modal.Footer className="setupFooter">{footer()}</Modal.Footer>
      </>
    );
  };

  const modal = () => {
    switch (mode) {
      case EDIT:
        break;
      case DELETE:
        break;
      case LIST:
        return listModal();
      case DETAIL:
        return <UserDetail back={back} userId={userId} />;
      default:
        return null;
    }
  };

  return modal();
}

export default JudgeAdmin;
