import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { TeamBanner } from '../session/teambanner';
import RoundCount from '../session/roundcount';
import './sessionheader.css';

function SessionHeader({ solo, mirror }) {
  const teamA = mirror === 'B' ? 'teamB' : 'teamA';
  const teamB = mirror === 'A' ? 'teamA' : 'teamB';

  if (mirror === 'A') {
  }

  return (
    <Container>
      <Row className="headers">
        <Col className="left">
          <TeamBanner teamSide={teamA} left={true} />
        </Col>
        <Col
          className={['center', solo ? 'soloRoundCount' : null].join(' ')}
          xs={1}
        >
          <RoundCount />
        </Col>
        {solo ? null : (
          <Col className="right">
            <TeamBanner teamSide={teamB} right={true} />
          </Col>
        )}
      </Row>
    </Container>
  );
}

export default SessionHeader;
