import React from 'react';
import { Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

function LoginBubble() {
  const history = useHistory();

  const handler = () => {
    history.push('/login');
  };

  return (
    <Button
      className="loginBubble"
      onClick={handler}
      variant="outline-secondary"
      style={{ whiteSpace: 'nowrap' }}
    >
      Log in
    </Button>
  );
}

export default LoginBubble;
