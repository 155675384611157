import { Col, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { numToApparatus, teamBrand } from '../../utilities/conversions';
import * as Icons from '../helpers/icons';
import { TeamBannerSelector } from './teambanner';
import { useSortedLineups } from './hooks';

function ScoreSheetHeader({ teamSide, index }) {
  const teamId = useSelector((state) => state.producer[teamSide].teamId);
  const lineupId = useSelector((state) => state.producer[teamSide].lineupId);
  const gender = useSelector((state) => state.session.gender);
  const sessionId = useSelector((state) => state.session.id);
  const lineups = useSortedLineups(sessionId);

  const teams = lineups.map((l) => {
    const { name } = teamBrand(l.team);
    return {
      ...l.team,
      lineupId: l.id,
      displayName: l.forceTitle || l.individuals ? l.title : name,
    };
  });

  const team = teams.find((t) => t.lineupId === lineupId);
  const { displayName } = team || {};
  const { logo } = teamBrand(team);

  return (
    <div className="teamBanner">
      <Row style={{ height: '100%' }}>
        <Col xs={10} className="vCenter">
          <TeamBannerSelector
            name={`${displayName}`}
            teams={teams}
            selected={teamId}
            side={teamSide}
            lineupId={lineupId}
            logo={logo}
          ></TeamBannerSelector>
        </Col>
        <Col
          className="vCenter icon"
          xs={2}
          style={{ height: '4rem', margin: 'auto' }}
        >
          {Icons?.[`${numToApparatus(index, true, gender)}noText`]}
        </Col>
      </Row>
    </div>
  );
}

export default ScoreSheetHeader;
