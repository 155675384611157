import CreateOverlay from '../mutations/CreateOverlay.graphql';
import GetOverlays from '../queries/GetOverlays.graphql';

const newOptimisticOverlay = () => ({
  __typename: 'Overlay',
  id: 'FAKE-OVERLAY-ID',
  displayAt: new Date(),
  command: '',
  _version: 0,
});

export function createOverlay(apolloClient, input) {
  const readResult = apolloClient.readQuery({
    query: GetOverlays,
    variables: {
      sessionId: input.sessionId,
    },
  });

  const existingOverlays = readResult?.SessionOverlays ?? { items: [] };
  return apolloClient.mutate({
    mutation: CreateOverlay,
    variables: {
      input,
    },
    optimisticResponse: {
      __typename: 'Mutation',
      createOverlay: {
        ...newOptimisticOverlay(),
        ...input,
      },
    },
    update: (apolloProxy, { data: { createOverlay } }) => {
      apolloProxy.writeQuery({
        query: GetOverlays,
        variables: {
          sessionId: input.sessionId,
        },
        data: {
          SessionOverlays: {
            ...existingOverlays,
            items: [...existingOverlays.items, createOverlay],
          },
        },
      });
    },
  });
}
