import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { userActions } from './redux/_actions';
import { setAmplifyConfig } from './utilities/set-amplify-config';
import { Hub } from 'aws-amplify';
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api';

export const UserContext = React.createContext(null);

export const UserProvider = ({ children }) => {
  const user = useSelector((state) => state.user.user);
  const profile = useSelector((state) => state.user.profile);
  const [client, setClient] = useState(null);
  const isAuthenticating = useSelector((state) => state.user.initialLoad);
  const dispatch = useDispatch();

  useEffect(() => {
    // this is a handy little event listener that allows us to
    // tie into auth events _before_ react knows about them.
    // this part is critical to set Amplify Config between
    // auth modes (for public/private session access)
    Hub.listen('auth', (data) => {
      switch (data.payload.event) {
        case 'signIn':
          setClient(setAmplifyConfig());
          break;
        case 'signOut':
          console.log('signOut');
          setClient(setAmplifyConfig(GRAPHQL_AUTH_MODE.API_KEY));
          break;
        default:
          break;
      }
    });
  }, []);

  useEffect(() => {
    const userEmail = user?.attributes?.email;
    if (userEmail) {
      dispatch(userActions.getUserProfile(userEmail));
    }
  }, [user, dispatch]);

  useEffect(() => {
    setClient(setAmplifyConfig());
    dispatch(userActions.getUser(setClient));
  }, [dispatch]);

  const cognitoGroups =
    user?.signInUserSession?.accessToken?.payload?.['cognito:groups'] || [];

  const isFan = ['admin', 'poweruser'].reduce(
    (isFan, group) => isFan && !cognitoGroups.includes(group),
    true
  );

  const isAdmin = cognitoGroups.includes('admin');

  const values = {
    user,
    profile,
    isAuthenticating,
    cognitoGroups,
    isFan,
    isAdmin,
    client,
  };
  return <UserContext.Provider value={values}>{children}</UserContext.Provider>;
};

export const useUser = () => {
  const context = React.useContext(UserContext);

  if (context === undefined) {
    throw new Error(
      '`useUser` hook must be used within a `UserProvider` component'
    );
  }
  return context;
};
