import React from 'react';
import { Modal } from 'react-bootstrap';
import VideoSetup from './videosetup';
import SessionDetail from '../admin/sessiondetail';

function SetupSession(props) {
  const {
    modeType,
    mode,
    handleMode,
    sessionId,
    sessionKey,
    isLoading,
  } = props;

  const formType = () => {
    switch (mode) {
      case modeType.TEAM_A:
        break;
      //return <TeamRoster />
      case modeType.TEAM_B:
        break;
      //return <TeamRoster />
      case modeType.JUDGES:
        break;
      case modeType.SESSION:
        return (
          <SessionDetail
            back={() => handleMode(null)}
            sessionId={sessionId}
            sessionKey={sessionKey}
            isLoading={isLoading}
          />
        );
      //return <JudgeRoster />
      case modeType.VIDEO:
        return <VideoSetup />;
      default:
        break;
    }
  };

  return (
    <Modal
      show={mode !== null}
      onHide={() => handleMode(null)}
      centered
      dialogClassName="setupModal fullHeight"
      animation={false}
      size="xl"
    >
      {formType()}
    </Modal>
  );
}

export default SetupSession;
