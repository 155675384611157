import { useEffect, useState } from 'react';
import { Container, Button } from 'react-bootstrap';
import JudgingFooter from './judgingfooter';
import ToastStack from './toaststack';
import { chevronUpIcon, chevronDownIcon } from '../helpers/icons';
import { useSelector } from 'react-redux';
import { useJudgingEnabled } from './hooks';
import { SessionStatus } from '../../models';
import { useUser } from '../../user-provider';
import './sessionfooter.css';

export default function SessionFooter({ teamA, teamB, sessionId, solo }) {
  const roundEnabled = useSelector((state) => state.producer.live);
  const judgingEnabled = useJudgingEnabled();
  const live = useSelector(
    (state) => state.session.status === SessionStatus.LIVE
  );
  const [isVisible, setIsVisible] = useState(live && judgingEnabled);
  const { isAdmin } = useUser();

  useEffect(() => {
    if (roundEnabled && judgingEnabled) {
      setIsVisible(true);
    }
  }, [roundEnabled, judgingEnabled]);

  return (
    <div
      className={['sessionFooter', isVisible ? '' : 'sessionFooterHidden'].join(
        ' '
      )}
    >
      <Container>
        <ToastStack />
        {isAdmin ? (
          <Button
            variant="light"
            className="footerToggle vCenter"
            onClick={() => setIsVisible(!isVisible)}
          >
            {isVisible ? chevronDownIcon : chevronUpIcon}
          </Button>
        ) : null}
        {judgingEnabled ? (
          <JudgingFooter
            teamA={teamA}
            teamB={teamB}
            sessionId={sessionId}
            solo={solo}
          />
        ) : null}
      </Container>
    </div>
  );
}
