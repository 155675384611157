import gql from 'graphql-tag';

// mutations

const createSession = gql`
  mutation($input: CreateSessionInput!) {
    createSession(input: $input) {
      id
      owner
      judging
      judgingRequired
      competition
      apparatus
      access
      gender
      endAt
      name
      status
      type
      startAt
      access
      sessionKey
      createdAt
      updatedAt
      producer
      teamScoring
      alternating
      judgePanel
      _version
      hostId
      hostType
      attendance
      location
      sessionTeams(sortDirection: ASC) {
        items {
          id
          teamId
          order
          _version
          _deleted
          sessionId
        }
      }
    }
  }
`;

const updateSession = gql`
  mutation UpdateSession(
    $input: UpdateSessionInput!
    $condition: ModelSessionConditionInput
  ) {
    updateSession(input: $input, condition: $condition) {
      id
      owner
      judging
      judgingRequired
      competition
      apparatus
      gender
      access
      endAt
      name
      status
      type
      startAt
      access
      sessionKey
      createdAt
      updatedAt
      producer
      teamScoring
      alternating
      judgePanel
      _version
      hostId
      hostType
      attendance
      location
      lineups {
        items {
          id
          teamId
          sessionId
          sessionTeamId
          order
          _version
          _deleted
          individuals
          routines {
            items {
              id
              sessionId
              clips {
                items {
                  id
                  startTime
                  endTime
                  duration
                  fileName
                  originURL
                  routineId
                  streamId
                  owner
                  _version
                }
              }
              athleteId
              apparatus
              order
              rotation
              lineupId
              _version
            }
          }
        }
      }
      sessionJudges(filter: { status: { eq: ACTIVE } }) {
        items {
          id
          sessionId
          userId
          status
          _version
        }
      }
      sessionTeams(sortDirection: ASC) {
        items {
          id
          teamId
          order
          _version
          _deleted
          sessionId
        }
      }
      streams(filter: { status: { ne: DELETED } }) {
        items {
          id
          streamId
          ingestURL
          outputURL
          index
          sessionId
          meta
          stats
          error
          status
          title
          _version
        }
      }
    }
  }
`;

const getSession = gql`
  query SessionByKey($sessionKey: String!) {
    SessionByKey(sessionKey: $sessionKey) {
      items {
        id
        judging
        judgingRequired
        competition
        apparatus
        access
        endAt
        owner
        name
        status
        type
        gender
        startAt
        access
        sessionKey
        producer
        teamScoring
        alternating
        judgePanel
        hostId
        hostType
        rtnId
        attendance
        location
        createdAt
        updatedAt
        lineups {
          items {
            id
            teamId
            sessionId
            sessionTeamId
            title
            forceTitle
            _version
            _deleted
            individuals
            updatedAt
            createdAt
            lineupData
            order
            team {
              name
              altNames
            }
            routines(filter: { status: { ne: DELETED } }, limit: 1000) {
              items {
                id
                sessionId
                scores {
                  items {
                    id
                    routineId
                    createdAt
                    value
                    type
                    _version
                    owner
                    updatedAt
                  }
                }
                clips {
                  items {
                    id
                    startTime
                    endTime
                    duration
                    fileName
                    originURL
                    routineId
                    streamId
                    owner
                    _version
                  }
                }
                inquiries {
                  items {
                    id
                    routineId
                    sessionId
                    status
                    _version
                  }
                }
                athleteId
                apparatus
                order
                rotation
                lineupId
                _version
                score
                status
                isEdited
                updatedAt
              }
            }
          }
        }
        sessionJudges(filter: { status: { eq: ACTIVE } }) {
          items {
            id
            sessionId
            userId
            status
            _version
          }
        }
        sessionTeams(sortDirection: ASC) {
          items {
            id
            teamId
            order
            _version
            _deleted
            sessionId
            team {
              id
              name
              slug
              location
              altNames
              gender
              colors
              type
              logos
              _version
              _lastChangedAt
              createdAt
              updatedAt
              owner
              coaches {
                items {
                  id
                  user {
                    id
                    name
                  }
                }
              }
              leagues {
                items {
                  id
                }
              }
              sessions {
                items {
                  id
                }
              }
              rosters(sortDirection: DESC) {
                items {
                  id
                  title
                  teamId
                  activeDate
                  archived
                  _version
                  updatedAt
                  createdAt
                  athletes(filter: { active: { eq: true } }, limit: 1000) {
                    items {
                      id
                      _version
                      rosterId
                      athleteId
                      classYear
                      position
                      active
                      athlete {
                        id
                        name
                        profileImg
                        rtnId
                      }
                    }
                  }
                }
              }
            }
          }
        }
        streams(filter: { status: { ne: DELETED } }) {
          items {
            id
            streamId
            _version
            status
            vodURL
            recordingURL
            recordingId
            meta
            vodMeta
            recordingMeta
            ingestURL
            outputURL
            tempOutputURL
            sessionKey
            sessionId
            index
            stats
            updatedAt
            type
            title
          }
        }
        judges
        admins
        producers
        coaches
        _version
      }
    }
  }
`;

const checkJoinSession = gql`
  query SessionByKey($sessionKey: String!) {
    SessionByKey(sessionKey: $sessionKey) {
      items {
        id
      }
    }
  }
`;

const getSessions = gql`
  query ListSessions(
    $filter: ModelSessionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSessions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        sessionKey
        name
        type
        gender
        judging
        judgingRequired
        competition
        apparatus
        access
        startAt
        endAt
        status
        teamScoring
        alternating
        judgePanel
        createdAt
        updatedAt
        _version
        _lastChangedAt
        hostId
        hostType
        rtnId
        attendance
        location
        owner
        producer
        judges
        admins
        producers
        coaches
        streams {
          items {
            id
            streamId
            _version
            status
            vodURL
            recordingURL
            recordingId
            meta
            vodMeta
            recordingMeta
            ingestURL
            outputURL
            tempOutputURL
            sessionKey
            sessionId
            index
            stats
            updatedAt
            type
            title
          }
        }
        sessionTeams(sortDirection: ASC) {
          items {
            id
            teamId
            order
            _version
            _deleted
            sessionId
          }
        }
        sessionJudges(filter: { status: { eq: ACTIVE } }) {
          items {
            id
            sessionId
            userId
            status
            user {
              id
              name
              judgeType
              judgeTypeProxy
              email
            }
            _version
          }
        }
        lineups {
          items {
            id
            teamId
            sessionId
            sessionTeamId
            order
            updatedAt
            title
            forceTitle
            _version
            _deleted
            individuals
            team {
              name
              altNames
            }
            routines {
              items {
                id
                sessionId
                clips {
                  items {
                    id
                    startTime
                    endTime
                    duration
                    fileName
                    originURL
                    routineId
                    streamId
                    owner
                    _version
                  }
                }
                athleteId
                apparatus
                order
                rotation
                lineupId
                _version
              }
            }
          }
        }
      }
      nextToken
    }
  }
`;

const getSessionsBySearch = gql`
  query SearchSessions(
    $filter: SearchableSessionFilterInput
    $sort: SearchableSessionSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchSessions(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        sessionKey
        name
        type
        gender
        judging
        judgingRequired
        competition
        apparatus
        access
        startAt
        endAt
        status
        teamScoring
        alternating
        judgePanel
        createdAt
        updatedAt
        _version
        rtnId
        hostId
        hostType
        rtnId
        attendance
        location
        owner
        producer
        judges
        admins
        producers
        coaches
        streams {
          items {
            id
            streamId
            _version
            status
            vodURL
            recordingURL
            recordingId
            meta
            vodMeta
            recordingMeta
            ingestURL
            outputURL
            tempOutputURL
            sessionKey
            sessionId
            index
            stats
            updatedAt
            type
            title
          }
        }
        sessionTeams(sortDirection: ASC) {
          items {
            id
            teamId
            order
            _version
            _deleted
            sessionId
          }
        }
        sessionJudges(filter: { status: { eq: ACTIVE } }) {
          items {
            id
            sessionId
            userId
            status
            user {
              id
              name
              judgeType
              judgeTypeProxy
              email
            }
            _version
          }
        }
        lineups {
          items {
            id
            teamId
            sessionId
            sessionTeamId
            order
            updatedAt
            title
            forceTitle
            lineupData
            _version
            _deleted
            individuals
            team {
              name
              altNames
            }
            routines {
              items {
                id
                sessionId
                clips {
                  items {
                    id
                    startTime
                    endTime
                    duration
                    fileName
                    originURL
                    routineId
                    streamId
                    owner
                    _version
                  }
                }
                athleteId
                apparatus
                order
                rotation
                lineupId
                _version
              }
            }
          }
        }
      }
      nextToken
      total
    }
  }
`;

const getAthletes = gql`
  query ListAthletes(
    $filter: ModelAthleteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAthletes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        gender
        name
        firstName
        lastName
        birthday
        homeTown
        slug
        altNames
        country
        colors
        type
        profileImg
        rtnId
        teamAffiliations
        rosters {
          items {
            id
          }
        }
        _version
        createdAt
        updatedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;

const getAthletesBySearch = gql`
  query SearchAthletes(
    $filter: SearchableAthleteFilterInput
    $sort: SearchableAthleteSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchAthletes(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        teamAffiliations
        userId
        gender
        name
        firstName
        lastName
        birthday
        homeTown
        country
        profileImg
        altNames
        slug
        type
        colors
        _version
        _deleted
        createdAt
        updatedAt
        rtnId
        rosters {
          items {
            id
          }
          nextToken
          startedAt
        }
        owner
      }
      nextToken
      total
    }
  }
`;

const getLeagues = gql`
  query ListLeagues(
    $filter: ModelLeagueFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLeagues(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        slug
        type
        gender
        colors
        altNames
        status
        logos
        _version
        teams {
          items {
            id
          }
        }
        createdAt
        updatedAt
        owner
      }
    }
  }
`;

const getLeaguesBySearch = gql`
  query SearchLeagues(
    $filter: SearchableLeagueFilterInput
    $sort: SearchableLeagueSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchLeagues(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        name
        slug
        type
        gender
        colors
        altNames
        status
        logos
        _version
        teams {
          items {
            id
          }
        }
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;

const getTeams = gql`
  query ListTeams(
    $filter: ModelTeamFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTeams(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        slug
        location
        altNames
        gender
        colors
        type
        logos
        _version
        createdAt
        updatedAt
        status
        owner
        rtnId
        triCode
        lineups {
          items {
            id
          }
        }
        coaches {
          items {
            id
            user {
              id
              name
            }
          }
        }
        leagues {
          items {
            id
          }
        }
        sessions {
          items {
            id
          }
        }
        rosters(sortDirection: DESC) {
          items {
            id
            title
            teamId
            activeDate
            archived
            _version
            updatedAt
            createdAt
            athletes {
              items {
                id
                _version
                rosterId
                athleteId
                classYear
                position
                active
              }
            }
          }
        }
      }
    }
  }
`;

const getTeamsBySearch = gql`
  query SearchTeams(
    $filter: SearchableTeamFilterInput
    $sort: SearchableTeamSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchTeams(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        name
        slug
        location
        altNames
        gender
        colors
        type
        logos
        _version
        createdAt
        updatedAt
        status
        owner
        rtnId
        triCode
        lineups {
          items {
            id
          }
        }
        coaches {
          items {
            id
            user {
              id
              name
            }
          }
        }
        leagues {
          items {
            id
          }
        }
        sessions {
          items {
            id
          }
        }
        rosters(sortDirection: DESC, limit: $limit) {
          items {
            id
            title
            teamId
            activeDate
            archived
            _version
            updatedAt
            createdAt
            athletes(limit: $limit) {
              items {
                id
                _version
                rosterId
                athleteId
                classYear
                position
                active
              }
            }
          }
        }
      }
      nextToken
    }
  }
`;

const getUsers = gql`
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        email
        status
        name
        updatedAt
        createdAt
        coach {
          items {
            id
          }
        }
        judgeType
        judgeTypeProxy
        judgeSessions {
          items {
            id
          }
        }
        firstName
        lastName
        location
        handle
        profileImg
        _version
      }
      nextToken
    }
  }
`;

const getUsersBySearch = gql`
  query SearchUsers(
    $filter: SearchableUserFilterInput
    $sort: SearchableUserSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchUsers(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        email
        status
        name
        updatedAt
        createdAt
        coach {
          items {
            id
          }
        }
        judgeType
        judgeTypeProxy
        judgeSessions {
          items {
            id
          }
        }
        firstName
        lastName
        location
        handle
        profileImg
        _version
      }
      nextToken
      total
    }
  }
`;

const getJudges = gql`
  query SearchUsers(
    $filter: SearchableUserFilterInput
    $sort: SearchableUserSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchUsers(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        email
        status
        name
        updatedAt
        createdAt
        coach {
          items {
            id
          }
        }
        judgeType
        judgeTypeProxy
        judgeSessions {
          items {
            id
          }
        }
        firstName
        lastName
        location
        handle
        profileImg
        _version
      }
      nextToken
      total
    }
  }
`;

const createLeague = /* GraphQL */ `
  mutation CreateLeague(
    $input: CreateLeagueInput!
    $condition: ModelLeagueConditionInput
  ) {
    createLeague(input: $input, condition: $condition) {
      id
      name
      altNames
      colors
      logos
      slug
      type
      gender
      logos
      _version
      createdAt
      updatedAt
      owner
    }
  }
`;

const updateLeague = `
  mutation UpdateLeague(
    $input: UpdateLeagueInput!
    $condition: ModelLeagueConditionInput
  ) {
    updateLeague(input: $input, condition: $condition) {
      id
      name
      altNames
      slug
      colors
      logos
      teams {
        items {
          id
          leagueId
          teamId
          _version
          createdAt
          updatedAt
          owner
          status
        }
      }
      type
      gender
      createdAt
      updatedAt
      _version
      owner
    }
  }
`;

const createAthlete = `
  mutation CreateAthlete(
    $input: CreateAthleteInput!
    $condition: ModelAthleteConditionInput
  ) {
    createAthlete(input: $input, condition: $condition) {
      id
      rosters {
        items {
          id
        }
      }
      teamAffiliations
      userId
      gender
      name
      firstName
      lastName
      birthday
      homeTown
      country
      profileImg
      altNames
      colors
      slug
      type
      _version
      createdAt
      updatedAt
      owner
    }
  }
`;

const updateAthlete = `
  mutation UpdateAthlete(
    $input: UpdateAthleteInput!
    $condition: ModelAthleteConditionInput
  ) {
    updateAthlete(input: $input, condition: $condition) {
      id
      rosters {
        items {
          id
        }
      }
      teamAffiliations
      userId
      gender
      name
      firstName
      lastName
      birthday
      homeTown
      country
      profileImg
      altNames
      slug
      type
      colors
      _version
      createdAt
      updatedAt
      owner
    }
  }
`;

const createTeam = `
  mutation CreateTeam(
      $input: CreateTeamInput!
      $condition: ModelTeamConditionInput
    ) {
      createTeam(input: $input, condition: $condition) {
        id
        name
        slug
        location
        altNames
        gender
        colors
        type
        logos
        _version
        _deleted
        createdAt
        updatedAt
        owner
        rtnId
        triCode
      }
    }
`;

const updateTeam = `
  mutation UpdateTeam(
    $input: UpdateTeamInput!
    $condition: ModelTeamConditionInput
  ) {
    updateTeam(input: $input, condition: $condition) {
      id
      name
      slug
      location
      altNames
      gender
      colors
      type
      logos
      _version
      _deleted
      createdAt
      updatedAt
      owner
      rtnId
      triCode
    }
  }
`;

const syncSessionById = `
  subscription syncSession($id: ID!) {
    subscribeToSession(id: $id) {
      id
      name
      status
      teamScoring
      alternating
      producer
      attendance
      location
      streams (
        filter: {
          status: {
            ne: DELETED
          }
        }
      ) {
        items {
          id
          streamId
          ingestURL
          outputURL
          index
          sessionId
          meta
          stats
          error
          status
          _version
          title
        }
      }
      _version
      __typename
    }
  }
`;

const syncStreamById = `
  subscription syncStream($id: ID!) {
    subscribeToStream(id: $id) {
      status
      _version
    }
  }  
`;

const syncSubScore = `
  subscription onUpdateSubScore($owner: String!) {
    onUpdateSubScore(owner: $owner) {
      id
    }
  }
`;

const syncNewStreamBySession = `
  subscription subscribeToNewStream($sessionId: ID!) {
    subscribeToNewStream(sessionId: $sessionId) {
      id
      streamId
      ingestURL
      outputURL
      session { 
        id
      }
      sessionId
      index
      sessionKey
      meta
      stats
      error
      status
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;

const createUser = `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      email
      status
      athlete {
        id
      }
      coach {
        items {
          id
          teamId
          userId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        nextToken
        startedAt
      }
      judgeType
      judgeTypeProxy
      judgeSessions {
        items {
          id
        }
      }
      createdAt
      updatedAt
      name
      firstName
      lastName
      location
      handle
      profileImg
      _version
      owner
    }
  }
`;

const createRoster = `
  mutation CreateRoster(
    $input: CreateRosterInput!
    $condition: ModelRosterConditionInput
  ) {
    createRoster(input: $input, condition: $condition) {
      id
      teamId
      athletes {
        items {
          id
        }
      }
      activeDate
      archived
      title
      updatedAt
      createdAt
      _version
      owner
    }
  }
`;

const updateRoster = `
  mutation UpdateRoster(
    $input: UpdateRosterInput!
    $condition: ModelRosterConditionInput
  ) {
    updateRoster(input: $input, condition: $condition) {
      id
      teamId
      athletes {
        items {
          id
        }
      }
      activeDate
      archived
      title
      updatedAt
      createdAt
      _version
      owner
    }
  }
`;

const batchCreateRosterLinks = `
  mutation BatchCreateRosterLinks($rosterLinks: [CreateRosterLinkInput]) {
    batchCreateRosterLinks(rosterLinks: $rosterLinks) {
      id
      roster {
        id
      }
      rosterId
      athlete {
        id
      }
      athleteId
      classYear
      position
      active
      _version
      createdAt
      updatedAt
      owner
    }
  }
`;

const createRosterLink = `
  mutation CreateRosterLink(
    $input: CreateRosterLinkInput!
    $condition: ModelRosterLinkConditionInput
  ) {
    createRosterLink(input: $input, condition: $condition) {
      id
      rosterId
      athleteId
      classYear
      position
      active
      _version
      createdAt
      updatedAt
      owner
    }
  }

`;

const updateRosterLink = `
  mutation UpdateRosterLink(
    $input: UpdateRosterLinkInput!
    $condition: ModelRosterLinkConditionInput
  ) {
    updateRosterLink(input: $input, condition: $condition) {
      id
      rosterId
      athleteId
      classYear
      position
      active
      _version
      createdAt
      updatedAt
      owner
    }
  }
`;

const createSessionTeam = `
  mutation CreateSessionTeam(
    $input: CreateSessionTeamInput!
    $condition: ModelSessionTeamConditionInput
  ) {
    createSessionTeam(input: $input, condition: $condition) {
      id
      sessionId
      teamId
      order
      _version
    }
  }
`;

const updateSessionTeam = `
  mutation UpdateSessionTeam(
    $input: UpdateSessionTeamInput!
    $condition: ModelSessionTeamConditionInput
  ) {
    updateSessionTeam(input: $input, condition: $condition) {
      id
      sessionId
      teamId
      order
      _version
    }
  }
`;

const createSessionJudge = `
  mutation CreateSessionJudge(
    $input: CreateSessionJudgeInput!
    $condition: ModelSessionJudgeConditionInput
  ) {
    createSessionJudge(input: $input, condition: $condition) {
      id
      sessionId
      userId
      status
      _version
    }
  }
`;

const updateSessionJudge = `
  mutation UpdateSessionJudge(
    $input: UpdateSessionJudgeInput!
    $condition: ModelSessionJudgeConditionInput
  ) {
    updateSessionJudge(input: $input, condition: $condition) {
      id
      sessionId
      userId
      status
      _version
    }
  }
`;

const userByEmail = `
  query UserByEmail(
    $email: AWSEmail
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    UserByEmail(
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        status
        athleteId
        coach {
          items { 
            id
            _version
          }
        }
        judgeType
        judgeTypeProxy
        judgeSessions {
          items {
            id
            _version

          }
        }
        createdAt
        updatedAt
        name
        firstName
        lastName
        location
        handle
        profileImg
        googleId
        facebookId
        appleId
        cognitoId
        _version
      }
    }
  }
`;

const createLineup = `
  mutation CreateLineup(
    $input: CreateLineupInput!
    $condition: ModelLineupConditionInput
  ) {
    createLineup(input: $input, condition: $condition) {
      id
      teamId
      sessionId
      sessionTeamId
      routines {
        items {
          id
        }
      }
      title
      forceTitle
      updatedAt
      createdAt
      lineupData
      order
      _version
    }
  }
`;

const updateLineup = `
  mutation UpdateLineup(
    $input: UpdateLineupInput!
    $condition: ModelLineupConditionInput
  ) {
    updateLineup(input: $input, condition: $condition) {
      id
      teamId
      sessionId
      sessionTeamId
      order
      team {
        id
        gender
        name
        slug
        location
        altNames
        gender
        colors
        logos
        type
        status
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
      }
      routines {
        items {
          id
          sessionId
          athleteId
          apparatus
          order
          rotation
          lineupId
          _version
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      title
      forceTitle
      updatedAt
      createdAt
      lineupData
      _version
    }
  }
`;

const updateStream = `
  mutation UpdateStream(
    $input: UpdateStreamInput!
    $condition: ModelStreamConditionInput
  ) {
    updateStream(input: $input, condition: $condition) {
      id
      streamId
      _version
      status
      vodURL
      recordingURL
      recordingId
      meta
      vodMeta
      recordingMeta
      ingestURL
      outputURL
      tempOutputURL
      sessionKey
      sessionId
      index
      stats
      updatedAt
    }
  }
`;

const syncLineupById = `
  subscription syncLineup($id: ID!) {
    subscribeToLineup(id: $id) {
      id
      teamId
      sessionId
      sessionTeamId
      order
      routines {
        items {
          id
          sessionId
          athleteId
          apparatus
          order
          rotation
          lineupId
          _version
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      title
      forceTitle
      updatedAt
      createdAt
      lineupData
      _version
      _deleted
      individuals
    }
  }  
`;

const updateUser = `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      email
      status
      athleteId
      coach {
        items { 
          id
          _version
        }
      }
      judgeType
      judgeTypeProxy
      judgeSessions {
        items {
          id
          _version

        }
      }
      createdAt
      updatedAt
      name
      firstName
      lastName
      location
      handle
      profileImg
      googleId
      facebookId
      appleId
      cognitoId
      _version
    }
  }
`;

const createRoutineGQL = `
  mutation CreateRoutine(
    $input: CreateRoutineInput!
    $condition: ModelRoutineConditionInput
  ) {
    createRoutine(input: $input, condition: $condition) {
      id
      sessionId
      athleteId
      apparatus
      order
      rotation
      lineupId
      status
      score
      _version
      createdAt
      updatedAt
    }
  }
`;

const updateRoutineGQL = `
  mutation UpdateRoutine(
    $input: UpdateRoutineInput!
    $condition: ModelRoutineConditionInput
  ) {
    updateRoutine(input: $input, condition: $condition) {
      id
      sessionId
      clips {
        items {
          id          
        }
      }
      athleteId
      apparatus
      order
      rotation
      lineupId
      status
      score
      scores {
        items {
          id
          routineId
          createdAt
          value
          type
          _version
          updatedAt
          owner
        }
      }
      _version
      createdAt
      updatedAt
      owner
    }
  }
`;

const createScoreGQL = `
  mutation CreateScore(
    $input: CreateScoreInput!
    $condition: ModelScoreConditionInput
  ) {
    createScore(input: $input, condition: $condition) {
      id
      routineId
      createdAt
      value
      type
      _version
      updatedAt
    }
  }
`;

const updateScoreGQL = `
  mutation UpdateScore(
    $input: UpdateScoreInput!
    $condition: ModelScoreConditionInput
  ) {
    updateScore(input: $input, condition: $condition) {
      id
      routineId
      createdAt
      value
      type
      _version
      updatedAt
    }
  }
`;

const createClipGQL = `
    mutation CreateClip(
    $input: CreateClipInput!
    $condition: ModelClipConditionInput
  ) {
    createClip(input: $input, condition: $condition) {
      id
      startTime
      endTime
      pgmStartTime
      pgmEndTime
      duration
      fileName
      originURL
      routineId
      _version
      createdAt
      updatedAt
      streamId
      owner
    }
  }
`;

const updateClipGQL = `
  mutation UpdateClip(
    $input: UpdateClipInput!
    $condition: ModelClipConditionInput
  ) {
    updateClip(input: $input, condition: $condition) {
      id
      startTime
      endTime
      pgmStartTime
      pgmEndTime
      duration
      fileName
      originURL
      routineId
      _version
      createdAt
      updatedAt
      streamId
      owner
    }
  }
`;

const syncScoreById = `
  subscription subscribeToScore($id: ID!) {
    subscribeToScore(id: $id) {
      id
      routineId
      createdAt
      value
      type
      _version
      updatedAt
      owner
    }
  }
`;

const syncNewScoresByRoutine = `
  subscription subscribeToNewScore($routineId: ID!) {
    subscribeToNewScore(routineId: $routineId) {
      id
      routineId
      createdAt
      value
      type
      _version
      _deleted
      _lastChangedAt
      updatedAt
      owner
    }
  }
`;

const syncRoutineById = `
  subscription subscribeToRoutine($id: ID!) {
    subscribeToRoutine(id: $id) {
      id
      sessionId
      clips {
        items {
          id
          startTime
          endTime
          pgmStartTime
          pgmEndTime
          duration
          fileName
          originURL
          routineId
          _version
          createdAt
          updatedAt
          streamId
          owner
        }
        nextToken
        startedAt
      }
      athleteId
      apparatus
      order
      rotation
      lineupId
      status
      score
      scores {
        items {
          id
          routineId
          createdAt
          value
          type
          _version
          updatedAt
          owner
        }
        startedAt
      }
      _version
      createdAt
      updatedAt
      owner
    }
  }
`;

const syncNewRoutinesByLineup = `
  subscription subscribeToNewRoutine($lineupId: ID!) {
    subscribeToNewRoutine {
      id
      sessionId
      clips {
        items {
          id
          startTime
          endTime
          pgmStartTime
          pgmEndTime
          duration
          fileName
          originURL
          routineId
          _version
          createdAt
          updatedAt
          streamId
          owner
        }
        startedAt
      }
      athleteId
      apparatus
      order
      rotation
      lineupId
      status
      score
      scores {
        items {
          id
          routineId
          createdAt
          value
          type
          _version
          updatedAt
          owner
        }
        nextToken
        startedAt
      }
      _version
      createdAt
      updatedAt
      owner
    }
  }
`;

const syncClipById = `
  subscription subscribeToClip($id: ID!) {
    subscribeToClip(id: $id) {
      id
      startTime
      endTime
      pgmStartTime
      pgmEndTime
      duration
      fileName
      originURL
      routineId
      _version
      createdAt
      updatedAt
      streamId
      owner
    }
  }
`;

const syncNewClipsByRoutine = `
  subscription subscribeToNewClip($routineId: ID!) {
    subscribeToNewClip(routineId: $routineId) {
      id
      startTime
      endTime
      pgmStartTime
      pgmEndTime
      duration
      fileName
      originURL
      routineId
      _version
      createdAt
      updatedAt
      streamId
      owner
    }
  }
`;

const createStreamNoCam = `
  mutation CreateStream($input: CreateStreamInput!) {
    createStream(input: $input) {
      id
      streamId
      ingestURL
      outputURL
      tempOutputURL
      vodURL
      recordingURL
      recordingId
      sessionKey
      sessionId
      index
      meta
      stats
      error
      status
      vodMeta
      recordingMeta
      createdAt
      updatedAt
      owner
      type
      _version
      _deleted
      _lastChangedAt
      session {
        id
        sessionKey
        name
        type
        gender
        judging
        judgingRequired
        competition
        apparatus
        access
        startAt
        endAt
        status
        teamScoring
        alternating
        judgePanel
        createdAt
        updatedAt
        admins
        producers
        coaches
        judges
        producer
        _version
        _deleted
        _lastChangedAt
        owner
        rotations {
          nextToken
          startedAt
        }
        squads {
          nextToken
          startedAt
        }
        sessionJudges {
          nextToken
          startedAt
        }
        sessionTeams {
          nextToken
          startedAt
        }
        rosters {
          nextToken
          startedAt
        }
        lineups {
          nextToken
          startedAt
        }
        streams {
          nextToken
          startedAt
        }
      }
    }
  }
`;

const getRosterAthletes = /* GraphQL */ `
  query GetRoster($id: ID!) {
    getRoster(id: $id) {
      id
      teamId
      activeDate
      archived
      title
      updatedAt
      createdAt
      _version
      _deleted
      _lastChangedAt
      athletes {
        items {
          id
          rosterId
          athleteId
          classYear
          position
          active
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
          __typename
          athlete {
            id
            userId
            gender
            name
            firstName
            lastName
            birthday
            homeTown
            slug
            altNames
            country
            colors
            type
            profileImg
            teamAffiliations
            rosters {
              items {
                id
              }
            }
            _version
            createdAt
            updatedAt
            owner
          }
        }
        nextToken
        startedAt
        __typename
      }
      owner
      __typename
    }
  }
`;

const getTeamRosterAthletes = /* GraphQL */ `
  query GetTeam($id: ID!) {
    getTeam(id: $id) {
      id
      rosters {
        items {
          id
          teamId
          activeDate
          archived
          title
          updatedAt
          createdAt
          _version
          _deleted
          _lastChangedAt
          athletes {
            items {
              id
              rosterId
              athleteId
              classYear
              position
              active
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
              owner
              __typename
              athlete {
                id
                userId
                gender
                name
                firstName
                lastName
                birthday
                homeTown
                slug
                altNames
                country
                colors
                type
                profileImg
                rtnId
                teamAffiliations
                rosters {
                  items {
                    id
                  }
                }
                _version
                createdAt
                updatedAt
                owner
              }
            }
            nextToken
            startedAt
            __typename
          }
          owner
          __typename
        }
      }
    }
  }
`;

export {
  createSession,
  updateSession,
  getSession,
  getSessions,
  getSessionsBySearch,
  getAthletes,
  getAthletesBySearch,
  createUser,
  getUsers,
  getTeamRosterAthletes,
  getTeams,
  getTeamsBySearch,
  getLeagues,
  getLeaguesBySearch,
  getJudges,
  getUsersBySearch,
  createLeague,
  updateLeague,
  updateTeam,
  createTeam,
  syncSessionById,
  syncSubScore,
  syncStreamById,
  syncNewStreamBySession,
  createAthlete,
  updateAthlete,
  createRoster,
  updateRoster,
  batchCreateRosterLinks,
  createRosterLink,
  updateRosterLink,
  createSessionTeam,
  updateSessionTeam,
  userByEmail,
  createLineup,
  updateLineup,
  checkJoinSession,
  updateStream,
  syncLineupById,
  updateUser,
  createRoutineGQL,
  createClipGQL,
  createScoreGQL,
  updateRoutineGQL,
  updateClipGQL,
  updateScoreGQL,
  syncScoreById,
  syncNewScoresByRoutine,
  syncRoutineById,
  syncNewRoutinesByLineup,
  syncClipById,
  syncNewClipsByRoutine,
  createSessionJudge,
  updateSessionJudge,
  createStreamNoCam,
  getRosterAthletes,
};
