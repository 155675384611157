import React, { useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { userActions } from '../../redux/_actions';
import { useHistory } from 'react-router-dom';
import { personFillIcon } from './icons';
import './userbubble.css';

function UserBubble({ loginRedirectPath }) {
  const [initials, setInitials] = useState('');
  const name = useSelector((state) => state.user?.user?.attributes?.name);
  const username = useSelector((state) => state.user?.user?.username);
  const history = useHistory();
  const loginBasePath = '/login';
  const loginPath = loginRedirectPath
    ? `${loginBasePath}?redirect=${loginRedirectPath}`
    : loginBasePath;

  useEffect(() => {
    setInitials(name ? getInitials(name) : '');
  }, [name]);

  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(userActions.logout(history));
  };

  const handleLogin = () => {
    history.push(loginPath);
  };

  const getInitials = (name) => {
    let names = name.split(' '),
      initials = names[0].substring(0, 1).toUpperCase();

    if (names.length > 1) {
      initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
  };

  return (
    <Dropdown className="userBubble">
      <Dropdown.Toggle
        id="dropdown-userBubble"
        className="vGray"
        variant="outline"
      >
        {username ? initials : personFillIcon}
      </Dropdown.Toggle>
      <Dropdown.Menu className="userBubble-menu">
        {username ? (
          <Dropdown.Item as="button" variant="outline" onSelect={handleLogout}>
            Logout
          </Dropdown.Item>
        ) : (
          <Dropdown.Item as="button" variant="outline" onSelect={handleLogin}>
            Login
          </Dropdown.Item>
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default UserBubble;
