import { Analytics } from 'aws-amplify';

export const analyticsService = {
  signup,
  login,
  join,
};

function signup(email) {
  Analytics.record({
    name: 'New User Signup',
    attributes: {
      email: email,
    },
  });
}

function login(email) {
  Analytics.record({
    name: 'User Login',
    attributes: {
      email: email,
    },
  });
}

function join(userId, sessionKey) {
  Analytics.record({
    name: 'Join Session',
    attributes: {
      sessionKey: sessionKey,
      userId: userId,
    },
  });
}
