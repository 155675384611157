import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

function HighlightShadow(props) {
  const { videoPlayer } = useSelector((state) => state);
  const { start, end, id, disabled } = props;
  const [highlight, setHighlight] = useState('');
  const playerRef = videoPlayer[`player${id}`];

  useEffect(() => {
    if (
      !disabled &&
      playerRef?.currentTime < end &&
      playerRef?.currentTime >= start
    ) {
      setHighlight('highlight');
    } else {
      setHighlight('');
    }
  }, [playerRef?.currentTime, end, start, disabled]);

  return <div className={['highlightshadow', highlight].join(' ')} />;
}

export default HighlightShadow;
