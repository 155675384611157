export const userConstants = {
  LOGIN_REQUEST: 'USER_LOGIN_REQUEST',
  LOGIN_SUCCESS: 'USER_LOGIN_SUCCESS',
  LOGIN_FAILURE: 'USER_LOGIN_FAILURE',
  LOGOUT_REQUEST: 'USER_LOGOUT_REQUEST',
  LOGOUT_SUCCESS: 'USER_LOGOUT_SUCCESS',
  LOGOUT_FAILURE: 'USER_LOGOUT_FAILURE',
  CODE_REQUEST: 'USER_CODE_REQUEST',
  CODE_SUCCESS: 'USER_CODE_SUCCESS',
  CODE_FAILURE: 'USER_CODE_FAILURE',
  REGISTER_REQUEST: 'USER_REGISTER_REQUEST',
  REGISTER_SUCCESS: 'USER_REGISTER_SUCCESS',
  REGISTER_FAILURE: 'USER_REGISTER_FAILURE',
  RESET_PASSWORD_REQUEST: 'USER_PASSWORD_RESET_REQUEST',
  RESET_PASSWORD_SUCCESS: 'USER_PASSWORD_RESET_SUCCESS',
  RESET_PASSWORD_FAILURE: 'USER_PASSWORD_RESET_FAILURE',
  GETUSER_REQUEST: 'USER_GETUSER_REQUEST',
  GETUSER_SUCCESS: 'USER_GETUSER_SUCCESS',
  GETUSER_FAILURE: 'USER_GETUSER_FAILURE',
  FBLOGIN_REQUEST: 'USER_FBLOGIN_REQUEST',
  GOOGLELOGIN_REQUEST: 'USER_GOOGLELOGIN_REQUEST',
  VERIFY_REQUEST: 'USER_VERIFY_REQUEST',
  VERIFY_SUCCESS: 'USER_VERIFY_SUCCESS',
  VERIFY_FAILURE: 'USER_VERIFY_FAILURE',
  REQUEST_VERIFICATION_CODE_REQUEST: 'USER_REQUEST_VERIFICATION_CODE_REQUEST',
  REQUEST_VERIFICATION_CODE_SUCCESS: 'USER_REQUEST_VERIFICATION_CODE_SUCCESS',
  REQUEST_VERIFICATION_CODE_FAILURE: 'USER_REQUEST_VERIFICATION_CODE_FAILURE',
  USER_BY_EMAIL_REQUEST: 'USER_USER_BY_EMAIL_REQUEST',
  USER_BY_EMAIL_SUCCESS: 'USER_USER_BY_EMAIL_SUCCESS',
  USER_BY_EMAIL_FAILURE: 'USER_USER_BY_EMAIL_FAILURE',
  GET_SESSIONS_REQUEST: 'USER_GET_SESSIONS_REQUEST',
  GET_SESSIONS_SUCCESS: 'USER_GET_SESSIONS_SUCCESS',
  GET_SESSIONS_FAILURE: 'USER_GET_SESSIONS_FAILURE',
  ADD_SESSION_SUCCESS: 'USER_ADD_SESSION_SUCCESS',
  SET_POST_LOGIN_REDIRECT: 'SET_POST_LOGIN_REDIRECT',
};

export const uiState = {
  LOGIN: '/login',
  LOGOUT: '/logout',
  START: '/start',
  SIGNUP: '/signup',
  CREATE: '/create',
  SESSION: '/session',
  SESSIONS: '/sessions',
  SETUP: '/setup',
  ADMIN: '/admin',
  JOIN: '/join',
  HOME: '/',
  FORGOT_PW: '/forgotpw',
  RESET_PW: '/resetpw',
  VERIFY: '/verify',
  PRIVACY: '/privacy',
  TERMS: '/terms',
  SCORESHEET: '/scoresheet',
};

export const initialUserState = {
  user: null,
  profile: null,
  initialLoad: true,
  sessions: { byId: [], allIds: [] },
  postLoginRedirect: null,
};

export const judgeType = {
  FIG_MENS: 'FIG_MENS',
  FIG_WOMENS: 'FIG_WOMENS',
  NCAA_MENS: 'NCAA_MENS',
  NCAA_WOMENS: 'NCAA_WOMENS',
  JO_MENS: 'JO_MENS',
  JO_WOMENS: 'JO_WOMENS',
};
