import {
  sessionRoleType,
  evaluatorConfigs,
  genderType,
  judgePanelType,
  sessionType,
} from '../redux/_constants';
import { StreamType } from '../models';
import { deltaE } from './conversions';
import { SIMILAR_COLOR_THRESHOLD } from './constants';

// Check user permissions for session to see what level of access they get
export const checkRole = (user, session) => {
  const { ADMIN, JUDGE, PRODUCER, FAN, COACH, ANNOUNCER } = sessionRoleType;

  if (!user || !session) {
    return;
  }

  const cognito = user?.user;
  const cognitoId = cognito?.attributes?.sub;
  const userId = user?.profile?.id;
  const cognitoGroups =
    cognito?.signInUserSession.idToken.payload['cognito:groups'] || [];

  if (cognitoGroups.includes('admin')) {
    return ADMIN;
  } else if (session?.admins?.includes(cognitoId)) {
    return ADMIN;
  } else if (session?.producers?.includes(cognitoId)) {
    return PRODUCER;
  } else if (session?.coaches?.includes(cognitoId)) {
    return COACH;
  } else if (
    session?.judges?.includes(cognitoId) ||
    session.sessionJudges.items.map((sj) => sj.userId).includes(userId)
  ) {
    return JUDGE;
  } else if (session?.announcers?.includes(cognitoId)) {
    return ANNOUNCER;
  } else {
    return FAN;
  }
};

export const isHead2Head = (session) => {
  // const { NCAAM, NCAAM4, SINGLE } = judgePanelType;

  const isWomensHead2Head =
    session.gender === genderType.FEMALE &&
    session.type === sessionType.DUAL &&
    !session.alternating;
  // && [NCAAW, NCAAW2, NCAAW4, SINGLE].includes(session.judgePanel);

  const isMensHead2Head =
    session.gender === genderType.MALE &&
    session.type === sessionType.DUAL &&
    !session.alternating;
  // && [NCAAM, NCAAM4, SINGLE].includes(session.judgePanel);

  return isWomensHead2Head || isMensHead2Head;
};

export const isAlternating = (session) => {
  const { NCAAW, NCAAW2, NCAAW2SV, NCAAW4 } = judgePanelType;

  return (
    session.gender === genderType.FEMALE &&
    session.type === sessionType.DUAL &&
    [NCAAW, NCAAW2, NCAAW2SV, NCAAW4].includes(session.judgePanel)
  );
};

export const isIntrasquad = (session) => {
  // Implied intrasquad, check if the teams are the same, lineup names are overrided
  // Typically a dual, all of lineups are force Title
  const sameTeam = session.lineups.items.every(
    (obj) => obj.teamId === session.lineups.items[0].teamId
  );

  return session.type === sessionType.DUAL && sameTeam;
};

export const getEvalConfig = (
  role,
  judgePanel = null,
  gender = genderType.MALE
) => {
  const { ADMIN, JUDGE, PRODUCER, FAN, COACH, ANNOUNCER } = sessionRoleType;
  const evalType = () => {
    if (judgePanel) {
      switch (judgePanel) {
        case judgePanelType.NCAAM:
          return evaluatorConfigs.NCAAM;
        case judgePanelType.NCAAM4:
          return evaluatorConfigs.NCAAM4;
        case judgePanelType.NCAAW:
          return evaluatorConfigs.NCAAW;
        case judgePanelType.NCAAW2:
          return evaluatorConfigs.NCAAW2;
        case judgePanelType.NCAAW2SV:
          return evaluatorConfigs.NCAAW2SV;
        case judgePanelType.NCAAW4:
          return evaluatorConfigs.NCAAW4;
        case judgePanelType.NCAAW6:
          return evaluatorConfigs.NCAAW6;
        case judgePanelType.FIG4:
          return evaluatorConfigs.FIG4;
        case judgePanelType.FIG5:
          return evaluatorConfigs.FIG5;
        case judgePanelType.FIG6:
          return evaluatorConfigs.FIG6;
        case judgePanelType.FAN:
          return evaluatorConfigs.FAN;
        case judgePanelType.SINGLE:
          return evaluatorConfigs.SINGLE;
        case judgePanelType.J4:
          return evaluatorConfigs.J4;
        case judgePanelType.OPEN:
          return evaluatorConfigs.OPEN;
        default:
          return evaluatorConfigs.DEFAULT;
      }
    } else {
      switch (gender) {
        case genderType.FEMALE:
          return evaluatorConfigs.NCAAW2;
        case genderType.MALE:
        case genderType.COED:
        default:
          return evaluatorConfigs.DEFAULT;
      }
    }
  };

  switch (role) {
    case FAN:
    case JUDGE:
    case ANNOUNCER:
    case ADMIN:
    case PRODUCER:
    case COACH:
    default:
      return JSON.parse(JSON.stringify(evalType()));
  }
};

export const getDisplayConfig = (judgePanel = null) => {
  const evalType = () => {
    if (judgePanel) {
      switch (judgePanel) {
        case judgePanelType.NCAAM:
        case judgePanelType.NCAAM4:
        case judgePanelType.SINGLE:
          return {
            avgPrecision: 3,
            scorePrecision: 3,
            dPrecision: 1,
            ePrecision: 1,
            nPrecision: 1,
            sbPrecision: 1,
            leaderboardE: true,
            leaderboardD: true,
            leaderboardSB: true,
            leaderboardSV: false,
            leaderboardMedals: true,
          };
        case judgePanelType.FIG4:
        case judgePanelType.FIG5:
        case judgePanelType.FIG6:
          return {
            avgPrecision: 3,
            scorePrecision: 3,
            dPrecision: 1,
            ePrecision: 1,
            nPrecision: 1,
            sbPrecision: 1,
            leaderboardE: true,
            leaderboardD: true,
            leaderboardSB: false,
            leaderboardSV: false,
            leaderboardMedals: true,
          };
        case judgePanelType.NCAAW:
        case judgePanelType.NCAAW2:
        case judgePanelType.NCAAW2SV:
        case judgePanelType.NCAAW4:
          return {
            avgPrecision: 3,
            jPrecision: 2,
            defaultJScore: '10.0',
            scorePrecision: 3,
            ePrecision: 2,
            nPrecision: 1,
            svPrecision: 2,
            leaderboardE: false,
            leaderboardD: false,
            leaderboardSB: false,
            leaderboardSV: false,
            leaderboardMedals: false,
          };
        case judgePanelType.NCAAW6:
          return {
            avgPrecision: 4,
            scorePrecision: 4,
            defaultJScore: '10.0',
            jPrecision: 2,
            ePrecision: 2,
            nPrecision: 1,
            svPrecision: 2,
            leaderboardE: false,
            leaderboardD: false,
            leaderboardSB: false,
            leaderboardSV: false,
            leaderboardMedals: false,
          };
        case judgePanelType.OPEN:
          return {
            avgPrecision: 3,
            scorePrecision: 3,
            ePrecision: 1,
            nPrecision: 1,
            svPrecision: 1,
            jPrecision: 3,
            leaderboardE: false,
            leaderboardD: false,
            leaderboardSB: false,
            leaderboardSV: false,
            leaderboardMedals: true,
          };
        case judgePanelType.FAN:
        case judgePanelType.J4:
        default:
          return {
            avgPrecision: 2,
            scorePrecision: 2,
            jPrecision: 0,
            leaderboardE: false,
            leaderboardD: false,
            leaderboardSB: false,
            leaderboardSV: false,
            leaderboardMedals: true,
          };
      }
    }
  };

  return JSON.parse(JSON.stringify(evalType()));
};

export const getVideoConfig = (streams) => {
  if (!Array.isArray(streams)) {
    return { cameraStream: false, productionStream: false };
  }

  const isProduction = (s) => s.type === StreamType.PRODUCTION;
  const isCamera = (s) => s.type === StreamType.CAMERA;
  const hasProductionStream = streams.some(isProduction);
  const hasCameraStream = streams.some(isCamera);
  const productionStream = streams.find(
    (s) => s.type === StreamType.PRODUCTION
  );

  return {
    hasCameraStream: hasCameraStream,
    hasProductionStream: hasProductionStream,
    productionStream: productionStream?.outputURL,
  };
};

// Given two teams, need to find the color for team 2 that is not the same
// Used for Same team intrasquad or two teams that have the same #1 color
// TODO: could make this more badass and choose colors based on RGB value
// Should be able to be overridden in PREGAME settings, use deltaE
export const colorSelector = (teamAColor, teamBOptions) => {
  return teamBOptions.find(
    (color) => deltaE(teamAColor, color) > SIMILAR_COLOR_THRESHOLD
  );
};

// In a dual competition that is live/non-head-to-head, calculate teamB's rotation
export const dualTeamBRotation = (rotation) => {
  switch (rotation) {
    case 1:
      return 2;
    case 2:
      return 1;
    case 3:
      return 4;
    case 4:
      return 3;
    case 5:
      return 6;
    case 6:
      return 5;
    default:
      return null;
  }
};
