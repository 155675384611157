import { useQuery } from '@apollo/client';
import { RoutineStatus } from '../models';
import GetLineupWithRoutines from '../apollo/queries/GetLineupWithRoutines.graphql';
import { displayName } from './conversions';

export function useClips(lineupId, round = 1) {
  const filteredRoutines = useRoutines(lineupId, round);
  const filteredClips = [];

  function sortByUpdatedAt(list) {
    return list.sort((a, b) => {
      return Date.parse(b.updatedAt) - Date.parse(a.updatedAt);
    });
  }

  filteredRoutines.forEach((routine) => {
    const streams = [];
    const files = [];
    const vod = [];
    const links = [];

    if (routine?.clips?.items?.length > 0) {
      const clips = routine.clips.items;

      clips.forEach((c) => {
        if (c.type === 'FILE' && c.status === 'ACTIVE') {
          files.push(c);
        }
        if (c.type === 'YOUTUBE' && c.status === 'ACTIVE') {
          links.push(c);
        }
        if (c.type === 'LINK' && c.status === 'ACTIVE') {
          links.push(c);
        }
        if (c.type === 'STREAM' && c.status === 'ACTIVE') {
          streams.push(c);
        }
        if (c.type === 'VOD' && c.status === 'ACTIVE') {
          vod.push(c);
        }
      });
    }

    const priority = [
      ...sortByUpdatedAt(files),
      ...sortByUpdatedAt(links),
      ...sortByUpdatedAt(vod),
      ...sortByUpdatedAt(streams),
    ];

    if (priority && priority.length > 0 && priority[0]) {
      filteredClips[routine.order] = priority[0];
    }
  });

  return filteredClips;
}

export function useRoutines(lineupId, round = 1) {
  const { data } = useQuery(GetLineupWithRoutines, {
    variables: {
      id: lineupId,
    },
    skip: !lineupId,
  });

  const filteredRoutines = [];

  if (data) {
    data.getLineup.routines.items.forEach((routine) => {
      if (
        routine.rotation === round &&
        routine.status !== RoutineStatus.DELETED
      ) {
        // removes duplicates and finds the newest one
        let curRoutine = filteredRoutines[routine.order];
        if (curRoutine) {
          if (new Date(curRoutine.updatedAt) < new Date(routine.updatedAt)) {
            filteredRoutines[routine.order] = routine; // Keep the most recent item
          }
        } else {
          filteredRoutines[routine.order] = routine; // Keep the most recent item
        }
      }
    });
  }

  return filteredRoutines;
}

export function useRoutinesByRoundAdmin(lineupId, round = 1) {
  const { data } = useQuery(GetLineupWithRoutines, {
    variables: {
      id: lineupId,
    },
    skip: !lineupId,
  });

  const sortedRoutines = [];
  if (data) {
    data.getLineup.routines.items.forEach((routine) => {
      if (
        routine.rotation === round &&
        routine.status !== RoutineStatus.DELETED
      ) {
        sortedRoutines.push(routine);
        sortedRoutines.sort((a, b) => a.order < b.order);
      }
    });
  }

  return sortedRoutines;
}

export function useRoutinesByApparatus(lineupId) {
  const { data } = useQuery(GetLineupWithRoutines, {
    variables: {
      id: lineupId,
    },
    skip: !lineupId,
  });

  const routines = data?.getLineup?.routines?.items;

  // Note this does not remove duplicates

  const sorted = [];
  const apparatus = [];

  routines &&
    routines.forEach((routine) => {
      if (!sorted[routine.apparatus]) {
        sorted[routine.apparatus] = [];
        apparatus.push(routine.apparatus);
      }
      sorted[routine.apparatus].push(routine);
    });

  apparatus.forEach((a) => {
    sorted[a].sort((a, b) => a.order < b.order);
  });

  return sorted;
}

export function useRoutinesByApparatusEC(lineupId) {
  const sorted = useRoutinesByApparatus(lineupId);

  const errors = [];
  const duplicates = [];

  sorted &&
    Object.keys(sorted).forEach((a) => {
      const seen = {};
      sorted[a].forEach((r) => {
        if (seen[r.order]) {
          duplicates.push(r);
          const key = `#${r.order + 1} ${r?.athlete?.name}, ${displayName(
            r?.lineup?.team?.name,
            r?.lineup?.team?.altNames
          )}, ${a}`; // Create a composite key
          errors.push(`Duplicate routine for ${key}`);
        } else {
          seen[r.order] = r;
        }
      });
    });

  return {
    routines: sorted,
    duplicates,
    errors,
  };
}

export function useMediaInfo() {
  // hook to extract info from mediainfo query on an mp4

  function extractInfo(stats = {}) {
    if (Object.keys(stats).length === 0) {
      return stats;
    }

    const track = stats?.['track'];

    const general =
      track && track.length > 0 && track[0] && track[0]?.['@type'] === 'General'
        ? track[0]
        : [];
    const video =
      track && track.length > 1 && track[1] && track[1]?.['@type'] === 'Video'
        ? track[1]
        : [];

    const result = {
      type: general?.Format,
      codec: video?.Format,
      resolution:
        (video?.Width && video?.Height && `${video?.Width}x${video?.Height}`) ??
        undefined,
      fps: general?.FrameRate,
      height: video?.Height,
      width: video?.Width,
      bitrate: video?.BitRate,
      duration: general?.Duration,
      size: general?.FileSize,
    };

    return result;
  }

  return extractInfo;
}
