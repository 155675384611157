import { combineReducers } from 'redux';
import { alert } from './alert.reducer';
import { producer } from './producer.reducer';
import { evaluator } from './evaluator.reducer';
import { videoPlayer } from './videoplayer.reducer';
import { user } from './user.reducer';
import { session } from './session.reducer';
import { admin } from './admin.reducer';
import { event } from './event.reducer';

const rootReducer = combineReducers({
  alert,
  producer,
  evaluator,
  videoPlayer,
  user,
  session,
  admin,
  event,
});

export default rootReducer;
