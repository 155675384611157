import { useContext } from 'react';
import { Button } from 'react-bootstrap';
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';
import { chevronLeftIcon, chevronRightIcon } from './icons';
import './sidescrollmenu.css';

export function useSideScrollMenu() {
  function menu({ keys, handler, selectedIndex, addButton, addHandler }) {
    const clickHandler = (index) => {
      if (index < 0) {
        return;
      }

      if (index === keys.length) {
        // handle the add case
        return addHandler && addHandler();
      } else {
        return handler && handler(index);
      }
    };

    const isSelected = (filter) => {
      let result = false;

      if (keys.length === 0) {
        return;
      }

      for (let i = 0; i < keys.length; i++) {
        if (keys?.[selectedIndex]?.value === filter?.value) {
          return true;
        }
      }

      return result;
    };

    const Arrow = ({ children, disabled, onClick, left, right }) => {
      return (
        <button
          className="arrow"
          disabled={disabled}
          onClick={onClick}
          style={{
            display: disabled ? 'none' : 'block',
            left: left ? 0 : null,
            right: right ? 0 : null,
            background: left
              ? 'linear-gradient(90deg, #fff, #fff 70%, rgba(255,255,255,0))'
              : 'linear-gradient(270deg, #fff, #fff 70%, rgba(255,255,255,0))',
          }}
        >
          {children}
        </button>
      );
    };

    const LeftArrow = () => {
      const { isFirstItemVisible, scrollPrev } = useContext(VisibilityContext);

      return (
        <Arrow
          left={true}
          disabled={isFirstItemVisible}
          onClick={() => scrollPrev()}
        >
          {chevronLeftIcon}
        </Arrow>
      );
    };

    const RightArrow = () => {
      const { isLastItemVisible, scrollNext } = useContext(VisibilityContext);

      return (
        <Arrow
          right={true}
          disabled={isLastItemVisible}
          onClick={() => scrollNext()}
        >
          {chevronRightIcon}
        </Arrow>
      );
    };

    const Card = ({ selected, itemId, filter, index }) => {
      return (
        <Button
          className={[
            'sideScrollMenuButton',
            isSelected(filter) ? 'selected' : null,
          ].join(' ')}
          variant="light"
          onClick={() => clickHandler(index)}
        >
          {filter.value}
        </Button>
      );
    };

    return (
      <div className="sideScrollMenuContainer">
        <ScrollMenu LeftArrow={LeftArrow} RightArrow={RightArrow}>
          {keys.map((f, i) => {
            return (
              <Card
                itemId={`${f.id}_${f.value}`} // NOTE: itemId is required for track items
                key={`${f.id}_${f.value}`}
                index={i}
                filter={f}
              />
            );
          })}
          {addButton && <Card index={keys.length} filter={addButton} />}
        </ScrollMenu>
      </div>
    );
  }

  return { sideScrollMenu: menu };
}
