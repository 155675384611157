import { useEffect, forwardRef } from 'react';
import { Row, OverlayTrigger, Button, Tooltip } from 'react-bootstrap';
import { questionIcon } from './icons';

const ToolTip = forwardRef(({ popper, children, show: _, ...props }, ref) => {
  useEffect(() => {
    popper.scheduleUpdate();
  }, [children, popper]);

  return (
    <Tooltip ref={ref} {...props}>
      {children}
    </Tooltip>
  );
});

export function BeaconButton() {
  const content = 'Get Help!';

  const handleClick = () => {
    window.Beacon('open');
  };

  return (
    <div className="scoreboard vCenter">
      <Row>
        <OverlayTrigger
          placement="top"
          delay={{ show: 700, hide: 0 }}
          overlay={<ToolTip id="popover-contained">{content}</ToolTip>}
        >
          <Button
            style={{
              background: 'radial-gradient(#00a2ff 80%, #ccc 20%)',
              minHeight: '48px',
              minWidth: '48px',
              color: 'white',
              fontSize: '1.8rem',
              border: '5px solid #eee',
              padding: '0',
            }}
            variant="light"
            onClick={handleClick}
            className="vCenter"
          >
            {questionIcon}
          </Button>
        </OverlayTrigger>
      </Row>
    </div>
  );
}
