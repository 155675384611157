import { useEffect, useState } from 'react';
import { Container, Button, Row, Col, Card, ListGroup } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { MEDIAURL } from '../../utilities/constants';
import './teamselector.css';
import { addBold } from '../helpers/search';
import { genderType } from '../../redux/_constants';
import { useQuery } from '@apollo/client';
import { AsyncTypeahead, Hint } from 'react-bootstrap-typeahead';
import { SearchFullTeams } from '../../apollo/queries/SearchFullTeams.graphql';
const { FEMALE } = genderType;

function TeamFilterAsync({
  gender,
  setSearchTeams,
  side,
  searchTerm,
  setSearchTerm,
  handleCancel,
  handleSelectTop,
  disabled = false,
  clearOnSelect = true,
  prompt,
}) {
  const { data, loading } = useQuery(SearchFullTeams, {
    variables: {
      filter: {
        name: { matchPhrasePrefix: searchTerm.trim() },
        gender: { eq: gender },
      },
      limit: 25,
      sort: {
        direction: 'asc',
        field: 'name',
      },
    },
  });

  useEffect(() => {
    if (data?.searchTeams?.items) {
      setSearchTeams(data.searchTeams.items || []);
    }
  }, [data?.searchTeams?.items]);

  useEffect(() => {
    document.getElementById('search-teams-input')?.focus();
  }, []);

  const searchKeyOverride = (e) => {
    switch (e.keyCode) {
      case 9: // Tab
        handleSelectTop();
        e.preventDefault();
        break;
      case 27: // Esc
        e.stopPropagation();
        handleCancel();
        break;
      case 13: // Return
        handleSelectTop();
        e.preventDefault();
        break;
      case 38: // Up
        break;
      case 40: // Down
        break;
      default:
        break;
    }
  };

  return (
    <div className={['teamFilter', side].join('')}>
      <AsyncTypeahead
        id="search-teams-typeahead"
        clearButton
        disabled={disabled}
        open={false}
        filterBy={['name']}
        isLoading={loading}
        onSearch={setSearchTerm}
        labelKey="name"
        className="editing"
        placeholder={prompt ?? 'Search for team...'}
        options={data ? data.searchTeams.items : []}
        onKeyDown={searchKeyOverride}
        renderInput={({ inputRef, referenceElementRef, ...inputProps }) => (
          <Hint>
            <input
              id="search-teams-input"
              {...inputProps}
              ref={(node) => {
                inputRef(node);
                referenceElementRef(node);
              }}
            />
          </Hint>
        )}
      />
    </div>
  );
}

function TeamSelector(props) {
  const { side, setTeam, session, values, isMulti, setTeams, teams } = props;
  const { sessionTeams } = useSelector((state) => state.admin);
  const [searchTeams, setSearchTeams] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  const handleSelectTop = () => {
    if (list.length > 1) {
      setTeam(side, teams.byId[list[1]]);
    } else {
      setTeam(side, list[0]);
    }
  };

  const handleSelect = (team) => {
    setTeam(side, team);
  };

  const handleCancel = () => {
    setTeam(null);
  };

  const selectedTeamVariant = (side, id) => {
    const selected = values[`team${side}`];
    const index = side.charCodeAt(0) - 65;
    const previous =
      session &&
      session.sessionTeams.items.length > index &&
      sessionTeams.byId[session.sessionTeams.items[index]].teamId;

    if (id === null) {
      if (selected || previous) {
        return 'light';
      }
      return 'primary';
    }

    if (selected === id) {
      return 'primary';
    }

    if (previous === id) {
      return 'primary';
    }

    return 'light';
  };

  useEffect(() => {
    const allIds = teams?.allIds || [];
    const byId = teams?.byId || {};

    searchTeams.forEach((t) => {
      if (allIds.indexOf(t.id) === -1) allIds.push(t.id);
      byId[t.id] = t;
    });

    setTeams({
      allIds,
      byId,
    });
  }, [searchTeams]);

  const list =
    teams &&
    teams.allIds
      .filter((id) => teams.byId[id].gender === values.gender)
      .filter(
        (id) =>
          searchTerm === '' ||
          teams.byId[id].name.toLowerCase().includes(searchTerm.toLowerCase())
      );

  list.unshift(null); // Add empty team to beginning

  const teamList = () => {
    return (
      <ListGroup>
        {list.map((id, i) => {
          const logoSrc = id
            ? teams.byId[id].logos
              ? `${MEDIAURL}${
                  JSON.parse(teams.byId[id].logos).metaData.filename
                }`
              : `v_logo.svg`
            : null;

          return (
            <ListGroup.Item key={`${id}_${i}`}>
              <Button
                variant={selectedTeamVariant(side, id)}
                onClick={() => handleSelect(id ? teams.byId[id] : null)}
              >
                <Card>
                  {id ? (
                    <Card.Img variant="top" src={logoSrc} />
                  ) : (
                    <span style={{ fontSize: '5rem', color: '#212529' }}>
                      {id ? null : '?'}
                    </span>
                  )}
                  <Card.Body>
                    <Card.Title>
                      {id
                        ? addBold(teams.byId[id].name, searchTerm)
                        : 'No Team'}
                    </Card.Title>
                  </Card.Body>
                </Card>
              </Button>
            </ListGroup.Item>
          );
        })}
      </ListGroup>
    );
  };

  const whichSide = () => {
    let sideClass = 'left';

    switch (side) {
      case 'A':
        break;
      case 'B':
        if (!isMulti) {
          sideClass = 'right';
        }
        break;
      case 'C':
        if (values.gender === FEMALE) {
          sideClass = 'right';
        }
        break;
      case 'D':
      case 'E':
      case 'F':
      case 'G':
      case 'H':
      default:
        sideClass = 'right';
        break;
    }

    return sideClass;
  };

  const teamSlot = () => {
    const sideClass = whichSide();

    if (sideClass === 'left') {
      return (
        <>
          <Col className={`side${sideClass}`}>{teamList()}</Col>
          <Col className="empty" />
        </>
      );
    }

    if (sideClass === 'right') {
      return (
        <>
          <Col className="empty" />
          <Col className={`side${sideClass}`}>{teamList()}</Col>
        </>
      );
    }

    return null;
  };

  return (
    <>
      <Container className="teamSelector">
        <Row>
          <Button
            variant="light"
            className="teamSelectorReturn"
            onClick={handleCancel}
          ></Button>
          {teamSlot()}
        </Row>
      </Container>
      <TeamFilterAsync
        gender={values.gender}
        setSearchTeams={setSearchTeams}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        side={whichSide() === 'left' ? 'A' : 'B'}
        handleCancel={handleCancel}
        handleSelectTop={handleSelectTop}
      />
    </>
  );
}

export default TeamSelector;
