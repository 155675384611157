import { Row, Col } from 'react-bootstrap';
import Evaluator from './evaluator';
import { ScoreboardButton } from './scoreboard';
import { BeaconButton } from '../helpers/beacon';
import { useUser } from '../../user-provider';

export default function JudgingFooter({ teamA, teamB, sessionId, solo }) {
  const { isAdmin } = useUser();

  return (
    <Row className="footers">
      <Col className={['left', solo ? null : 'dualEval'].join(' ')}>
        <Evaluator team={teamA} sessionId={sessionId} />
      </Col>
      <Col className="center" xs={1}>
        {isAdmin ? (
          <BeaconButton />
        ) : (
          <ScoreboardButton sessionId={sessionId} />
        )}
      </Col>
      {solo ? null : (
        <Col className={['right', solo ? null : 'dualEval'].join(' ')}>
          <Evaluator team={teamB} sessionId={sessionId} />
        </Col>
      )}
    </Row>
  );
}
