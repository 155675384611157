import CreatePenalty from '../mutations/CreatePenalty.graphql';
import UpdatePenalty from '../mutations/UpdatePenalty.graphql';
import GetLineupWithRoutines from '../queries/GetLineupWithRoutines.graphql';

const newOptimisticInquiry = () => ({
  __typename: 'Penalty',
  id: 'FAKE-PENALTY-ID',
  _version: 0,
});

export function createPenalty(apolloClient, input) {
  return apolloClient.mutate({
    mutation: CreatePenalty,
    variables: {
      input,
    },
    optimisticResponse: {
      __typename: 'Mutation',
      createPenalty: {
        ...newOptimisticInquiry(),
        ...input,
      },
    },
    update: (apolloProxy, { data: { createPenalty } }) => {
      const { getLineup } = apolloProxy.readQuery({
        query: GetLineupWithRoutines,
        variables: {
          id: input.lineupId,
        },
      });
      apolloProxy.writeQuery({
        query: GetLineupWithRoutines,
        variables: {
          id: input.lineupId,
        },
        data: {
          getLineup: {
            ...getLineup,
            penalties: {
              ...getLineup.penalties,
              items: [...getLineup.penalties.items, createPenalty],
            },
          },
        },
      });
    },
  });
}

export function updatePenalty(apolloClient, currentData, newData) {
  return apolloClient.mutate({
    mutation: UpdatePenalty,
    variables: {
      input: {
        id: currentData.id,
        _version: currentData._version,
        ...newData,
      },
    },
    optimisticResponse: {
      __typename: 'Mutation',
      updatePenalty: {
        ...currentData,
        updatedAt: new Date(),
        ...newData,
      },
    },
  });
}
