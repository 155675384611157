import { Dropdown, DropdownButton, Button } from 'react-bootstrap';
import React from 'react';
import { addHours, differenceInMinutes, isPast, addMinutes } from 'date-fns';
import { saveAs } from 'file-saver';
import { createEvent } from 'ics';
import { logEventToGoogleAnalytics } from '../../utilities/log-event-to-google-analytics';

function prepDateForCalendar(date) {
  return date.toISOString().replace(/[-:.]/g, '');
}

function generateGoogleCalendarLink(title, description = '', startAt, endAt) {
  const startAtDate = new Date(startAt);
  const endAtDate = endAt ? new Date(endAt) : addHours(startAtDate, 2);
  const startDateTime = prepDateForCalendar(startAtDate);
  const endDateTime = prepDateForCalendar(endAtDate);
  const urlParams = new URLSearchParams({
    action: 'TEMPLATE',
    text: title,
    details: description,
    dates: `${startDateTime}/${endDateTime}`,
  });

  return `http://www.google.com/calendar/render?${urlParams.toString()}`;
}

function createICalEvent(title, description = '', startAt, endAt) {
  const minutes = endAt
    ? differenceInMinutes(new Date(endAt), new Date(startAt))
    : 120;
  const startDate = new Date(startAt);

  createEvent(
    {
      title,
      description,
      busyStatus: 'BUSY',
      start: [
        startDate.getUTCFullYear(),
        startDate.getUTCMonth() + 1,
        startDate.getUTCDate(),
        startDate.getUTCHours(),
        startDate.getUTCMinutes(),
      ],
      startInputType: 'utc',
      duration: { minutes },
    },
    (error, value) => {
      if (error) {
        console.error(error);
      }

      saveAs(new Blob([value]), 'event.ics');
    }
  );
}

const calendarEvent = 'calendar_event_create';
const calendarParam = 'calendar_event_type';

function CalendarDropdown({
  eventTitle,
  eventDescription,
  sessionId,
  startAt,
  endAt,
  handler,
  disabled,
}) {
  const startAtDate = new Date(startAt);
  const endAtDate = endAt
    ? addHours(new Date(endAt), 1)
    : addHours(startAtDate, 3);
  const isOngoing = isPast(addMinutes(startAtDate, -60)) && !isPast(endAtDate);
  const isComing = !isPast(addMinutes(startAtDate, -60)); // lead in by 30 minutes

  const openGoogleCalendarLink = () => {
    logEventToGoogleAnalytics(calendarEvent, {
      [calendarParam]: 'google calendar',
    });
    window.open(
      generateGoogleCalendarLink(eventTitle, eventDescription, startAt, endAt)
    );
  };

  const selectICalEvent = () => {
    logEventToGoogleAnalytics(calendarEvent, {
      [calendarParam]: 'ical',
    });
    createICalEvent(eventTitle, eventDescription, startAt, endAt);
  };

  return !isComing ? (
    <Button
      size="sm"
      variant="link"
      className={isOngoing ? 'live' : 'past'}
      onClick={handler}
      disabled={disabled}
    >
      {isOngoing ? 'Join Live' : 'View Postgame'}
    </Button>
  ) : (
    <DropdownButton title="Add to Calendar" size="sm" variant="link">
      <Dropdown.Item onSelect={openGoogleCalendarLink}>
        Google Calendar
      </Dropdown.Item>
      <Dropdown.Item onSelect={selectICalEvent}>iCal / Outlook</Dropdown.Item>
    </DropdownButton>
  );
}

export default CalendarDropdown;
