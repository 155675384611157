import { useState, useEffect } from 'react';
import { searchIcon, xIcon } from './icons';
import { useAsyncDebounce } from 'react-table';
import { Spinner, Row } from 'react-bootstrap';
import { useHotkeys } from 'react-hotkeys-hook';

// Method used to highlight searchstring in found results
function addBold(text, highlight) {
  const start = text && text.toLowerCase().indexOf(highlight.toLowerCase());
  if (start === -1 || highlight.length === 0) {
    return text;
  }

  return (
    <>
      {text.substring(0, start)}
      <b>{text.substring(start, start + highlight.length)}</b>
      {text.substring(start + highlight.length)}
    </>
  );
}

function noResults(type) {
  return (
    <Row style={{ minHeight: '100%', textAlign: 'center' }} className="vCenter">
      <span>No {type} found.</span>
    </Row>
  );
}

function SearchInput(props) {
  const { searchTerm, search } = props;
  const [focused, setFocused] = useState(
    searchTerm && searchTerm !== '' ? true : false
  );
  const [tempSearchTerm, setTempSearchTerm] = useState(searchTerm);
  const isLoading = searchTerm !== tempSearchTerm;

  useEffect(() => {
    if (focused) {
      document.getElementById('search-input').focus();
    }
  }, [focused]);

  useEffect(() => {
    if (searchTerm === '') {
      setTempSearchTerm('');
      setFocused(false);
    }
  }, [searchTerm]);

  useHotkeys('meta+f', (e) => {
    e.preventDefault();
    setFocused(true);
  });

  const debounceSetSearchTerm = useAsyncDebounce(search, 500);

  const handleInput = (e) => {
    e.preventDefault();
    setTempSearchTerm(e.target.value);
    debounceSetSearchTerm(e.target.value);
  };

  const searchKeyOverride = (e) => {
    switch (e.keyCode) {
      case 9: // Tab
        break;
      case 27: // Esc
        // Cancel search
        e.preventDefault();
        setFocused(false);
        break;
      case 13: // Return
        handleInput(e);
        break;
      case 38: // Up
      case 40: // Down
      default:
        setFocused(true);
        break;
    }
  };

  const cancelSearch = (e) => {
    e.preventDefault();
    search('');
    setTempSearchTerm('');
    document.getElementById('search-input').focus();
  };

  const showResults = () => {
    setFocused(true);
  };

  const hideResults = (e) => {
    if (!e.currentTarget.contains(e.relatedTarget)) {
      if (tempSearchTerm === '') {
        setFocused(false);
      }
    }
  };

  const spinner = () => {
    return <Spinner role="status" animation="border" size="sm" />;
  };

  const clearButton = () => {
    if (!focused) {
      return null;
    }

    return isLoading || tempSearchTerm !== '' ? (
      <button onClick={cancelSearch} disabled={isLoading}>
        {isLoading ? spinner() : xIcon}
      </button>
    ) : focused ? (
      <div className="searchIconFiller" />
    ) : null;
  };

  return (
    <div
      className={['search-container', focused ? null : 'icon-only'].join(' ')}
      onFocus={showResults}
      onBlur={hideResults}
      onClick={showResults}
    >
      <div className="search-header">{searchIcon}</div>
      <div
        className={['search-input-container', focused ? null : 'hidden'].join(
          ' '
        )}
      >
        <input
          id="search-input"
          placeholder="Search"
          onChange={handleInput}
          value={tempSearchTerm}
          autoCorrect="off"
          autoComplete="off"
          autoCapitalize="none"
          spellCheck="false"
          onKeyDown={searchKeyOverride}
        />
      </div>
      {clearButton()}
    </div>
  );
}

export { addBold, SearchInput, noResults };
