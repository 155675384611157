/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_mobile_analytics_app_id": "5a38305d9d7f496f98823cce7c96c045",
    "aws_mobile_analytics_app_region": "us-west-2",
    "Analytics": {
        "AWSPinpoint": {
            "appId": "5a38305d9d7f496f98823cce7c96c045",
            "region": "us-west-2"
        }
    },
    "aws_cloud_logic_custom": [
        {
            "name": "virtiusapisession",
            "endpoint": "https://qd6zam8kgd.execute-api.us-west-2.amazonaws.com/main",
            "region": "us-west-2"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://zebubixnn5bepaq5phtahm6agy.appsync-api.us-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-m3w2ldhjwfdg7izh3oeeadbdbi",
    "aws_cognito_identity_pool_id": "us-west-2:0dd3adb6-a005-4f15-87e7-be3e3d969d80",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_pAR26BF4U",
    "aws_user_pools_web_client_id": "mlulnihmlma34ir0t0d1qrlrd",
    "oauth": {
        "domain": "virtius-dev.auth.us-west-2.amazoncognito.com",
        "scope": [
            "aws.cognito.signin.user.admin",
            "email",
            "openid",
            "phone",
            "profile"
        ],
        "redirectSignIn": "http://localhost:3001/,https://dev.virti.us/,https://virti.us/",
        "redirectSignOut": "http://localhost:3001/,https://dev.virti.us/,https://virti.us/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [
        "FACEBOOK",
        "GOOGLE"
    ],
    "aws_cognito_signup_attributes": [
        "NAME",
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "virtiuswebappstorage05055-main",
    "aws_user_files_s3_bucket_region": "us-west-2",
    "aws_cognito_login_mechanisms": [
        "PREFERRED_USERNAME"
    ]
};


export default awsmobile;
