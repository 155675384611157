import React, { useState, useEffect } from 'react';
import { Modal, Button, Row, Spinner, Alert, Form } from 'react-bootstrap';
import LoginModalFooter from './loginmodalfooter';
import { useFormik } from 'formik';
import { eye, eyeSlash } from '../helpers/icons';
import { useDispatch, useSelector } from 'react-redux';
import { userActions, alertActions } from '../../redux/_actions';
import { uiState } from '../../redux/_constants';
import * as Yup from 'yup';
import InputAutofocus from '../helpers/inputautofocus';
import { useHistory } from 'react-router-dom';

function Signup() {
  const { alert } = useSelector((state) => state);
  const [showPW, setShowPW] = useState(false);
  const [isRegistering, setIsRegistering] = useState(false);
  const [isAuthenticating, setIsAuthenticating] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (!alert.clear) {
      setIsRegistering(false);
      setIsAuthenticating(false);
    }
  }, [alert.clear]);

  const handleMode = (mode) => {
    history.push(mode);
  };

  const togglePW = () => {
    setShowPW(!showPW);
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Required'),
    fullName: Yup.string().required('Required').min(3, 'Minimum 3 characters'),
    password: Yup.string()
      .required('Required')
      .matches(
        /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
        'Invalid password'
      ),
  });

  const { handleSubmit, handleChange, values, errors } = useFormik({
    initialValues: {
      email: '',
      fullName: '',
      password: '',
    },
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit(values) {
      setIsRegistering(true);
      dispatch(alertActions.clear());
      dispatch(userActions.register(values, history));
    },
  });

  const registerBody = () => {
    return (
      <Form onSubmit={handleSubmit}>
        {!alert.clear ? (
          <Row>
            <Alert
              dismissible
              onClose={() => dispatch(alertActions.clear())}
              variant={alert.type === 'alert-danger' ? 'danger' : 'success'}
            >
              {alert.message}
            </Alert>
          </Row>
        ) : null}
        <Row>
          <label>
            Email
            {errors.email ? (
              <span className="form-error">{errors.email}</span>
            ) : null}
          </label>
          {InputAutofocus({
            name: 'email',
            value: values.email,
            handler: handleChange,
            disabled: isRegistering || isAuthenticating,
          })}
        </Row>
        <Row>
          <label>
            Full Name
            {errors.fullName ? (
              <span className="form-error">{errors.fullName}</span>
            ) : null}
          </label>
          <input
            name="fullName"
            type="text"
            autoComplete="off"
            onChange={handleChange}
            values={values.fullName}
            disabled={isRegistering || isAuthenticating}
          />
        </Row>
        <Row>
          <label>
            Password
            {errors.password ? (
              <span className="form-error">{errors.password}</span>
            ) : null}
          </label>
          <div className="password-container">
            <input
              name="password"
              type={showPW ? 'text' : 'password'}
              autoComplete="off"
              onChange={handleChange}
              values={values.password}
              disabled={isRegistering || isAuthenticating}
            />
            <i onClick={togglePW}>{showPW ? eye : eyeSlash}</i>
          </div>
          <div className="passwordSpecs">
            Requires minimum 8 characters, uppercase letter, special character,
            and a number.
          </div>
        </Row>
        <Row>
          <Button
            variant="outline-primary"
            className="loginButton"
            type="submit"
            disabled={isRegistering}
          >
            {isRegistering || isAuthenticating ? (
              <>
                {isRegistering ? 'Signing up... ' : 'Logging in... '}
                <Spinner
                  variant="primary"
                  role="status"
                  animation="border"
                  size="sm"
                />
              </>
            ) : (
              'Sign Up'
            )}
          </Button>
        </Row>
        <Row>
          <div className="newToVirtius">
            <span>Have an account?</span>
            <button
              type="button"
              className="signUp"
              onClick={() => handleMode(uiState.LOGIN)}
              disabled={isRegistering || isAuthenticating}
            >
              Log In
            </button>
          </div>
        </Row>
      </Form>
    );
  };

  const headerMsg = () => {
    const virtius = (
      <>
        <span className="vBlue">V</span>
        <span className="vGray">irtius</span>
      </>
    );

    return (
      <>
        <span>Begin your experience with&nbsp;</span>
        {virtius}
        <span>!</span>
      </>
    );
  };

  return (
    <>
      <Modal.Header className="loginHeader">{headerMsg()}</Modal.Header>
      <Modal.Body className="loginForm">{registerBody()}</Modal.Body>
      <Modal.Footer className="loginSocial">
        <LoginModalFooter
          disabled={isRegistering || isAuthenticating}
          login={setIsAuthenticating}
        />
      </Modal.Footer>
    </>
  );
}

export default Signup;
