import React from 'react';
import { Link } from 'react-router-dom';

function Privacy() {
  return (
    <div className="homeText">
      <p>Last Updated: January 28, 2021</p>
      <p>
        Virtius (“we” or “us”) is committed to protecting your privacy. This
        Privacy Policy describes how we collect, store, use and distribute
        information through our software, website, documentation, and related
        services (together, the “Services”).
      </p>
      <p>
        Capitalized terms not defined in this Privacy Policy have the meaning as
        set forth in the Terms of Service for the Services, which can be found
        at <Link to="/terms">Terms</Link>.
      </p>
      <ol>
        <li>
          Consent. By using the Services, you consent to the use of your
          Personal Information as described in this Privacy Policy. If you are
          not of legal age to form a binding contract (in many jurisdictions,
          this age is 18), you may only use the Services and disclose
          information to us with your parent’s or legal guardian’s express
          consent. Furthermore, children under the age of 13 cannot use or
          register for the Services in any way. Review this Privacy Policy with
          your parent or legal guardian to make sure you understand it. Except
          as set forth in this Privacy Policy, your Personal Information will
          not be used for any other purpose without your consent. We do not
          actively collect Personal Information for the purpose of sale of such
          information in a way that specifically identifies the individual (i.e.
          we don’t sell customer lists). However, as described in this policy,
          we do allow third parties and service providers to collect Personal
          Information through the Services and share information with third
          parties and service providers for business purposes. You may withdraw
          your consent to our processing of your Personal Information at any
          time. However, withdrawing consent may result in your inability to
          continue using some or all of the Services.
          <p></p>
        </li>
        <li>
          Collection of Information.
          <p></p>
          <h6>Registration Information</h6>
          <p>
            When registering for or using the Services, we may require you to
            provide certain personally identifiable information, such as your
            name, company/organization name, address, phone number, email
            address, and/or social media username (these are referred to below
            as your “Registration Information”). Your Registration Information,
            together with any other information we gather through the Services
            that may be used to identify, contact, or locate you individually,
            are collectively referred to herein as your “Personal Information.”
          </p>
          <h6>Billing Information</h6>
          <p>
            When purchasing the Services, we will require you to provide
            financial and billing information, such as billing name and address,
            and credit card number (“Billing Information”).{' '}
          </p>
          <h6>Data, Diagnostic & Login Information</h6>
          <p>
            You may be able to create, upload, publish, transmit, distribute,
            display, store or share information, data, text, graphics, video,
            messages or other materials using our Services (this is collectively
            referred to below as “Data”). Some of this Data may be stored and
            maintained on our servers. If you run into technical errors in the
            course of using the Services, we may request your permission to
            obtain a crash report along with certain logging information from
            your system documenting the error (“Diagnostic Information”). Such
            information may contain information regarding your Operating System
            version, hardware, browser version, and your email address, if
            provided. Additionally, certain login information may be maintained
            in a cookie stored locally on your personal computing device (i.e.
            not on a server) in order to streamline the login process (“Login
            Information”).
          </p>
          <h6>Usage and Analytics Information</h6>
          <p>
            We may also collect information through the use of commonly-used
            information-gathering tools, such as cookies, log files, and Web
            beacons. Collectively, this information is referred to as “Usage and
            Analytics Information.”{' '}
          </p>
          <h7>Cookies</h7>
          <p>
            We use standard browser cookies to allow us to remember details of a
            user session. For example, we may use cookies to make it easier for
            the user to resume a recent session without re-entering their log-in
            details.
          </p>
          <p>
            You can instruct your browser to refuse all cookies or to indicate
            when a cookie is being sent. However, if you do not accept cookies,
            you may not be able to use some portions of our Services.
          </p>
          <h7>Log Files</h7>
          <p>
            As is true of most websites, we gather certain information
            automatically to analyze trends in the aggregate and administer the
            Services. This information may include your Internet Protocol (IP)
            address (or the proxy server you use to access the World Wide Web),
            your browser type, the pages and files you viewed, your operating
            system, your actions in connection with our Services, and date/time
            stamps associated with your usage.{' '}
          </p>
          <h7>Web Beacons</h7>
          <p>
            We use web beacons alone or in conjunction with cookies to compile
            information about your usage of the Services and interaction with
            emails from us. Web beacons are clear electronic images that can
            recognize certain types of information on your computer, such as
            cookies, when you viewed a particular website or Service tied to the
            web beacon, and a description of a website or Service tied to the
            web beacon. For example, we may place web beacons in marketing
            emails that notify us when you click on a link in the email that
            directs you to one of our websites. We also use web beacons to
            operate and improve the Services and our email communications.
          </p>
          <h7>Geo-Location Information</h7>
          <p>
            We do not use GPS technology to collect any information regarding
            your precise real-time geo-location while using the Services.
            However, we may use elements of your Usage and Analytics Information
            (such as your IP address) to determine your generalized location.
            This information is referred to as “Generalized Geo-Location
            Information.”
          </p>
        </li>
        <li>
          Use of Information. We use the information we collect in the following
          ways:
          <p></p>
          <h6>Registration Information</h6>
          <p>
            We use this information to manage your account, to provide the
            Services, to maintain our customer/visitor lists, to respond to your
            inquiries or request feedback, for identification and authentication
            purposes, for service improvement, and to address issues like
            malicious use of the Services. We may also use Registration
            Information for limited marketing purposes, namely, to contact you
            to further discuss your interest in the Services, and to send you
            information about us or our partners.
          </p>
          <h6>Billing Information </h6>
          <p>
            We use Billing Information to manage your account, to provide the
            Services, and to check the financial qualifications of prospective
            customers and to collect payment for the Services. We may use a
            third-party service provider to manage credit card processing. If we
            do so, such a service provider will not be permitted to store,
            retain, or use Billing Information except for the sole purpose of
            credit card processing on our behalf.{' '}
          </p>
          <h6>Data, Diagnostic Information and Login Information</h6>
          <p>
            We use this information for the purpose of administering and
            improving our Services to you. We may also use this information in a
            de-identified, anonymous way in conjunction with an analytics
            service such as Google Analytics to monitor and analyze use of the
            Services, for the Services’ technical administration, to increase
            the Services’ functionality and user-friendliness, to provide
            services to our business partners, and for purposes of research and
            data enhancement.
          </p>
          <h6>Usage and Analytics Information</h6>
          <p>
            We use this information to analyze overall trends, to help us
            improve the Services, to track and aggregate non-personal
            information, and to provide the Services. For example, we use IP
            addresses to monitor the regions from which customers and visitors
            navigate the Websites.{' '}
          </p>
          <h6>Geo-Location Information</h6>
          <p>
            We may use this information for the purpose of administering and
            improving our Services to you, such as by providing you with
            relevant advertisements and promotions. We may also use your
            Geo-Location Information in an anonymized manner in conjunction with
            an analytics service such as Google Analytics to monitor and analyze
            use of the Services, for the Services’ technical administration, and
            to increase the Services’ functionality and user-friendliness.
          </p>
          <p></p>
          <p>
            Please note that if you are registering for or using the Services as
            an administrator on behalf of an organization, you may be able to
            view the activity and/or content associated with your sub-users’ use
            of the Services, including, but not limited to, their Personal
            Information.
          </p>
          <p>
            If we plan to use your Personal Information in the future for any
            other purposes not identified above, we will only do so after
            informing you by updating this Privacy Policy. See further the
            section of this Privacy Policy entitled “Amendment of this Privacy
            Policy”.
          </p>
        </li>
        <li>
          Disclosures & Transfers. We not disclose or transfer your Personal
          Information to third parties without your permission, except as
          specified in this Privacy Policy (see further below).
          <p></p>
          <p>
            Please note that if you are using the Services at the request or as
            part of an organization, your organization’s account administrator
            may be able to view certain activity and content associated with
            your use of the Services, including, but not limited to, elements of
            your Personal Information.
          </p>
          <p>
            We may disclose your Personal Information to third parties without
            your consent if we have reason to believe that disclosing this
            information is necessary to identify, contact or bring legal action
            against someone who may be causing injury to or interference with
            (either intentionally or unintentionally) our rights or property,
            other users of the Services, or anyone else (including the rights or
            property of anyone else) that could be harmed by such activities. We
            may disclose Personal Information when we believe in good faith that
            such disclosure is required by and in accordance with the law.
          </p>
          <p>
            We may also disclose your Personal Information in connection with a
            corporate re-organization, a merger or amalgamation with another
            entity, or a sale of all or a substantial portion of our assets or
            stock, including any due diligence exercise carried out in relation
            to the same, provided that the information disclosed continues to be
            used for the purposes permitted by this Privacy Policy by the entity
            acquiring the information.
          </p>
        </li>
        <li>
          Non-US Users. These Services are hosted in the United States and are
          intended primarily for visitors located within the United States. If
          you choose to use the Services from other regions of the world with
          laws governing data collection and use that may differ from U.S. law,
          then please note that you may be transferring your Personal
          Information outside of those regions to the United States for storage
          and processing. By providing your Personal Information through the
          Services, you consent to such transfer, storage, and processing.
          <p></p>
        </li>
        <li>
          Security. The security of your Personal Information is important to
          us. We use commercially reasonable efforts to store and maintain your
          Personal Information in a secure environment. We take technical,
          contractual, administrative, and physical security steps designed to
          protect Personal Information that you provide to us. We have
          implemented procedures designed to limit the dissemination of your
          Personal Information to only such designated staff as are reasonably
          necessary to carry out the stated purposes we have communicated to
          you.
          <p></p>
          <p>
            You are also responsible for helping to protect the security of your
            Personal Information. For instance, never give out your password,
            and safeguard your user name, password and personal credentials when
            you are using the Services, so that other people will not have
            access to your Personal Information. Furthermore, you are
            responsible for maintaining the security of any personal computing
            device on which you utilize the Services.{' '}
          </p>
        </li>
        <li>
          Sharing Information with Third Parties. You may be able to share
          Personal Information with third parties through use of the Services.
          The privacy policies of these third parties are not under our control
          and may differ from ours. The use of any information that you may
          provide to any third parties will be governed by the privacy policy of
          such third party or by your independent agreement with such third
          party, as the case may be. If you have any doubts about the privacy of
          the information you are providing to a third party, we recommend that
          you contact that third party directly for more information or to
          review its privacy policy.
          <p></p>
          <h6>Google Analytics</h6>
          <p>
            We use Google Analytics to track and report website traffic. Google
            uses the data collected to track and monitor the use of our
            Services. This data is shared with other Google services. For more
            information about how Google uses your data, please visit:{' '}
            <a href="https://policies.google.com/technologies/partner-sites">
              https://policies.google.com/technologies/partner-sites
            </a>
            .
          </p>
          <h6>AWS Pinpoint</h6>
          <p>
            We use AWS Pinpoint, offered by Amazon Web Services, to provide
            marketing and analytics. For more information about Amazon Web
            Services uses your data, please visit:{' '}
            <a href="https://aws.amazon.com/compliance/data-privacy-faq/">
              https://aws.amazon.com/compliance/data-privacy-faq/
            </a>
            .
          </p>
          <h6>Social Login</h6>
          <p>
            We employ third party tools to provide you with the convenience you
            are used to from elsewhere on the internet to sign in to Virtius
            with one click (so called “social login”). Some of these tools can
            track your actions when you interact with them. At Virtius you can
            sign in with Google, Facebook, or your email/password. Please refer
            to the terms of the respective providers for further guidance:
          </p>
          <p>
            <a href="https://policies.google.com/privacy">Google</a>
          </p>
          <p>
            <a href="https://www.facebook.com/policy.php">Facebook</a>
          </p>
        </li>
        <li>
          Retention. We will keep your Personal Information for as long as it
          remains necessary for the identified purpose or as required by law,
          which may extend beyond the termination of our relationship with you.
          We may retain certain data as necessary to prevent fraud or future
          abuse, or for legitimate business purposes, such as analysis of
          aggregated, non-personally-identifiable data, account recovery, or if
          required by law. All retained Personal Information will remain subject
          to the terms of this Privacy Policy. Please note that if you request
          that your Personal Information be removed from our databases, it may
          not be possible to completely delete all of your Personal Information
          due to technological and legal constraints.
          <p></p>
        </li>
        <li>
          Amendment of this Privacy Policy. We reserve the right to change this
          Privacy Policy at any time. If we decide to change this Privacy Policy
          in the future, we will post or provide appropriate notice. Any
          non-material change (such as clarifications) to this Privacy Policy
          will become effective on the date the change is posted, and any
          material changes will become effective 30 days from their posting{' '}
          <Link to="/privacy">here</Link> or via email to your listed email
          address. Unless stated otherwise, our current Privacy Policy applies
          to all Personal Information that we have about you and your account.
          The date on which the latest update was made is indicated at the top
          of this document. We recommend that you print a copy of this Privacy
          Policy for your reference and revisit this policy from time to time to
          ensure you are aware of any changes. Your continued use of the
          Services signifies your acceptance of any changes.
          <p></p>
        </li>
        <li>
          Access and Accuracy. You have the right to access the Personal
          Information we hold about you in order to verify the Personal
          Information we have collected in respect to you and to have a general
          account of our uses of that information. Upon receipt of your written
          request, we will provide you with a copy of your Personal Information,
          although in certain limited circumstances we may not be able to make
          all relevant information available to you, such as where that
          information also pertains to another user. In such circumstances we
          will provide reasons for the denial to you upon request. We will
          endeavor to deal with all requests for access and modifications in a
          timely manner.
          <p>
            We will make every reasonable effort to keep your Personal
            Information accurate and up-to-date, and we will provide you with
            mechanisms to update, correct, delete or add to your Personal
            Information as appropriate. As appropriate, this amended Personal
            Information will be transmitted to those parties to which we are
            permitted to disclose your information. Having accurate Personal
            Information about you enables us to give you the best possible
            service.
          </p>
        </li>
        <li>
          Notice to California Consumers.
          <p>
            Under California Civil Code Sections 1798.83-1798.84, California
            residents are entitled to ask us for a notice identifying the
            categories of Personal Information which we share with our
            affiliates and/or third parties for marketing purposes, and
            providing contact information for such affiliates and/or third
            parties. If you are a California resident and would like a copy of
            this notice, please submit a written request to:{' '}
            <a href="mailto:support@virti.us">support@virti.us</a>.{' '}
          </p>
        </li>
        <li>
          Contact Us. You can help by keeping us informed of any changes such as
          a change of email address or telephone number. If you would like to
          access your information, if you have any questions, comments or
          suggestions, if you find any errors in our information about you, or
          if you have a complaint concerning our compliance with applicable
          privacy laws, please contact us at{' '}
          <a href="mailto:support@virti.us">support@virti.us</a> or by mail at:
          <p></p>
          <p style={{ margin: '0', fontWeight: '500' }}>Virtius Inc</p>
          <p style={{ margin: '0' }}>180 Steuart St. Unit 192750</p>
          <p style={{ margin: '0' }}>San Francisco, CA 94119 USA</p>
        </li>
      </ol>
    </div>
  );
}

export default Privacy;
