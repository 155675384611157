import React, { useState, useRef, useEffect, useImperativeHandle } from 'react';
import { Row, Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { adminActions, alertActions } from '../../redux/_actions';
import { modeType, lineupDefault } from '../../utilities/constants';

export const TeamLineup = React.forwardRef((props, ref) => {
  const { teamId, reset, setIsEditing, setTabMode, tabMode } = props;
  const { teams, status, lineups } = useSelector((state) => state.admin);
  const team = teams.byId[teamId];
  const [lineup, setLineup] = useState(null);
  const { LIST, DETAIL, CREATE, EDIT } = modeType;
  const dispatch = useDispatch();
  const formRef = useRef();

  useEffect(() => {
    if (!alert.clear && alert.type === 'alert-success') {
      // Update to see if need to add new roster or selected roster
      if (tabMode === CREATE) {
        // assign last on AllIds array since it was added by push
        const id = lineups.allIds[lineups.allIds.length - 1];
        setLineup(lineups.byId[id]);
        setTabMode(EDIT);
      }

      // Handle the effects of a save operation and data updates
      if (tabMode === EDIT) {
        setTabMode(DETAIL);
        setLineup(lineups.byId[lineup.id]); // need to set to new roster
        reset();
      }
    }
  }, [alert, lineups, tabMode]);

  useEffect(() => {
    if (teamId !== status.filterId) {
      // This line is still required to populate the edit options table
      //dispatch(adminActions.getTeamAthletes(teamId));
    }
  }, [teamId, status.filterId]);

  useImperativeHandle(ref, () => ({
    submit() {
      if (!alert.clear) {
        dispatch(alertActions.clear());
      }
      formRef.current.submit();
    },
    create() {
      if (!alert.clear) {
        dispatch(alertActions.clear());
      }
      const input = { ...lineupDefault, teamId: teamId };
      dispatch(adminActions.createLineup(input));
      setTabMode(CREATE);
    },
    back() {
      if (!alert.clear) {
        dispatch(alertActions.clear());
      }
      setIsEditing(false);
      setTabMode(LIST);
    },
    edit() {
      if (!alert.clear) {
        dispatch(alertActions.clear());
      }
      setTabMode(EDIT);
    },
  }));

  const emptyList = () => {
    return (
      <Row
        style={{ minHeight: '40vh', textAlign: 'center' }}
        className="vCenter"
      >
        <span>No lineups created.</span>
      </Row>
    );
  };

  const setupBody = () => {
    if (team && team.lineups && team.lineups.items.length === 0) {
      return emptyList();
    } else {
      return emptyList();
    }
  };

  return (
    <Form onSubmit={null} className="teamLineup">
      {setupBody()}
    </Form>
  );
});
