import React from 'react';
import { Row, Col } from 'react-bootstrap';
import DeltaIndicator from './deltaindicator';
import { useSelector } from 'react-redux';
import { useQuery } from '@apollo/client';
import {
  roundScore,
  convertMillipointsToDisplay,
} from '../../utilities/scoring';
import { sessionViewType } from '../../redux/_constants';
import { dualTeamBRotation } from '../../utilities/session';
import { RoutineStatus } from '../../models';

import GetLineupWithRoutines from '../../apollo/queries/GetLineupWithRoutines.graphql';

function RoundDeltas({ rounds }) {
  const view = useSelector((state) => state.session.view);
  const isAlternating = useSelector((state) => state.session.alternating);
  const { MIRRORA, MIRRORB } = sessionViewType;

  const teamA = view === MIRRORB ? 'teamB' : 'teamA';
  const teamB = view === MIRRORA ? 'teamA' : 'teamB';

  const teamALineupId = useSelector((state) => state.producer[teamA].lineupId);
  const teamBLineupId = useSelector((state) => state.producer[teamB].lineupId);
  const shouldShowScores = useSelector((state) => state.producer.scores);
  const judgePanel = useSelector((state) => state.session.judgePanel);

  const colorA = useSelector((state) => state.producer[teamA].color);
  const colorB = useSelector((state) => state.producer[teamB].color);
  const scores = useSelector((state) => state.producer.scores);
  const teamScoringType = useSelector((state) => state.session.teamScoring);

  const { data: lineupDataA } = useQuery(GetLineupWithRoutines, {
    variables: { id: teamALineupId },
  });
  const { data: lineupDataB } = useQuery(GetLineupWithRoutines, {
    variables: { id: teamBLineupId },
  });

  if (!lineupDataA || !lineupDataB || !shouldShowScores) {
    return null;
  }

  function currentRoundRoutines(routines, currentRound) {
    return routines.reduce((currentRoutines, routine) => {
      if (
        routine.rotation === currentRound &&
        routine.status !== RoutineStatus.DELETED
      ) {
        currentRoutines[routine.order] = routine;
      }
      return currentRoutines;
    }, []);
  }

  const deltas = [];

  for (let i = 0; i < rounds; i++) {
    scores &&
      deltas.push({
        scoreA: roundScore(
          currentRoundRoutines(lineupDataA.getLineup.routines.items, i + 1),
          teamScoringType
        ),
        scoreB: roundScore(
          currentRoundRoutines(
            lineupDataB.getLineup.routines.items,
            isAlternating ? dualTeamBRotation(i + 1) : i + 1
          ),
          teamScoringType
        ),
      });
  }

  return (
    <div className="scoreDeltas">
      {deltas.map((d, index) => {
        return (
          <div key={`roundDelta${index}`}>
            <Row className="rDeltaRow">
              <Col className="rDeltaCol vCenter">
                {d.scoreA === 0 && d.scoreB === 0
                  ? '--'
                  : convertMillipointsToDisplay(Math.abs(d.scoreA - d.scoreB))}
                <DeltaIndicator
                  scoreA={d.scoreA}
                  scoreB={d.scoreB}
                  colorA={colorA}
                  colorB={colorB}
                  sensitivity={judgePanel}
                />
              </Col>
            </Row>
            {index < deltas.length - 1 ? (
              <hr style={{ borderTop: '1px solid transparent' }} />
            ) : null}
          </div>
        );
      })}
    </div>
  );
}

export default RoundDeltas;
