import { Auth } from 'aws-amplify';
import { API, graphqlOperation } from 'aws-amplify';
import { userByEmail } from '../../graphql/graphql';

export const authService = {
  getUser,
  getUserByEmail,
  logOut,
  logIn,
  register,
  fbLogin,
  googleLogin,
  getPasswordResetCode,
  verify,
  requestVerificationCode,
  resetPassword,
};

async function resetPassword(email, password, code) {
  return await Auth.forgotPasswordSubmit(email.toLowerCase(), code, password);
}

async function getPasswordResetCode(email) {
  return await Auth.forgotPassword(email.toLowerCase()).then((res) => {
    return res;
  });
}

function fbLogin() {
  return Auth.federatedSignIn({ provider: 'Facebook' });
}

function googleLogin() {
  return Auth.federatedSignIn({ provider: 'Google' });
}

async function getUser() {
  return Auth.currentAuthenticatedUser();
}

async function getUserByEmail(email) {
  return await API.graphql(
    graphqlOperation(userByEmail, {
      email: email.toLowerCase(),
    })
  );
}

async function logOut() {
  return await Auth.signOut().then((data) => {
    return data;
  });
}

async function logIn({ email, password }) {
  return await Auth.signIn(email.toLowerCase(), password).then((user) => {
    return user;
  });
}

async function register({ email, password, fullName }) {
  return await Auth.signUp({
    username: email.toLowerCase(),
    password: password,
    attributes: {
      name: fullName,
    },
  }).then((user) => {
    return user;
  });
}

async function verify(email, code) {
  return await Auth.confirmSignUp(email.toLowerCase(), code).then((res) => {
    return res;
  });
}

async function requestVerificationCode(email) {
  return await Auth.resendSignUp(email.toLowerCase()).then((res) => {
    return res;
  });
}
