import { useState, useRef, useEffect } from 'react';
import { Row, Col, Button, ButtonGroup } from 'react-bootstrap';
import {
  // checkIcon,
  // lockIcon,
  caretUpIcon,
  caretDownIcon,
} from '../helpers/icons';
import {
  scoreCheckStrict,
  scoreJCheckStrict,
  scoreValid,
  convertPointsToMillipoints,
  convertMillipointsToPoints,
  // scoreTypeToDisplay,
  newestScoreOfType,
  scorePrecisionToDisplay,
  useRoutineScore,
} from '../../utilities/scoring';
import { useSelector, useDispatch } from 'react-redux';
import { useApolloClient, useQuery } from '@apollo/client';
import { createScore, useErrorHandler } from '../../apollo/helpers';
import { useEvalConfig, useDisplayConfig } from './hooks';
import { ScoreType } from '../../models';
import { alertActions } from '../../redux/_actions';
import { useHotkeys } from 'react-hotkeys-hook';
import GetFullRoutine from '../../apollo/queries/GetFullRoutine.graphql';
import EvaluatorSlabFinalize from './evaluatorslabfinalize';

/*

test cases for the calculator:

* Up / down arrows in general
* Up / down arrows beyond 10 and 0
* Keyboard up and down
* Every number input first especially 0
* 1, 10, 100, 1000, 10000, 100000
* 0, 00, 000
* Reset score with default 10.00
* Backspace from 0.00
* Enter a number and then continue with arrows

*/

const InputComponent = (
  inputRef,
  score,
  type,
  changeHandler,
  keyHandler,
  inputLock,
  precision,
  subScoresComplete
) => {
  const { minimumDeduction } = useEvalConfig();

  useEffect(() => {
    if (inputRef && inputRef.current) {
      // console.log('focus');
      // console.log(score);
      // inputRef.current.focus();
      if (!inputLock) {
        // console.log(inputLock);
        // inputRef.current.select();
      }
      checkSelection();
    }
  }, [inputLock, inputRef, type, score]);

  const checkSelection = () => {
    const input = inputRef.current;
    const start = input.selectionStart;
    const end = input.selectionEnd;

    // console.log(inputRef.current);

    if (start === end) {
      // console.log('Cursor is at position:', start, end);
    } else {
      // console.log('Text is highlighted from position:', start, 'to', end);
    }
  };

  // const checkIfFocused = () => {
  //   const input = inputRef.current;
  //   if (input === document.activeElement) {
  //     console.log('Input is focused');
  //   } else {
  //     console.log('Input is not focused');
  //   }
  // };

  // console.log({ score, type });

  // checkSelection();

  // const scoreToDisplay =
  //   (score && !!Number(score) && Number(score)?.toFixed(precision)) ||
  //   (inputLock ? '--' : '');
  const finalScoreToDisplay = type === 'FINAL' && !subScoresComplete;
  const scoreToDisplay = finalScoreToDisplay || !score ? '--' : score;

  // console.log(score);
  // console.log(scoreToDisplay);

  return (
    <input
      id="scoreInputNumpad"
      ref={inputRef}
      inputMode="decimal"
      // type="number"
      type="text"
      value={scoreToDisplay}
      // value={score}
      name="score"
      min="0.0"
      max={type === 'SB' ? '0.1' : '10.0'}
      step={type === 'ND' ? '0.1' : minimumDeduction}
      placeholder={type === 'SB' ? '0.1' : ''}
      onChange={changeHandler}
      // onKeyPress={keyHandler}
      disabled={inputLock}
      autoComplete="off"
      onFocus={(e) => {
        // e.preventDefault();
        e.currentTarget.select();
      }}
    />
  );
};

const FocusInput = () => {
  const inputElement = document.getElementById('scoreInputNumpad');
  if (inputElement) {
    // inputElement.focus();
    // console.log('in FocusInput');
    // inputElement.select();
  }
};

const defaultScores = {
  D: '',
  D1: '',
  D2: '',
  E: '',
  E1: '',
  E2: '',
  E3: '',
  E4: '',
  E5: '',
  E6: '',
  SB: '0.1',
  ND: '',
  SV: '10.00',
  SV1: '10.00',
  SV2: '10.00',
  SV3: '10.00',
  SV4: '10.00',
  SV5: '10.00',
  SV6: '10.00',
  F: '10',
  J: '10.00',
  J1: '10.00',
  J2: '10.00',
  J3: '10.00',
  J4: '10.00',
  J5: '10.00',
  J6: '10.00',
  OPEN: '',
  FINAL: '',
  '-': '',
};

export function ScoringPanelSlabInput({
  type,
  routineId,
  sessionId,
  score = '10.00',
  setSelectedType,
  isSelected,
  disabled,
  showHidden,
}) {
  const displayConfig = useDisplayConfig();
  const precision = scorePrecisionToDisplay(type, displayConfig);
  const internalScore =
    (score &&
      Number(convertMillipointsToPoints(score?.value)).toFixed(precision)) ||
    '';

  // console.log({showHidden})

  return (
    <Row>
      <Col
        xs={4}
        className="scoreLabel"
        style={{ fontWeight: disabled ? 'inherit' : '800' }}
      >
        {type}
      </Col>
      <Col xs={8} className="scoreButton">
        <Button
          className={isSelected ? 'selected' : ''}
          onClick={() => {
            setSelectedType(isSelected ? '-' : type);
            FocusInput();
          }}
          variant="secondary-outline"
          disabled={disabled}
        >
          {showHidden ? '**.**' : internalScore}
        </Button>
      </Col>
    </Row>
  );
}

export function EvaluatorSlabInput({ type, team, routineId, sessionId }) {
  const userId = useSelector((state) => state.user.profile?.id);
  const { disableInputChecking, minimumDeduction } = useEvalConfig();
  const displayConfig = useDisplayConfig();
  const [inputString, setInputString] = useState('');
  const [inputKey, setInputKey] = useState('');
  const [inputLock, setInputLock] = useState(true);
  const inputRef = useRef();
  const dispatch = useDispatch();
  const routineScore = useRoutineScore();
  const precision = scorePrecisionToDisplay(type, displayConfig);

  const { data, error } = useQuery(GetFullRoutine, {
    variables: { id: routineId },
    skip: !routineId || routineId === 'FAKE-ROUTINE-ID',
  });

  const routine = data?.getRoutine;
  const { scoreTotal, subScoresComplete } = routineScore({
    scores: { items: routine?.scores?.items },
  });

  // console.log(scoreTotal);
  const score =
    (routine && newestScoreOfType(routine.scores.items, type)) || null;

  // console.log(score);

  const defaultJScore = displayConfig?.defaultJScore || defaultScores[type];
  const defaultScore = type?.includes('J')
    ? parseFloat(defaultJScore).toFixed(precision)
    : defaultScores[type];

  if (typeof defaultScore === 'undefined') {
    throw new Error(
      `EvaluatorInput: defaultScore for score type=${type} is undefined`
    );
  }

  if (error) {
    throw new Error(error);
  }

  const [internalScore, setInternalScore] = useState(defaultScore); // can be number or string
  const apolloClient = useApolloClient();

  const handleCreateScoreErrors = useErrorHandler(
    'There was a problem submitting the score'
  );

  // console.log(internalScore);
  // console.log(inputString);

  useEffect(() => {
    // console.log(inputLock);
    if (score && !['FINAL'].includes(type)) {
      setInternalScore(
        convertMillipointsToPoints(score.value).toFixed(precision)
      );
      setInputString('');
      // console.log(score.value)
      // console.log(convertMillipointsToPoints(score.value))
      setInputLock(true);
    } else if (['FINAL'].includes(type)) {
      // console.log(scoreTotal)
      setInternalScore(
        convertMillipointsToPoints(scoreTotal).toFixed(precision)
      );
      setInputLock(true);
    } else if (['-'].includes(type)) {
      setInternalScore(defaultScore);
      setInputLock(true);
    } else {
      // needed to reset the indicators / score if changing routines
      setInternalScore(defaultScore);
      setInputLock(false);
    }
  }, [score, setInternalScore, setInputLock, defaultScores, type, routineId]);

  useHotkeys(
    'enter',
    (e) => {
      e.stopPropagation();
      setInputLock(true);
      submitScore();
      // console.log(typeof internalScore);
      // keyHandler(e);
    },
    [inputString, inputKey, setInternalScore, score, internalScore]
  );

  useHotkeys(
    '0,1,2,3,4,5,6,7,8,9',
    (e) => {
      e.stopPropagation();
      // console.log('inside useHotkeys digits');
      // console.log(inputLock);
      // console.log(inputString);
      if (!inputLock) {
        // handleNumberInput(e.key);
        // console.log('handling?');
        handleNumPad(e.key);
      }
    },
    [inputLock, inputString]
  );

  useHotkeys(
    'backspace, delete',
    (e) => {
      if (!inputLock) {
        // handleNumberInput(e.key);
        // console.log('handling backspace / delete');
        handleBackspace();
      }
    },
    [inputLock, inputString]
  );

  useHotkeys(
    'up',
    (e) => {
      e.preventDefault();
      if (!inputLock) {
        handleUp();
      }
    },
    [inputLock, inputString]
  );

  useHotkeys(
    'down',
    (e) => {
      e.preventDefault();
      if (!inputLock) {
        handleDown();
      }
    },
    [inputLock, inputString]
  );

  useHotkeys(
    'c',
    (e) => {
      e.preventDefault();
      if (!inputLock) {
        handleClear();
      }
    },
    [inputLock, inputString]
  );

  useHotkeys(
    'esc',
    (e) => {
      e.preventDefault();
      // console.log("esc")
      // console.log(inputLock)
      if (!inputLock) {
        // console.log("esc")
        handleEsc();
      }
    },
    [inputLock, inputString]
  );

  const changeHandler = (e) => {
    const {
      D,
      D1,
      D2,
      E,
      E1,
      E2,
      E3,
      E4,
      E5,
      E6,
      F,
      SB,
      ND,
      SV,
      SV1,
      SV2,
      SV3,
      SV4,
      SV5,
      SV6,
      J,
      J1,
      J2,
      J3,
      J4,
      J5,
      J6,
      OPEN,
    } = ScoreType;

    switch (type) {
      case D:
      case D1:
      case D2:
      case E:
      case E1:
      case E2:
      case E3:
      case E4:
      case E5:
      case E6:
      case SB:
      case ND:
        changeHandlerE(e);
        break;
      case SV:
      case SV1:
      case SV2:
      case SV3:
      case SV4:
      case SV5:
      case SV6:
      case J:
      case J1:
      case J2:
      case J3:
      case J4:
      case J5:
      case J6:
        changeHandlerJ(e);
        break;
      case OPEN:
      case F:
      default:
        passThroughChangeHandler(e);
        break;
    }
  };

  const changeHandlerE = (e) => {
    const val = e.target.value;
    let targetInput = '';

    // console.log('value: ', val);
    // console.log('input string: ', inputString)
    // console.log('input key: ', inputKey)

    // Edge cases
    // Scroll up / down to ends
    // Copy and paste random
    // Type input without .
    // Extra same character input
    // Delete doesn't change number
    // Start with period, need to auto add 0
    // Cancel out 'e' & '-' character
    // Scroll down to 0 from nothing yields 0.0
    // Delete 1 from 0.1 yields 0, track key & input

    // Inputs:
    // 10, 100, 10.0
    // Copy paste: 0, 0.0, 234
    // Arrows -> 0, -> 10
    // Delete digits of 1.0

    if (val === '') {
      targetInput = '';
    } else if (val === '1.00' || (val === '10' && inputString === '9.9')) {
      targetInput = '100';
    } else if (inputKey === '' && inputString === '' && val === '0') {
      // targetInput = val + '0';
      targetInput = val; // needed to work for 0.
    } else if (
      '0123456789'.includes(val) &&
      Math.abs((Number(val) - Number(inputString)).toFixed(1)) === 0.1
    ) {
      targetInput = val + '0';
    } else if (scoreCheckStrict(val) !== scoreCheckStrict(inputString)) {
      targetInput = val;
    } else {
      targetInput = inputString;
    }

    if (val === '') {
      setInputKey('');
    } // Reset inputKey if value deleted
    setInputString(targetInput);
    const formattedScore = scoreCheckStrict(targetInput);

    // console.log('targetInput: ', targetInput)
    // console.log('scoreCheck: ', formattedScore)
    // console.log('Number(scoreCheck): ', Number(formattedScore));

    if (formattedScore.slice(-2) === '.0' || val[0] === '.') {
      setInternalScore(Number(formattedScore).toFixed(1));
    } else if (formattedScore.slice(-2, -1) === '.') {
      setInternalScore(Number(formattedScore));
    } else {
      setInternalScore(val);
    }
  };

  const changeHandlerJ = (e) => {
    const rawVal = e.target.value;
    const val = rawVal.length > 5 ? rawVal.substring(0, 5) : rawVal;
    let targetInput = '';

    // console.log({rawVal, val});

    if (val === '') {
      targetInput = '';
    } else if (val === '1.00' || (val === '10' && inputString === '9.95')) {
      targetInput = '100';
    } else if (inputKey === '' && inputString === '' && val === '0') {
      // targetInput = val + '0';
      targetInput = val; // needed to work for 0.
    } else if (
      '0123456789'.includes(val) &&
      Math.abs((Number(val) - Number(inputString)).toFixed(1)) === 0.1
    ) {
      targetInput = val + '0';
    } else if (scoreJCheckStrict(val) !== scoreJCheckStrict(inputString)) {
      targetInput = val;
    } else {
      targetInput = inputString;
    }

    if (val === '') {
      setInputKey('');
    } // Reset inputKey if value deleted
    setInputString(targetInput);
    // console.log(targetInput)
    const formattedScore = scoreJCheckStrict(targetInput);

    if (formattedScore.slice(-2) === '.0' || val[0] === '.') {
      // console.log({formattedScore})
      setInternalScore(Number(formattedScore).toFixed(1));
      // console.log("a")
    } else if (formattedScore.slice(-2, -1) === '.') {
      setInternalScore(Number(formattedScore));
      // console.log("b")
    } else if (formattedScore.slice(-3, -2) === '.') {
      setInternalScore(Number(formattedScore).toFixed(2));
      // console.log("c")
    } else {
      setInternalScore(val);
      // console.log("d")
    }
  };

  const keyHandler = (e) => {
    const exclude = [101, 45]; // Prevent chars: e:101, -:45

    if (exclude.includes(e.charCode)) {
      e.preventDefault();
    }

    if (e.charCode === 13 || e.keyCode === 13) {
      // User presses return/enter
      submitScore();
    }

    setInputKey(e.key);

    /*
    console.log('key: ', e.key)
    const code = e.charCode;
    console.log(code)
    */
  };

  function submitScore(e) {
    // console.log(internalScore)
    if (internalScore === '') {
      return;
    }

    // need to do quick scoreValid check before submitting, override if OPEN scoring
    if (type !== ScoreType.OPEN && !scoreValid(internalScore)) {
      dispatch(
        alertActions.error('Invalid score. Please modify and re-enter.')
      );
      return;
    }

    const input = {
      value: convertPointsToMillipoints(internalScore),
      routineId,
      sessionId,
      type,
      userId,
    };

    // console.log('submitScore');
    // console.log({input})

    createScore(apolloClient, input).catch(handleCreateScoreErrors);
  }

  const passThroughChangeHandler = (e) => {
    setInternalScore(Number(e.target.value));
  };

  const handleNumPad = (val) => {
    const newInput = inputString + val;
    // const newInput = val;
    // console.log('inside handleNumPad');
    // console.log(val);
    // console.log(inputString);
    // console.log(newInput);
    FocusInput();
    //changeHandler({ target: { value: Number(newInput) } });
    changeHandler({ target: { value: String(newInput) } });
  };

  const handleBackspace = () => {
    const newInput = inputString
      ? inputString.replace('.', '').slice(0, -1)
      : '';
    // const newInput = val;
    // console.log({inputString, newInput});
    FocusInput();
    changeHandler({ target: { value: String(newInput) } });
  };

  const handleUp = () => {
    if (inputRef.current.value === '--') return;
    const increment = ['ND'].includes(type) ? 0.1 : minimumDeduction;
    const nextVal = parseFloat(inputRef.current.value) + increment;
    const value = (nextVal > 10 ? 10 : nextVal).toFixed(2);
    // console.log({nextVal, value, curval: inputRef.current.value})
    changeHandler({
      target: { value },
    });
  };

  const handleDown = () => {
    if (inputRef.current.value === '--') return;
    const increment = ['ND'].includes(type) ? 0.1 : minimumDeduction;
    const nextVal = parseFloat(inputRef.current.value) - increment;
    const value = (nextVal < 0 ? 0 : nextVal).toFixed(2);
    changeHandler({
      target: { value },
    });
  };

  const handleClear = () => {
    setInputLock(false);
    setInputString('');
    setInternalScore(defaultScore);
  };

  const handleEdit = () => {
    setInputLock(false);
    setInputString('');
  };

  const handleEsc = () => {
    setInputLock(true);
    setInputString('');
  };

  // console.log({ inputLock, subScoresComplete });

  const BigButton = () => {
    const clearButton = (
      <Button
        variant="secondary"
        // style={{width: '30%'}}
        className="vCenter valid clear"
        // disabled={!inputLock || !subScoresComplete}
        onClick={handleClear}
      >
        <span>CLEAR</span>
      </Button>
    );

    const editButton = (
      <Button
        variant="warning"
        className="vCenter valid"
        disabled={
          !inputLock || (!subScoresComplete && type === 'FINAL') || type === '-'
        } // || !subScoresComplete}
        onClick={handleEdit}
      >
        <span>EDIT</span>
      </Button>
    );

    const componentSubmit = (
      <Button
        variant="success"
        // style={{width: '66%'}}
        className={[
          'vCenter',
          'save',
          (internalScore !== '' ? scoreValid(internalScore) : false)
            ? 'valid'
            : null,
        ].join(' ')}
        disabled={internalScore !== '' ? !scoreValid(internalScore) : true}
        onClick={submitScore}
      >
        <span>SAVE</span>
      </Button>
    );

    const finalSubmit = (
      <EvaluatorSlabFinalize
        scores={routine?.scores?.items || []}
        team={team}
        routineData={routine}
      />
    );

    let selectedButton = null;

    if (type === 'FINAL' && routine /*&& subScoresComplete*/) {
      selectedButton = finalSubmit;
    } else if (inputLock) {
      selectedButton = editButton;
    } else {
      selectedButton = (
        <ButtonGroup>
          {componentSubmit}
          {clearButton}
        </ButtonGroup>
      );
    }

    return selectedButton;
  };

  return (
    <div className="evaluatorSlabInputSlab">
      <Row className="scoreLine">
        {/*<div className="inputType">{scoreTypeToDisplay(type)}</div>*/}
        {/*<Col xs={3} className="vCenter">*/}
        {/*{inputLock ? (
            <Button
              variant="light"
              className="vCenter valid"
              onClick={() => {
                setInputLock(false);
                setInputString('');
              }}
            >
              {lockIcon}
            </Button>
          ) : (
            <Button
              variant="light"
              className={[
                'vCenter',
                'submitCheck',
                (internalScore !== '' ? scoreValid(internalScore) : false)
                  ? 'valid'
                  : null,
              ].join(' ')}
              disabled={
                internalScore !== '' ? !scoreValid(internalScore) : true
              }
              onClick={submitScore}
            >
              {checkIcon}
            </Button>
          )}*/}
        {/*</Col>*/}
        <Col xs={12} className="vCenter">
          {InputComponent(
            inputRef,
            internalScore,
            type,
            disableInputChecking ? passThroughChangeHandler : changeHandler,
            keyHandler,
            inputLock,
            precision,
            subScoresComplete
          )}
          <div onClick={FocusInput} className="inputLead vCenter">
            {type || '-'}
          </div>
        </Col>
      </Row>
      <Row className="numberPad">
        <Col xs={12} className="vCenter numberRow">
          <ButtonGroup style={{ textAlign: 'middle' }} className="numberGroup">
            <Button
              variant="light"
              className="vCenter numButton"
              onClick={() => handleNumPad(1)}
              disabled={inputLock}
            >
              <span style={{ width: '100%', textAlign: 'center' }}>1</span>
            </Button>
            <Button
              variant="light"
              className="vCenter numButton"
              onClick={() => handleNumPad(2)}
              disabled={inputLock}
            >
              <span style={{ width: '100%', textAlign: 'center' }}>2</span>
            </Button>
            <Button
              variant="light"
              className="vCenter numButton"
              onClick={() => handleNumPad(3)}
              disabled={inputLock}
            >
              <span style={{ width: '100%', textAlign: 'center' }}>3</span>
            </Button>
          </ButtonGroup>
        </Col>
        <Col xs={12} className="vCenter numberRow">
          <ButtonGroup style={{ textAlign: 'middle' }} className="numberGroup">
            <Button
              variant="light"
              className="vCenter numButton"
              onClick={() => handleNumPad(4)}
              disabled={inputLock}
            >
              <span style={{ width: '100%', textAlign: 'center' }}>4</span>
            </Button>
            <Button
              variant="light"
              className="vCenter numButton"
              onClick={() => handleNumPad(5)}
              disabled={inputLock}
            >
              <span style={{ width: '100%', textAlign: 'center' }}>5</span>
            </Button>
            <Button
              variant="light"
              className="vCenter numButton"
              onClick={() => handleNumPad(6)}
              disabled={inputLock}
            >
              <span style={{ width: '100%', textAlign: 'center' }}>6</span>
            </Button>
          </ButtonGroup>
        </Col>
        <Col xs={12} className="vCenter numberRow">
          <ButtonGroup style={{ textAlign: 'middle' }} className="numberGroup">
            <Button
              variant="light"
              className="vCenter numButton"
              onClick={() => handleNumPad(7)}
              disabled={inputLock}
            >
              <span style={{ width: '100%', textAlign: 'center' }}>7</span>
            </Button>
            <Button
              variant="light"
              className="vCenter numButton"
              onClick={() => handleNumPad(8)}
              disabled={inputLock}
            >
              <span style={{ width: '100%', textAlign: 'center' }}>8</span>
            </Button>
            <Button
              variant="light"
              className="vCenter numButton"
              onClick={() => handleNumPad(9)}
              disabled={inputLock}
            >
              <span style={{ width: '100%', textAlign: 'center' }}>9</span>
            </Button>
          </ButtonGroup>
        </Col>
        <Col xs={12} className="vCenter numberRow">
          <ButtonGroup style={{ textAlign: 'middle' }} className="numberGroup">
            <Button
              variant="light"
              className="vCenter numButton"
              onClick={handleDown}
              disabled={inputLock}
            >
              {caretDownIcon}
            </Button>
            <Button
              variant="light"
              className="vCenter numButton"
              onClick={() => handleNumPad(0)}
              disabled={inputLock}
            >
              <span style={{ width: '100%', textAlign: 'center' }}>0</span>
            </Button>
            <Button
              variant="light"
              className="vCenter numButton"
              onClick={handleUp}
              disabled={inputLock}
            >
              {caretUpIcon}
            </Button>
          </ButtonGroup>
        </Col>
      </Row>
      <Row className="submitLine">
        <Col className="vCenter">
          <BigButton />
        </Col>
      </Row>
    </div>
  );
}
