import React from 'react';
import { Modal, Button, Row } from 'react-bootstrap';
import { SessionType } from '../../models/index';
import SelectGender from './selectgender';
import './create.css';

function Create(props) {
  const { setSetup, setup, setGender, gender } = props;
  const { DUAL, SOLO, MULTI } = SessionType;

  const createSessionBody = () => {
    return (
      <Row>
        <Row className="vCenter">
          <div className="createButtonBackground">
            <Button
              variant="light"
              className={[
                'createSoloButton',
                setup === SOLO ? 'active' : null,
              ].join(' ')}
              onClick={() => setSetup(SOLO)}
            >
              <span className="vBlue">S</span>
              <span className="vGray">olo</span>
            </Button>
            <Button
              variant="light"
              className={[
                'createDualButton',
                setup === DUAL ? 'active' : null,
              ].join(' ')}
              onClick={() => setSetup(DUAL)}
            >
              <span className="vBlue">D</span>
              <span className="vGray">ual</span>
            </Button>
          </div>
        </Row>
        <Row className="vCenter">
          <div className="createButtonBackground">
            <Button
              variant="light"
              className={[
                'createMultiButton',
                setup === MULTI ? 'active' : null,
              ].join(' ')}
              onClick={() => setSetup(MULTI)}
            >
              <span className="vBlue">M</span>
              <span className="vGray">ulti</span>
            </Button>
          </div>
        </Row>
      </Row>
    );
  };

  const headerMsg = () => {
    const virtius = (
      <>
        <span className="vBlue">V</span>
        <span className="vGray">irtius</span>
      </>
    );

    return (
      <>
        <span>Create new&nbsp;</span>
        {virtius}
        <span>&nbsp;session</span>
      </>
    );
  };

  const setupFooter = () => {
    const selectSession = (
      <span>Select session type (Solo, Dual, Multi). </span>
    );

    const backToSelectSession = (
      <>
        <span>
          Select gender for {setup} session.
          <br />
          Back to
        </span>
        <button
          type="button"
          className="linkButton"
          disabled={!setup}
          onClick={() => setSetup(null)}
        >
          select session.
        </button>
      </>
    );

    return (
      <Row>
        <div className="modalLink">
          {setup ? backToSelectSession : selectSession}
        </div>
      </Row>
    );
  };

  return (
    <>
      <Modal.Header className="createOrJoinHeader">{headerMsg()}</Modal.Header>
      <Modal.Body className="createOrJoinForm">
        {setup ? (
          <SelectGender gender={gender} setGender={setGender} />
        ) : (
          createSessionBody()
        )}
      </Modal.Body>
      <Modal.Footer className="createOrJoinForm">{setupFooter()}</Modal.Footer>
    </>
  );
}

export default Create;
