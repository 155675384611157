import React from 'react';
import { Row, Form } from 'react-bootstrap';
import { useSelector } from 'react-redux';

export const LeagueTeams = React.forwardRef((props, ref) => {
  const { leagueId } = props;
  const { leagues } = useSelector((state) => state.admin);
  const league = leagues.byId[leagueId];

  const emptyList = () => {
    return (
      <Row
        style={{ minHeight: '40vh', textAlign: 'center' }}
        className="vCenter"
      >
        <span>No teams assigned.</span>
      </Row>
    );
  };

  const setupBody = () => {
    if (league.teams.items.length === 0) {
      return emptyList();
    } else {
      return null;
    }
  };

  return (
    <Form onSubmit={null} className="teamCoaches">
      {setupBody()}
    </Form>
  );
});
