import { useEffect } from 'react';
import { Container, Row, Col, Spinner } from 'react-bootstrap';
import { heroLogo } from '../helpers/logos';

function FullscreenLoading(props) {
  const { message, isDarkMode } = props;
  const defaultMessage = 'Loading...';

  useEffect(() => {
    document.documentElement.setAttribute(
      'data-theme',
      isDarkMode ? 'dark' : null
    );
  }, [isDarkMode]);

  return (
    <div className="main">
      <Container>
        <Row style={{ height: '40vh' }} />
        <Row style={{ height: '15vh' }}>
          <Col className="center" style={{ display: 'flex', flexWrap: 'wrap' }}>
            <div className="heroLogo"> {heroLogo} </div>
            <div className="heroMessage">
              <span>{message ?? defaultMessage}&nbsp;</span>
              <Spinner
                variant="secondary"
                role="status"
                animation="border"
                size="sm"
              />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default FullscreenLoading;
