import React, { useEffect, useState, useRef } from 'react';
import { Modal, Tabs, Tab, Button, Row, Spinner, Alert } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { alertActions } from '../../redux/_actions';
import { LeagueProfile } from './leagueprofile';
import { LeagueTeams } from './leagueteams';

const status = {
  CREATING: 'CREATING',
  SAVING: 'SAVING',
  EDITING: 'EDITING',
  LOADING: 'LOADING',
  STANDBY: 'STANDBY',
};

function LeagueDetail(props) {
  const { back, leagueId } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [key, setKey] = useState('profile');
  const [mode, setMode] = useState(status.STANDBY);
  const { alert } = useSelector((state) => state);
  const { leagues } = useSelector((state) => state.admin);
  const dispatch = useDispatch();
  const tabRef1 = useRef();
  const tabRef2 = useRef();

  useEffect(() => {
    if (!alert.clear) {
      setIsLoading(false);
      setMode(status.STANDBY);
    }
  }, [alert.clear]);

  useEffect(() => {
    dispatch(alertActions.clear());
  }, [dispatch]);

  //console.log(mode)
  //console.log(isEditing)

  const handleClick = (e) => {
    switch (key) {
      case 'profile':
        if (isEditing) {
          setMode(status.SAVING);
          save(tabRef1);
          setIsEditing(false);
        } else {
          setMode(status.EDITING);
          setIsEditing(true);
        }
        break;
      case 'teams':
        if (isEditing) {
          setMode(status.SAVING);
          save(tabRef2);
        } else {
          if (tabRef2.current.mode === 'LIST') {
            setMode(status.CREATING);
            setIsLoading(true);
            create(tabRef2);
            setIsEditing(true);
          }
          if (tabRef2.current.mode === 'DETAIL') {
            setMode(status.EDITING);
            setIsEditing(true);
            edit(tabRef2);
          }
        }
        break;
      default:
        break;
    }
  };

  const changeTab = (k) => {
    setMode(status.STANDBY);
    //setIsEditing(false);
    setKey(k);
  };

  const buttonText = () => {
    if (isLoading) {
      return (
        <>
          {mode === status.CREATING ? 'Creating... ' : 'Saving ... '}
          <Spinner
            variant="primary"
            role="status"
            animation="border"
            size="sm"
          />
        </>
      );
    }

    if (isEditing) {
      return 'Save';
    }

    switch (key) {
      case 'profile':
        return 'Edit';
      case 'teams':
        return 'Add';
      default:
        return 'Create';
    }
  };

  const create = (ref) => {
    ref && ref.current && ref.current.create();
  };

  const save = (ref) => {
    ref && ref.current && ref.current.submit();
  };

  const edit = (ref) => {
    ref && ref.current && ref.current.edit();
  };

  /*
  const tabback = (ref) => {
    setMode(status.STANDBY);
    setIsEditing(false);
    ref && ref.current && ref.current.back();
  };
  */

  const reset = () => {
    setMode(status.STANDBY);
    setIsEditing(false);
    setIsLoading(false);
  };

  //console.log(buttonText())
  //console.log(key)

  const body = () => {
    return (
      <>
        {!alert.clear ? (
          <Row style={{ margin: '0 0 1rem 0' }}>
            <Alert
              dismissible
              onClose={() => dispatch(alertActions.clear())}
              variant={alert.type === 'alert-danger' ? 'danger' : 'success'}
              style={{ textAlign: 'center', width: '100%' }}
            >
              {alert.message}
            </Alert>
          </Row>
        ) : null}
        <Row>
          <Tabs
            id="leagueDetailTabs"
            transition={false}
            activeKey={key}
            onSelect={(k) => changeTab(k)}
            disabled={isLoading}
          >
            <Tab eventKey="profile" title="Profile">
              <LeagueProfile
                ref={tabRef1}
                leagueId={leagueId}
                isEditing={isEditing}
                isSaving={isLoading}
                reset={reset}
                setIsLoading={setIsLoading}
              />
            </Tab>
            <Tab eventKey="teams" title="Teams">
              <LeagueTeams
                ref={tabRef2}
                leagueId={leagueId}
                isEditing={isEditing}
                isSaving={isLoading}
                reset={reset}
                setIsLoading={setIsLoading}
                setIsEditing={setIsEditing}
              />
            </Tab>
          </Tabs>
        </Row>
      </>
    );
  };

  const returnButton = () => {
    return (
      <button
        type="button"
        className="linkButton"
        disabled={isLoading}
        onClick={back}
      >
        League list
      </button>
    );
  };

  const footer = () => {
    return (
      <>
        <Row className="vCenter" style={{ margin: '0 auto' }}>
          <Button
            variant="outline-primary"
            className="formButton"
            onClick={handleClick}
            disabled={isLoading}
          >
            {buttonText()}
          </Button>
        </Row>
        <Row>
          <div className="modalLink">
            <span>Back to</span>
            {returnButton()}
          </div>
        </Row>
      </>
    );
  };

  const headerMsg = () => {
    return (
      <>
        <span>League Admin:&nbsp;</span>
        <span style={{ fontWeight: '400' }}>{leagues.byId[leagueId].name}</span>
      </>
    );
  };

  const modal = () => {
    return (
      <>
        <Modal.Header className="adminHeader">{headerMsg()}</Modal.Header>
        <Modal.Body className="adminForm">{body()}</Modal.Body>
        <Modal.Footer className="adminForm">{footer()}</Modal.Footer>
      </>
    );
  };

  return modal();
}

export default LeagueDetail;
