import React, { useEffect, useState, useRef } from 'react';
import {
  Modal,
  Button,
  Row,
  Alert,
  ProgressBar,
  InputGroup,
  FormControl,
} from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { alertActions } from '../../redux/_actions';
import ReactPlayer from 'react-player';
import { Loading } from './loading';
import { VideoMetricsYouTube } from './videometrics';
import { POLLING_INTERVAL } from '../../utilities/constants';
import { useInterval } from '../../utilities/hooks';
import { useYouTubeStats } from '../../utilities/video';
import { youtubeIcon } from './icons';
import './linkvideopreview.css';

function LinkVideoPreview(props) {
  const {
    clear,
    name,
    round,
    position,
    clip,
    handleSaveLink,
    progress,
  } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [stats, setStats] = useState(null);
  const [link, setLink] = useState(clip?.originURL ?? '');
  const [url, setUrl] = useState(clip?.originURL ?? '');
  const alert = useSelector((state) => state.alert);
  const dispatch = useDispatch();
  const playerRef = useRef();
  const ytStats = useYouTubeStats();

  useEffect(() => {
    document.getElementById('link-input').focus();
  }, []);

  useEffect(() => {
    if (!alert.clear) {
      setIsLoading(false);
    }
  }, [alert.clear]);

  useEffect(() => {
    dispatch(alertActions.clear());
  }, [dispatch]);

  const close = () => {
    if (!alert.clear) {
      dispatch(alertActions.clear());
    }
    clear();
  };

  const updateStats = () => {
    const player = playerRef?.current?.getInternalPlayer();
    const newStats = ytStats({ player });
    setStats(newStats);
  };

  useInterval(
    async () => {
      updateStats();
    },
    POLLING_INTERVAL.DOUBLE,
    !playerRef?.getInternalPlayer && !stats?.duration && url
  );

  const handleLoadLink = () => {
    setStats(null);
    setUrl(link);
  };

  const handleLinkInput = (e) => {
    e.preventDefault();
    if (isYoutubeLink(e.target.value)) {
      setLink(e.target.value);
    } else {
      setLink(null);
    }
  };

  const handleEnter = (e) => {
    if (e.key === 'Enter' && link && link !== url) {
      handleLoadLink();
    }
  };

  const isYoutubeLink = (input) => {
    // This still needs work to prevent some other URLs from getting through
    const regexURL = /^(http(s)?:\/\/)?((w){3}.)?youtu(be|.be)?(\.com)?\/.+/;
    const regexID = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    const match = input.match(regexID);
    if (match && match[7].length === 11 && input.match(regexURL)) {
      return true;
    } else {
      return false;
    }
  };

  const noVideoLoaded = (
    <span className="noYTVideo">{youtubeIcon} Preview</span>
  );

  const showPreview = () => {
    return (
      <div className="videoWrapper">
        <ReactPlayer
          ref={playerRef}
          className="videoPlayer"
          url={url}
          controls={true}
          width="100%"
          height="100%"
          muted={false}
          progressInterval={1000}
          autoPlay={false}
        />
        <VideoMetricsYouTube stats={stats} defaultOn={false} />
        {!url ? noVideoLoaded : null}
      </div>
    );
  };

  const linkInput = () => {
    return (
      <InputGroup className="linkInput">
        <FormControl
          placeholder="Enter YouTube URL"
          aria-label="Youtube Link"
          aria-describedby="basic-addon2"
          onChange={handleLinkInput}
          onKeyPress={handleEnter}
          id="link-input"
          value={link}
        />
        <Button
          variant="secondary"
          disabled={!link || link === url}
          onClick={handleLoadLink}
        >
          {link === url ? 'Loaded' : 'Load'}
        </Button>
      </InputGroup>
    );
  };

  const body = () => {
    return (
      <>
        {!alert.clear ? (
          <Row style={{ padding: '0 15px', margin: '1rem -15px' }}>
            <Alert
              dismissible
              onClose={() => dispatch(alertActions.clear())}
              variant={alert.type === 'alert-danger' ? 'danger' : 'success'}
              style={{ textAlign: 'center', width: '100%' }}
            >
              {alert.message}
            </Alert>
          </Row>
        ) : null}
        {isLoading ? <Loading /> : showPreview()}
        {linkInput()}
      </>
    );
  };

  const closeButton = (
    <Button variant="outline-secondary" className="closeButton" onClick={close}>
      Close
    </Button>
  );

  const linkButton = (
    <Button
      variant="outline-success"
      className="linkButton"
      onClick={() => handleSaveLink(url, stats)}
      disabled={
        progress > 0 ||
        link !== url ||
        url === clip?.originURL ||
        !stats?.duration
      }
    >
      {progress > 0 ? (progress === 100 ? 'Success!' : 'Saving...') : 'Save'}
    </Button>
  );

  const footer = () => {
    return (
      <>
        {progress ? <ProgressBar now={progress} variant="success" /> : null}
        <Row className="vCenter" style={{ margin: '0 auto' }}>
          {progress === null ? closeButton : linkButton}
        </Row>
        <Row />
      </>
    );
  };

  const headerMsg = () => {
    return (
      <>
        <span>
          YouTube Video Preview (R{round} » {position})
        </span>
        <span className="headerName">{name}</span>
      </>
    );
  };

  return (
    <>
      <Modal.Header className="linkVideoPreviewHeader">
        {headerMsg()}
      </Modal.Header>
      <Modal.Body className="linkVideoPreviewBody">{body()}</Modal.Body>
      <Modal.Footer className="linkVideoPreviewFooter">{footer()}</Modal.Footer>
    </>
  );
}

export default LinkVideoPreview;
