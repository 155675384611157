import { useState, useImperativeHandle, forwardRef, useEffect } from 'react';
import {
  Button,
  Row,
  Col,
  Form,
  ToggleButtonGroup,
  ToggleButton,
} from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { adminActions, alertActions } from '../../redux/_actions';
import * as Yup from 'yup';
import {
  sessionType,
  genderType,
  teamScoreType,
  judgePanelType,
} from '../../redux/_constants';
import { SessionHostType } from '../../models';
import * as Defaults from '../../utilities/constants';
import {
  strApparatusToArray,
  arrayToStrApparatus,
} from '../../utilities/conversions';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import TeamSelector from '../setup/teamselector';
import { dataUtilities } from '../../utilities/data';
import {
  prettyTeamScoring,
  prettyJudgePanel,
  prettyHostType,
} from '../../utilities/scoring';
import TeamTypeahead from '../helpers/teamtypeahead';

export const SessionSetup = forwardRef((props, ref) => {
  const {
    sessionId,
    isEditing,
    isSaving,
    reset,
    setIsLoading,
    footerToggle,
  } = props;
  const { sessions, sessionTeams } = useSelector((state) => state.admin);
  const session = sessions.byId[sessionId];
  const setup = session?.type ? session.type : null;
  const [teamSelector, setTeamSelector] = useState(null);
  const dispatch = useDispatch();
  const adminTeams = useSelector((state) => state.admin.teams);
  const [teams, setTeams] = useState(adminTeams);
  const { wApparatusAbbv, mApparatusAbbv, MEDIAURL } = Defaults;
  const { /*MALE,*/ FEMALE } = genderType;
  const { SOLO, MULTI } = sessionType;
  const gender = session.gender;
  const abbv = gender === FEMALE ? wApparatusAbbv : mApparatusAbbv;

  useEffect(() => {
    setTeams(adminTeams);
  }, [adminTeams]);

  const validationSchema = Yup.object().shape({
    judging: Yup.boolean(),
    judgingRequired: Yup.boolean(),
    now: Yup.boolean(),
    name: Yup.string(),
    judgeCount: Yup.number(),
    teamA: Yup.string().nullable(),
    teamB: Yup.string().nullable(),
    teamC: Yup.string().nullable(),
    teamD: Yup.string().nullable(),
    teamE: Yup.string().nullable(),
    teamF: Yup.string().nullable(),
    teamG: Yup.string().nullable(),
    teamH: Yup.string().nullable(),
    lineup: Yup.boolean(),
    competition: Yup.boolean(),
    apparatus: Yup.string(),
    startAt: Yup.string(),
    teamScoring: Yup.string().required('Required'),
    alternating: Yup.boolean(),
    judgePanel: Yup.string().nullable(),
    hostId: Yup.string().nullable(),
    hostType: Yup.string().nullable(),
    rtnId: Yup.string().nullable(),
  });

  const sortedSessionTeams = session.sessionTeams.items
    .map((id) => sessionTeams.byId[id])
    .filter((st) => !st._deleted)
    .sort((a, b) => {
      return a.order - b.order;
    });

  const defaultTeams = {};
  sortedSessionTeams.forEach((st, i) => {
    if (!st._deleted) {
      defaultTeams[`team${String.fromCharCode(65 + i)}`] = st.teamId;
    }
  });

  const getDefault = () => {
    const result = {
      id: session.id,
      judging: session.judging,
      judgingRequired: session.judgingRequired,
      name: session.name,
      teamA: '',
      teamB: '',
      teamC: '',
      teamD: '',
      teamE: '',
      teamF: '',
      teamG: '',
      teamH: '',
      ...defaultTeams,
      status: session.status,
      competition: session.competition,
      apparatus: strApparatusToArray(session.apparatus),
      gender: session.gender,
      sessionKey: session.sessionKey,
      startAt: new Date(session.startAt), // have to convert to Date
      endAt: session.endAt ? new Date(session.endAt) : '',
      type: session.type,
      now: false,
      lineup: false,
      teamScoring: session.teamScoring,
      alternating: session.alternating ?? false,
      judgePanel: session.judgePanel,
      hostId: session.hostId,
      hostType: session.hostType,
      rtnId: session.rtnId || '',
    };

    return result;
  };

  const {
    setFieldValue,
    handleSubmit,
    handleChange,
    values /* errors */,
  } = useFormik({
    initialValues: getDefault(),
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit(values) {
      const teamChange = dataUtilities.checkSessionTeamChange(
        values,
        session,
        sessionTeams
      );

      // Check to see if there are any changes
      if (
        values.name === session.name &&
        values.judging === session.judging &&
        values.judgingRequired === session.judgingRequired &&
        values.status === session.status &&
        values.competition === session.competition &&
        arrayToStrApparatus(values.apparatus) === session.apparatus &&
        values.gender === session.gender &&
        values.startAt.toJSON() === session.startAt &&
        (values.endAt ? values.endAt.toJSON() : null) === session.endAt &&
        values.type === session.type &&
        values.teamScoring === session.teamScoring &&
        values.alternating === session.alternating &&
        values.judgePanel === session.judgePanel &&
        values.teamA ===
          (sortedSessionTeams.length > 0 ? sortedSessionTeams[0].teamId : '') &&
        values.teamB ===
          (sortedSessionTeams.length > 1 ? sortedSessionTeams[1].teamId : '') &&
        values.teamC ===
          (sortedSessionTeams.length > 2 ? sortedSessionTeams[2].teamId : '') &&
        values.teamD ===
          (sortedSessionTeams.length > 3 ? sortedSessionTeams[3].teamId : '') &&
        values.teamE ===
          (sortedSessionTeams.length > 4 ? sortedSessionTeams[4].teamId : '') &&
        values.teamF ===
          (sortedSessionTeams.length > 5 ? sortedSessionTeams[5].teamId : '') &&
        values.teamG ===
          (sortedSessionTeams.length > 6 ? sortedSessionTeams[6].teamId : '') &&
        values.teamH ===
          (sortedSessionTeams.length > 7 ? sortedSessionTeams[7].teamId : '') &&
        teamChange.updates.length === 0 &&
        teamChange.creates.length === 0 &&
        values.hostId === session.hostId &&
        values.hostType === session.hostType &&
        ((values.rtnId === '' && session.rtnId === null) ||
          values.rtnId === session.rtnId)
      ) {
        dispatch(alertActions.success('No changes made.'));
        reset();
        return;
      }

      const {
        teamA,
        teamB,
        teamC,
        teamD,
        teamE,
        teamF,
        teamG,
        teamH,
        now,
        lineup,
        ...rest
      } = values;

      const input = {
        ...rest,
        apparatus: arrayToStrApparatus(values.apparatus),
        endAt: values.endAt ? values.endAt : null,
        _version: session._version,
      };

      setIsLoading(true);
      dispatch(alertActions.clear());
      dispatch(adminActions.editSession(input, teamChange));
    },
  });

  // console.log(errors)
  // console.log(values);

  useImperativeHandle(ref, () => ({
    submit() {
      handleSubmit();
    },
  }));

  const setTeam = (side, team = null) => {
    if (side !== null) {
      setFieldValue(`team${side}`, team ? team.id : '');

      // Upgrade to Dual format from Solo
      if (side === 'B' && team && values.type === sessionType.SOLO) {
        setFieldValue('type', sessionType.DUAL);
      }
    }
    setTeamSelector(null);
    footerToggle(false);
  };

  const getLogo = (side) => {
    const index = side.charCodeAt(0) - 65; // convert A to 0
    const sessionTeam = sortedSessionTeams.filter((e) => {
      return e.order === index;
    });
    const teamId = sessionTeam.length && sessionTeam.teamId;

    const selectedTeamId = values[`team${side}`];
    let logo = '';

    if (selectedTeamId) {
      logo =
        teams.byId[selectedTeamId]?.logos &&
        JSON.parse(teams.byId[selectedTeamId]?.logos).metaData.filename;
    } else if (teamId) {
      logo =
        teams.byId[teamId]?.logos &&
        JSON.parse(teams.byId[teamId]?.logos).metaData.filename;
    }

    return logo ? (
      <img
        className="img-thumbnail"
        src={`${MEDIAURL}${logo}`}
        alt="Team Logo"
      />
    ) : (
      '?'
    );
  };

  const goTeamSelector = (side) => {
    setTeamSelector(side);
    footerToggle(true);
  };

  const teamWidget = (side, logo) => {
    return (
      <Col>
        <label>Team {side}</label>
        <Button
          variant={logo === '?' ? 'light' : 'outline-light'}
          disabled={(setup === SOLO && side === 'B') || isSaving || !isEditing}
          className="teamSelect"
          onClick={() => goTeamSelector(side)}
          style={{ fontSize: logo === '?' ? '5rem' : null }}
        >
          {logo}
        </Button>
      </Col>
    );
  };

  const setupBody = () => {
    const logos = {
      A: getLogo('A'),
      B: getLogo('B'),
      C: getLogo('C'),
      D: getLogo('D'),
      E: getLogo('E'),
      F: getLogo('F'),
      G: getLogo('G'),
      H: getLogo('H'),
    };

    return (
      <>
        <Row>
          <div
            className={[
              'setupFormLine',
              'teamSelection',
              setup === MULTI ? `multi ${gender.toLowerCase()}` : null,
            ].join(' ')}
          >
            {teamWidget('A', logos['A'])}
            {setup !== MULTI ? (
              <Col className="vsCol">
                <label>&nbsp;</label>
                <div
                  className={['vs', setup === SOLO ? 'disabled' : null].join(
                    ' '
                  )}
                >
                  <span className="vBlue">V</span>
                  <span className="vGray">S</span>
                </div>
              </Col>
            ) : null}
            {teamWidget('B', logos['B'])}
            {setup === MULTI ? teamWidget('C', logos['C']) : null}
            {setup === MULTI ? teamWidget('D', logos['D']) : null}
            {setup === MULTI // && gender === MALE
              ? teamWidget('E', logos['E'])
              : null}
            {setup === MULTI // && gender === MALE
              ? teamWidget('F', logos['F'])
              : null}
            {setup === MULTI // && gender === MALE
              ? teamWidget('G', logos['G'])
              : null}
            {setup === MULTI // && gender === MALE
              ? teamWidget('H', logos['H'])
              : null}
          </div>
        </Row>
        <Row>
          <div className="setupFormLine">
            <Col>
              <label>When</label>
              <Form.Check
                type="switch"
                label="Now"
                id="now"
                checked={values.now}
                onChange={handleChange}
                disabled={isSaving || !isEditing}
              />
            </Col>
            <Col>
              <label>Date / Time</label>
              <DatePicker
                name="startAt"
                disabled={isSaving || !isEditing}
                selected={values.startAt}
                onChange={(value) => setFieldValue('startAt', value)}
                showTimeSelect
                //minDate={new Date()}  Allow backdating in admin
                dateFormat="MM/dd/yyyy h:mm aa"
                popperPlacement="auto"
              />
            </Col>
          </div>
        </Row>
        <Row>
          <div className="setupFormLine">
            <Col>
              <label>Format</label>
              <Form.Check
                type="switch"
                label="Competition"
                id="competition"
                checked={values.competition}
                onChange={handleChange}
                disabled={isSaving || !isEditing}
              />
            </Col>
            <Col>
              <label>Scoring</label>
              <Form.Control
                as="select"
                label="Required"
                id="teamScoring"
                value={values.teamScoring ?? undefined}
                onChange={(e) =>
                  setFieldValue(
                    'teamScoring',
                    teamScoreType[
                      Object.keys(teamScoreType)[e.target.selectedIndex - 1]
                    ]
                  )
                }
                disabled={isSaving || !isEditing}
              >
                <option value="Select..." disabled={values.teamScoring} hidden>
                  Select...
                </option>
                {Object.values(teamScoreType).map((type, i) => {
                  return (
                    <option key={i} value={type}>
                      {prettyTeamScoring(type)}
                    </option>
                  );
                })}
              </Form.Control>
            </Col>
          </div>
        </Row>
        <Row>
          <div className="setupFormLine">
            <Col>
              <label>Apparatus</label>
              <ToggleButtonGroup
                name="apparatus"
                className="apparatusToggle"
                type="checkbox"
                value={values.apparatus}
                onChange={(value) => {
                  if (value.length > 0) {
                    setFieldValue('apparatus', value);
                  }
                }}
              >
                {abbv.map((a, i) => {
                  return (
                    <ToggleButton
                      variant="outline-primary"
                      className="vCenter"
                      value={i}
                      key={i}
                      disabled={isSaving || !isEditing}
                    >
                      {a}
                    </ToggleButton>
                  );
                })}
              </ToggleButtonGroup>
            </Col>
            <Col>
              <label>Meet ID</label>
              <Form.Control
                plaintext
                placeholder="--"
                as="input"
                id="rtnId"
                name="rtnId"
                type="text"
                autoComplete="off"
                onChange={handleChange}
                value={values.rtnId}
                disabled={isSaving || !isEditing}
              />
            </Col>
          </div>
        </Row>
        <Row>
          <div className="setupFormLine">
            <Col>
              <label>Alternating</label>
              <Form.Check
                type="switch"
                label="Enabled"
                id="alternating"
                onChange={handleChange}
                checked={values.alternating}
                disabled={isSaving || !isEditing}
              />
            </Col>
            <Col>
              <label>Judge Panel</label>
              <Form.Control
                as="select"
                label="Required"
                id="judgePanel"
                value={values.judgePanel ?? undefined}
                onChange={(e) =>
                  setFieldValue(
                    'judgePanel',
                    judgePanelType[
                      Object.keys(judgePanelType)[e.target.selectedIndex - 1]
                    ]
                  )
                }
                disabled={isSaving || !isEditing}
              >
                <option value="Select..." disabled={values.judgePanel} hidden>
                  Select...
                </option>
                {Object.values(judgePanelType).map((type, i) => {
                  return (
                    <option key={i} value={type}>
                      {prettyJudgePanel(type)}
                    </option>
                  );
                })}
              </Form.Control>
            </Col>
          </div>
        </Row>
        <Row>
          <div className="setupFormLine">
            <Col>
              <label>Host Type</label>
              <div key="inline-radio">
                {[]
                  .concat(Object.keys(SessionHostType).sort())
                  .map((type, i) => {
                    return (
                      <Form.Check
                        inline
                        custom
                        label={prettyHostType(type)}
                        name="group1"
                        type="radio"
                        id={type}
                        key={type}
                        disabled={isSaving || !isEditing}
                        onChange={(e) => {
                          setFieldValue('hostType', type);
                        }}
                        checked={values.hostType === type}
                      />
                    );
                  })}
              </div>
            </Col>
            <Col>
              <label>Host</label>
              <TeamTypeahead
                gender={gender}
                clearOnSelect={false}
                onSelected={(team) => {
                  setFieldValue('hostId', team.id);
                  const result = new Promise((res) => {
                    res();
                  });
                  return result;
                }}
                prompt={
                  values.hostType === SessionHostType.TEAM
                    ? values.hostId === ''
                      ? 'Search for team...'
                      : teams.byId[values.hostId]?.name
                    : '--'
                }
                disabled={
                  values.hostType !== SessionHostType.TEAM ||
                  isSaving ||
                  !isEditing
                }
                style={{ backgroundColor: 'inherit' }}
              />
            </Col>
          </div>
        </Row>
        <Row>
          <div className="setupFormLine">
            <Col>
              <label>Session Name</label>
              <input
                name="name"
                type="text"
                autoComplete="off"
                onChange={handleChange}
                value={values.name}
                disabled={isSaving || !isEditing}
              />
            </Col>
          </div>
        </Row>
      </>
    );
  };

  return (
    <Form onSubmit={handleSubmit} className="createOrJoinForm">
      {setupBody()}
      {teamSelector ? (
        <TeamSelector
          side={teamSelector}
          setTeam={setTeam}
          session={session}
          values={values}
          setTeams={setTeams}
          teams={teams}
        />
      ) : null}
    </Form>
  );
});
