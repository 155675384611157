import { ScoreType } from '../../models';

export const evaluatorConstants = {
  OPEN_INQUIRY: 'EVALUATOR_OPEN_INQUIRY',
  CLEAR_INQUIRY: 'EVALUATOR_CLEAR_INQUIRY',
  OPEN_EDIT: 'EVALUATOR_OPEN_EDIT',
  CLEAR_EDIT: 'EVALUATOR_CLEAR_EDIT',
};

export const NCAAMEvaluatorConfig = {
  dPanel: [{ name: 'Difficulty', type: ScoreType.D }],
  ePanel: [
    { name: 'E1 Judge', type: ScoreType.E1 },
    { name: 'E2 Judge', type: ScoreType.E2 },
  ],
  nDeduct: true,
  stickBonus: true,
  minimumDeduction: 0.1,
  maxTeamLineup: 5,
  aaIndex: 6,
};

export const NCAAM4EvaluatorConfig = {
  dPanel: [{ name: 'Difficulty', type: ScoreType.D }],
  ePanel: [
    { name: 'E1 Judge', type: ScoreType.E1 },
    { name: 'E2 Judge', type: ScoreType.E2 },
    { name: 'E3 Judge', type: ScoreType.E3 },
    { name: 'E4 Judge', type: ScoreType.E4 },
  ],
  nDeduct: true,
  stickBonus: true,
  minimumDeduction: 0.1,
  maxTeamLineup: 5,
  aaIndex: 6,
};

export const NCAAWEvaluatorConfig = {
  dPanel: [{ name: 'Start Value', type: ScoreType.SV }],
  ePanel: [],
  jPanel: [
    { name: 'J1 Judge', type: ScoreType.J1 },
    { name: 'J2 Judge', type: ScoreType.J2 },
  ],
  nDeduct: true,
  stickBonus: false,
  startValue: true,
  startValueOptional: true,
  minimumDeduction: 0.05,
  disableInputChecking: false,
  maxTeamLineup: 6,
  exhibitionIndex: 7,
};

export const NCAAW2EvaluatorConfig = {
  svPanel: [
    { name: 'SV1', type: ScoreType.SV1 },
    { name: 'SV2', type: ScoreType.SV2 },
  ],
  dPanel: [],
  ePanel: [],
  jPanel: [
    { name: 'J1 Judge', type: ScoreType.J1 },
    { name: 'J2 Judge', type: ScoreType.J2 },
  ],
  roles: {
    [ScoreType.J1]: {
      [ScoreType.J1]: true,
      [ScoreType.SV1]: true,
      [ScoreType.ND]: true,
      finalize: true,
      inquiry: true,
    },
    [ScoreType.J2]: {
      [ScoreType.J2]: true,
      [ScoreType.SV2]: true,
      [ScoreType.ND]: false,
      finalize: false,
      inquiry: false,
    },
  },
  nDeduct: true,
  stickBonus: false,
  startValue: true,
  startValueOptional: true,
  minimumDeduction: 0.05,
  disableInputChecking: false,
  maxTeamLineup: 6,
  exhibitionIndex: 7,
};

export const NCAAW2SVEvaluatorConfig = {
  ...NCAAW2EvaluatorConfig,
  startValueOptional: false,
};

export const NCAAW4EvaluatorConfig = {
  svPanel: [
    { name: 'SV1', type: ScoreType.SV1 },
    { name: 'SV2', type: ScoreType.SV2 },
    { name: 'SV3', type: ScoreType.SV3 },
    { name: 'SV4', type: ScoreType.SV4 },
  ],
  dPanel: [],
  ePanel: [],
  jPanel: [
    { name: 'J1 Judge', type: ScoreType.J1 },
    { name: 'J2 Judge', type: ScoreType.J2 },
    { name: 'J3 Judge', type: ScoreType.J3 },
    { name: 'J4 Judge', type: ScoreType.J4 },
  ],
  roles: {
    [ScoreType.J1]: {
      [ScoreType.J1]: true,
      [ScoreType.SV1]: true,
      [ScoreType.ND]: true,
      finalize: true,
      inquiry: true,
    },
    [ScoreType.J2]: {
      [ScoreType.J2]: true,
      [ScoreType.SV2]: true,
      [ScoreType.ND]: false,
      finalize: false,
      inquiry: false,
    },
    [ScoreType.J3]: {
      [ScoreType.J3]: true,
      [ScoreType.SV3]: true,
      [ScoreType.ND]: false,
      finalize: false,
      inquiry: false,
    },
    [ScoreType.J4]: {
      [ScoreType.J4]: true,
      [ScoreType.SV4]: true,
      [ScoreType.ND]: false,
      finalize: false,
      inquiry: false,
    },
  },
  nDeduct: true,
  stickBonus: false,
  startValue: true,
  minimumDeduction: 0.05,
  disableInputChecking: false,
  startValueOptional: false,
  maxTeamLineup: 6,
  // exhibitionIndex: 7,
};

export const NCAAW6EvaluatorConfig = {
  svPanel: [
    { name: 'SV1', type: ScoreType.SV1 },
    { name: 'SV2', type: ScoreType.SV2 },
    { name: 'SV3', type: ScoreType.SV3 },
    { name: 'SV4', type: ScoreType.SV4 },
    { name: 'SV5', type: ScoreType.SV5 },
    { name: 'SV6', type: ScoreType.SV6 },
  ],
  dPanel: [],
  ePanel: [],
  jPanel: [
    { name: 'J1 Judge', type: ScoreType.J1 },
    { name: 'J2 Judge', type: ScoreType.J2 },
    { name: 'J3 Judge', type: ScoreType.J3 },
    { name: 'J4 Judge', type: ScoreType.J4 },
    { name: 'J5 Judge', type: ScoreType.J5 },
    { name: 'J6 Judge', type: ScoreType.J6 },
  ],
  roles: {
    [ScoreType.J1]: {
      [ScoreType.J1]: true,
      [ScoreType.SV1]: true,
      [ScoreType.ND]: true,
      finalize: true,
      inquiry: true,
    },
    [ScoreType.J2]: {
      [ScoreType.J2]: true,
      [ScoreType.SV2]: true,
      [ScoreType.ND]: false,
      finalize: false,
      inquiry: false,
    },
    [ScoreType.J3]: {
      [ScoreType.J3]: true,
      [ScoreType.SV3]: true,
      [ScoreType.ND]: false,
      finalize: false,
      inquiry: false,
    },
    [ScoreType.J4]: {
      [ScoreType.J4]: true,
      [ScoreType.SV4]: true,
      [ScoreType.ND]: false,
      finalize: false,
      inquiry: false,
    },
    [ScoreType.J5]: {
      [ScoreType.J5]: true,
      [ScoreType.SV5]: true,
      [ScoreType.ND]: false,
      finalize: false,
      inquiry: false,
    },
    [ScoreType.J6]: {
      [ScoreType.J6]: true,
      [ScoreType.SV6]: true,
      [ScoreType.ND]: false,
      finalize: false,
      inquiry: false,
    },
  },
  nDeduct: true,
  stickBonus: false,
  startValue: true,
  minimumDeduction: 0.05,
  disableInputChecking: false,
  startValueOptional: false,
  maxTeamLineup: 6,
  // exhibitionIndex: 7,
};

export const fiveEvaluatorConfig = {
  dPanel: [{ name: 'D Judge', type: ScoreType.D }],
  ePanel: [
    { name: 'E1 Judge', type: ScoreType.E1 },
    { name: 'E2 Judge', type: ScoreType.E2 },
    { name: 'E3 Judge', type: ScoreType.E3 },
  ],
  nDeduct: true,
  stickBonus: true,
  minimumDeduction: 0.1,
};

export const fig6EvaluatorConfig = {
  dPanel: [
    { name: 'D1 Judge', type: ScoreType.D1 },
    { name: 'D2 Judge', type: ScoreType.D2 },
  ],
  ePanel: [
    { name: 'E1 Judge', type: ScoreType.E1 },
    { name: 'E2 Judge', type: ScoreType.E2 },
    { name: 'E3 Judge', type: ScoreType.E3 },
    { name: 'E4 Judge', type: ScoreType.E4 },
    { name: 'E5 Judge', type: ScoreType.E5 },
    { name: 'E6 Judge', type: ScoreType.E6 },
  ],
  nDeduct: true,
  stickBonus: false,
  minimumDeduction: 0.1,
};

export const fig5EvaluatorConfig = {
  dPanel: [
    { name: 'D1 Judge', type: ScoreType.D1 },
    { name: 'D2 Judge', type: ScoreType.D2 },
  ],
  ePanel: [
    { name: 'E1 Judge', type: ScoreType.E1 },
    { name: 'E2 Judge', type: ScoreType.E2 },
    { name: 'E3 Judge', type: ScoreType.E3 },
    { name: 'E4 Judge', type: ScoreType.E4 },
    { name: 'E5 Judge', type: ScoreType.E5 },
  ],
  nDeduct: true,
  stickBonus: false,
  minimumDeduction: 0.1,
};

export const fig4EvaluatorConfig = {
  dPanel: [
    { name: 'D1 Judge', type: ScoreType.D1 },
    { name: 'D2 Judge', type: ScoreType.D2 },
  ],
  ePanel: [
    { name: 'E1 Judge', type: ScoreType.E1 },
    { name: 'E2 Judge', type: ScoreType.E2 },
    { name: 'E3 Judge', type: ScoreType.E3 },
    { name: 'E4 Judge', type: ScoreType.E4 },
  ],
  nDeduct: true,
  stickBonus: false,
  minimumDeduction: 0.1,
};

export const singleEvaluatorConfig = {
  dPanel: [{ name: 'D Judge', type: ScoreType.D }],
  ePanel: [{ name: 'E Judge', type: ScoreType.E }],
  nDeduct: true,
  stickBonus: true,
  minimumDeduction: 0.1,
};

export const fanEvaluatorConfig = {
  dPanel: [],
  ePanel: [],
  fPanel: [{ name: 'FAN', type: ScoreType.F }],
  nDeduct: false,
  stickBonus: false,
  minimumDeduction: 1,
};

export const j4EvaluatorConfig = {
  dPanel: [],
  ePanel: [],
  jPanel: [
    { name: 'J1 Judge', type: ScoreType.J1 },
    { name: 'J2 Judge', type: ScoreType.J2 },
    { name: 'J3 Judge', type: ScoreType.J3 },
    { name: 'J4 Judge', type: ScoreType.J4 },
  ],
  nDeduct: false,
  stickBonus: false,
  minimumDeduction: 1,
};

export const openEvaluatorConfig = {
  dPanel: [],
  ePanel: [],
  jPanel: [{ name: 'Open Score', type: ScoreType.OPEN }],
  nDeduct: false,
  stickBonus: false,
  minimumDeduction: 0.001,
  disableInputChecking: true,
};

export const defaultEvaluatorConfig = NCAAMEvaluatorConfig;

export const evaluatorConfigs = {
  NCAAM: NCAAMEvaluatorConfig,
  NCAAM4: NCAAM4EvaluatorConfig,
  FIVE: fiveEvaluatorConfig,
  SINGLE: singleEvaluatorConfig,
  FAN: fanEvaluatorConfig,
  J4: j4EvaluatorConfig,
  NCAAW: NCAAWEvaluatorConfig,
  NCAAW2: NCAAW2EvaluatorConfig,
  NCAAW2SV: NCAAW2SVEvaluatorConfig,
  NCAAW4: NCAAW4EvaluatorConfig,
  NCAAW6: NCAAW6EvaluatorConfig,
  FIG5: fig5EvaluatorConfig,
  FIG6: fig6EvaluatorConfig,
  FIG4: fig4EvaluatorConfig,
  OPEN: openEvaluatorConfig,
  DEFAULT: defaultEvaluatorConfig,
};

export const subScoreType = {
  D: 'DIFFICULTY',
  E: 'EXECUTION',
  SV: 'START VALUE',
  ND: 'NEUTRAL DEDUCTION',
  SB: 'STICK BONUS',
  F: 'FAN',
  J: 'JUDGE',
};

export const subScoreTypeAbbv = {
  D: 'D',
  E: 'E',
  ND: 'ND',
  SB: 'SB',
  SV: 'SV',
  F: 'F',
  J: 'J',
};

export const initialEvaluatorState = {
  config: defaultEvaluatorConfig,
  activeInquiryByLineupId: {},
  activeEditByLineupId: {},
};
