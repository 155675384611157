import { useState } from 'react';
import { useQuery } from '@apollo/client';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { SearchFullAthletes } from '../../apollo/queries/SearchFullAthletes.graphql';
import { GetTeam } from '../../apollo/queries/GetTeam.graphql';

function TeamName({ teamId }) {
  const { data } = useQuery(GetTeam, {
    variables: {
      id: teamId,
    },
  });

  return <span className="mr-1">{data?.getTeam.name}</span>;
}

function AthleteTypeahead({
  gender,
  onSelected,
  prompt,
  disabled = false,
  clearOnSelect = true,
}) {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedAthletes, setSelectedAthletes] = useState([]);

  const { data, loading } = useQuery(SearchFullAthletes, {
    variables: {
      filter: {
        name: { matchPhrasePrefix: searchTerm.trim() },
        gender: { eq: gender },
      },
      limit: 25,
      sort: {
        direction: 'asc',
        field: 'name',
      },
    },
  });

  return (
    <AsyncTypeahead
      id="athlete-typeahead"
      clearButton
      disabled={disabled}
      dropup
      filterBy={['name']}
      isLoading={loading}
      onChange={(selectedAthletes) => {
        setSelectedAthletes(selectedAthletes);
        if (selectedAthletes.length !== 0) {
          onSelected(selectedAthletes[0]).then(() => {
            if (clearOnSelect) {
              setSelectedAthletes([]);
            }
          });
        } else {
          setSelectedAthletes([]);
        }
      }}
      onSearch={setSearchTerm}
      selected={selectedAthletes}
      labelKey="name"
      className="editing"
      placeholder={prompt ?? 'Search for athlete...'}
      options={data ? data.searchAthletes.items : []}
      onKeyDown={(e) => e.stopPropagation()}
      renderMenuItemChildren={(option) => {
        const teamIds = JSON.parse(option.teamAffiliations || '[]');

        return (
          <div style={{ fontWeight: '500' }}>
            {option.name}
            <small style={{ padding: '0 1rem' }}>
              {teamIds.map((teamId) => (
                <TeamName key={teamId} teamId={teamId} />
              ))}
              {teamIds.length === 0 && <span>No teams</span>}
            </small>
          </div>
        );
      }}
    />
  );
}

export default AthleteTypeahead;
