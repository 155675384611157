import { useState } from 'react';
import MatchUp from './matchup';
import SelectGender from '../setup/selectgender';
import { GenderType } from '../../models/index';
import { matches2024 as mMatches } from '../../utilities/homepage';
import { matches2024 as wMatches } from '../../utilities/wcga';
import matchupGridStyles from './matchupgrid.module.css';

function MatchupGrid() {
  const [gender, setGender] = useState(GenderType.FEMALE);
  const { genderContainer } = matchupGridStyles;

  /*
  const [matchList, setMatchList] = useState([]);
  const apolloClient = useApolloClient();

  useEffect(() => {
    const list = [];

    matches.forEach((matchRow) => {
      matchRow.forEach((match) => {
        console.log(match);
        apolloClient
          .query({
            query: GetMatch,
            variables: {
              sessionKey: match.sessionId,
            },
          })
          .then(({ data }) => {
            list.push(data.SessionByKey.items[0]);
          });
      });
    });

    console.log(list);
    setMatchList(list);
  }, [apolloClient]);

  console.log(matchList);
  */

  const selectMatches = (gender) => {
    let selected = null;

    switch (gender) {
      case GenderType.MALE:
        selected = mMatches;
        break;
      case GenderType.FEMALE:
        selected = wMatches;
        break;
      case GenderType.COED:
        selected = [...wMatches, ...mMatches];
        break;
      default:
        selected = [];
        break;
    }

    return selected;
  };

  const matches = selectMatches(gender);

  return (
    <div className="heroGrid">
      <div className={genderContainer}>
        <SelectGender gender={gender} setGender={setGender} />
      </div>
      <ul>
        {matches &&
          matches.map((matchRow, i) => {
            let headText = '';

            if (matchRow.length === 0) {
              return null;
            }

            if (i === 0) {
              headText = 'latest';
            }

            if (i === 2) {
              headText = 'upcoming';
            }

            if (i === 4) {
              headText = 'recent';
            }

            if (i === 6) {
              headText = 'past';
            }

            return (
              <li key={i}>
                <h5>{headText}</h5>
                {matchRow.map((match, j) => {
                  return <MatchUp match={match} key={j} />;
                })}
              </li>
            );
          })}
      </ul>
    </div>
  );
}

export default MatchupGrid;
