import { API, graphqlOperation, Storage } from 'aws-amplify';
import {
  getTeams,
  getTeamsBySearch,
  getLeagues,
  getLeaguesBySearch,
  getUsers,
  getUsersBySearch,
  updateLeague,
  getAthletes,
  getAthletesBySearch,
  getSessions,
  getSessionsBySearch,
  createLeague,
  createAthlete,
  updateUser,
  createTeam,
  updateTeam,
  updateAthlete,
  createRoster,
  updateRoster,
  createRosterLink,
  updateRosterLink,
  updateStream,
  updateLineup,
  getJudges,
  createSessionJudge,
  updateSessionJudge,
  getRosterAthletes,
  getTeamRosterAthletes,
} from '../../graphql/graphql';
import { deleteLineup } from '../../graphql/mutations';
import { nanoid } from 'nanoid';
import { store } from '../store';
//import { DataStore, Predicates } from '@aws-amplify/datastore';
//import { RosterLink } from '../../models';
import { QUERY_SIZE, MAX_QUERY_SIZE, statusType } from '../_constants';

export const adminService = {
  loadRosterAthletes,
  loadTeamRosterAthletes,
  loadTeams,
  loadLeagues,
  addLeague,
  addTeam,
  uploadPhoto,
  uploadPrivatePhoto,
  loadSessions,
  loadAthletes,
  loadUsers,
  editTeam,
  addAthlete,
  editAthlete,
  addRoster,
  editRoster,
  editLeague,
  updateStreams,
  editUser,
  loadJudges,
  updateJudges,
  searchUsers,
  searchAthletes,
  searchJudges,
  searchSessions,
  searchTeams,
  searchLeagues,
  updateLineups,
};

async function loadUsers(params = null) {
  const nextToken = params?.nextToken;
  return await API.graphql(
    graphqlOperation(getUsers, { limit: QUERY_SIZE, nextToken })
  );
}

async function searchUsers(params = null) {
  const nextToken = params?.nextToken;
  const query = params?.searchQuery || null;
  const filter = query && {
    or: [
      { name: { matchPhrasePrefix: `${query}` } },
      { email: { matchPhrasePrefix: `${query}` } },
      { email: { wildcard: `*${query}*` } },
    ],
  };

  return await API.graphql(
    graphqlOperation(getUsersBySearch, {
      limit: QUERY_SIZE,
      nextToken,
      filter,
      sort: { direction: 'asc', field: 'name' },
    })
  );
}

async function loadAthletes(params = null) {
  const nextToken = params?.nextToken;
  return await API.graphql(
    graphqlOperation(getAthletes, { limit: MAX_QUERY_SIZE, nextToken })
  );
}

async function loadTeamRosterAthletes(params = null) {
  const nextToken = params?.nextToken;
  return await API.graphql(
    graphqlOperation(getTeamRosterAthletes, {
      id: params.id,
      limit: MAX_QUERY_SIZE,
      nextToken,
    })
  );
}

async function searchAthletes(params = null) {
  const nextToken = params?.nextToken;
  const query = params?.searchQuery || null;
  const filter = query && {
    name: { matchPhrasePrefix: `${query}` },
  };

  return await API.graphql(
    graphqlOperation(getAthletesBySearch, {
      limit: QUERY_SIZE,
      nextToken,
      filter,
      sort: { direction: 'asc', field: 'name' },
    })
  );
}

async function loadJudges(params = null) {
  const nextToken = params?.nextToken;
  return await API.graphql(
    graphqlOperation(getJudges, {
      limit: MAX_QUERY_SIZE,
      filter: { judgeTypeProxy: { exists: true } },
      nextToken,
      sort: { direction: 'asc', field: 'name' },
    })
  );
}

async function searchJudges(params = null) {
  const nextToken = params?.nextToken;
  const query = params?.searchQuery || null;
  const filter = query
    ? {
        and: [
          { judgeTypeProxy: { exists: true } },
          {
            or: [
              { name: { matchPhrasePrefix: `${query}` } },
              { email: { matchPhrasePrefix: `${query}` } },
              { email: { wildcard: `*${query}*` } },
            ],
          },
        ],
      }
    : { judgeTypeProxy: { exists: true } };

  return await API.graphql(
    graphqlOperation(getJudges, {
      limit: QUERY_SIZE,
      filter,
      nextToken,
      sort: { direction: 'asc', field: 'name' },
    })
  );
}

async function loadSessions(params = null) {
  const nextToken = params?.nextToken;

  return await API.graphql(
    graphqlOperation(getSessions, {
      limit: MAX_QUERY_SIZE,
      nextToken,
      filter: { status: { ne: 'DELETED' } },
      sort: { direction: 'desc', field: 'startAt' },
    })
  );
}

async function searchSessions(params = null) {
  const nextToken = params?.nextToken;
  const query = params?.searchQuery || null;
  const filter = query
    ? {
        and: [
          {
            status: { ne: 'DELETED' },
          },
          {
            or: [
              { name: { matchPhrasePrefix: `${query}` } },
              { name: { wildcard: `*${query}*` } },
            ],
          },
        ],
      }
    : {
        status: { ne: 'DELETED' },
      };

  return await API.graphql(
    graphqlOperation(getSessionsBySearch, {
      limit: QUERY_SIZE,
      filter,
      nextToken,
      sort: { direction: 'desc', field: 'startAt' },
    })
  );
}

async function loadTeams(params = null) {
  const nextToken = params?.nextToken;

  return await API.graphql(
    graphqlOperation(getTeams, {
      limit: QUERY_SIZE,
      nextToken,
    })
  );
}

async function loadRosterAthletes(params = null) {
  const nextToken = params?.nextToken;

  return await API.graphql(
    graphqlOperation(getRosterAthletes, {
      id: params.id,
      limit: QUERY_SIZE,
      nextToken,
    })
  );
}

async function searchTeams(params = null) {
  const nextToken = params?.nextToken;
  const query = params?.searchQuery || null;
  const filter = query
    ? {
        and: [
          {
            status: { ne: 'DELETED' },
          },
          {
            or: [
              { name: { matchPhrasePrefix: `${query}` } },
              { name: { wildcard: `*${query}*` } },
            ],
          },
        ],
      }
    : {
        status: { ne: 'DELETED' },
      };

  return await API.graphql(
    graphqlOperation(getTeamsBySearch, {
      limit: MAX_QUERY_SIZE,
      filter,
      nextToken,
      sort: { direction: 'asc', field: 'name' },
    })
  );
}

async function loadLeagues(params = null) {
  const nextToken = params?.nextToken;

  return await API.graphql(
    graphqlOperation(getLeagues, {
      limit: QUERY_SIZE,
      nextToken,
    })
  );
}

async function searchLeagues(params = null) {
  const nextToken = params?.nextToken;
  const query = params?.searchQuery || null;
  const filter = query
    ? {
        and: [
          {
            status: { ne: 'DELETED' },
          },
          {
            or: [
              { name: { matchPhrasePrefix: `${query}` } },
              { name: { wildcard: `*${query}*` } },
            ],
          },
        ],
      }
    : {
        status: { ne: 'DELETED' },
      };

  return await API.graphql(
    graphqlOperation(getLeaguesBySearch, {
      limit: QUERY_SIZE,
      filter,
      nextToken,
      sort: { direction: 'asc', field: 'name' },
    })
  );
}

async function addLeague(input) {
  const { altNames, logos, colors } = input;
  const { username } = store.getState().user;
  const id = nanoid(6);

  console.log('in addleague');
  console.log(input);

  let imgMeta = null;
  //var imgUploadRequest = null;

  if (logos !== null) {
    let imgId = nanoid();

    imgMeta = {
      metaData: {
        type: 'league',
        typeid: id,
        id: imgId,
        owner: username,
        originalname: logos.name,
        size: logos.size,
        imgtype: logos.type,
        filename: `upload/images/league/${imgId}`,
        updated: Date.now(),
      },
    };
  }

  const JSONinput = {
    ...input,
    altNames: altNames.length > 0 ? JSON.stringify(altNames) : null,
    logos: logos ? JSON.stringify(imgMeta) : null,
    colors: colors ? JSON.stringify(colors) : null,
    id: id,
    status: statusType.ACTIVE,
  };

  console.log(JSONinput);

  return await API.graphql(graphqlOperation(createLeague, { input: JSONinput }))
    .then((res) => {
      const imgRes = logos ? uploadPhoto(logos, imgMeta) : null;
      console.log('image uploading');
      return [res, imgRes];
    })
    .catch((error) => {
      return error;
    });
}

async function uploadPrivatePhoto(file, meta) {
  return await Storage.vault.put(meta.metaData.filename, file, meta);
}

async function uploadPhoto(file, meta) {
  return await Storage.put(meta.metaData.filename, file, meta);
}

async function addTeam(input) {
  const { altNames, logos, colors } = input;
  const { username } = store.getState().user;
  const id = nanoid();
  let imgMeta = null;
  //var imgUploadRequest = null;

  if (logos !== null) {
    let imgId = nanoid();

    imgMeta = {
      metaData: {
        type: 'team',
        typeid: id,
        id: imgId,
        owner: username,
        originalname: logos.name,
        size: logos.size,
        imgtype: logos.type,
        filename: `upload/images/team/${imgId}`,
        updated: Date.now(),
      },
    };
  }

  const JSONinput = {
    ...input,
    altNames: altNames.length > 0 ? JSON.stringify(altNames) : null,
    logos: logos ? JSON.stringify(imgMeta) : null,
    colors: colors ? JSON.stringify(colors) : null,
    id,
    status: statusType.ACTIVE,
  };

  return await API.graphql(graphqlOperation(createTeam, { input: JSONinput }))
    .then((res) => {
      const imgRes = logos ? uploadPhoto(logos, imgMeta) : null;
      console.log('image uploading');
      return [res, imgRes];
    })
    .catch((error) => {
      return error;
    });
}

async function editTeam(input, changedImg = false) {
  const { altNames, logos, colors, id, _version, rtnId, triCode } = input;
  const { username } = store.getState().user;
  let imgMeta = null;

  if (changedImg && logos !== null) {
    let imgId = nanoid();

    imgMeta = {
      metaData: {
        type: 'team',
        typeid: id,
        id: imgId,
        owner: username,
        originalname: logos.name,
        size: logos.size,
        imgtype: logos.type,
        filename: `upload/images/team/${imgId}`,
        updated: Date.now(),
      },
    };
  }

  const JSONinput = {
    ...input,
    altNames: altNames.length > 0 ? JSON.stringify(altNames) : null,
    logos: changedImg
      ? JSON.stringify(imgMeta)
      : logos
      ? JSON.stringify(logos)
      : null,
    colors: colors ? JSON.stringify(colors) : null,
    id: id,
    triCode,
    rtnId,
    _version: _version,
  };

  return await API.graphql(graphqlOperation(updateTeam, { input: JSONinput }))
    .then((res) => {
      const imgRes = changedImg && logos ? uploadPhoto(logos, imgMeta) : null;
      console.log('image uploading');
      return [res, imgRes];
    })
    .catch((error) => {
      return error;
    });
}

async function addAthlete(input) {
  const { altNames, profileImg, colors, teamAffiliations } = input;
  const { username } = store.getState().user;
  const id = nanoid();
  let imgMeta = [];
  //var imgUploadRequest = null;

  if (profileImg !== null) {
    let imgId = nanoid();

    imgMeta.push({
      metaData: {
        type: 'athlete',
        typeid: id,
        id: imgId,
        owner: username,
        originalname: profileImg.name,
        size: profileImg.size,
        imgtype: profileImg.type,
        filename: `upload/images/athlete/${imgId}`,
        updated: Date.now(),
      },
    });
  }

  const JSONinput = {
    ...input,
    altNames: altNames.length > 0 ? JSON.stringify(altNames) : null,
    profileImg: profileImg ? JSON.stringify(imgMeta) : null,
    colors: colors ? JSON.stringify(colors) : null,
    teamAffiliations:
      teamAffiliations && teamAffiliations.length > 0
        ? JSON.stringify(teamAffiliations)
        : null,
    id: id,
    status: statusType.ACTIVE,
  };

  return await API.graphql(
    graphqlOperation(createAthlete, { input: JSONinput })
  )
    .then((res) => {
      const imgRes = profileImg ? uploadPhoto(profileImg, imgMeta[0]) : null;
      console.log('image uploading');
      return [res, imgRes];
    })
    .catch((error) => {
      return error;
    });
}

async function editAthlete(input, changedImg = false, athlete = {}) {
  const {
    altNames,
    profileImg,
    colors,
    teamAffiliations,
    id,
    _version,
  } = input;
  const { username } = store.getState().user;
  let imgMeta = [];
  let newMeta = null;
  const prevImg = athlete?.profileImg ? JSON.parse(athlete?.profileImg) : [];
  let oldMeta = Array.isArray(prevImg) ? prevImg : [prevImg];

  if (changedImg && profileImg !== null) {
    let imgId = nanoid();

    newMeta = {
      metaData: {
        type: 'athlete',
        typeid: id,
        id: imgId,
        owner: username,
        originalname: profileImg.name,
        size: profileImg.size,
        imgtype: profileImg.type,
        filename: `upload/images/athlete/${imgId}`,
        updated: Date.now(),
      },
    };
  }

  imgMeta = [newMeta, ...oldMeta];

  const JSONinput = {
    ...input,
    altNames: altNames.length > 0 ? JSON.stringify(altNames) : null,
    profileImg: changedImg
      ? JSON.stringify(imgMeta)
      : profileImg
      ? JSON.stringify(profileImg)
      : null,
    colors: colors ? JSON.stringify(colors) : null,
    teamAffiliations:
      teamAffiliations && teamAffiliations.length > 0
        ? JSON.stringify(teamAffiliations)
        : null,
    id: id,
    _version: _version,
  };

  return await API.graphql(
    graphqlOperation(updateAthlete, { input: JSONinput })
  )
    .then((res) => {
      const imgRes =
        profileImg && changedImg ? uploadPhoto(profileImg, newMeta) : null;
      return [res, imgRes];
    })
    .catch((error) => {
      return error;
    });
}

async function addRoster(input) {
  return await API.graphql(graphqlOperation(createRoster, { input: input }));
}

async function editRoster(input) {
  // Check for title changes (activeDate / title)
  const { title, table, roster, newData } = input;

  // Save each item old fashioned way
  const createBatch =
    table &&
    table.creates &&
    Promise.all(
      table.creates.map((i) => {
        return API.graphql(
          graphqlOperation(createRosterLink, {
            input: {
              classYear: newData[i].classYear ? newData[i].classYear : null,
              position: newData[i].position,
              active: table.added.includes(i), // must be on the added list
              rosterId: roster.id,
              athleteId: newData[i].athleteId,
            },
          })
        );
      })
    );

  // Updates the old fashione way
  const updateBatch =
    table &&
    table.updates &&
    Promise.all(
      table.updates.map((i) => {
        return API.graphql(
          graphqlOperation(updateRosterLink, {
            input: {
              id: newData[i].rosterLinkId,
              classYear: newData[i].classYear ? newData[i].classYear : null,
              position: newData[i].position,
              active: table.removed.includes(i)
                ? false
                : table.added.includes(i)
                ? true
                : newData[i].active,
              _version: newData[i]._version, // perhaps need pull this from redux normalizr in case of subsequent changes?
            },
          })
        );
      })
    );

  // Update roster if updating/creating batches regardless to get the updatedAt time updated & add new athletes via rosterLink
  const rosterPayload = title
    ? title
    : { id: roster.id, _version: roster._version };
  const rosterReq = API.graphql(
    graphqlOperation(updateRoster, { input: rosterPayload })
  );

  // Think we need to sequence this to do the batches then do Roster so tha we can get newest roster back
  return await Promise.all([createBatch, updateBatch])
    .then(async (res) => {
      return [await rosterReq, ...res];
    })
    .catch((error) => {
      return error;
    });
}

async function editLeague(input, changedImg = false) {
  const { altNames, logos, colors, id, _version } = input;
  const { username } = store.getState().user;
  let imgMeta = null;

  if (changedImg && logos !== null) {
    let imgId = nanoid();

    imgMeta = {
      metaData: {
        type: 'league',
        typeid: id,
        id: imgId,
        owner: username,
        originalname: logos.name,
        size: logos.size,
        imgtype: logos.type,
        filename: `upload/images/league/${imgId}`,
        updated: Date.now(),
      },
    };
  }

  const JSONinput = {
    ...input,
    altNames: altNames.length > 0 ? JSON.stringify(altNames) : null,
    logos: changedImg
      ? JSON.stringify(imgMeta)
      : logos
      ? JSON.stringify(logos)
      : null,
    colors: colors ? JSON.stringify(colors) : null,
    id: id,
    _version: _version,
  };

  return await API.graphql(graphqlOperation(updateLeague, { input: JSONinput }))
    .then((res) => {
      const imgRes = changedImg && logos ? uploadPhoto(logos, imgMeta) : null;
      console.log('image uploading');
      return [res, imgRes];
    })
    .catch((error) => {
      return error;
    });
}

async function updateStreams(input) {
  return await Promise.all(
    input.map((stream) => {
      return API.graphql(graphqlOperation(updateStream, { input: stream }));
    })
  );
}

async function updateLineups(input) {
  const updates = [];
  const deletes = [];

  input.forEach((i) => {
    if (i?._deleted) {
      deletes.push(i);
    } else {
      updates.push(i);
    }
  });

  const updateBatch = Promise.all(
    updates.map((i) => {
      delete i._deleted;
      return API.graphql(
        graphqlOperation(updateLineup, {
          input: i,
        })
      );
    })
  );

  const deleteBatch = Promise.all(
    deletes.map((i) => {
      return API.graphql(
        graphqlOperation(deleteLineup, {
          input: {
            id: i.id,
            _version: i._version,
          },
        })
      );
    })
  );

  return await Promise.all([updateBatch, deleteBatch]);
}

async function editUser(input, changedImg = false, user = {}) {
  const { profileImg, colors, id, _version, judgeType } = input;
  const { username } = store.getState().user;
  let imgMeta = [];
  let newMeta = null;
  const prevImg = user?.profileImg ? JSON.parse(user?.profileImg) : [];
  let oldMeta = Array.isArray(prevImg) ? prevImg : [prevImg];

  if (changedImg && profileImg !== null) {
    let imgId = nanoid();

    newMeta = {
      metaData: {
        type: 'user',
        typeid: id,
        id: imgId,
        owner: username,
        originalname: profileImg.name,
        size: profileImg.size,
        imgtype: profileImg.type,
        filename: `upload/images/user/${imgId}`,
        updated: Date.now(),
      },
    };
  }

  imgMeta = [newMeta, ...oldMeta];

  // Added for string Proxy of judgeType for search
  const judgeTypeProxy = JSON.stringify(judgeType);

  const JSONinput = {
    ...input,
    judgeTypeProxy: judgeTypeProxy,
    profileImg: changedImg
      ? JSON.stringify(imgMeta)
      : profileImg
      ? JSON.stringify(profileImg)
      : null,
    colors: colors ? JSON.stringify(colors) : null,
    id: id,
    _version: _version,
  };

  console.log(JSONinput);

  return await API.graphql(graphqlOperation(updateUser, { input: JSONinput }))
    .then((res) => {
      const imgRes =
        profileImg && changedImg ? uploadPhoto(profileImg, newMeta) : null;
      return [res, imgRes];
    })
    .catch((error) => {
      return error;
    });
}

async function updateJudges(input) {
  // where added and removed are lists of id's
  const { added, removed } = input;

  // Save each item old fashioned way
  const createBatch =
    added &&
    Promise.all(
      added.map((i) => {
        return API.graphql(
          graphqlOperation(createSessionJudge, {
            input: {
              status: i.status,
              sessionId: i.sessionId,
              userId: i.userId,
            },
          })
        );
      })
    );

  // Updates the old fashioned way
  const updateBatch =
    removed &&
    Promise.all(
      removed.map((i) => {
        return API.graphql(
          graphqlOperation(updateSessionJudge, {
            input: {
              id: i.id,
              status: i.status,
              _version: i._version,
            },
          })
        );
      })
    );

  return await Promise.all([createBatch, updateBatch])
    .then((res) => {
      //console.log(res);
      console.log('Created and updated all Session Judge links.');
      return res;
    })
    .catch((error) => {
      return error;
    });
}
