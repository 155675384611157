import React, { useState, useEffect, useCallback, useRef } from 'react';
import {
  Modal,
  Button,
  Row,
  Col,
  Alert,
  Spinner,
  Form,
  Popover,
  OverlayTrigger,
} from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { adminActions, alertActions } from '../../redux/_actions';
import {
  genderType,
  leagueType,
  imgType,
  FILE_SIZE,
} from '../../redux/_constants';
import * as Yup from 'yup';
import { SketchPicker } from 'react-color';
import * as Slugify from 'slugify';
import { useDropzone } from 'react-dropzone';
import { Typeahead } from 'react-bootstrap-typeahead';
import { MEDIAURL } from '../../utilities/constants';
import { espnTricodes } from '../../utilities/tricodes';

function TeamForm(props) {
  const { back } = props;
  const [colors, setColors] = useState([
    '#00A2FF',
    '#5E5E5E',
    '#FFFFFF',
    '#000000',
  ]);
  const [logo, setLogo] = useState(null);
  const [isCreating, setIsCreating] = useState(false);
  const dispatch = useDispatch();
  const { alert } = useSelector((state) => state);
  const hiddenFileInput = useRef(null);
  let typeaheadRef = useRef();

  useEffect(() => {
    if (!alert.clear) {
      setIsCreating(false);
    }
  }, [alert.clear]);

  useEffect(() => {
    if (alert.type === 'alert-success') {
      back();
    }
  }, [alert.type, back]);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Required'),
    slug: Yup.string().required('Required'),
    colors: Yup.array().of(Yup.string()),
    altNames: Yup.string().nullable(),
    triCode: Yup.string().nullable(),
    rtnId: Yup.string().nullable(),
    logos: Yup.mixed()
      .nullable()
      .notRequired()
      .test(
        'FILE_SIZE',
        'Uploaded file is too big.',
        (value) => !value || (value && value.size <= FILE_SIZE)
      )
      .test(
        'FILE_FORMAT',
        'Uploaded file has unsupported format.',
        (value) => !value || (value && imgType.includes(value.type))
      ),
    type: Yup.string().required('Required'),
    gender: Yup.string().required('Required'),
  });

  const {
    setFieldValue,
    handleSubmit,
    handleChange,
    values,
    errors,
  } = useFormik({
    initialValues: {
      name: '',
      slug: '',
      colors: ['#00A2FF', '#5E5E5E', '#FFFFFF', '#000000'],
      altNames: '',
      logos: null,
      type: '',
      gender: '',
      triCode: '',
      rtnId: '',
    },
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit(values) {
      setIsCreating(true);
      dispatch(alertActions.clear());
      dispatch(adminActions.createTeam(values));
    },
  });

  const calcSlug = (name) => {
    const slug = Slugify(name, { lower: true, strict: true });
    return slug;
  };

  const checkBase64 = (string) => {
    return string.substr(0, 5) === 'data:';
  };

  const colorButton = (index) => {
    return (
      <OverlayTrigger
        placement="bottom"
        //trigger={["click", "focus"]}
        trigger="click"
        rootClose
        overlay={
          <Popover id={`popover-colorpicker-${index}`}>
            <SketchPicker
              disableAlpha
              presetColors={[]}
              color={colors[index]}
              onChange={(value) => {
                setFieldValue(`colors[${index}]`, value.hex);
                colors[index] = value.hex;
                setColors(colors);
              }}
            />
          </Popover>
        }
      >
        <Button
          className="colorSelect"
          disabled={isCreating}
          style={{
            marginLeft: index === 0 ? '0' : null,
            background: colors[index],
          }}
        ></Button>
      </OverlayTrigger>
    );
  };

  const onDrop = useCallback(
    (acceptedFiles) => {
      const handleLogoChange = (files) => {
        const fileUploaded = files;
        if (!fileUploaded) {
          return false;
        }

        if (!alert.clear) {
          dispatch(alertActions.clear());
        }

        let reader = new FileReader();
        reader.onload = (e) => {
          const img = new Image();
          img.onload = () => {
            if (fileUploaded.size > FILE_SIZE) {
              dispatch(alertActions.error('Image exceeds max 5MB.'));
              return false;
            }
            setLogo(reader.result);
          };
          img.onerror = () => {
            dispatch(alertActions.error('Could not load image.'));
            return false;
          };
          img.src = e.target.result;
        };

        setFieldValue(`logos`, fileUploaded);
        reader.readAsDataURL(
          Array.isArray(fileUploaded) ? fileUploaded?.[0] : fileUploaded
        );
      };

      handleLogoChange(acceptedFiles);
    },
    [alert.clear, dispatch, setFieldValue]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const logoButton = () => {
    const handleClick = (event) => {
      hiddenFileInput.current.click();
    };

    return (
      <>
        <label>Logo</label>
        <Button
          variant="outline-light"
          disabled={isCreating}
          className="logoSelect"
          onClick={handleClick}
          style={{ flexDirection: logo ? 'column' : null }}
        >
          {logo ? (
            <img
              className="img-thumbnail"
              src={checkBase64(logo) ? logo : `${MEDIAURL}${logo}`}
              alt="Team logo"
            />
          ) : isDragActive ? (
            '+'
          ) : (
            '?'
          )}
        </Button>
        <input
          type="file"
          {...getInputProps()}
          ref={hiddenFileInput}
          onChange={(e) => onDrop(e.target.files[0])}
          style={{ display: 'none' }}
          accept="image/png,image/gif,image/jpeg"
        />
      </>
    );
  };

  const onKeyDown = useCallback((e) => {
    if (e.key === 'Enter') {
      setFieldValue('altNames', [...values.altNames, e.target.value]);
      typeaheadRef.clear();
    }
  });

  const setupBody = () => {
    return (
      <>
        {!alert.clear ? (
          <Row style={{ padding: '0 15px' }}>
            <Alert
              dismissible
              onClose={() => dispatch(alertActions.clear())}
              variant={alert.type === 'alert-danger' ? 'danger' : 'success'}
            >
              {alert.message}
            </Alert>
          </Row>
        ) : null}
        <Row>
          <div className="setupFormLine">
            <Col {...getRootProps()}>{logoButton()}</Col>
            <Col sm={8}>
              <Row>
                <label>Colors</label>
                {colorButton(0)}
                {colorButton(1)}
                {colorButton(2)}
                {colorButton(3)}
              </Row>
              <Row>
                <div className="setupFormLine">
                  <Col style={{ padding: '0 2% 0 0' }}>
                    <label>
                      Gender
                      {errors.gender ? (
                        <span className="form-error">{errors.gender}</span>
                      ) : null}
                    </label>
                    <Form.Control
                      as="select"
                      label="Required"
                      id="gender"
                      defaultValue="Select..."
                      onChange={handleChange}
                      disabled={isCreating}
                    >
                      <option value="Select..." disabled hidden>
                        Select...
                      </option>
                      {Object.values(genderType).map((type, i) => {
                        return <option key={i}>{type}</option>;
                      })}
                    </Form.Control>
                  </Col>
                  <Col style={{ padding: '0 0 0 2%' }}>
                    <label>
                      Type
                      {errors.type ? (
                        <span className="form-error">{errors.type}</span>
                      ) : null}
                    </label>
                    <Form.Control
                      as="select"
                      label="Required"
                      id="type"
                      defaultValue="Select..."
                      onChange={handleChange}
                      disabled={isCreating}
                    >
                      <option value="Select..." disabled hidden>
                        Select...
                      </option>
                      {Object.values(leagueType).map((type, i) => {
                        return <option key={i}>{type}</option>;
                      })}
                    </Form.Control>
                  </Col>
                </div>
              </Row>
              <Row>
                <div className="setupFormLine">
                  <Col style={{ padding: '0 2% 0 0' }}>
                    <label>
                      RTN ID
                      {errors.rtnId ? (
                        <span className="form-error">{errors.rtnId}</span>
                      ) : null}
                    </label>
                    <Form.Control
                      plaintext
                      name="rtnId"
                      type="text"
                      autoComplete="off"
                      value={values.rtnId}
                      onChange={handleChange}
                      placeholder="Optional"
                      disabled={isCreating}
                    />
                  </Col>
                  <Col style={{ padding: '0 0 0 2%' }}>
                    <label>
                      Tricode
                      {errors.type ? (
                        <span className="form-error">{errors.triCode}</span>
                      ) : null}
                    </label>
                    <Typeahead
                      onChange={(selected) => {
                        const topTriCode = selected[0];
                        if (topTriCode) {
                          setFieldValue('triCode', topTriCode);
                        }
                      }}
                      options={[].concat(
                        Object.keys(espnTricodes).map((k) => {
                          return { team: espnTricodes[k], triCode: k };
                        })
                      )}
                      id="triCode"
                      labelKey="triCode"
                      className="typeahead"
                      clearButton
                      filterBy={['triCode', 'team']}
                      disabled={isCreating}
                      placeholder="Optional"
                      renderMenuItemChildren={(option) => (
                        <span>
                          {option?.triCode}: {option?.team}
                        </span>
                      )}
                    />
                  </Col>
                </div>
              </Row>
            </Col>
          </div>
        </Row>

        <Row>
          <Col>
            <label>
              Name
              {errors.name ? (
                <span className="form-error">{errors.name}</span>
              ) : null}
            </label>
            <Form.Control
              name="name"
              type="text"
              autoComplete="off"
              onChange={(e) => {
                setFieldValue('name', e.target.value);
                setFieldValue('slug', calcSlug(e.target.value));
              }}
              value={values.name}
              disabled={isCreating}
              placeholder="Enter team name"
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <label>Slug</label>
            <Form.Control
              plaintext
              readOnly
              name="slug"
              type="text"
              autoComplete="off"
              value={values.slug}
              disabled={isCreating}
              placeholder="Autogenerated"
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <label>Other Names (Use #hashtag for display name)</label>
            <Typeahead
              onChange={(selected) => {
                setFieldValue('altNames', selected);
              }}
              allowNew
              options={[]}
              id="teamAltNames"
              className="typeahead"
              multiple
              disabled={isCreating}
              placeholder="Optional"
              selected={values.altNames ? values.altNames : []}
              renderMenu={(results, menuProps) => null}
              onKeyDown={onKeyDown}
              ref={(a) => (typeaheadRef = a)}
            />
          </Col>
        </Row>
      </>
    );
  };

  const setupFooter = () => {
    return (
      <>
        <Row>
          <Button
            variant="outline-primary"
            className="formButton"
            onClick={handleSubmit}
            disabled={isCreating}
          >
            {isCreating ? (
              <>
                {'Creating team... '}
                <Spinner
                  variant="primary"
                  role="status"
                  animation="border"
                  size="sm"
                />
              </>
            ) : (
              'Create team'
            )}
          </Button>
        </Row>
        <Row>
          <div className="modalLink">
            <span>Back to</span>
            <button
              type="button"
              className="linkButton"
              disabled={isCreating}
              onClick={back}
            >
              Team list
            </button>
          </div>
        </Row>
      </>
    );
  };

  const headerMsg = () => {
    const virtius = (
      <>
        <span className="vBlue">V</span>
        <span className="vGray">irtius</span>
      </>
    );

    return (
      <>
        <span>Create&nbsp;</span>
        {virtius}
        <span>&nbsp;Team</span>
      </>
    );
  };

  return (
    <>
      <Modal.Header className="adminHeader">{headerMsg()}</Modal.Header>
      <Form onSubmit={handleSubmit}>
        <Modal.Body className="adminForm">{setupBody()}</Modal.Body>
        <Modal.Footer className="adminForm">{setupFooter()}</Modal.Footer>
      </Form>
    </>
  );
}

export default TeamForm;
