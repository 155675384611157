import React from 'react';
import { fbIcon, googleIcon } from '../helpers/icons';
import { Row, Button, Col } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { userActions } from '../../redux/_actions';

function LoginModalFooter(props) {
  const { disabled, login } = props;
  const dispatch = useDispatch();

  const fbLogin = () => {
    login(true);
    dispatch(userActions.fbLogin());
  };

  const googleLogin = () => {
    login(true);
    dispatch(userActions.googleLogin());
  };

  return (
    <>
      <Row>
        <Button onClick={fbLogin} className="fbButton" disabled={disabled}>
          <Row className="socialButtonRow">
            <Col className="icon" xs={3}>
              {fbIcon}
            </Col>
            <Col className="label" xs={9}>
              Log in with Facebook
            </Col>
          </Row>
        </Button>
      </Row>
      <Row>
        <Button
          onClick={googleLogin}
          className="googleButton"
          disabled={disabled}
        >
          <Row className="socialButtonRow">
            <Col className="icon" xs={3}>
              {googleIcon}
            </Col>
            <Col className="label" xs={9}>
              Log in with Google
            </Col>
          </Row>
        </Button>
      </Row>
    </>
  );
}

export default LoginModalFooter;
