import React from 'react';
import { Row, Col } from 'react-bootstrap';
import DeltaIndicator from './deltaindicator';
import { useSelector } from 'react-redux';
import { useQuery } from '@apollo/client';
import {
  roundScore,
  roundScoreLegacy,
  convertMillipointsToDisplay,
  convertPointsToMillipoints,
} from '../../utilities/scoring';
import { sessionViewType } from '../../redux/_constants';
import { dualTeamBRotation } from '../../utilities/session';
import { RoutineStatus, SessionStatus } from '../../models';
import { useDisplayConfig } from './hooks';
import { SessionTeamScoreType } from '../../models';
import './scoredeltas.css';

import GetLineupWithRoutines from '../../apollo/queries/GetLineupWithRoutines.graphql';

function ScoreDeltas({
  customRoundA,
  customRoundB,
  customLineupA,
  customLineupB,
}) {
  const view = useSelector((state) => state.session.view);
  const status = useSelector((state) => state.session.status);
  const isEditing = useSelector((state) => state.producer.edit);
  const isAlternating = useSelector((state) => state.session.alternating);
  const { PREGAME, LIVE } = SessionStatus;
  const { MIRRORA, MIRRORB } = sessionViewType;

  const shouldAlternate = isAlternating && [PREGAME, LIVE].includes(status);

  const teamA = view === MIRRORB ? 'teamB' : 'teamA';
  const teamB = view === MIRRORA ? 'teamA' : 'teamB';

  const teamALineupId = useSelector((state) => state.producer[teamA].lineupId);
  const teamBLineupId = useSelector((state) => state.producer[teamB].lineupId);
  const round = useSelector((state) => state.producer.round);
  const shouldShowScores = useSelector((state) => state.producer.scores);
  const judgePanel = useSelector((state) => state.session.judgePanel);

  const colorA = useSelector((state) => state.producer[teamA].color);
  const colorB = useSelector((state) => state.producer[teamB].color);
  const scores = useSelector((state) => state.producer.scores);
  const teamScoringType = useSelector((state) => state.session.teamScoring);
  const showRoundSummary = teamScoringType !== SessionTeamScoreType.NONE;

  const legacyLineupA = useSelector((state) => state.producer[teamA].lineup);
  const legacyLineupB = useSelector((state) => state.producer[teamB].lineup);
  const lineupA = customLineupA || legacyLineupA;
  const lineupB = customLineupB || legacyLineupB;
  const lineupLengthA = lineupA.length;
  const lineupLengthB = lineupB.length;

  const displayConfig = useDisplayConfig();

  const { data: lineupDataA } = useQuery(GetLineupWithRoutines, {
    variables: { id: teamALineupId },
  });
  const { data: lineupDataB } = useQuery(GetLineupWithRoutines, {
    variables: { id: teamBLineupId },
  });

  if (!lineupDataA || !lineupDataB || !shouldShowScores) {
    return null;
  }

  function currentRoundRoutines(routines, currentRound) {
    return routines.reduce((currentRoutines, routine) => {
      if (
        routine.rotation === currentRound &&
        routine.status !== RoutineStatus.DELETED
      ) {
        currentRoutines[routine.order] = routine;
      }
      return currentRoutines;
    }, []);
  }

  const teamARoutines = currentRoundRoutines(
    lineupDataA.getLineup.routines.items,
    customRoundA || round
  );
  const teamBRoutines = currentRoundRoutines(
    lineupDataB.getLineup.routines.items,
    customRoundB || (shouldAlternate ? dualTeamBRotation(round) : round)
  );

  const maxLength = Math.max(lineupLengthA, lineupLengthB);
  const deltas = [];

  for (let i = 0; i < maxLength; i++) {
    scores &&
      deltas.push({
        scoreA:
          teamARoutines[i]?.score ??
          (lineupA[i]?.score && convertPointsToMillipoints(lineupA[i]?.score)),
        scoreB:
          teamBRoutines[i]?.score ??
          (lineupB[i]?.score && convertPointsToMillipoints(lineupB[i]?.score)),
      });
  }

  const roundScoreA = teamARoutines.length
    ? roundScore(teamARoutines, teamScoringType)
    : convertPointsToMillipoints(roundScoreLegacy(lineupA, teamScoringType));
  const roundScoreB = teamBRoutines.length
    ? roundScore(teamBRoutines, teamScoringType)
    : convertPointsToMillipoints(roundScoreLegacy(lineupB, teamScoringType));

  return (
    <div className="scoreDeltas">
      <Col className="sDeltaCol">
        <div style={{ margin: '0 -15px' }}>
          <ul>
            {deltas.map(({ scoreA, scoreB }, i) => (
              <li key={i} className="vCenter">
                {typeof scoreA === 'number' && typeof scoreB === 'number'
                  ? convertMillipointsToDisplay(
                      Math.abs(scoreA - scoreB),
                      displayConfig.scorePrecision
                    )
                  : '--'}
                <DeltaIndicator
                  scoreA={typeof scoreA === 'number' ? scoreA : 0}
                  scoreB={typeof scoreB === 'number' ? scoreB : 0}
                  colorA={colorA}
                  colorB={colorB}
                  sensitivity={judgePanel}
                />
              </li>
            ))}
            {isEditing ? <li key="+" className="vCenter"></li> : null}
          </ul>
        </div>
      </Col>
      <hr style={{ borderTop: '1px solid transparent' }} />
      {showRoundSummary ? (
        <Row className="rDeltaRow">
          <Col className="rDeltaCol vCenter">
            {roundScoreA === 0 && roundScoreB === 0
              ? '--'
              : convertMillipointsToDisplay(
                  Math.abs(roundScoreA - roundScoreB),
                  displayConfig.scorePrecision
                )}
            <DeltaIndicator
              scoreA={roundScoreA}
              scoreB={roundScoreB}
              colorA={colorA}
              colorB={colorB}
              sensitivity={judgePanel}
            />
          </Col>
        </Row>
      ) : null}
    </div>
  );
}

export default ScoreDeltas;
