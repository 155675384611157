import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import Join from './join';
import Create from './create';
import Sessions from './sessions';
import SessionForm from './sessionform';
import { uiState } from '../../redux/_constants';
import { useHistory, useLocation } from 'react-router-dom';

function CreateOrJoin() {
  const [joinId, setJoinId] = useState(null);
  const [setup, setSetup] = useState(null);
  const [gender, setGender] = useState(null);
  const { CREATE, JOIN, START, SESSIONS } = uiState;
  const history = useHistory();
  const location = useLocation();

  const handleMode = (mode) => {
    history.push(mode);
  };

  const reset = () => {
    setSetup(null);
    setGender(null);
  };

  const formType = () => {
    switch (location.pathname) {
      case CREATE:
        if (setup === null || gender === null) {
          return (
            <Create
              setSetup={setSetup}
              setGender={setGender}
              setup={setup}
              gender={gender}
            />
          );
        } else {
          return <SessionForm setup={setup} gender={gender} reset={reset} />;
        }
      case JOIN:
        return <Join id={joinId} setId={setJoinId} />;
      case SESSIONS:
        return <Sessions />;
      default:
        return null;
    }
  };

  return (
    <Modal
      show={[CREATE, JOIN, SESSIONS].includes(location.pathname)}
      onHide={() => handleMode(START)}
      centered
      dialogClassName="createOrJoinModal"
      animation={false}
      size={[SESSIONS].includes(location.pathname) ? 'lg' : null}
      scrollable={[SESSIONS].includes(location.pathname)}
    >
      {formType()}
    </Modal>
  );
}

export default CreateOrJoin;
