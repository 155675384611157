import { Container, Row, Col, Spinner } from 'react-bootstrap';
import producerStyles from '../session/producer.module.css';
import { heroLogo } from '../helpers/logos';
import './producerloading.css';

function ProducerLoading(props) {
  const defaultMessage = 'Producer Mode Loading...';

  return (
    <>
      <Container fluid className={producerStyles.container} />
      <Container fluid className={producerStyles.menu}>
        <Row>
          <Col className="producerLoadingText">
            <span>{defaultMessage}</span>
            <Spinner
              variant="secondary"
              role="status"
              animation="border"
              size="sm"
            />
          </Col>
          <Col className="producerLoadingLogo">{heroLogo}</Col>
        </Row>
      </Container>
    </>
  );
}

export default ProducerLoading;
