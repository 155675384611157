import { useEffect, useState, useMemo, useContext } from 'react';
import { Modal, Button, Row, Alert, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { alertActions } from '../../redux/_actions';
import TableComponent from '../helpers/tablecomponent';
import {
  displayName,
  numToApparatus,
  teamBrand,
  isMWApparatus,
  isLetter,
  alphaVal,
} from '../../utilities/conversions';
import { Loading, ScrollLoading } from '../helpers/loading';
import { sCircle, chevronLeftIcon, chevronRightIcon } from '../helpers/icons';
import {
  MEDIAURL,
  mApparatusAbbv,
  wApparatusAbbv,
} from '../../utilities/constants';
import { GenderType, RoutineStatus, SessionTeamScoreType } from '../../models';
import { SearchInput, addBold, noResults } from '../helpers/search';
import {
  convertMillipointsToDisplay,
  convertDisplayToMillipoints,
  useRoutineScore,
  sessionScore,
  sessionPenalties,
} from '../../utilities/scoring';
import { ToggleTimer } from '../helpers/toggletimer';
import { ExportData } from './exportdata';
import './leaderboard.css';
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';
import { useHotkeys } from 'react-hotkeys-hook';
import { judgePanelType } from '../../redux/_constants';
import { useInterval } from '../../utilities/hooks';
import { useDisplayConfig, useTeamsByAthleteId } from './hooks';

const defaultSort = [
  { id: 'apparatus', desc: false },
  { id: 'score', desc: true },
  { id: 'e', desc: true },
];

function Leaderboard(props) {
  const { onHide, lineups, session, type } = props;
  const { MALE, FEMALE, COED } = GenderType;
  const [isLoading, setIsLoading] = useState(true);
  const [timerOn, setTimerOn] = useState(true);
  const isScrollLoading = false;
  const alert = useSelector((state) => state.alert);
  const [searchTerm, setSearchTerm] = useState('');
  const [filterKeys, setFilterKeys] = useState([]);
  const [filterIndex, setFilterIndex] = useState(0);
  const [groupKeys, setGroupKeys] = useState([]);
  const [sortKeys, setSortKeys] = useState(defaultSort);
  const dispatch = useDispatch();
  const routineScore = useRoutineScore();
  const displayConfig = useDisplayConfig();
  const sessionId = session?.id;
  const sessionName = session?.name;
  const apparatus = session?.apparatus;
  const gender = session?.gender;
  const teamScoringType = session?.teamScoring;
  const isTeamScoring = teamScoringType !== SessionTeamScoreType.NONE;
  const judgePanel = session?.judgePanel;
  const dScorePrecision =
    displayConfig?.svPrecision || displayConfig?.dPrecision;
  const { scorePrecision } = displayConfig;
  const isStartValue = displayConfig.leaderboardSV;
  const showMedals = displayConfig?.leaderboardMedals;
  const teamsByAthleteId = useTeamsByAthleteId(sessionId);

  useEffect(() => {
    if (!alert.clear) {
      setIsLoading(false);
    }
  }, [alert.clear]);

  useEffect(() => {
    if (lineups && lineups.length !== 0) {
      setIsLoading(false);
    }
  }, [lineups]);

  useEffect(() => {
    dispatch(alertActions.clear());
  }, [dispatch]);

  const close = () => {
    if (!alert.clear) {
      dispatch(alertActions.clear());
    }
    onHide();
  };

  // Table row sorting based on apparatus order
  const apparatusSort = useMemo(
    () => (rowA, rowB, columnId, choices) => {
      const a = rowA && choices.indexOf(rowA?.original[columnId]);
      const b = rowB && choices.indexOf(rowB?.original[columnId]);

      if (a > b) return 1;
      if (b > a) return -1;
      return 0;
    },
    []
  );

  // Required for conversion between int and string

  const scoreSort = useMemo(
    () => (rowA, rowB, columnId) => {
      const a =
        rowA && rowA?.isGrouped
          ? convertDisplayToMillipoints(rowA?.values[columnId], scorePrecision)
          : rowA?.original &&
            convertDisplayToMillipoints(
              rowA?.original[columnId],
              scorePrecision
            );
      const b =
        rowB && rowB?.isGrouped
          ? convertDisplayToMillipoints(rowB?.values[columnId], scorePrecision)
          : rowB?.original &&
            convertDisplayToMillipoints(
              rowB?.original[columnId],
              scorePrecision
            );

      if (a > b) return 1;
      if (b > a) return -1;
      return 0;
    },
    []
  );

  const rankSort = useMemo(
    () => (rowA, rowB, columnId) => {
      const a =
        rowA && rowA?.isGrouped ? rowA.values : rowA.original && rowA?.original;

      const b =
        rowB && rowB?.isGrouped ? rowB.values : rowB.original && rowB?.original;

      // console.log(rowA.isGrouped);
      //console.log(rowA.values);
      // console.log(rowA);
      //console.log(columnId);

      const aComp = rowA?.isGrouped ? a[columnId]?.[0]?.rank : a[columnId];
      const bComp = rowB?.isGrouped ? b[columnId]?.[0]?.rank : b[columnId];

      if (aComp > bComp) return 1;
      if (aComp < bComp) return -1;
      if (
        aComp === bComp &&
        convertDisplayToMillipoints(a['e'], scorePrecision) <
          convertDisplayToMillipoints(b['e'], scorePrecision)
      )
        return 1;
      if (
        aComp === bComp &&
        convertDisplayToMillipoints(a['e'], scorePrecision) >
          convertDisplayToMillipoints(b['e'], scorePrecision)
      )
        return -1;
      return 0;
    },
    []
  );

  const rankMedal = (rank, isTie, show = true) => {
    return (
      <div style={{ position: 'relative', minWidth: '2rem' }}>
        <span>{rank === 0 ? '' : rank}</span>
        {isTie ? <sup>T</sup> : null}
        {!show ? null : (
          <div
            style={{
              position: 'absolute',
              width: '1.5rem',
              height: '1.5rem',
              borderRadius: '1.5rem',
              right: '0',
              top: '0',
              background: `${
                rank === 1
                  ? 'radial-gradient(transparent 33%, gold 24%, transparent 36%, gold 50%)'
                  : rank === 2
                  ? 'radial-gradient(transparent 33%, silver 24%, transparent 36%, silver 50%)'
                  : rank === 3
                  ? 'radial-gradient(transparent 33%, #E59E41 24%, transparent 36%, #E59E41 50%)'
                  : 'none'
              }`,
            }}
          />
        )}
      </div>
    );
  };

  const headers = useMemo(
    () =>
      [
        {
          Header: <span className="columnLeftHeader">#</span>,
          accessor: 'rank',
          sortType: rankSort,
          sortDescFirst: true,
          sortInverted: true,
          Cell: (r) => {
            const { row } = r;
            const { rank, isTie } = row.original;
            return rankMedal(rank, isTie, showMedals);
          },
          aggregate: (rank) => {
            return rank;
          },
          aggregateValue: (values, row, column) => {
            let result = { ...values };
            if (groupKeys.includes('name')) {
              result = {
                ...result,
                rank: row.original.aaRanking,
                isTie: row.original.aaIsTie,
              };
            }

            if (groupKeys.includes('team')) {
              result = {
                ...result,
                rank: row.original.teamRanking,
                isTie: row.original.teamIsTie,
              };
            }
            return result;
          },
          Aggregated: ({ row, value, rows }) => {
            if (groupKeys.includes('name') || groupKeys.includes('team')) {
              return rankMedal(value[0].rank, value[0].isTie, showMedals);
            }
            return null;
          },
          disableGroupBy: true,
        },
        {
          Header: <span className="columnLeftHeader">Name</span>,
          accessor: 'name',
          Cell: (r) => addBold(r.cell.value, searchTerm),
          Aggregated: ({ value }) => {
            return <span>{value}</span>;
          },
        },
        {
          Header: <span className="columnLeftHeader">Team</span>,
          accessor: 'team',
          Cell: (r) => {
            const logo = r.cell.isGrouped
              ? r.cell.row?.leafRows[0]?.original?.teamLogo
              : r.cell.row?.original?.teamLogo;

            return (
              <>
                {logo ? (
                  <img
                    className="scoreboardLogo"
                    src={`${MEDIAURL}${logo}`}
                    alt=""
                  />
                ) : null}
                <span>{addBold(r.cell.value, searchTerm)}</span>
              </>
            );
          },
          aggregate: (logo, aggregated) => {
            return { logo: logo.length ? logo[0] : '', team: aggregated[0] };
          },
          aggregateValue: (values, row, column) => {
            return row.original.teamLogo;
          },
          Aggregated: (table) => {
            const logo = table.value.logo;
            return (
              <>
                {logo ? (
                  <img
                    className="scoreboardLogo"
                    src={`${MEDIAURL}${logo}`}
                    alt=""
                  />
                ) : null}
                <span>{table.value.team}</span>
              </>
            );
          },
        },
        {
          Header: <span className="columnLeftHeader">Apparatus</span>,
          accessor: 'apparatus',
          filter: 'includes',
          disableGroupBy: true,
          sortType: (rowA, rowB, columnId) => {
            if (
              rowA &&
              rowB &&
              rowA?.original?.gender === rowB?.original?.gender
            ) {
              switch (rowA?.original?.gender) {
                case MALE:
                  return apparatusSort(rowA, rowB, columnId, mApparatusAbbv);
                case FEMALE:
                  return apparatusSort(rowA, rowB, columnId, wApparatusAbbv);
                case COED:
                default:
                  return null;
              }
            }
            return apparatusSort(rowA, rowB, columnId, []);
          },
          Cell: (r) => {
            const gender = r.cell.row?.original?.gender;
            const apparatus = r.cell?.value;
            let result = [];
            // console.log('in cell of apparatus');
            // console.log(r);

            switch (gender) {
              case MALE:
                result = mApparatusAbbv.map((a) => (a === apparatus ? a : ''));
                break;
              case FEMALE:
                result = wApparatusAbbv.map((a) => (a === apparatus ? a : ''));
                break;
              case COED:
              default:
                result = [...wApparatusAbbv, ...mApparatusAbbv].map((a) =>
                  a === apparatus ? a : ''
                );
                break;
            }

            return (
              <Row className="leaderboardApparatusList">
                {result.map((a, i) => (
                  <Col key={`${a}${i}`} className={a ? null : 'empty'}>
                    {a}
                  </Col>
                ))}
              </Row>
            );
          },
          aggregate: (gender, apparatus) => {
            // console.log(gender);
            switch (gender[0]) {
              case MALE:
                return mApparatusAbbv.map((a) =>
                  apparatus.includes(a) ? a : ''
                );
              case FEMALE:
                return wApparatusAbbv.map((a) =>
                  apparatus.includes(a) ? a : ''
                );
              case COED:
              default:
                return [...wApparatusAbbv, ...mApparatusAbbv].map((a) =>
                  apparatus.includes(a) ? a : ''
                );
            }
          },
          aggregateValue: (values, row, column) => {
            // console.log('aggregateValue');
            return row.original.gender;
          },
          Aggregated: ({ row, value }) => {
            // console.log('hello');
            return (
              <Row className="leaderboardApparatusList">
                {value.map((a, i) => (
                  <Col key={`${row.id}${a}${i}`} className={a ? null : 'empty'}>
                    {a}
                  </Col>
                ))}
              </Row>
            );
          },
        },
        {
          Header: <span className="columnRightHeader">Score</span>,
          accessor: 'score',
          sortType: scoreSort,
          sortDescFirst: true,
          disableGroupBy: true,
          Cell: (r) => {
            if (r.cell.row?.original?.status === RoutineStatus.COMPLETE) {
              return (
                <span className="columnRight">
                  {addBold(r.cell.value, searchTerm)}
                </span>
              );
            } else {
              switch (r.cell.row?.original?.status) {
                case RoutineStatus.ON_EVAL:
                  return (
                    <span className="columnRight">
                      <span className="scoreboardRoutineStatus">On Eval</span>
                      <span style={{ color: '#ffc107' }}>&nbsp;◉</span>
                    </span>
                  );
                case RoutineStatus.ON_AIR:
                  return (
                    <span className="columnRight">
                      <span className="scoreboardRoutineStatus">On Air</span>
                      <span style={{ color: '#28a745' }}>&nbsp;◉</span>
                    </span>
                  );
                case RoutineStatus.STANDBY:
                  return (
                    <span className="columnRight">
                      <span className="scoreboardRoutineStatus">Standby</span>
                      <span style={{ color: '#6c757d' }}>&nbsp;◉</span>
                    </span>
                  );
                default:
                  return '';
              }
            }
          },
          aggregate: (scores, aggregatedValues) => {
            const scoreMps = scores.map((s) =>
              convertDisplayToMillipoints(s, scorePrecision)
            );
            const value = scoreMps.reduce((total, score) => total + score, 0);
            const result = convertMillipointsToDisplay(value, scorePrecision);
            if (groupKeys.includes('team')) {
              // Hacky way of overriding the team score calculation

              // console.log(scores);
              // console.log(aggregatedValues);
              // console.log(convertMillipointsToDisplay(scores[0]));
              return convertMillipointsToDisplay(scores[0], scorePrecision);
            }
            return result;
          },
          aggregateValue: (values, row, column) => {
            if (groupKeys.includes('team')) {
              // console.log(row);
              // console.log(values);
              return row?.original?.teamScore;
            }
            return values;
          },
          Aggregated: ({ value, cell, row }) => {
            let score = value;
            if (groupKeys.includes('team')) {
              // console.log(value);
              score = value;
            }
            return (
              <span className="columnRight">{addBold(score, searchTerm)}</span>
            );
          },
        },
        {
          Header: (
            <span className="columnRightHeader">
              {isStartValue ? 'SV' : 'Diff.'}
            </span>
          ),
          accessor: 'd',
          sortType: scoreSort,
          sortDescFirst: true,
          disableGroupBy: true,
          excludeFromOpen: true,
          Cell: (r) => {
            if (r.cell.row?.original?.status === RoutineStatus.COMPLETE) {
              return (
                <span className="columnRight">
                  {addBold(r.cell.value, searchTerm)}
                </span>
              );
            } else {
              return '';
            }
          },
          aggregate: (scores) => {
            const scoreMps = scores.map((s) => convertDisplayToMillipoints(s));
            const value = scoreMps.reduce((total, score) => {
              return total + (!!score ? score : 0);
            }, 0);
            const result =
              (!!value &&
                Number(convertMillipointsToDisplay(value)).toFixed(
                  dScorePrecision
                )) ??
              '';
            return result;
          },
          Aggregated: ({ value, row }) => {
            let score = value;
            if (row.groupByID === 'team' && row.canExpand) {
              score = '';
            }

            return (
              <span
                style={{
                  fontVariantNumeric: 'tabular-nums',
                }}
                className="columnRight"
              >
                {addBold(score, searchTerm)}
              </span>
            );
          },
        },
        {
          Header: <span className="columnRightHeader">Exec.</span>,
          accessor: 'e',
          sortType: scoreSort,
          sortDescFirst: true,
          disableGroupBy: true,
          excludeFromOpen: true,
          Cell: (r) => {
            if (r.cell.row?.original?.status === RoutineStatus.COMPLETE) {
              return (
                <span className="columnRight">
                  {addBold(r.cell.value, searchTerm)}
                </span>
              );
            } else {
              return '';
            }
          },
          aggregate: (scores) => {
            const scoreMps = scores.map((s) => convertDisplayToMillipoints(s));
            const value = scoreMps.reduce((total, score) => {
              return total + (!!score ? score : 0);
            }, 0);
            const result =
              (!!value && convertMillipointsToDisplay(value)) ?? '';
            return result;
          },
          Aggregated: ({ value, row }) => {
            let score = value;
            if (row.groupByID === 'team' && row.canExpand) {
              score = '';
            }
            return (
              <span className="columnRight">{addBold(score, searchTerm)}</span>
            );
          },
        },
        {
          Header: <span className="columnRightHeader">SB</span>,
          accessor: 'sb',
          sortType: scoreSort,
          sortDescFirst: true,
          disableGroupBy: true,
          excludeFromOpen: true,
          Cell: (r) => {
            if (r.cell.row?.original?.status === RoutineStatus.COMPLETE) {
              return r.cell.value > 0 ? (
                <div className="green" style={{ marginTop: '-2px' }}>
                  <span className="columnRight">{sCircle}</span>
                </div>
              ) : (
                ''
              );
            } else {
              return '';
            }
          },
          aggregate: (scores) => {
            const scoreMps = scores.map((s) => convertDisplayToMillipoints(s));
            const result = scoreMps.reduce((total, score) => total + score, 0);
            return convertMillipointsToDisplay(result);
          },
          Aggregated: ({ value }) => {
            return value > 0 ? (
              <div className="green" style={{ marginTop: '-2px' }}>
                {sCircle}
                <span className="superScriptCount">
                  &nbsp;
                  {Number.parseInt(value * 10) > 1
                    ? Number.parseInt(value * 10)
                    : null}
                </span>
              </div>
            ) : (
              ''
            );
          },
        },
        {
          accessor: 'isIndividual',
          Cell: (r) => {
            return (
              <span>{r.cell.row?.original?.isIndividual ? 'T' : 'F'}</span>
            );
          },
        },
      ].filter(
        (col) => !(col.excludeFromOpen && judgePanel === judgePanelType.OPEN)
      ),
    [
      searchTerm,
      scoreSort,
      apparatusSort,
      COED,
      FEMALE,
      MALE,
      groupKeys,
      rankSort,
      judgePanel,
    ]
  );

  const data = useMemo(() => {
    const allScores = [];
    const rankings = {
      team: [],
      AA: {},
    };

    for (let i = 0; i < lineups.length; i++) {
      const lineupData = JSON.parse(lineups[i].lineupData);
      const currentLineup = lineups[i];
      const { title, forceTitle, individuals } = currentLineup;

      const routines = Object.values(
        currentLineup?.routines.items.reduce((acc, item) => {
          const key = `${item?.athlete?.name}, ${item?.lineup?.team?.name}, ${
            item.apparatus
          }, ${item.order + 1}`; // Create a composite key
          if (item.status !== RoutineStatus.DELETED) {
            if (acc[key]) {
              if (new Date(acc[key].updatedAt) < new Date(item.updatedAt)) {
                acc[key] = item;
              }
            } else {
              acc[key] = item;
            }
          }
          return acc;
        }, {})
      );

      const baseScore = sessionScore(routines, teamScoringType);
      const { neutral } = sessionPenalties(currentLineup);
      const teamScore = baseScore - neutral;

      if (!individuals) {
        rankings['team'] = [...rankings['team'], teamScore];
      }

      const teamAltName = displayName(
        currentLineup.team.name,
        currentLineup.team.altNames
      );
      const teamDisplayName = forceTitle ? title : teamAltName;

      allScores.push(
        ...routines
          .map((r) => {
            const rotation = r.rotation;
            const lineupSlot = lineupData?.[rotation]?.[r.order];

            if (!lineupSlot) {
              return {};
            }

            const athleteId = lineupData[rotation][r.order].athleteId;
            const name = lineupData[rotation][r.order].name;
            const matchLineup = athleteId === r.athleteId;
            const rScore = routineScore(r);

            // Not handling exhibitions at this time like scoring.js
            rankings[r.apparatus] = rankings[r.apparatus]
              ? [...rankings[r.apparatus], r.score]
              : [r.score];
            rankings['AA'][r.athleteId] = rankings['AA'][r.athleteId]
              ? (rankings['AA'][r.athleteId] += r.score)
              : r.score;

            const rosterTeam = teamsByAthleteId[athleteId]; // unique to leaderboard not scoring.js
            const { name: rosterTeamName } = teamBrand(rosterTeam);

            return {
              apparatus: r.apparatus,
              score: convertMillipointsToDisplay(r.score, scorePrecision),
              order: r.order,
              team: individuals ? rosterTeamName : teamDisplayName, // unique to leaderboard
              // team:
              //   (rosterTeam
              //     ? displayName(rosterTeam.name, rosterTeam.altNames)
              //     : displayName(
              //         currentLineup.team.name,
              //         currentLineup.team.altNames
              //       )) +
              //   (sessionName !== currentLineup.title
              //     ? `: ${currentLineup.title}`
              //     : ''),
              rotation: r.rotation,
              name: matchLineup ? name : '',
              updatedAt: r.updatedAt,
              startedAt: r.startedAt,
              completedAt: r.completedAt,
              athleteId: r.athleteId,
              gender: currentLineup.team.gender,
              teamLogo: JSON.parse((rosterTeam || currentLineup.team)?.logos)
                ?.metaData?.filename,
              status: r.status,
              d: convertMillipointsToDisplay(rScore.scoreD, dScorePrecision),
              e: convertMillipointsToDisplay(rScore.scoreEAverage, 3),
              sb: convertMillipointsToDisplay(rScore.scoreSB, 1),
              teamScore: teamScore,
              isIndividual: !!individuals,
            };
          })
          .filter((r) => r?.name && r?.athleteId)
      );
    }

    // Apply rankings
    const aaRankings = rankings['AA'];
    rankings['AA'] = Object.values(rankings['AA']);
    Object.keys(rankings).forEach((k) => {
      rankings[k] = rankings[k].slice().sort((a, b) => b - a);
    });

    allScores.forEach((s) => {
      s.rank =
        rankings[s.apparatus].indexOf(
          convertDisplayToMillipoints(s.score, scorePrecision)
        ) + 1;
      s.isTie =
        s.rank < rankings[s.apparatus].length &&
        convertDisplayToMillipoints(s.score, scorePrecision) ===
          rankings[s.apparatus][s.rank];
      s.teamRanking = rankings['team'].indexOf(s.teamScore) + 1;
      s.teamIsTie =
        s.teamRanking < rankings['team'].length &&
        s.teamScore === rankings['team'][s.teamRanking];
      s.aaRanking = rankings['AA'].indexOf(aaRankings[s.athleteId]) + 1;
      s.aaIsTie =
        s.aaRanking < rankings['AA'].length &&
        aaRankings[s.athleteId] === rankings['AA'][s.aaRanking];
    });

    // Sort by CompletedAt
    const sortedScores = allScores.sort((a, b) => {
      return (
        new Date(a.completedAt).getTime() - new Date(b.completedAt).getTime()
      );
    });

    return sortedScores;
  }, [lineups, teamScoringType, routineScore, filterIndex, filterKeys]);

  const table = () => {
    const empty = data.length === 0;
    return empty ? (
      noResults('scores')
    ) : (
      <TableComponent
        columns={headers}
        data={data}
        textSort={true}
        //onClick={athleteDetail}
        searchTerm={searchTerm}
        showFooter={true}
        filterColumns={[
          'name',
          'team',
          'apparatus',
          'score',
          'd',
          'e',
          'sb',
          'isIndividual',
        ]}
        filterKeys={filterKeys}
        groupKeys={groupKeys}
        sortKeys={sortKeys}
        groupable={true}
        fixedHeader={true}
        lineNumbers={false}
        initialState={{
          hiddenColumns: [
            //isTeamScoring ? null : 'team',
            'isIndividual',
            displayConfig.leaderboardD ? null : 'd',
            displayConfig.leaderboardE ? null : 'e',
            displayConfig.leaderboardSB ? null : 'sb',
          ],
        }}
      />
    );
  };

  const handleSearch = (query) => {
    setSearchTerm(query);
  };

  const teamList = lineups
    .filter((l) => !l?.individuals)
    .map((s) => ({
      id: 'team',
      value:
        displayName(s.team.name, s.team.altNames) +
        (sessionName !== s.title ? `: ${s.title}` : ''),
    }));

  const apparatusList = [];

  apparatus.split('').forEach((a, i) => {
    if (Number.parseInt(a) !== 0) {
      apparatusList.push({
        id: 'apparatus',
        value: numToApparatus(i + 1, true, gender),
      });
    }
  });

  const teamFilterList = isTeamScoring
    ? [{ id: 'team', value: 'Teams' }, ...teamList]
    : [];

  const allFilterList = isTeamScoring ? [{ id: 'all', value: 'All' }] : [];

  const filterList = [
    ...allFilterList,
    //{ id: 'score', value: 'Top 3' },
    { id: 'name', value: 'AA' },
    ...apparatusList,
    ...teamFilterList,
  ];

  useEffect(() => {
    if (!isTeamScoring) {
      setGroupKeys(['name']);
      setFilterKeys([]);
      setSortKeys([{ id: 'score', desc: true }]);
      setSortKeys([
        { id: 'rank', desc: true },
        { id: 'apparatus', desc: true },
      ]);
    }
  }, [isTeamScoring]);

  useEffect(() => {
    if (type) {
      let newFilter = null;
      if (isMWApparatus(type)) {
        newFilter = { id: 'apparatus', value: String(type).toUpperCase() };
      } else if (String(type).toUpperCase() === 'AA') {
        newFilter = { id: 'name', value: 'AA' };
      } else if (String(type).toUpperCase() === 'TEAMS') {
        newFilter = { id: 'team', value: 'Teams' };
      } else {
        const isLineup = isLetter(type) && alphaVal(type) < teamList.length;
        if (isLineup) {
          newFilter = teamList[alphaVal(type)];
        }
      }

      if (!!newFilter) {
        handleFilter(newFilter);
        setTimerOn(false);
      }
    }
  }, [type]);

  useHotkeys(
    'left, ,',
    (e) => {
      e.preventDefault();
      const next = (filterIndex - 1 + filterList.length) % filterList.length;
      handleFilter(filterList[next]);
      setTimerOn(false);
    },
    [filterIndex]
  );

  useHotkeys(
    'right, .',
    (e) => {
      e.preventDefault();
      const next = (filterIndex + 1) % filterList.length;
      handleFilter(filterList[next]);
      setTimerOn(false);
    },
    [filterIndex]
  );

  useHotkeys(
    't',
    (e) => {
      e.preventDefault();
      setTimerOn(!timerOn);
    },
    [timerOn]
  );

  useInterval(
    async () => {
      const next = (filterIndex + 1) % filterList.length;
      handleFilter(filterList[next]);
    },
    6000,
    timerOn
  );

  const handleClickFilter = (e, filter) => {
    e.preventDefault();
    handleFilter(filter);
    setTimerOn(false);
  };

  const handleFilter = (filter) => {
    apparatusList.forEach(function (a, i) {
      if (filter.value === a.value) {
        setFilterIndex((isTeamScoring ? 2 : 1) + i);
        setFilterKeys([filter]);
        setGroupKeys([]);
        setSortKeys(defaultSort);
      }
    });

    teamList.forEach(function (a, i) {
      if (filter.value === a.value) {
        setFilterIndex(3 + apparatusList.length + i);
        setFilterKeys([filter]);
        setGroupKeys([]);
        setSortKeys(defaultSort);
      }
    });

    switch (filter.value) {
      case 'All':
        setFilterIndex(0);
        setSearchTerm('');
        setFilterKeys([]);
        setGroupKeys([]);
        setSortKeys(defaultSort);
        break;
      //case 'Top 3':
      case 'AA':
        setFilterIndex(isTeamScoring ? 1 : 0);
        setGroupKeys(['name']);
        setFilterKeys([]);
        setSortKeys([{ id: 'score', desc: true }]);
        setSortKeys([
          { id: 'rank', desc: true },
          { id: 'apparatus', desc: true },
        ]);
        break;
      case 'Teams':
        setFilterIndex(2 + apparatusList.length);
        // setGroupKeys(['team', 'apparatus']);
        setGroupKeys(['team']);
        setFilterKeys([{ id: 'isIndividual', value: 'F' }]);
        setSortKeys([{ id: 'score', desc: true }]);
        break;
      default:
        return null;
    }
  };

  const filters = () => {
    const isSelected = (filter) => {
      let selected = false;

      // Handle the All condition
      if (
        filterKeys.length === 0 &&
        filter.id === 'all' &&
        groupKeys.length === 0
      ) {
        return true;
      }

      for (let i = 0; i < filterKeys.length; i++) {
        if (filterKeys[i]?.value === filter.value) {
          return true;
        }
      }

      for (let i = 0; i < groupKeys.length; i++) {
        if (groupKeys[i] === filter.id && filter.value === 'Teams') {
          return true;
        }
        if (groupKeys[i] === filter.id && filter.value === 'AA') {
          return true;
        }
      }

      return selected;
    };

    const Arrow = ({ children, disabled, onClick, left, right }) => {
      return (
        <button
          className="arrow"
          disabled={disabled}
          onClick={onClick}
          style={{
            display: disabled ? 'none' : 'block',
            left: left ? 0 : null,
            right: right ? 0 : null,
            background: left
              ? 'linear-gradient(90deg, #fff, #fff 70%, rgba(255,255,255,0))'
              : 'linear-gradient(270deg, #fff, #fff 70%, rgba(255,255,255,0))',
          }}
        >
          {children}
        </button>
      );
    };

    const LeftArrow = () => {
      const { isFirstItemVisible, scrollPrev } = useContext(VisibilityContext);

      return (
        <Arrow
          left={true}
          disabled={isFirstItemVisible}
          onClick={() => scrollPrev()}
        >
          {chevronLeftIcon}
        </Arrow>
      );
    };

    const RightArrow = () => {
      const { isLastItemVisible, scrollNext } = useContext(VisibilityContext);

      return (
        <Arrow
          right={true}
          disabled={isLastItemVisible}
          onClick={() => scrollNext()}
        >
          {chevronRightIcon}
        </Arrow>
      );
    };

    const Card = ({ selected, itemId, filter }) => {
      return (
        <Button
          className={[
            'scoreboardFilterButton',
            isSelected(filter) ? 'selected' : null,
          ].join(' ')}
          variant="light"
          onClick={(e) => handleClickFilter(e, filter)}
        >
          {filter.value}
        </Button>
      );
    };

    return (
      <div className="filterMenuContainer">
        <ScrollMenu LeftArrow={LeftArrow} RightArrow={RightArrow}>
          {filterList.map((f) => {
            return (
              <Card
                itemId={`${f.id}_${f.value}`} // NOTE: itemId is required for track items
                key={`${f.id}_${f.value}`}
                filter={f}
              />
            );
          })}
        </ScrollMenu>
      </div>
    );
  };

  const body = () => {
    return (
      <>
        {!alert.clear && false ? (
          <Row style={{ padding: '0 15px', margin: '1rem -15px' }}>
            <Alert
              dismissible
              onClose={() => dispatch(alertActions.clear())}
              variant={alert.type === 'alert-danger' ? 'danger' : 'success'}
              style={{ textAlign: 'center', width: '100%' }}
            >
              {alert.message}
            </Alert>
          </Row>
        ) : null}
        {filters()}
        {isLoading ? <Loading /> : table()}
      </>
    );
  };

  const footer = () => {
    return (
      <>
        <Row className="vCenter" style={{ margin: '0 auto' }}>
          <Button
            variant="outline-secondary"
            className="closeButton"
            onClick={close}
            disabled={isLoading}
          >
            Close
          </Button>
        </Row>
        <Row />
      </>
    );
  };

  const headerMsg = () => {
    return (
      <>
        <span style={{ paddingLeft: '0.4rem' }}>Leaderboard:</span>
        <span className="scoreboardSessionName"> {sessionName}</span>
      </>
    );
  };

  const headerTeamScore = () => {
    const sortedLineups = [...lineups]
      .filter((l) => !l?.individuals)
      .sort((a, b) => a.order - b.order);

    const result = sortedLineups.map((l) => {
      const routines = Object.values(
        l?.routines.items.reduce((acc, item) => {
          const key = `${item?.athlete?.name}, ${item?.lineup?.team?.name}, ${
            item.apparatus
          }, ${item.order + 1}`; // Create a composite key
          if (item.status !== RoutineStatus.DELETED) {
            if (acc[key]) {
              if (new Date(acc[key].updatedAt) < new Date(item.updatedAt)) {
                acc[key] = item; // Keep the most recent item
              }
            } else {
              acc[key] = item; // Keep the most recent item
            }
          }
          return acc;
        }, {})
      );

      const baseScore = sessionScore(routines, teamScoringType);
      const { neutral } = sessionPenalties(l);
      const teamScore = baseScore - neutral;
      const teamLogo = JSON.parse(l.team?.logos)?.metaData?.filename;

      return (
        <span key={`${l.team.id}_${l.id}`}>
          <img
            className="scoreboardHeaderLogo"
            src={`${MEDIAURL}${teamLogo}`}
            alt=""
          />
          {convertMillipointsToDisplay(teamScore, scorePrecision)}
        </span>
      );
    });

    return <div className="scoreboardHeaderScores">{result}</div>;
  };

  return (
    <>
      <Modal.Header className="scoreboardHeader">
        {headerMsg()}
        {isTeamScoring ? headerTeamScore() : null}
        <div className="scoreboardFunctions">
          {data?.length !== 0 ? (
            <SearchInput searchTerm={searchTerm} search={handleSearch} />
          ) : null}
          <ExportData sessionId={sessionId} />
          <ToggleTimer setTimerOn={setTimerOn} timerOn={timerOn} />
        </div>
      </Modal.Header>
      <Modal.Body className="scoreboardBody">
        {body()}
        {isScrollLoading ? <ScrollLoading /> : null}
      </Modal.Body>
      <Modal.Footer className="scoreboardFooter">{footer()}</Modal.Footer>
    </>
  );
}

export default Leaderboard;
