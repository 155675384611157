import { useQuery } from '@apollo/client';
import GetMatch from '../apollo/queries/GetMatch.graphql';
const logoPath = 'upload/images/team/';

export const matchA = {
  sessionId: 'rEi16Sj63Q',
  teamA: 'Stanford',
  teamALogo: logoPath + '0W6sAzrt4E1huvv82J16X',
  teamBLogo: logoPath + 'PxDxmb07uD2R7WfxgvD5U',
  teamB: 'Cal',
  gameTime: '2023-01-28T22:00:00.000Z',
};

export const matchB = {
  sessionId: 'pohNIwyCAK',
  teamA: 'Alabama',
  teamALogo: logoPath + '5krk0_YwXvq_C58VbmYD6',
  teamBLogo: logoPath + '5WPFADXjkdDjfNTUnv9b2',
  teamB: 'Auburn',
  gameTime: '2023-02-04T00:30:00.000Z',
};

export const matchC = {
  sessionId: 'oDV8BvfGS5',
  teamA: 'Alaska',
  teamALogo: logoPath + 'TKmVq3b39HRy-6Fz1FJ8M',
  teamBLogo: logoPath + 'jEF0FWWjH-7--oGD-8nTW',
  teamB: 'William & Mary',
  gameTime: '2023-02-04T04:00:00.000Z',
};

export const matchD = {
  sessionId: 'gIuUvCfKTy',
  teamA: 'Washington',
  teamALogo: logoPath + 'PxHRrEmCh4h8dFmITMnSM',
  teamBLogo: logoPath + 'SUgsb-OU3HGO7oWFRzfhd',
  teamB: 'Oregon State',
  gameTime: '2023-02-04T21:00:00.000Z',
};

export const matchE = {
  sessionId: 'axRltrX71Q',
  teamA: 'Stanford',
  teamALogo: logoPath + '0W6sAzrt4E1huvv82J16X',
  teamBLogo: logoPath + 'IvFg7MbvgCuET9yVr2j2-',
  teamB: 'Arizona',
  gameTime: '2023-02-05T02:00:00.000Z',
};

export const matchF = {
  sessionId: 'OJllHYlTo0',
  teamA: 'Simpson',
  teamALogo: logoPath + 'e4ReYbsesszxOxrprmRfI',
  teamBLogo: logoPath + 'DvpQ8aJqMsi2aXyHkqWpb',
  teamB: 'Greenville',
  gameTime: '2023-02-11T17:00:00.000Z',
  title: 'Simpson vs Greenville (W)',
};

export const matchG = {
  sessionId: 'SjuFIVt5ay',
  teamA: 'Illinois',
  teamB: 'Utah',
  teamALogo: logoPath + 't9wWLsOPF2bpVZHqjsogl',
  teamBLogo: logoPath + '4lpZr33sN1C_mxiYQk0LN',
  teamCLogo: logoPath + 'aw4N42hwRE2Ewc7dZ6prh',
  teamDLogo: logoPath + '4meFHEcSQmI9SgiY7CIwr',
  teamC: 'Illinois State',
  teamD: 'Georgia',
  gameTime: '2023-02-11T20:15:00.000Z',
  title: 'Illinois / Utah / Illinois State / Georgia (Metroplex)',
};

export const matchH = {
  sessionId: '7zFNxt-40-',
  teamA: 'Alabama',
  teamB: 'Oklahoma',
  teamALogo: logoPath + '5krk0_YwXvq_C58VbmYD6',
  teamBLogo: logoPath + 'hqph8HiyQRcSV8QF_G78t',
  teamCLogo: logoPath + 'Y4pMJrwhkkku_HEtQvYKN',
  teamDLogo: logoPath + 'TxEa6Awt5quagSXQIhqtk',
  teamC: 'Arkansas',
  teamD: 'Stanford',
  gameTime: '2023-02-12T01:15:00.000Z',
  title: 'Alabama / Oklahoma / Arkansas / Stanford (Metroplex)',
};

export const matchI = {
  sessionId: 'DXi-QwGnwW',
  teamA: 'Yale',
  teamB: 'Bridgeport',
  teamALogo: logoPath + '-l6HqzpxgzJauEm2zPaNJ',
  teamBLogo: logoPath + '4nMkHZZ0t_RtwvQEFqEMr',
  teamCLogo: logoPath + 'fdZErpFakdfjKY8u7q-kS',
  teamC: 'Southern Conn.',
  gameTime: '2023-02-12T18:00:00.000Z',
  title: 'Yale / Bridgeport / Southern Conn.',
};

export const matchJ = {
  sessionId: 'xEigWooLZQ',
  teamA: 'Stanford',
  teamALogo: logoPath + '0W6sAzrt4E1huvv82J16X',
  teamBLogo: logoPath + 'PxHRrEmCh4h8dFmITMnSM',
  teamB: 'Washington',
  gameTime: '2023-02-14T00:00:00.000Z',
};

export const matchK = {
  sessionId: 'GNjYlkT6XM',
  teamA: 'Simpson',
  teamALogo: logoPath + 'e4ReYbsesszxOxrprmRfI',
  teamBLogo: logoPath + 'TKmVq3b39HRy-6Fz1FJ8M',
  teamB: 'Alaska',
  gameTime: '2023-02-18T00:00:00.000Z',
};

export const matchL = {
  sessionId: 'xvHZ0fRdCb',
  teamA: 'William & Mary',
  teamALogo: logoPath + 'jEF0FWWjH-7--oGD-8nTW',
  teamBLogo: logoPath + '4nMkHZZ0t_RtwvQEFqEMr',
  teamB: 'Bridgeport',
  gameTime: '2023-02-18T19:00:00.000Z',
};

export const matchM = {
  sessionId: 'AyyI1OsS5R',
  teamA: 'William & Mary',
  teamALogo: logoPath + 'jEF0FWWjH-7--oGD-8nTW',
  teamBLogo: logoPath + 'OfBVOo4OcfTz9HyW5LcJz',
  teamB: 'Fisk',
  gameTime: '2023-02-19T19:00:00.000Z',
};

export const matchN = {
  sessionId: 'C5loLewqmk',
  teamA: 'Springfield',
  teamALogo: logoPath + 'B90OmKJn62sRjh404Y3eD',
  teamBLogo: logoPath + 'qf-eFY2ramiGGd-35gIe3',
  teamB: 'Centenary',
  gameTime: '2023-02-26T18:00:00.000Z',
};

export const matchO = {
  sessionId: 'Yhrl2JSGLC',
  teamA: 'Washington',
  teamALogo: logoPath + 'PxHRrEmCh4h8dFmITMnSM',
  teamBLogo: logoPath + 'ZaoNOG0LZOU9gwoMdGrnz',
  teamB: 'Arizona State',
  gameTime: '2023-02-26T22:00:00.000Z',
};

export const matchP = {
  sessionId: 'wcfm7zQb2W',
  teamA: 'Bowling Green',
  teamALogo: logoPath + '2zMuDgazSTePXdNLXu6cG',
  teamBLogo: logoPath + 'S7efA9rBwUCOUQONAATb6',
  teamB: 'Ball State',
  gameTime: '2023-03-03T23:00:00.000Z',
};

export const matchQ = {
  sessionId: '0cZZZ2Ua1l',
  teamA: 'San Jose State',
  teamALogo: logoPath + '2OpYQV8pvTrxham5gmSdg',
  teamBLogo: logoPath + 'O_CTTamlZYZlPw-le0CkD',
  teamB: 'Southern Utah',
  gameTime: '2023-03-05T22:00:00.000Z',
};

export const matchR = {
  sessionId: 'NjPdif6MAa',
  teamA: 'Auburn',
  teamALogo: logoPath + '5WPFADXjkdDjfNTUnv9b2',
  teamBLogo: logoPath + 'RfZGXFyiH2JuGXjQ007hQ',
  teamB: 'Penn State',
  gameTime: '2023-03-11T01:00:00.000Z',
};

export const matchS = {
  sessionId: 'Y0lJkz67XN',
  teamA: 'Alabama',
  teamALogo: logoPath + '5krk0_YwXvq_C58VbmYD6',
  teamBLogo: logoPath + 'O71-ZNT0pJ34uussTr4Kp',
  teamB: 'Boise State',
  gameTime: '2023-03-11T01:00:00.000Z',
};

export const matchT = {
  sessionId: '8IV0HhDyZd',
  teamALogo: logoPath + 'yHsLi9L2fa0mKWBmsPRva',
  teamA: 'Cornell',
  teamB: 'William & Mary',
  teamBLogo: logoPath + 'jEF0FWWjH-7--oGD-8nTW',
  teamC: 'Southern Conn.',
  teamCLogo: logoPath + 'fdZErpFakdfjKY8u7q-kS',
  teamD: 'Bridgeport',
  teamDLogo: logoPath + '4nMkHZZ0t_RtwvQEFqEMr',
  gameTime: '2023-03-18T16:00:00.000Z',
  title: 'GEC Championships Session #1',
  multiStream: { 1: 'VT', 2: 'UB', 3: 'BB', 4: 'FX' },
};

export const matchU = {
  sessionId: '2TmRiVgJxi',
  teamALogo: logoPath + 'fqxwp3NIzV6b4uuylbqfH',
  teamA: 'Brown',
  teamB: 'Yale',
  teamBLogo: logoPath + '-l6HqzpxgzJauEm2zPaNJ',
  teamC: 'UPenn',
  teamCLogo: logoPath + '7H7GYktvhz4ygAfhHs-Ed',
  teamD: 'West Chester',
  teamDLogo: logoPath + 'GMA9gX5dEfSAXJ_3RuGJe',
  gameTime: '2023-03-18T22:00:00.000Z',
  title: 'GEC Championships Session #2',
  multiStream: { 1: 'VT', 2: 'UB', 3: 'BB', 4: 'FX' },
};

export const matches2023 = [
  [matchT, matchU],
  [matchR, matchS],
  [matchP, matchQ],
  [matchN, matchO],
  [matchJ, matchK, matchL, matchM],
  [matchF, matchG, matchH, matchI],
  [matchB, matchC, matchD, matchE],
  [matchA],
];

export const matchF24 = {
  sessionId: 'FnZXlreyix',
  teamA: 'Bowling Green',
  teamALogo: logoPath + '2zMuDgazSTePXdNLXu6cG',
  teamB: 'Illinois State',
  teamBLogo: logoPath + 'aw4N42hwRE2Ewc7dZ6prh',
  gameTime: '2024-01-07T19:00:00.000Z',
  title: 'Bowling Green vs Illinois State',
};

export const matchE24 = {
  sessionId: 'Ul9Rd2Gda4',
  teamA: 'UCLA',
  teamALogo: logoPath + 'LvA1VLX0gEgeqNb11WmGm',
  teamB: 'Cal',
  teamBLogo: logoPath + 'PxDxmb07uD2R7WfxgvD5U',
  teamC: 'Auburn',
  teamCLogo: logoPath + '5WPFADXjkdDjfNTUnv9b2',
  teamD: 'Alabama',
  teamDLogo: logoPath + '5krk0_YwXvq_C58VbmYD6',
  gameTime: '2024-01-07T02:00:00.000Z',
  title: 'Super 16: Session Four',
};

export const matchD24 = {
  sessionId: 'Bpbv1cfjxZ',
  teamA: 'Oregon State',
  teamALogo: logoPath + 'SUgsb-OU3HGO7oWFRzfhd',
  teamB: 'Minnesota',
  teamBLogo: logoPath + 'L1nfNwj-98ysElWuNme9W',
  teamC: 'Southern Utah',
  teamCLogo: logoPath + 'O_CTTamlZYZlPw-le0CkD',
  teamD: 'BYU',
  teamDLogo: logoPath + 'IjOElnrwW4y1C_fOjQDqP',
  gameTime: '2024-01-06T20:00:00.000Z',
  title: 'Super 16: Session Three',
};

export const matchC24 = {
  sessionId: 'rUwuGGB1nO',
  teamA: 'Oklahoma',
  teamALogo: logoPath + 'hqph8HiyQRcSV8QF_G78t',
  teamB: 'Kentucky',
  teamBLogo: logoPath + 'jBxu8sY8t1WJSF6e43fOh',
  teamC: 'Michigan State',
  teamCLogo: logoPath + '3fMqUSVleD8cXKx7acX-5',
  teamD: 'Michigan',
  teamDLogo: logoPath + '_pStX-aYWTvumVvf_BPYe',
  gameTime: '2024-01-06T03:00:00.000Z',
  title: 'Super 16: Session Two',
};

export const matchB24 = {
  sessionId: 'vpNBY1X6yS',
  teamA: 'Washington',
  teamALogo: logoPath + 'PxHRrEmCh4h8dFmITMnSM',
  teamB: 'Denver',
  teamBLogo: logoPath + 'H04xwPRzTS5vbEFaXbx4W',
  teamC: 'Illinois',
  teamCLogo: logoPath + 't9wWLsOPF2bpVZHqjsogl',
  teamD: 'San Jose State',
  teamDLogo: logoPath + '2OpYQV8pvTrxham5gmSdg',
  gameTime: '2024-01-05T21:00:00.000Z',
  title: 'Super 16: Session One',
};

export const matchA24 = {
  sessionId: 'eUb0Ac9vAq',
  teamA: 'UPenn',
  teamALogo: logoPath + '7H7GYktvhz4ygAfhHs-Ed',
  teamB: 'Penn State',
  teamBLogo: logoPath + 'RfZGXFyiH2JuGXjQ007hQ',
  teamC: 'Pitt',
  teamCLogo: logoPath + '3dd_oTAMydg9FcSTb_14p',
  teamD: 'Temple',
  teamDLogo: logoPath + '-2aUC7ugrc_ZH7Gcip46v',
  gameTime: '2024-01-05T23:00:00.000Z',
  title: 'Keystone Classic',
};

export const matchG24 = {
  sessionId: 'mM_ijMk2ZX',
  teamA: 'UW-Eau Claire',
  teamALogo: logoPath + 'LauSa_ZJaGoO2xcVMSdzx',
  teamB: 'Winona State',
  teamBLogo: logoPath + 'WeJiXDNMqwN034-bzJsev',
  teamC: 'UW-Whitewater',
  teamCLogo: logoPath + 'CbWKimoC_0RpBy-M-lcSy',
  gameTime: '2024-01-11T23:00:00.000Z',
  title: 'WIAC Triangular',
};

export const matchH24 = {
  sessionId: 'ti5cah2B8z',
  teamA: 'Clemson',
  teamALogo: logoPath + 'M_ZZbKT6N5hoeI9gOzW08',
  teamB: 'William & Mary',
  teamBLogo: logoPath + 'MG4yA_-sLEqBckLyfy71-',
  gameTime: '2024-01-13T00:00:00.000Z',
};

export const matchI24 = {
  sessionId: 'evvNdBTiNE',
  teamA: 'Maryland',
  teamALogo: logoPath + 'b3SmbX-UKRPk1HkzRPrD_',
  teamB: 'West Chester',
  teamBLogo: logoPath + 'GMA9gX5dEfSAXJ_3RuGJe',
  gameTime: '2024-01-13T00:00:00.000Z',
};

export const matchJ24 = {
  sessionId: 'ZHshFPhs2E',
  teamA: 'Arkansas',
  teamALogo: logoPath + 'Y4pMJrwhkkku_HEtQvYKN',
  teamB: 'Georgia',
  teamBLogo: logoPath + '4meFHEcSQmI9SgiY7CIwr',
  gameTime: '2024-01-13T00:45:00.000Z',
};

export const matchK24 = {
  sessionId: 'b1bDW6NIpr',
  teamA: 'Bowling Green',
  teamALogo: logoPath + '2zMuDgazSTePXdNLXu6cG',
  teamB: 'Northern Illinois',
  teamBLogo: logoPath + 'F0jgfsNlRXL8b3Qq54Cb-',
  gameTime: '2024-01-13T17:00:00.000Z',
};

export const matchL24 = {
  sessionId: 'D5XE0vMihH',
  teamA: 'Pitt',
  teamALogo: logoPath + '3dd_oTAMydg9FcSTb_14p',
  teamB: 'Kent State',
  teamBLogo: logoPath + '_MlyfrlwP0n50yFezrUXy',
  gameTime: '2024-01-13T21:00:00.000Z',
};
export const matchM24 = {
  sessionId: 'K_oaga9BMT',
  teamA: 'Minnesota',
  teamALogo: logoPath + 'L1nfNwj-98ysElWuNme9W',
  teamB: 'Eastern Michigan',
  teamBLogo: logoPath + '6cWyxe7aJmnRMuhatRDwQ',
  gameTime: '2024-01-13T22:00:00.000Z',
};
export const matchN24 = {
  sessionId: 'HxNMoYoo41',
  teamA: 'Denver',
  teamALogo: logoPath + 'H04xwPRzTS5vbEFaXbx4W',
  teamB: 'Arizona State',
  teamBLogo: logoPath + 'ZaoNOG0LZOU9gwoMdGrnz',
  gameTime: '2024-01-14T20:45:00.000Z',
};

export const matchO24 = {
  sessionId: 'D2y_LD_kzm',
  useBeta: true,
  teamA: 'Fisk',
  teamALogo: logoPath + 'OfBVOo4OcfTz9HyW5LcJz',
  teamB: 'Talladega',
  teamBLogo: logoPath + 'xN4c1x-Sy7TW5aI2WhGmX',
  teamC: 'Iowa State',
  teamCLogo: logoPath + '9__Q1pXevKzpCxsjpRkoA',
  teamD: 'Brown',
  teamDLogo: logoPath + 'bm282RMpGww0qoStmL9Gr',
  teamE: 'William & Mary',
  teamELogo: logoPath + 'MG4yA_-sLEqBckLyfy71-',
  teamF: 'Rutgers',
  teamFLogo: logoPath + 'Keb4zpuxg0jy2H45dR0dD',
  gameTime: '2024-01-15T21:00:00.000Z',
  title: 'MLK Invitational (Canceled)',
};

export const matchP24 = {
  sessionId: '0k79RnKd04',
  teamA: 'Maryland',
  teamALogo: logoPath + 'b3SmbX-UKRPk1HkzRPrD_',
  teamB: 'Rutgers',
  teamBLogo: logoPath + 'Keb4zpuxg0jy2H45dR0dD',
  gameTime: '2024-01-20T01:00:00.000Z',
};

export const matchQ24 = {
  sessionId: 'CkpmY6a7bU',
  teamA: 'Boise State',
  teamALogo: logoPath + 'y_1ONBk8XQMNlCXqUolhg',
  teamB: 'Air Force',
  teamBLogo: logoPath + 'aG4vsbouTroxjQ37u40rr',
  teamC: 'San Jose State',
  teamCLogo: logoPath + '2OpYQV8pvTrxham5gmSdg',
  teamD: 'West Chester',
  teamDLogo: logoPath + 'GMA9gX5dEfSAXJ_3RuGJe',
  gameTime: '2024-01-20T02:00:00.000Z',
  title: 'Boise State Quad',
};

export const matchR24 = {
  sessionId: 'hPCo7KChNE',
  teamA: 'Southern Utah',
  teamALogo: logoPath + 'O_CTTamlZYZlPw-le0CkD',
  teamB: 'Arizona State',
  teamBLogo: logoPath + 'ZaoNOG0LZOU9gwoMdGrnz',
  teamC: 'Utah State',
  teamCLogo: logoPath + 'HdErQDTTzua7xSgglBuEV',
  gameTime: '2024-01-20T02:00:00.000Z',
  title: 'Southern Utah Tri',
};

export const matchS24 = {
  sessionId: 'p1pqaBETGB',
  teamA: 'Simpson',
  teamALogo: logoPath + 'e4ReYbsesszxOxrprmRfI',
  teamBLogo: logoPath + 'DvpQ8aJqMsi2aXyHkqWpb',
  teamB: 'Greenville',
  gameTime: '2024-01-20T19:00:00.000Z',
};

export const matchT24 = {
  sessionId: 'ftaywZGLEV',
  teamA: 'Cornell',
  teamALogo: logoPath + '9uULeRpRgc-bXv0239H3a',
  teamBLogo: logoPath + '4nMkHZZ0t_RtwvQEFqEMr',
  teamB: 'Bridgeport',
  gameTime: '2024-01-21T19:00:00.000Z',
};

export const matchU24 = {
  sessionId: 'S08GGmRFMB',
  teamA: 'Centenary',
  teamALogo: logoPath + 'qf-eFY2ramiGGd-35gIe3',
  teamBLogo: logoPath + 'RlQ4ghHJPe45JOh4yT6Uy',
  teamB: "Texas Woman's",
  gameTime: '2024-01-21T20:00:00.000Z',
};

export const matchV24 = {
  sessionId: 'MqTh79Ciru',
  teamA: 'Clemson',
  teamALogo: logoPath + 'M_ZZbKT6N5hoeI9gOzW08',
  teamBLogo: logoPath + '3dd_oTAMydg9FcSTb_14p',
  teamB: 'Pitt',
  gameTime: '2024-01-21T21:00:00.000Z',
};

export const matchW24 = {
  sessionId: '1lTtBbL1G3',
  teamA: 'Denver',
  teamALogo: logoPath + 'H04xwPRzTS5vbEFaXbx4W',
  teamB: 'Oklahoma',
  teamBLogo: logoPath + 'hqph8HiyQRcSV8QF_G78t',
  teamC: 'UCLA',
  teamCLogo: logoPath + 'LvA1VLX0gEgeqNb11WmGm',
  teamD: 'Stanford',
  teamDLogo: logoPath + '0W6sAzrt4E1huvv82J16X',
  gameTime: '2024-01-21T21:00:00.000Z',
  title: 'Denver vs Oklahoma, UCLA, Stanford',
};

export const matchX24 = {
  sessionId: 'sswjEx52G8',
  teamA: 'Oregon State',
  teamALogo: logoPath + 'SUgsb-OU3HGO7oWFRzfhd',
  teamBLogo: logoPath + 'fqxwp3NIzV6b4uuylbqfH',
  teamB: 'Brown',
  gameTime: '2024-01-21T22:00:00.000Z',
};

export const matchY24 = {
  sessionId: 'WjJue2giGw',
  teamA: 'Ball State',
  teamALogo: logoPath + 'S7efA9rBwUCOUQONAATb6',
  teamB: 'Bowling Green',
  teamBLogo: logoPath + '2zMuDgazSTePXdNLXu6cG',
  gameTime: '2024-01-23T00:00:00.000Z',
};

export const matchZ24 = {
  sessionId: 'qtkyXpRPeW',
  teamA: 'Minnesota',
  teamALogo: logoPath + 'L1nfNwj-98ysElWuNme9W',
  teamBLogo: logoPath + 'p8ENUoErkkuHz3msOYb8d',
  teamB: 'Iowa',
  gameTime: '2024-01-23T01:00:00.000Z',
};

export const matchAA24 = {
  sessionId: 'UlwQI0WdUa',
  teamA: 'UW-Stout',
  teamALogo: logoPath + '2uKGYOQeEjD5U6ofcavh2',
  teamB: 'UW-Eau Claire',
  teamBLogo: logoPath + 'LauSa_ZJaGoO2xcVMSdzx',
  gameTime: '2024-01-27T00:00:00.000Z',
};

export const matchAB24 = {
  sessionId: '8X5LfCygHF',
  teamA: 'Arkansas',
  teamALogo: logoPath + 'Y4pMJrwhkkku_HEtQvYKN',
  teamB: 'Auburn',
  teamBLogo: logoPath + '5WPFADXjkdDjfNTUnv9b2',
  gameTime: '2024-01-27T00:45:00.000Z',
};

export const matchAC24 = {
  sessionId: 'QGn0vH190T',
  teamA: 'UW-Oshkosh',
  teamALogo: logoPath + 'Gq5i5lMGRFcxbXrFBVTp9',
  teamB: 'Simpson',
  teamBLogo: logoPath + 'e4ReYbsesszxOxrprmRfI',
  gameTime: '2024-01-27T01:00:00.000Z',
};

export const matchAE24 = {
  sessionId: '3msjxqOrwu',
  teamA: 'Greenville',
  teamALogo: logoPath + 'DvpQ8aJqMsi2aXyHkqWpb',
  teamB: 'Centenary',
  teamBLogo: logoPath + 'qf-eFY2ramiGGd-35gIe3',
  gameTime: '2024-01-27T20:00:00.000Z',
};

export const matchAF24 = {
  sessionId: 'W-JLNhx-Wo',
  teamA: 'UW-Whitewater',
  teamALogo: logoPath + 'CbWKimoC_0RpBy-M-lcSy',
  teamB: 'UW-La Crosse',
  teamBLogo: logoPath + '3m9gkYeibmj5CT99Ay7Ey',
  gameTime: '2024-01-27T22:00:00.000Z',
};

export const matchAD24 = {
  sessionId: 'hztaHaBaWX',
  teamA: 'West Chester',
  teamALogo: logoPath + 'GMA9gX5dEfSAXJ_3RuGJe',
  teamB: 'SUNY Cortland',
  teamBLogo: logoPath + 'aQuR5cQdzlNHGsY89kU1F',
  teamC: 'SUNY Brockport',
  teamCLogo: logoPath + 'ghW_zO5ZLUPbYGniXSAR-',
  teamD: 'Ithaca',
  teamDLogo: logoPath + 'QA39D-hmXma8IzFp4m__b',
  title: 'West Chester vs Cortland, Brockport, Ithaca',
  gameTime: '2024-01-27T18:00:00.000Z',
};

export const matchAG24 = {
  sessionId: 'W35YCfdzby',
  teamA: 'Minnesota',
  teamALogo: logoPath + 'L1nfNwj-98ysElWuNme9W',
  teamB: 'Maryland',
  teamBLogo: logoPath + 'b3SmbX-UKRPk1HkzRPrD_',
  gameTime: '2024-01-27T22:00:00.000Z',
};

export const matchAH24 = {
  sessionId: 'A7f71xfzmb',
  teamA: 'Cal',
  teamALogo: logoPath + 'PxDxmb07uD2R7WfxgvD5U',
  teamB: 'Oregon State',
  teamBLogo: logoPath + 'SUgsb-OU3HGO7oWFRzfhd',
  gameTime: '2024-01-27T22:00:00.000Z',
};

export const matchAI24 = {
  sessionId: 'ljJkUqrRad',
  teamA: 'Springfield',
  teamALogo: logoPath + 'B90OmKJn62sRjh404Y3eD',
  teamB: 'Rhode Island College',
  teamBLogo: logoPath + 'SUgsb-OU3HGO7oWFRzfhd',
  gameTime: '2024-01-28T18:00:00.000Z',
};

export const matchAJ24 = {
  sessionId: 'bUEEu0CAal',
  teamA: 'Yale',
  teamALogo: logoPath + '-l6HqzpxgzJauEm2zPaNJ',
  teamB: 'Penn',
  teamBLogo: logoPath + '7H7GYktvhz4ygAfhHs-Ed',
  gameTime: '2024-01-28T18:00:00.000Z',
};

export const matchAK24 = {
  sessionId: 'ifu_WtJz_E',
  teamA: 'San Jose State',
  teamALogo: logoPath + '2OpYQV8pvTrxham5gmSdg',
  teamB: 'Sacramento State',
  teamBLogo: logoPath + 'o6Lk9LwIRcHlaeJ_0z1ZJ',
  gameTime: '2024-01-28T22:00:00.000Z',
};

export const matchAL24 = {
  sessionId: 'MFSfy94DXP',
  teamA: 'Bridgeport',
  teamALogo: logoPath + '4nMkHZZ0t_RtwvQEFqEMr',
  teamB: 'Rhode Island',
  teamBLogo: logoPath + 'UVeeOAqNplREWtucLClb-',
  teamC: 'Ursinus',
  teamCLogo: logoPath + 'X0jwCzEEfHa25usxd9wpD',
  teamD: 'Alaska',
  teamDLogo: logoPath + 'TKmVq3b39HRy-6Fz1FJ8M',
  gameTime: '2024-01-26T22:00:00.000Z',
};

export const matchAM24 = {
  sessionId: '9PSUOF6dz5',
  gameTime: '2024-02-03T00:00:00.000Z',
  title: 'Pitt vs UNC ',
  teamA: 'Pitt',
  teamALogo: 'upload/images/team/3dd_oTAMydg9FcSTb_14p',
  teamB: 'UNC',
  teamBLogo: 'upload/images/team/2zuCt_Fvldy7Y7Nh4yCuG',
};

export const matchAN24 = {
  sessionId: 'Koa3KvkH5x',
  gameTime: '2024-02-03T00:00:00.000Z',
  title: 'UW-Stout vs Gustavus ',
  teamA: 'UW-Stout',
  teamALogo: 'upload/images/team/2uKGYOQeEjD5U6ofcavh2',
  teamB: 'Gustavus',
  teamBLogo: 'upload/images/team/jueyFEPEII5hbEk_pHILg',
};

const matchAO24 = {
  sessionId: 'qDu2KhV52P',
  gameTime: '2024-02-03T00:00:00.000Z',
  title: 'Clemson vs NC State ',
  teamA: 'Clemson',
  teamALogo: 'upload/images/team/M_ZZbKT6N5hoeI9gOzW08',
  teamB: 'NC State',
  teamBLogo: 'upload/images/team/q3-Uqn-P1Eoa_XULt81_Z',
};

const matchAP24 = {
  sessionId: 'keZieeX6kI',
  gameTime: '2024-02-04T18:00:00.000Z',
  title: 'Ball State vs Kent State ',
  teamA: 'Ball State',
  teamALogo: 'upload/images/team/S7efA9rBwUCOUQONAATb6',
  teamB: 'Kent State',
  teamBLogo: 'upload/images/team/_MlyfrlwP0n50yFezrUXy',
};

const matchAQ24 = {
  sessionId: 'PAe58ybCAv',
  gameTime: '2024-02-04T18:00:00.000Z',
  title: 'Maryland vs Ohio State',
  teamA: 'Maryland',
  teamALogo: 'upload/images/team/b3SmbX-UKRPk1HkzRPrD_',
  teamB: 'Ohio State',
  teamBLogo: 'upload/images/team/aK78bVxajwW7haLwXIGaM',
};

const matchAR24 = {
  sessionId: 'wEOVK2kScZ',
  gameTime: '2024-02-04T19:00:00.000Z',
  title: 'Bowling Green vs Central Michigan, UW-Oshkosh',
  teamA: 'Bowling Green',
  teamALogo: 'upload/images/team/2zMuDgazSTePXdNLXu6cG',
  teamB: 'CMU',
  teamBLogo: 'upload/images/team/TayK4o3QhC1gcKrHiPeX9',
  teamC: 'UW-Oshkosh',
  teamCLogo: 'upload/images/team/Gq5i5lMGRFcxbXrFBVTp9',
};

// const matchAS24 = {
//   sessionId: '20WDrpHF6b',
//   gameTime: '2024-02-04T19:00:00.000Z',
//   title: 'Hamline vs UW-Whitewater',
//   teamA: 'Hamline',
//   teamALogo: 'upload/images/team/lJNBrvLeQMzRkmVaGvf5e',
//   teamB: 'UW-Whitewater',
//   teamBLogo: 'upload/images/team/CbWKimoC_0RpBy-M-lcSy',
// };

const matchAT24 = {
  sessionId: 'pOcdeLvH4e',
  gameTime: '2024-02-04T19:00:00.000Z',
  title: 'Penn vs Bridgeport, Cornell, William & Mary',
  teamA: 'Penn',
  teamALogo: 'upload/images/team/7H7GYktvhz4ygAfhHs-Ed',
  teamB: 'Bridgeport',
  teamBLogo: 'upload/images/team/4nMkHZZ0t_RtwvQEFqEMr',
  teamC: 'Cornell',
  teamCLogo: 'upload/images/team/9uULeRpRgc-bXv0239H3a',
  teamD: 'William & Mary',
  teamDLogo: 'upload/images/team/MG4yA_-sLEqBckLyfy71-',
};

const matchAU24 = {
  sessionId: 'qvQs408dY2',
  gameTime: '2024-02-04T21:00:00.000Z',
  title: 'Denver vs Towson ',
  teamA: 'Denver',
  teamALogo: 'upload/images/team/H04xwPRzTS5vbEFaXbx4W',
  teamB: 'Towson',
  teamBLogo: 'upload/images/team/7E-jHUkEK-WMl5uA31it-',
};

const matchAV24 = {
  sessionId: 'm7rRvm6RYH',
  gameTime: '2024-02-04T22:00:00.000Z',
  title: 'San Jose State vs Air Force ',
  teamA: 'San Jose State',
  teamALogo: 'upload/images/team/2OpYQV8pvTrxham5gmSdg',
  teamB: 'Air Force',
  teamBLogo: 'upload/images/team/aG4vsbouTroxjQ37u40rr',
};

const matchAW24 = {
  sessionId: 'cr45UL30d_',
  gameTime: '2024-02-06T03:00:00.000Z',
  title: 'Stanford vs Washington',
  teamA: 'Stanford',
  teamALogo: 'upload/images/team/0W6sAzrt4E1huvv82J16X',
  teamB: 'Washington',
  teamBLogo: 'upload/images/team/PxHRrEmCh4h8dFmITMnSM',
};

const matchAX24 = {
  sessionId: '1eXV5CdaXI',
  gameTime: '2024-02-09T23:00:00.000Z',
  title: 'Penn vs Temple',
  teamA: 'Penn',
  teamALogo: 'upload/images/team/7H7GYktvhz4ygAfhHs-Ed',
  teamB: 'Temple',
  teamBLogo: 'upload/images/team/-2aUC7ugrc_ZH7Gcip46v',
};

const matchAY24 = {
  sessionId: 'AYeJWe853r',
  gameTime: '2024-02-09T23:00:00.000Z',
  title: 'UW-Eau Claire vs Simpson',
  teamA: 'UW-Eau Claire',
  teamALogo: 'upload/images/team/LauSa_ZJaGoO2xcVMSdzx',
  teamB: 'Simpson',
  teamBLogo: 'upload/images/team/e4ReYbsesszxOxrprmRfI',
};

const matchAZ24 = {
  sessionId: 'ZhfNVheqqz',
  gameTime: '2024-02-10T00:00:00.000Z',
  title: 'Pitt vs Clemson',
  teamA: 'Pitt',
  teamALogo: 'upload/images/team/3dd_oTAMydg9FcSTb_14p',
  teamB: 'Clemson',
  teamBLogo: 'upload/images/team/M_ZZbKT6N5hoeI9gOzW08',
};

const matchAAA24 = {
  sessionId: 'T2quWUHUJ3',
  gameTime: '2024-02-10T01:00:00.000Z',
  title: 'Centenary vs Alaska, Talladega',
  teamA: 'Centenary',
  teamALogo: 'upload/images/team/qf-eFY2ramiGGd-35gIe3',
  teamB: 'Alaska',
  teamBLogo: 'upload/images/team/TKmVq3b39HRy-6Fz1FJ8M',
  teamC: 'Talladega',
  teamCLogo: 'upload/images/team/xN4c1x-Sy7TW5aI2WhGmX',
};

const matchAAB24 = {
  sessionId: 'TNSCdd1pD1',
  gameTime: '2024-02-10T02:00:00.000Z',
  title: 'Air Force vs Sac State, SEMO',
  teamA: 'Air Force',
  teamALogo: 'upload/images/team/aG4vsbouTroxjQ37u40rr',
  teamB: 'Sac State',
  teamBLogo: 'upload/images/team/o6Lk9LwIRcHlaeJ_0z1ZJ',
  teamC: 'SEMO',
  teamCLogo: 'upload/images/team/P9x9kIRocdJPCwq2sHg53',
};

const matchAAC24 = {
  sessionId: 'ZKEKVdfLKc',
  gameTime: '2024-02-10T02:00:00.000Z',
  title: 'Southern Utah vs Boise State',
  teamA: 'Southern Utah',
  teamALogo: 'upload/images/team/O_CTTamlZYZlPw-le0CkD',
  teamB: 'Boise State',
  teamBLogo: 'upload/images/team/y_1ONBk8XQMNlCXqUolhg',
};

const matchAAD24 = {
  sessionId: '6YOkkuvPuM',
  gameTime: '2024-02-10T02:30:00.000Z',
  title: 'Stanford vs Arizona State ',
  teamA: 'Stanford',
  teamALogo: 'upload/images/team/0W6sAzrt4E1huvv82J16X',
  teamB: 'Arizona State',
  teamBLogo: 'upload/images/team/ZaoNOG0LZOU9gwoMdGrnz',
};

const matchAAE24 = {
  sessionId: 'oPlWOBaEy5',
  gameTime: '2024-02-10T03:00:00.000Z',
  title: 'San Jose State vs UC Davis',
  teamA: 'San Jose State',
  teamALogo: 'upload/images/team/2OpYQV8pvTrxham5gmSdg',
  teamB: 'UC Davis',
  teamBLogo: 'upload/images/team/FSkO6kJa0O9yd7mc4kyck',
};

const matchAAF24 = {
  sessionId: 'FZXhkVXpB2',
  gameTime: '2024-02-10T04:00:00.000Z',
  title: 'Oregon State vs UCLA',
  teamA: 'Oregon State',
  teamALogo: 'upload/images/team/SUgsb-OU3HGO7oWFRzfhd',
  teamB: 'UCLA',
  teamBLogo: 'upload/images/team/LvA1VLX0gEgeqNb11WmGm',
};

const matchAAG24 = {
  sessionId: 'N5_InsfbAY',
  gameTime: '2024-02-10T23:00:00.000Z',
  title: 'Maryland vs Nebraska',
  teamA: 'Maryland',
  teamALogo: 'upload/images/team/b3SmbX-UKRPk1HkzRPrD_',
  teamB: 'Nebraska',
  teamBLogo: 'upload/images/team/T3KXi0DD27Z8-2uXk4MNV',
};

const matchAAH24 = {
  sessionId: 'fZnTahRNep',
  gameTime: '2024-02-10T18:00:00.000Z',
  title: 'West Chester vs Ursinus',
  teamA: 'West Chester',
  teamALogo: 'upload/images/team/GMA9gX5dEfSAXJ_3RuGJe',
  teamB: 'Ursinus',
  teamBLogo: 'upload/images/team/X0jwCzEEfHa25usxd9wpD',
};

// const matchAAJ24 = {
//   sessionId: '7s9mGsqNQU',
//   gameTime: '2024-02-10T21:00:00.000Z',
//   title: 'SCSU vs RIC, Springfield',
//   teamA: 'SCSU',
//   teamALogo: 'upload/images/team/fdZErpFakdfjKY8u7q-kS',
//   teamB: 'RIC',
//   teamBLogo: 'upload/images/team/UVeeOAqNplREWtucLClb-',
//   teamC: 'Springfield',
//   teamCLogo: 'upload/images/team/B90OmKJn62sRjh404Y3eD',
// };

const matchAAI24 = {
  sessionId: 'B53AGYD79c',
  gameTime: '2024-02-10T22:00:00.000Z',
  title: 'Minnesota vs Michigan',
  teamA: 'Minnesota',
  teamALogo: 'upload/images/team/L1nfNwj-98ysElWuNme9W',
  teamB: 'Michigan',
  teamBLogo: 'upload/images/team/_pStX-aYWTvumVvf_BPYe',
};

const matchAAJ24 = {
  sessionId: 'p5jAviwFhZ',
  gameTime: '2024-02-11T18:00:00.000Z',
  title: 'Empire State Collegiate Championship',
  teamA: 'Cornell',
  teamALogo: 'upload/images/team/9uULeRpRgc-bXv0239H3a',
  teamB: 'LIU',
  teamBLogo: 'upload/images/team/_aJBZM4RBuKXJMTdfBOvC',
  teamC: 'Cortland',
  teamCLogo: 'upload/images/team/aQuR5cQdzlNHGsY89kU1F',
  teamD: 'Utica',
  teamDLogo: 'upload/images/team/kZ-c-F3GqjCA8fNwlZxKp',
  teamE: 'Brockport',
  teamELogo: 'upload/images/team/ghW_zO5ZLUPbYGniXSAR-',
  teamF: 'Ithaca',
  teamFLogo: 'upload/images/team/QA39D-hmXma8IzFp4m__b',
};

const matchAAK24 = {
  sessionId: 'usE2bNCkKk',
  gameTime: '2024-02-11T18:00:00.000Z',
  title: 'Yale vs Bridgeport, SCSU',
  teamA: 'Yale',
  teamALogo: 'upload/images/team/-l6HqzpxgzJauEm2zPaNJ',
  teamB: 'Bridgeport',
  teamBLogo: 'upload/images/team/4nMkHZZ0t_RtwvQEFqEMr',
  teamC: 'SCSU',
  teamCLogo: 'upload/images/team/fdZErpFakdfjKY8u7q-kS',
};

const matchAAL24 = {
  sessionId: '4cuMBrfbTn',
  gameTime: '2024-02-11T18:30:00.000Z',
  title: 'Centenary vs Talladega, Alaska, Greenville',
  teamA: 'Centenary',
  teamALogo: 'upload/images/team/qf-eFY2ramiGGd-35gIe3',
  teamB: 'Talladega',
  teamBLogo: 'upload/images/team/xN4c1x-Sy7TW5aI2WhGmX',
  teamC: 'Alaska',
  teamCLogo: 'upload/images/team/TKmVq3b39HRy-6Fz1FJ8M',
  teamD: 'Greenville',
  teamDLogo: 'upload/images/team/DvpQ8aJqMsi2aXyHkqWpb',
};

const matchAAM24 = {
  sessionId: 'Bzf6x_Rw_L',
  gameTime: '2024-02-11T19:00:00.000Z',
  title: 'Bowling Green vs EMU, WVU, Fisk',
  teamA: 'Bowling Green',
  teamALogo: 'upload/images/team/2zMuDgazSTePXdNLXu6cG',
  teamB: 'Eastern Michigan',
  teamBLogo: 'upload/images/team/6cWyxe7aJmnRMuhatRDwQ',
  teamC: 'WVU',
  teamCLogo: 'upload/images/team/A7o7XcB3BbAxavXLRUBhF',
  teamD: 'Fisk',
  teamDLogo: 'upload/images/team/OfBVOo4OcfTz9HyW5LcJz',
};

const matchAAN24 = {
  sessionId: 'KZhSqqj2fo',
  gameTime: '2024-02-11T20:00:00.000Z',
  title: 'California vs Arizona',
  teamA: 'California',
  teamALogo: 'upload/images/team/PxDxmb07uD2R7WfxgvD5U',
  teamB: 'Arizona',
  teamBLogo: 'upload/images/team/IvFg7MbvgCuET9yVr2j2-',
};

const matchAAO24 = {
  sessionId: 'MwbXCx-zQo',
  gameTime: '2024-02-11T21:00:00.000Z',
  title: 'Denver vs Iowa State, SEMO',
  teamA: 'Denver',
  teamALogo: 'upload/images/team/H04xwPRzTS5vbEFaXbx4W',
  teamB: 'Iowa State',
  teamBLogo: 'upload/images/team/9__Q1pXevKzpCxsjpRkoA',
  teamC: 'SEMO',
  teamCLogo: 'upload/images/team/P9x9kIRocdJPCwq2sHg53',
};

const matchAAP24 = {
  sessionId: 'r--956Iviy',
  gameTime: '2024-02-17T00:00:00.000Z',
  title: 'UW-Eau Claire vs UW-Oshkosh',
  teamA: 'UW-Eau Claire',
  teamALogo: 'upload/images/team/LauSa_ZJaGoO2xcVMSdzx',
  teamB: 'UW-Oshkosh',
  teamBLogo: 'upload/images/team/Gq5i5lMGRFcxbXrFBVTp9',
};

const matchAAQ24 = {
  sessionId: 'Fcg0CGq0CY',
  gameTime: '2024-02-17T00:30:00.000Z',
  title: 'Hamline vs Gustavus',
  teamA: 'Hamline',
  teamALogo: 'upload/images/team/lJNBrvLeQMzRkmVaGvf5e',
  teamB: 'Gustavus',
  teamBLogo: 'upload/images/team/jueyFEPEII5hbEk_pHILg',
};

const matchAAR24 = {
  sessionId: 'xrhclIf-bz',
  gameTime: '2024-02-17T02:00:00.000Z',
  title: 'Air Force vs Centenary, UC Davis',
  teamA: 'Air Force',
  teamALogo: 'upload/images/team/y1PAeO8-bQUAsnjjxlYqx',
  teamB: 'Centenary',
  teamBLogo: 'upload/images/team/qf-eFY2ramiGGd-35gIe3',
  teamC: 'UC Davis',
  teamCLogo: 'upload/images/team/FSkO6kJa0O9yd7mc4kyck',
};

const matchAAS24 = {
  sessionId: 'H7xfYfegTc',
  gameTime: '2024-02-17T19:00:00.000Z',
  title: 'Oregon State vs Stanford',
  teamA: 'Oregon State',
  teamALogo: 'upload/images/team/SUgsb-OU3HGO7oWFRzfhd',
  teamB: 'Stanford',
  teamBLogo: 'upload/images/team/0W6sAzrt4E1huvv82J16X',
};

const matchAAT24 = {
  sessionId: 'BoyMegawhB',
  gameTime: '2024-02-17T19:00:00.000Z',
  title: 'Greenville vs Simpson',
  teamA: 'Greenville',
  teamALogo: 'upload/images/team/DvpQ8aJqMsi2aXyHkqWpb',
  teamB: 'Simpson',
  teamBLogo: 'upload/images/team/e4ReYbsesszxOxrprmRfI',
};

// const matchAAU24 = {
//   sessionId: 'sTBkI4QFHL',
//   gameTime: '2024-02-17T21:00:00.000Z',
//   title: 'NC State vs Clemson',
//   teamA: 'NC State',
//   teamALogo: 'upload/images/team/q3-Uqn-P1Eoa_XULt81_Z',
//   teamB: 'Clemson',
//   teamBLogo: 'upload/images/team/M_ZZbKT6N5hoeI9gOzW08',
// };

const matchAAV24 = {
  sessionId: '6yz1k-HYsF',
  gameTime: '2024-02-17T22:00:00.000Z',
  title: 'UW-Whitewater vs UW-Stout',
  teamA: 'UW-Whitewater',
  teamALogo: 'upload/images/team/CbWKimoC_0RpBy-M-lcSy',
  teamB: 'UW-Stout',
  teamBLogo: 'upload/images/team/2uKGYOQeEjD5U6ofcavh2',
};

const matchAAW24 = {
  sessionId: 'Q8U4UoeCI7',
  gameTime: '2024-02-18T18:00:00.000Z',
  title: 'Springfield vs Cortland',
  teamA: 'Springfield',
  teamALogo: 'upload/images/team/B90OmKJn62sRjh404Y3eD',
  teamB: 'Cortland',
  teamBLogo: 'upload/images/team/aQuR5cQdzlNHGsY89kU1F',
};

const matchAAX24 = {
  sessionId: 'ILZd3zuZOp',
  gameTime: '2024-02-24T00:30:00.000Z',
  title: 'Clemson vs UNC',
  teamA: 'Clemson',
  teamALogo: 'upload/images/team/M_ZZbKT6N5hoeI9gOzW08',
  teamB: 'UNC',
  teamBLogo: 'upload/images/team/2zuCt_Fvldy7Y7Nh4yCuG',
};

const matchAAY24 = {
  sessionId: 'oXAc32gygK',
  gameTime: '2024-02-24T00:00:00.000Z',
  title: 'UW-Oshkosh vs UW-Whitewater',
  teamA: 'UW-Oshkosh',
  teamALogo: 'upload/images/team/Gq5i5lMGRFcxbXrFBVTp9',
  teamB: 'UW-Whitewater',
  teamBLogo: 'upload/images/team/CbWKimoC_0RpBy-M-lcSy',
};

const matchAAZ24 = {
  sessionId: 'nobdkUiPpm',
  gameTime: '2024-02-24T01:15:00.000Z',
  title: 'Arkansas vs Kentucky',
  teamA: 'Arkansas',
  teamALogo: 'upload/images/team/Y4pMJrwhkkku_HEtQvYKN',
  teamB: 'Kentucky',
  teamBLogo: 'upload/images/team/jBxu8sY8t1WJSF6e43fOh',
};

const matchAAAA24 = {
  sessionId: 'TLp_IiXAIV',
  gameTime: '2024-02-24T19:00:00.000Z',
  title: 'William & Mary vs West Chester',
  teamA: 'William & Mary',
  teamALogo: 'upload/images/team/MG4yA_-sLEqBckLyfy71-',
  teamB: 'West Chester',
  teamBLogo: 'upload/images/team/GMA9gX5dEfSAXJ_3RuGJe',
};

const matchAAAB24 = {
  sessionId: 'kpM_G0hULk',
  gameTime: '2024-02-24T23:00:00.000Z',
  title: 'Ivy Classic @ Penn',
  teamA: 'Penn',
  teamALogo: 'upload/images/team/7H7GYktvhz4ygAfhHs-Ed',
  teamB: 'Brown',
  teamBLogo: 'upload/images/team/bm282RMpGww0qoStmL9Gr',
  teamC: 'Cornell',
  teamCLogo: 'upload/images/team/9uULeRpRgc-bXv0239H3a',
  teamD: 'Yale',
  teamDLogo: 'upload/images/team/-l6HqzpxgzJauEm2zPaNJ',
};

const matchAAAC24 = {
  sessionId: 'L_hYTPQXuD',
  gameTime: '2024-02-25T20:00:00.000Z',
  title: 'Simpson vs Hamline',
  teamA: 'Simpson',
  teamALogo: 'upload/images/team/e4ReYbsesszxOxrprmRfI',
  teamB: 'Hamline',
  teamBLogo: 'upload/images/team/lJNBrvLeQMzRkmVaGvf5e',
};

const matchAAAD24 = {
  sessionId: 'C2c6IeRlbq',
  gameTime: '2024-02-25T20:00:00.000Z',
  title: 'UW-Stout vs UW-La Crosse',
  teamA: 'UW-Stout',
  teamALogo: 'upload/images/team/2uKGYOQeEjD5U6ofcavh2',
  teamB: 'UWL',
  teamBLogo: 'upload/images/team/3m9gkYeibmj5CT99Ay7Ey',
};

const matchAAAE24 = {
  sessionId: '7f-Rvo0Cgc',
  gameTime: '2024-02-25T21:00:00.000Z',
  title: 'Pitt vs NC State',
  teamA: 'Pitt',
  teamALogo: 'upload/images/team/3dd_oTAMydg9FcSTb_14p',
  teamB: 'NC State',
  teamBLogo: 'upload/images/team/q3-Uqn-P1Eoa_XULt81_Z',
};

const matchAAAF24 = {
  sessionId: '9hKwmzJAPC',
  gameTime: '2024-02-27T00:00:00.000Z',
  title: 'Centenary vs Bridgeport',
  teamA: 'Centenary',
  teamALogo: 'upload/images/team/qf-eFY2ramiGGd-35gIe3',
  teamB: 'Bridgeport',
  teamBLogo: 'upload/images/team/4nMkHZZ0t_RtwvQEFqEMr',
};

const matchAAAG24 = {
  sessionId: 'lVQsJYzyEd',
  gameTime: '2024-02-27T02:00:00.000Z',
  title: 'Southern Utah vs Iowa State',
  teamA: 'Southern Utah',
  teamALogo: 'upload/images/team/O_CTTamlZYZlPw-le0CkD',
  teamB: 'Iowa State',
  teamBLogo: 'upload/images/team/9__Q1pXevKzpCxsjpRkoA',
};

const matchAAAH24 = {
  sessionId: '04xRxXdPUu',
  gameTime: '2024-03-01T00:00:00.000Z',
  title: 'UW-Oshkosh vs UWL, UW-Stout',
  teamA: 'UW-Oshkosh',
  teamALogo: 'upload/images/team/Gq5i5lMGRFcxbXrFBVTp9',
  teamB: 'UWL',
  teamBLogo: 'upload/images/team/3m9gkYeibmj5CT99Ay7Ey',
  teamC: 'UW-Stout',
  teamCLogo: 'upload/images/team/2uKGYOQeEjD5U6ofcavh2',
};

// const matchAAAI24 = {
//    sessionId: "sXIEzoMxmL",
//    gameTime: "2024-03-01T23:00:00.000Z",
//    title: "Brockport vs Utica",
//    teamA: "Brockport",
//    teamALogo: "upload/images/team/ghW_zO5ZLUPbYGniXSAR-",
//    teamB: "Utica",
//    teamBLogo: "upload/images/team/kZ-c-F3GqjCA8fNwlZxKp",
// }

const matchAAAJ24 = {
  sessionId: 'J1R5Hsal35',
  gameTime: '2024-03-02T00:00:00.000Z',
  title: 'Maryland vs CMU, GW, W&M',
  teamA: 'Maryland',
  teamALogo: 'upload/images/team/b3SmbX-UKRPk1HkzRPrD_',
  teamB: 'CMU',
  teamBLogo: 'upload/images/team/TayK4o3QhC1gcKrHiPeX9',
  teamC: 'GW',
  teamCLogo: 'upload/images/team/R7CeYsmiyWvBNZKrG88UH',
  teamD: 'William & Mary',
  teamDLogo: 'upload/images/team/MG4yA_-sLEqBckLyfy71-',
};

// const matchAAAK24 = {
//    sessionId: "YahCIRMBVq",
//    gameTime: "2024-03-02T00:00:00.000Z",
//    title: "Michigan State vs UNH",
//    teamA: "Michigan State",
//    teamALogo: "upload/images/team/3fMqUSVleD8cXKx7acX-5",
//    teamB: "UNH",
//    teamBLogo: "upload/images/team/Ad1PKfzx7Ayt-g_rVjLaX",
// }

const matchAAAL24 = {
  sessionId: 'a0ONcX3iGQ',
  gameTime: '2024-03-02T00:00:00.000Z',
  title: 'UW-Eau Claire vs Hamline',
  teamA: 'UW-Eau Claire',
  teamALogo: 'upload/images/team/LauSa_ZJaGoO2xcVMSdzx',
  teamB: 'Hamline',
  teamBLogo: 'upload/images/team/lJNBrvLeQMzRkmVaGvf5e',
};

const matchAAAM24 = {
  sessionId: 'hcqFm_owo3',
  gameTime: '2024-03-02T02:00:00.000Z',
  title: 'Air Force vs San Jose State, Utah State',
  teamA: 'Air Force',
  teamALogo: 'upload/images/team/y1PAeO8-bQUAsnjjxlYqx',
  teamB: 'San Jose State',
  teamBLogo: 'upload/images/team/2OpYQV8pvTrxham5gmSdg',
  teamC: 'Utah State',
  teamCLogo: 'upload/images/team/HdErQDTTzua7xSgglBuEV',
};

const matchAAAN24 = {
  sessionId: 'HsyDGbaTXa',
  gameTime: '2024-03-02T01:00:00.000Z',
  title: 'Stanford vs UCLA ',
  teamA: 'Stanford',
  teamALogo: 'upload/images/team/0W6sAzrt4E1huvv82J16X',
  teamB: 'UCLA',
  teamBLogo: 'upload/images/team/LvA1VLX0gEgeqNb11WmGm',
};

// const matchAAAO24 = {
//    sessionId: "v0S6BoJJZM",
//    gameTime: "2024-03-02T00:00:00.000Z",
//    title: "Winona State vs Simpson",
//    teamA: "Winona State",
//    teamALogo: "upload/images/team/WeJiXDNMqwN034-bzJsev",
//    teamB: "Simpson",
//    teamBLogo: "upload/images/team/e4ReYbsesszxOxrprmRfI",
// }

const matchAAAP24 = {
  sessionId: 'tQuXkyJWLU',
  gameTime: '2024-03-02T22:00:00.000Z',
  title: 'California vs Utah',
  teamA: 'California',
  teamALogo: 'upload/images/team/PxDxmb07uD2R7WfxgvD5U',
  teamB: 'Utah',
  teamBLogo: 'upload/images/team/4lpZr33sN1C_mxiYQk0LN',
};

const matchAAAQ24 = {
  sessionId: '5se87t88n_',
  gameTime: '2024-03-02T22:00:00.000Z',
  title: 'UW-Whitewater vs Gustavus',
  teamA: 'UW-Whitewater',
  teamALogo: 'upload/images/team/CbWKimoC_0RpBy-M-lcSy',
  teamB: 'Gustavus',
  teamBLogo: 'upload/images/team/jueyFEPEII5hbEk_pHILg',
};

// const matchAAAR24 = {
//    sessionId: "ubRmrt3bZs",
//    gameTime: "2024-03-03T18:00:00.000Z",
//    title: "Cortland vs Utica",
//    teamA: "Cortland",
//    teamALogo: "upload/images/team/aQuR5cQdzlNHGsY89kU1F",
//    teamB: "Utica",
//    teamBLogo: "upload/images/team/kZ-c-F3GqjCA8fNwlZxKp",
// }

const matchAAAS24 = {
  sessionId: 'LBbNc1IvgP',
  gameTime: '2024-03-03T18:00:00.000Z',
  title: 'Springfield vs Ithaca',
  teamA: 'Springfield',
  teamALogo: 'upload/images/team/B90OmKJn62sRjh404Y3eD',
  teamB: 'Ithaca',
  teamBLogo: 'upload/images/team/QA39D-hmXma8IzFp4m__b',
};

const matchAAAT24 = {
  sessionId: 'RspAoOTtax',
  gameTime: '2024-03-03T18:00:00.000Z',
  title: 'Yale vs Maryland, UNH, SCSU',
  teamA: 'Yale',
  teamALogo: 'upload/images/team/-l6HqzpxgzJauEm2zPaNJ',
  teamB: 'Maryland',
  teamBLogo: 'upload/images/team/b3SmbX-UKRPk1HkzRPrD_',
  teamC: 'UNH',
  teamCLogo: 'upload/images/team/Ad1PKfzx7Ayt-g_rVjLaX',
  teamD: 'SCSU',
  teamDLogo: 'upload/images/team/fdZErpFakdfjKY8u7q-kS',
};

const matchAAAU24 = {
  sessionId: '1aQqddNyVj',
  gameTime: '2024-03-03T19:00:00.000Z',
  title: 'Bridgeport vs Rhode Island, Brown, Talladega',
  teamA: 'Bridgeport',
  teamALogo: 'upload/images/team/4nMkHZZ0t_RtwvQEFqEMr',
  teamB: 'RIC',
  teamBLogo: 'upload/images/team/UVeeOAqNplREWtucLClb-',
  teamC: 'Brown',
  teamCLogo: 'upload/images/team/bm282RMpGww0qoStmL9Gr',
  teamD: 'Talladega',
  teamDLogo: 'upload/images/team/xN4c1x-Sy7TW5aI2WhGmX',
};

const matchAAAV24 = {
  sessionId: 'ZY3D2t_9kU',
  gameTime: '2024-03-03T20:45:00.000Z',
  title: 'Denver vs SJSU, Lindenwood, Air Force',
  teamA: 'Denver',
  teamALogo: 'upload/images/team/H04xwPRzTS5vbEFaXbx4W',
  teamB: 'San Jose State',
  teamBLogo: 'upload/images/team/2OpYQV8pvTrxham5gmSdg',
  teamC: 'Lindenwood',
  teamCLogo: 'upload/images/team/7cZrH_sQif_oQplyrQz5d',
  teamD: 'Air Force',
  teamDLogo: 'upload/images/team/y1PAeO8-bQUAsnjjxlYqx',
};

const matchAAAW24 = {
  sessionId: 'ghJjW0T13Q',
  gameTime: '2024-03-05T02:00:00.000Z',
  title: 'Southern Utah vs BYU',
  teamA: 'Southern Utah',
  teamALogo: 'upload/images/team/O_CTTamlZYZlPw-le0CkD',
  teamB: 'BYU',
  teamBLogo: 'upload/images/team/IjOElnrwW4y1C_fOjQDqP',
};

const matchAAAX24 = {
  sessionId: 'sfCGG4I86p',
  gameTime: '2024-03-02T03:00:00.000Z',
  title: 'Oregon State vs Arizona State',
  teamA: 'Oregon State',
  teamALogo: 'upload/images/team/SUgsb-OU3HGO7oWFRzfhd',
  teamB: 'Arizona State',
  teamBLogo: 'upload/images/team/ZaoNOG0LZOU9gwoMdGrnz',
};

// const matchAAAY24 = {
//   sessionId: 'JnASE201wS',
//   gameTime: '2024-03-09T00:00:00.000Z',
//   title: 'Michigan vs Georgia',
//   teamA: 'Michigan',
//   teamALogo: 'upload/images/team/_pStX-aYWTvumVvf_BPYe',
//   teamB: 'Georgia',
//   teamBLogo: 'upload/images/team/4meFHEcSQmI9SgiY7CIwr',
// };

const matchAAAZ24 = {
  sessionId: '_uZJpMxclh',
  gameTime: '2024-03-09T00:00:00.000Z',
  title: 'Clemson vs Air Force',
  teamA: 'Clemson',
  teamALogo: 'upload/images/team/M_ZZbKT6N5hoeI9gOzW08',
  teamB: 'Air Force',
  teamBLogo: 'upload/images/team/y1PAeO8-bQUAsnjjxlYqx',
};

const matchAABA24 = {
  sessionId: 'wxrcbs2Aem',
  gameTime: '2024-03-09T00:00:00.000Z',
  title: 'Pitt vs Bowling Green',
  teamA: 'Pitt',
  teamALogo: 'upload/images/team/3dd_oTAMydg9FcSTb_14p',
  teamB: 'Bowling Green',
  teamBLogo: 'upload/images/team/2zMuDgazSTePXdNLXu6cG',
};

const matchAABB24 = {
  sessionId: '4gVi85EQfz',
  gameTime: '2024-03-09T00:00:00.000Z',
  title: 'West Chester vs Centenary, W&M',
  teamA: 'West Chester',
  teamALogo: 'upload/images/team/GMA9gX5dEfSAXJ_3RuGJe',
  teamB: 'Centenary',
  teamBLogo: 'upload/images/team/qf-eFY2ramiGGd-35gIe3',
  teamC: 'William & Mary',
  teamCLogo: 'upload/images/team/MG4yA_-sLEqBckLyfy71-',
};

const matchAABC24 = {
  sessionId: '7eLMZxxM1u',
  gameTime: '2024-03-09T02:00:00.000Z',
  title: 'Southern Utah vs Penn State',
  teamA: 'Southern Utah',
  teamALogo: 'upload/images/team/O_CTTamlZYZlPw-le0CkD',
  teamB: 'Penn State',
  teamBLogo: 'upload/images/team/RfZGXFyiH2JuGXjQ007hQ',
};

const matchAABD24 = {
  sessionId: 'cDlg0ZuKVF',
  gameTime: '2024-03-09T03:00:00.000Z',
  title: 'Oregon State vs Washington',
  teamA: 'Oregon State',
  teamALogo: 'upload/images/team/SUgsb-OU3HGO7oWFRzfhd',
  teamB: 'Washington',
  teamBLogo: 'upload/images/team/PxHRrEmCh4h8dFmITMnSM',
};

const matchAABE24 = {
  sessionId: 'YahCIRMBVq',
  gameTime: '2024-03-02T00:00:00.000Z',
  title: 'Michigan State vs UNH',
  teamA: 'Michigan State',
  teamALogo: 'upload/images/team/3fMqUSVleD8cXKx7acX-5',
  teamB: 'UNH',
  teamBLogo: 'upload/images/team/Ad1PKfzx7Ayt-g_rVjLaX',
};

const matchAABF24 = {
  sessionId: 'VPdmGgVPWa',
  gameTime: '2024-03-09T19:00:00.000Z',
  title: 'Michigan State vs CMU, Fisk, Greenville',
  teamA: 'Michigan State',
  teamALogo: 'upload/images/team/3fMqUSVleD8cXKx7acX-5',
  teamB: 'CMU',
  teamBLogo: 'upload/images/team/TayK4o3QhC1gcKrHiPeX9',
  teamC: 'Fisk',
  teamCLogo: 'upload/images/team/OfBVOo4OcfTz9HyW5LcJz',
  teamD: 'Greenville',
  teamDLogo: 'upload/images/team/DvpQ8aJqMsi2aXyHkqWpb',
};

const matchAABG24 = {
  sessionId: 'LhyJf8JFap',
  gameTime: '2024-03-10T17:00:00.000Z',
  title: 'Cornell vs Centenary',
  teamA: 'Cornell',
  teamALogo: 'upload/images/team/9uULeRpRgc-bXv0239H3a',
  teamB: 'Centenary',
  teamBLogo: 'upload/images/team/qf-eFY2ramiGGd-35gIe3',
};

const matchAABH24 = {
  sessionId: '_Yjndp8bAA',
  gameTime: '2024-03-10T21:00:00.000Z',
  title: 'Arkansas vs Oklahoma',
  teamA: 'Arkansas',
  teamALogo: 'upload/images/team/Y4pMJrwhkkku_HEtQvYKN',
  teamB: 'Oklahoma',
  teamBLogo: 'upload/images/team/JjSGKxJh81xZlOMu-Oxd1',
};

const matchAABI24 = {
  sessionId: 'sjvniB-xrw',
  gameTime: '2024-03-10T21:00:00.000Z',
  title: 'SJSU vs Boise State, Illinois State, Yale',
  teamA: 'San Jose State',
  teamALogo: 'upload/images/team/2OpYQV8pvTrxham5gmSdg',
  teamB: 'Boise State',
  teamBLogo: 'upload/images/team/y_1ONBk8XQMNlCXqUolhg',
  teamC: 'Illinois State',
  teamCLogo: 'upload/images/team/aw4N42hwRE2Ewc7dZ6prh',
  teamD: 'Yale',
  teamDLogo: 'upload/images/team/-l6HqzpxgzJauEm2zPaNJ',
};

const matchAABJ24 = {
  sessionId: 'wGZm_oX_Wt',
  gameTime: '2024-03-10T21:00:00.000Z',
  title: 'Stanford vs California ',
  teamA: 'Stanford',
  teamALogo: 'upload/images/team/0W6sAzrt4E1huvv82J16X',
  teamB: 'California',
  teamBLogo: 'upload/images/team/PxDxmb07uD2R7WfxgvD5U',
};

const matchAABK24 = {
  sessionId: 'VccbuQKQJV',
  gameTime: '2024-03-15T15:00:00.000Z',
  title: 'West Chester vs Cornell',
  teamA: 'West Chester',
  teamALogo: 'upload/images/team/GMA9gX5dEfSAXJ_3RuGJe',
  teamB: 'Cornell',
  teamBLogo: 'upload/images/team/9uULeRpRgc-bXv0239H3a',
};

// const matchAABL24 = {
//   sessionId: 'wrKgK79TXm',
//   gameTime: '2024-03-15T23:00:00.000Z',
//   title: 'Michigan vs Denver',
//   teamA: 'Michigan',
//   teamALogo: 'upload/images/team/_pStX-aYWTvumVvf_BPYe',
//   teamB: 'Denver',
//   teamBLogo: 'upload/images/team/H04xwPRzTS5vbEFaXbx4W',
// };

const matchAABM24 = {
  sessionId: 'DNHs9qEUkS',
  gameTime: '2024-03-16T00:00:00.000Z',
  title: 'Arkansas vs Nebraska',
  teamA: 'Arkansas',
  teamALogo: 'upload/images/team/Y4pMJrwhkkku_HEtQvYKN',
  teamB: 'Nebraska',
  teamBLogo: 'upload/images/team/T3KXi0DD27Z8-2uXk4MNV',
};

const matchAABN24 = {
  sessionId: 'tBtoj7_mU5',
  gameTime: '2024-03-16T02:00:00.000Z',
  title: 'California vs San Jose State, UC Davis',
  teamA: 'California',
  teamALogo: 'upload/images/team/PxDxmb07uD2R7WfxgvD5U',
  teamB: 'San Jose State',
  teamBLogo: 'upload/images/team/2OpYQV8pvTrxham5gmSdg',
  teamC: 'UC Davis',
  teamCLogo: 'upload/images/team/FSkO6kJa0O9yd7mc4kyck',
};

const matchAABO24 = {
  sessionId: 'YSVrDaRkQM',
  gameTime: '2024-03-17T17:00:00.000Z',
  title: 'Ball State vs Eastern Michigan',
  teamA: 'Ball State',
  teamALogo: 'upload/images/team/S7efA9rBwUCOUQONAATb6',
  teamB: 'Eastern Michigan',
  teamBLogo: 'upload/images/team/6cWyxe7aJmnRMuhatRDwQ',
};

const matchAABP24 = {
  sessionId: 'Y9iu0ZzqsN',
  gameTime: '2024-03-17T19:00:00.000Z',
  title: 'Minnesota vs Air Force, WVU, NIU',
  teamA: 'Minnesota',
  teamALogo: 'upload/images/team/L1nfNwj-98ysElWuNme9W',
  teamB: 'Air Force',
  teamBLogo: 'upload/images/team/y1PAeO8-bQUAsnjjxlYqx',
  teamC: 'WVU',
  teamCLogo: 'upload/images/team/A7o7XcB3BbAxavXLRUBhF',
  teamD: 'NIU',
  teamDLogo: 'upload/images/team/F0jgfsNlRXL8b3Qq54Cb-',
};

const matchAABQ24 = {
  sessionId: 'xSe14tvO3O',
  gameTime: '2024-03-17T21:00:00.000Z',
  title: 'California vs Stanford, Sac State',
  teamA: 'California',
  teamALogo: 'upload/images/team/PxDxmb07uD2R7WfxgvD5U',
  teamB: 'Stanford',
  teamBLogo: 'upload/images/team/0W6sAzrt4E1huvv82J16X',
  teamC: 'Sac State',
  teamCLogo: 'upload/images/team/o6Lk9LwIRcHlaeJ_0z1ZJ',
};

const matchAABR24 = {
  sessionId: '_K266LTrHU',
  gameTime: '2024-03-23T16:00:00.000Z',
  title: "Big Ten Championships '24 Session #1",
  teamA: 'Nebraska',
  teamALogo: 'upload/images/team/T3KXi0DD27Z8-2uXk4MNV',
  teamB: 'Penn State',
  teamBLogo: 'upload/images/team/RfZGXFyiH2JuGXjQ007hQ',
  teamC: 'Rutgers',
  teamCLogo: 'upload/images/team/Keb4zpuxg0jy2H45dR0dD',
  teamD: 'Maryland',
  teamDLogo: 'upload/images/team/b3SmbX-UKRPk1HkzRPrD_',
  teamE: 'Illinois',
  teamELogo: 'upload/images/team/9f9Eo7XB1tk4pQ0zNIudV',
  teamF: 'Iowa',
  teamFLogo: 'upload/images/team/p8ENUoErkkuHz3msOYb8d',
};

const matchAABS24 = {
  sessionId: 'jgI-1jMssw',
  gameTime: '2024-03-23T21:30:00.000Z',
  title: "Big Ten Championships '24 Session #2",
  teamA: 'Michigan',
  teamALogo: 'upload/images/team/_pStX-aYWTvumVvf_BPYe',
  teamB: 'Minnesota',
  teamBLogo: 'upload/images/team/L1nfNwj-98ysElWuNme9W',
  teamC: 'Ohio State',
  teamCLogo: 'upload/images/team/aK78bVxajwW7haLwXIGaM',
  teamD: 'Michigan State',
  teamDLogo: 'upload/images/team/3fMqUSVleD8cXKx7acX-5',
};

const matchAABT24 = {
  sessionId: 'D9Fc4vXs-0',
  gameTime: '2024-03-23T16:00:00.000Z',
  title: "GEC Championships '24 Session #1",
  teamA: 'SCSU',
  teamALogo: 'upload/images/team/fdZErpFakdfjKY8u7q-kS',
  teamB: 'William & Mary',
  teamBLogo: 'upload/images/team/I7JzeUKc_Vorq8GCRtXXT',
  teamC: 'Cornell',
  teamCLogo: 'upload/images/team/9uULeRpRgc-bXv0239H3a',
  teamD: 'Bridgeport',
  teamDLogo: 'upload/images/team/4nMkHZZ0t_RtwvQEFqEMr',
};

const matchAABU24 = {
  sessionId: '6rTsFjjQKH',
  gameTime: '2024-03-23T22:00:00.000Z',
  title: "GEC Championships '24 Session #2",
  teamA: 'Yale',
  teamALogo: 'upload/images/team/-l6HqzpxgzJauEm2zPaNJ',
  teamB: 'Brown',
  teamBLogo: 'upload/images/team/bm282RMpGww0qoStmL9Gr',
  teamC: 'West Chester',
  teamCLogo: 'upload/images/team/GMA9gX5dEfSAXJ_3RuGJe',
  teamD: 'Penn',
  teamDLogo: 'upload/images/team/7H7GYktvhz4ygAfhHs-Ed',
};

const matchAABV24 = {
  sessionId: 'Q2mPxFDxuY',
  gameTime: '2024-03-23T18:00:00.000Z',
  title: "MAC Championships '24",
  teamA: 'EMU',
  teamALogo: 'upload/images/team/6cWyxe7aJmnRMuhatRDwQ',
  teamB: 'NIU',
  teamBLogo: 'upload/images/team/F0jgfsNlRXL8b3Qq54Cb-',
  teamC: 'WMU',
  teamCLogo: 'upload/images/team/4njSVBoc7CyvU415YzKj0',
  teamD: 'Kent State',
  teamDLogo: 'upload/images/team/_MlyfrlwP0n50yFezrUXy',
  teamE: 'CMU',
  teamELogo: 'upload/images/team/TayK4o3QhC1gcKrHiPeX9',
  teamF: 'Bowling Green',
  teamFLogo: 'upload/images/team/2zMuDgazSTePXdNLXu6cG',
  teamG: 'Ball State',
  teamGLogo: 'upload/images/team/S7efA9rBwUCOUQONAATb6',
};

const matchAABW24 = {
  sessionId: '3MsDIP63FK',
  gameTime: '2024-03-23T23:00:00.000Z',
  title: "ACC Gymnastics Championship '24",
  teamA: 'NC State',
  teamALogo: 'upload/images/team/q3-Uqn-P1Eoa_XULt81_Z',
  teamB: 'Clemson',
  teamBLogo: 'upload/images/team/M_ZZbKT6N5hoeI9gOzW08',
  teamC: 'Pitt',
  teamCLogo: 'upload/images/team/3dd_oTAMydg9FcSTb_14p',
  teamD: 'UNC',
  teamDLogo: 'upload/images/team/2zuCt_Fvldy7Y7Nh4yCuG',
};

const matchAABX24 = {
  sessionId: 'kH8m5CSAvK',
  gameTime: '2024-03-09T20:30:00.000Z',
  title: "WIAC Championships '24",
  teamA: 'UWL',
  teamALogo: 'upload/images/team/3m9gkYeibmj5CT99Ay7Ey',
  teamB: 'UW-Oshkosh',
  teamBLogo: 'upload/images/team/Gq5i5lMGRFcxbXrFBVTp9',
  teamC: 'UW-Whitewater',
  teamCLogo: 'upload/images/team/CbWKimoC_0RpBy-M-lcSy',
  teamD: 'UW-Stout',
  teamDLogo: 'upload/images/team/2uKGYOQeEjD5U6ofcavh2',
  teamE: 'UW-Eau Claire',
  teamELogo: 'upload/images/team/LauSa_ZJaGoO2xcVMSdzx',
  teamF: 'Winona State',
  teamFLogo: 'upload/images/team/WeJiXDNMqwN034-bzJsev',
  teamG: 'Gustavus',
  teamGLogo: 'upload/images/team/jueyFEPEII5hbEk_pHILg',
  teamH: 'Hamline',
  teamHLogo: 'upload/images/team/lJNBrvLeQMzRkmVaGvf5e',
};

const matchAABY24 = {
  sessionId: 'TacNm4c6K_',
  gameTime: '2024-04-03T19:00:00.000Z',
  title: "NCAA Regional '24 @ Arkansas: Boise State vs BYU",
  teamA: 'Boise State',
  teamALogo: 'upload/images/team/y_1ONBk8XQMNlCXqUolhg',
  teamB: 'BYU',
  teamBLogo: 'upload/images/team/IjOElnrwW4y1C_fOjQDqP',
};

const matchAABZ24 = {
  sessionId: 'IGEKodXguu',
  gameTime: '2024-04-04T21:00:00.000Z',
  title: "NCAA Regional '24 @ Cal: Southern Utah vs San Jose State",
  teamA: 'Southern Utah',
  teamALogo: 'upload/images/team/O_CTTamlZYZlPw-le0CkD',
  teamB: 'San Jose State',
  teamBLogo: 'upload/images/team/2OpYQV8pvTrxham5gmSdg',
};

const matchAACA24 = {
  sessionId: 'vI7EOKzmj6',
  gameTime: '2024-04-04T18:00:00.000Z',
  title: "NCAA Regional '24 @ Arkansas: Second Round #1",
  teamA: 'Kentucky',
  teamALogo: 'upload/images/team/jBxu8sY8t1WJSF6e43fOh',
  teamB: 'Arkansas',
  teamBLogo: 'upload/images/team/Y4pMJrwhkkku_HEtQvYKN',
  teamC: 'Arizona',
  teamCLogo: 'upload/images/team/IvFg7MbvgCuET9yVr2j2-',
  teamD: 'Nebraska',
  teamDLogo: 'upload/images/team/T3KXi0DD27Z8-2uXk4MNV',
};

const matchAACB24 = {
  sessionId: 'EZH04Xh8_5',
  gameTime: '2024-04-05T20:00:00.000Z',
  title: "NCAA Regional '24 @ Cal: Second Round #1",
  teamA: 'Denver',
  teamALogo: 'upload/images/team/H04xwPRzTS5vbEFaXbx4W',
  teamB: 'UCLA',
  teamBLogo: 'upload/images/team/LvA1VLX0gEgeqNb11WmGm',
  teamC: 'Arizona State',
  teamCLogo: 'upload/images/team/ZaoNOG0LZOU9gwoMdGrnz',
  teamD: 'Washington',
  teamDLogo: 'upload/images/team/PxHRrEmCh4h8dFmITMnSM',
};

const matchAACC24 = {
  sessionId: 'eR-wWlp2nd',
  gameTime: '2024-04-05T00:00:00.000Z',
  title: "NCAA Regional '24 @ Arkansas: Second Round #2",
  teamA: 'LSU',
  teamALogo: 'upload/images/team/k3g_c_3ZBsgwZ1KIup38W',
  teamB: 'Minnesota',
  teamBLogo: 'upload/images/team/L1nfNwj-98ysElWuNme9W',
  teamC: 'Oregon State',
  teamCLogo: 'upload/images/team/SUgsb-OU3HGO7oWFRzfhd',
  teamD: 'BYU',
  teamDLogo: 'upload/images/team/IjOElnrwW4y1C_fOjQDqP',
};

const matchAACD24 = {
  sessionId: 'pkeZV0B-Qp',
  gameTime: '2024-04-06T02:00:00.000Z',
  title: "NCAA Regional '24 @ Cal: Second Round #2",
  teamA: 'California',
  teamALogo: 'upload/images/team/PxDxmb07uD2R7WfxgvD5U',
  teamB: 'Auburn',
  teamBLogo: 'upload/images/team/5WPFADXjkdDjfNTUnv9b2',
  teamC: 'Stanford',
  teamCLogo: 'upload/images/team/0W6sAzrt4E1huvv82J16X',
  teamD: 'Southern Utah',
  teamDLogo: 'upload/images/team/O_CTTamlZYZlPw-le0CkD',
};

const matchAACE24 = {
  sessionId: 'eIYcTe0Ozb',
  gameTime: '2024-04-06T22:00:00.000Z',
  title: "NCAA Regional '24 @ Arkansas: Final",
  teamA: 'Arkansas',
  teamALogo: 'upload/images/team/Y4pMJrwhkkku_HEtQvYKN',
  teamB: 'Kentucky',
  teamBLogo: 'upload/images/team/jBxu8sY8t1WJSF6e43fOh',
  teamC: 'LSU',
  teamCLogo: 'upload/images/team/k3g_c_3ZBsgwZ1KIup38W',
  teamD: 'Minnesota',
  teamDLogo: 'upload/images/team/L1nfNwj-98ysElWuNme9W',
};

const matchAACF24 = {
  sessionId: 'YClnXFtFjr',
  gameTime: '2024-04-08T00:00:00.000Z',
  title: "NCAA Regional '24 @ Cal: Final",
  teamA: 'Stanford',
  teamALogo: 'upload/images/team/0W6sAzrt4E1huvv82J16X',
  teamB: 'Denver',
  teamBLogo: 'upload/images/team/H04xwPRzTS5vbEFaXbx4W',
  teamC: 'California',
  teamCLogo: 'upload/images/team/PxDxmb07uD2R7WfxgvD5U',
  teamD: 'Arizona State',
  teamDLogo: 'upload/images/team/ZaoNOG0LZOU9gwoMdGrnz',
};

const matchAACG24 = {
  sessionId: 'ijqUXREx6V',
  gameTime: '2024-04-12T18:00:00.000Z',
  title: 'USAGCN Championships Session #1',
  teamA: 'USAG Individuals',
  teamALogo: 'upload/images/team/L1ugnzjPhb-5zR5rbZS_g',
  teamB: 'Cornell',
  teamBLogo: 'upload/images/team/9uULeRpRgc-bXv0239H3a',
  teamC: 'Air Force',
  teamCLogo: 'upload/images/team/y1PAeO8-bQUAsnjjxlYqx',
  teamD: 'TWU',
  teamDLogo: 'upload/images/team/RlQ4ghHJPe45JOh4yT6Uy',
  multiStream: { 1: 'VT', 2: 'UB', 3: 'BB', 4: 'FX' },
};

const matchAACH24 = {
  sessionId: 'Qwap9uHFJw',
  gameTime: '2024-04-12T23:00:00.000Z',
  title: 'USAGCN Championships Session #2',
  teamA: 'USAG Individuals',
  teamALogo: 'upload/images/team/L1ugnzjPhb-5zR5rbZS_g',
  teamB: 'West Chester',
  teamBLogo: 'upload/images/team/GMA9gX5dEfSAXJ_3RuGJe',
  teamC: 'Brown',
  teamCLogo: 'upload/images/team/bm282RMpGww0qoStmL9Gr',
  teamD: 'Lindenwood',
  teamDLogo: 'upload/images/team/7cZrH_sQif_oQplyrQz5d',
  multiStream: { 1: 'VT', 2: 'UB', 3: 'BB', 4: 'FX' },
};

const matchAACI24 = {
  sessionId: '9cu0jEnp-P',
  gameTime: '2024-04-13T23:00:00.000Z',
  title: 'USAGCN Championships Session #3 (Team Final)',
  teamC: 'Lindenwood',
  teamCLogo: 'upload/images/team/7cZrH_sQif_oQplyrQz5d',
  teamB: 'TWU',
  teamBLogo: 'upload/images/team/RlQ4ghHJPe45JOh4yT6Uy',
  teamA: 'West Chester',
  teamALogo: 'upload/images/team/GMA9gX5dEfSAXJ_3RuGJe',
  teamD: 'Air Force',
  teamDLogo: 'upload/images/team/y1PAeO8-bQUAsnjjxlYqx',
  multiStream: { 1: 'VT', 2: 'UB', 3: 'BB', 4: 'FX' },
};

const matchAACJ24 = {
  sessionId: '4poD-v1vj-',
  gameTime: '2024-04-14T17:00:00.000Z',
  title: 'USAGCN Championships Session #4 (Individual Finals)',
  teamA: 'USAG Individuals',
  teamALogo: 'upload/images/team/L1ugnzjPhb-5zR5rbZS_g',
};

export const matches2024 = [
  [matchAACJ24],
  [],
  [],
  [],
  [matchAACI24],
  [matchAACG24, matchAACH24],
  [matchAACE24, matchAACF24],
  [matchAACB24, matchAACD24],
  [matchAABY24, matchAACA24, matchAABZ24, matchAACC24],
  [matchAABR24, matchAABS24],
  [matchAABT24, matchAABU24],
  [matchAABV24, matchAABW24],
  [matchAABO24, matchAABP24, matchAABQ24],
  [matchAABK24, matchAABM24, matchAABN24],
  [matchAABF24, matchAABG24, matchAABH24, matchAABI24, matchAABJ24],
  [matchAABX24],
  [matchAAAZ24, matchAABA24, matchAABB24, matchAABC24, matchAABD24],
  [matchAAAS24, matchAAAT24, matchAAAU24, matchAAAV24, matchAAAW24],
  [matchAAAP24, matchAAAQ24],
  [
    matchAABE24,
    matchAAAJ24,
    matchAAAL24,
    matchAAAN24,
    matchAAAM24,
    matchAAAX24,
  ],
  [matchAAAH24, matchAAAF24, matchAAAG24],
  [matchAAAC24, matchAAAD24, matchAAAE24],
  [matchAAY24, matchAAX24, matchAAZ24],
  [matchAAAA24, matchAAAB24],
  [matchAAS24, matchAAT24, matchAAV24, matchAAW24],
  [matchAAP24, matchAAQ24, matchAAR24],
  [matchAAJ24, matchAAK24, matchAAL24, matchAAM24, matchAAN24, matchAAO24],
  [matchAAH24, matchAAI24, matchAAG24],
  [matchAX24, matchAY24, matchAZ24, matchAAA24, matchAAB24],
  [matchAAC24, matchAAD24, matchAAE24, matchAAF24],
  [matchAU24, matchAV24, matchAW24],
  [matchAP24, matchAQ24, matchAR24, matchAT24],
  [matchAM24, matchAN24, matchAO24],
  [matchAJ24, matchAK24],
  [matchAD24, matchAE24, matchAF24, matchAG24, matchAH24],
  [matchAL24, matchAA24, matchAB24, matchAC24],
  [matchX24, matchY24, matchZ24],
  [matchT24, matchU24, matchV24, matchW24],
  [matchP24, matchR24, matchS24],
  [matchO24],
  [matchK24, matchL24, matchM24, matchN24],
  [matchG24, matchH24, matchI24, matchJ24],
  [matchB24, matchC24, matchD24, matchE24],
  [matchA24, matchF24],
];

export function useMatch(key) {
  const { data } = useQuery(GetMatch, {
    variables: {
      sessionKey: key,
    },
  });

  return data;
}
