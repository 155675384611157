import React from 'react';
import { Button, Row, Col } from 'react-bootstrap';
import { useQuery } from '@apollo/client';
import EvaluatorFinalize from './evaluatorfinalize';
import EvaluatorInput from './evaluatorinput';
import GetFullRoutine from '../../apollo/queries/GetFullRoutine.graphql';
import { newestScoreOfType } from '../../utilities/scoring';
import { useEvalConfig } from './hooks';
import './scoringpanel.css';

export default function ScoringPanel({
  team,
  sessionId,
  routineId,
  judgeType,
}) {
  const { data, error } = useQuery(GetFullRoutine, {
    variables: { id: routineId },
    skip: !routineId || routineId === 'FAKE-ROUTINE-ID',
  });

  // console.log('scoringPanel render');

  const {
    dPanel,
    ePanel,
    fPanel,
    jPanel,
    svPanel,
    nDeduct,
    stickBonus,
    roles,
    startValueOptional = false,
  } = useEvalConfig();
  const routine = data?.getRoutine;
  const extraPanelWidth = +nDeduct + stickBonus + ((nDeduct || stickBonus) & 1);
  const evalPanelWidth = 9 - extraPanelWidth;
  const roleCheck = roles?.[judgeType?.toUpperCase()] || false;

  if (error) return <span>{error.message}</span>;
  if (!routine) return null;

  const judgeTypeRoles =
    (roles && judgeType && roleCheck && Object.keys(roleCheck)) || [];

  const hasPendingScores = routine.scores.items.some(
    (score) => score.id === 'FAKE-SCORE-ID'
  );

  const hasScoresFor = (panel) =>
    (panel &&
      panel.every(({ type }) =>
        routine.scores.items.some((score) => score.type === type)
      )) ||
    !panel;

  const scoreField = () => {
    if (hasPendingScores) {
      return <span>Sending...</span>;
    }

    if (roleCheck && !roles?.[judgeType.toUpperCase()]?.['finalize']) {
      return null;
    }

    if (
      hasScoresFor(ePanel) &&
      hasScoresFor(dPanel) &&
      hasScoresFor(jPanel) &&
      (hasScoresFor(svPanel) || startValueOptional)
    ) {
      return (
        <EvaluatorFinalize
          scores={routine.scores.items}
          team={team}
          routineData={routine}
        />
      );
    }

    return <span>On Eval</span>;
  };

  const evalInput = ({ type, i }) => {
    const score = newestScoreOfType(routine.scores.items, type);

    const showType =
      !judgeType ||
      !roleCheck ||
      (judgeTypeRoles.includes(type) &&
        roles?.[judgeType.toUpperCase()]?.[type]);

    if (!showType) {
      return null;
    }
    return (
      <EvaluatorInput
        type={type}
        routineId={routineId}
        sessionId={sessionId}
        score={score}
        key={`eval-input-${type}-${i}`}
      />
    );
  };

  return (
    <div className="evaluator">
      <Row style={{ height: '100%' }}>
        <Col className="evalPanel" xs={evalPanelWidth}>
          {svPanel?.map(({ type }, i) => {
            return (
              <React.Fragment key={i}>
                {evalInput({ type, i })}
                {evalInput({ type: jPanel?.[i]?.type, i })}
              </React.Fragment>
            );
          })}
          {!svPanel &&
            jPanel?.map(({ type }, i) => {
              return evalInput({ type, i });
            })}
          {fPanel?.map(({ type }, i) => {
            return evalInput({ type, i });
          })}
          {ePanel?.map(({ type }, i) => {
            return evalInput({ type, i });
          })}
          {dPanel?.map(({ type }, i) => {
            return evalInput({ type, i });
          })}
        </Col>
        {extraPanelWidth ? (
          <Col className="evalExtras" xs={extraPanelWidth}>
            {nDeduct &&
              (dPanel.length !== 0 || svPanel.length !== 0) &&
              evalInput({ type: 'ND', i: 0 })}
            {stickBonus &&
              dPanel.length !== 0 &&
              evalInput({ type: 'SB', i: 0 })}
          </Col>
        ) : null}
        <Col className="vCenter tscore" xs={3}>
          {scoreField()}
        </Col>
      </Row>
    </div>
  );
}

export function DisabledScoringPanel({
  onAir,
  onEval,
  onStandby,
  routineId,
  judgeType,
}) {
  const { data, error } = useQuery(GetFullRoutine, {
    variables: { id: routineId },
    skip: !routineId || routineId === 'FAKE-ROUTINE-ID',
  });
  const {
    ePanel,
    dPanel,
    fPanel,
    jPanel,
    svPanel,
    nDeduct,
    stickBonus,
    roles,
  } = useEvalConfig();

  if (error) return <span>{error.message}</span>;

  const routine = data?.getRoutine;
  const extraPanelWidth = +nDeduct + stickBonus + ((nDeduct || stickBonus) & 1);
  const evalPanelWidth = 9 - extraPanelWidth;
  const roleCheck = roles?.[judgeType?.toUpperCase()] || false;
  const judgeTypeRoles =
    (roles && judgeType && roleCheck && Object.keys(roleCheck)) || [];

  const evalButton = ({ type, i }) => {
    const score = routine && newestScoreOfType(routine.scores.items, type);
    const showType =
      !judgeType ||
      !roleCheck ||
      (judgeTypeRoles.includes(type) &&
        roles?.[judgeType.toUpperCase()]?.[type]);

    if (!showType) {
      return null;
    }

    return (
      <Button
        className={type}
        variant="secondary"
        disabled={true}
        key={`eval-button-${type}-${i}`}
      >
        {type}
        {!!score ? <div className="lockIndicator" /> : null}
      </Button>
    );
  };

  return (
    <div className="evaluator">
      <Row style={{ height: '100%' }}>
        <Col className="evalPanel" xs={evalPanelWidth}>
          {svPanel?.map(({ type }, i) => {
            return (
              <React.Fragment key={i}>
                {evalButton({ type, i })}
                {evalButton({ type: jPanel?.[i]?.type, i })}
              </React.Fragment>
            );
          })}
          {!svPanel &&
            jPanel?.map(({ type }, i) => {
              return evalButton({ type, i });
            })}
          {fPanel?.map(({ type }, i) => {
            return evalButton({ type, i });
          })}
          {ePanel?.map(({ type }, i) => {
            return evalButton({ type, i });
          })}
          {dPanel?.map(({ type }, i) => {
            return evalButton({ type, i });
          })}
        </Col>
        {extraPanelWidth ? (
          <Col className="evalExtras" xs={extraPanelWidth}>
            {
              nDeduct &&
                (dPanel.length !== 0 || svPanel.length !== 0) &&
                evalButton({ type: 'ND', i: 0 })
              // (
              //   <Button className="ND" variant="secondary" disabled={true}>
              //     ND
              //     {routine &&
              //     !!newestScoreOfType(routine.scores.items, 'ND') &&
              //     newestScoreOfType(routine.scores.items, 'ND')?.value > 0 ? (
              //       <div className="lockIndicator" />
              //     ) : null}
              //   </Button>
              // )
            }
            {
              stickBonus &&
                dPanel.length !== 0 &&
                evalButton({ type: 'SB', i: 0 })
              // (
              //   <Button className="SB" variant="secondary" disabled={true}>
              //     SB
              //     {routine &&
              //     !!newestScoreOfType(routine.scores.items, 'SB') &&
              //     newestScoreOfType(routine.scores.items, 'SB')?.value > 0 ? (
              //       <div className="lockIndicator" />
              //     ) : null}
              //   </Button>
              // )
            }
          </Col>
        ) : null}
        <Col className="vCenter tscore" xs={3}>
          {onAir ? (
            <span>On Air</span>
          ) : onEval ? (
            <span>On Eval</span>
          ) : onStandby ? (
            <span>Standby</span>
          ) : null}
        </Col>
      </Row>
    </div>
  );
}
