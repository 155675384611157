import { producerConstants, initialProducerState } from '../_constants';

export function producer(state = initialProducerState, action) {
  switch (action.type) {
    case producerConstants.RESET:
      return {
        ...state,
        teamA: action.data.teamA,
        teamB: action.data.teamB,
        round: action.data.round,
        loaded: true,
        perTeam: action.data.perTeam,
        evaluatorConfig: action.data.evaluatorConfig,
        exportHeader: action.data.exportHeader,
        streamingConfig: action.data.streamingConfig,
        live: action.data.live,
        undo: false,
      };
    case producerConstants.INITIALIZE:
      return {
        ...state,
        ...action.data,
        /*
        teamA: action.data.teamA,
        teamB: action.data.teamB,
        round: action.data.round,
        loaded: true,
        perTeam: action.data.perTeam,
        evaluatorConfig: action.data.evaluatorConfig,
        exportData: action.data.exportData,
        exportHeader: action.data.exportHeader,
        streamingConfig: action.data.streamingConfig,
        live: action.data.live,*/
      };
    case producerConstants.SYNC_PRODUCER:
      return {
        ...state,
        ...action.data,
        teamA: {
          ...state.teamA,
          ...action.data?.teamA,
        },
        teamB: {
          ...state.teamB,
          ...action.data?.teamB,
        },
      };
    case producerConstants.JUDGE:
      return {
        ...state,
        judge: action.judge,
        edit: false,
        clip: false,
        upload: false,
      };
    case producerConstants.EDIT:
      return {
        ...state,
        edit: action.edit,
        clip: false,
        judge: false,
        upload: false,
      };
    case producerConstants.CLIP:
      return {
        ...state,
        clip: action.clip,
        edit: false,
        judge: false,
        upload: false,
      };
    case producerConstants.UPLOAD:
      return {
        ...state,
        upload: action.upload,
        clip: false,
        edit: false,
        judge: false,
      };
    case producerConstants.LEADERBOARD:
      return {
        ...state,
        leaderboard: action.leaderboard,
      };
    case producerConstants.LIVE:
      return {
        ...state,
        live: action.live,
        completedRounds: action.completedRounds,
        undo: false,
      };
    case producerConstants.RESET_LINEUP:
      return {
        ...state,
        teamA: {
          ...state.teamA,
        },
        teamB: {
          ...state.teamB,
        },
      };
    case producerConstants.SET_END:
      return {
        ...state,
        [action.data.team]: {
          ...state[action.data.team],
          lineup: action.data?.lineup ?? state[action.data.team].lineup,
        },
      };
    case producerConstants.EXPORT_DATA:
      return {
        ...state,
        exportData: action.data,
      };
    case producerConstants.CHANGE_ROUND:
      return {
        ...state,
        round: action.data.round,
        teamA: {
          ...state.teamA,
          lineup: action.data.teamALineup ?? state.teamA.lineup,
        },
        teamB: {
          ...state.teamB,
          lineup: action.data?.teamBLineup ?? state?.teamB?.lineup,
        },
        undo: false,
      };
    case producerConstants.UPDATE_LINEUP_SUCCESS:
      return {
        ...state,
        [`team${action.data.team}`]: {
          ...state[`team${action.data.team}`],
          lineup:
            action.data.newLineup ?? state[`team${action.data.team}`].lineup,
        },
      };
    case producerConstants.SHOW_SCORES:
      return {
        ...state,
        scores: action.show,
      };
    case producerConstants.FORCE:
      return {
        ...state,
        force: !state.force,
      };
    case producerConstants.FORCE_SELECT:
      return {
        ...state,
        force: action.data.force,
        teamA: {
          ...state.teamA,
        },
        teamB: {
          ...state?.teamB,
        },
      };
    case producerConstants.UNDO:
      return {
        ...state,
        undo: !state.undo,
      };
    case producerConstants.TEAM_BANNER_CHANGE:
      return {
        ...state,
        ...action.data,
      };
    default:
      return state;
  }
}
