// From Termly cookie banner
import { useEffect } from 'react';
import { useUser } from '../user-provider';

export default function CookieBanner() {
  const user = useUser();
  const script = document.createElement('script');

  script.src =
    'https://app.termly.io/resource-blocker/53f50ab2-3722-4efe-b7f8-43f0bd87f990?autoBlock=on';
  script.async = true;

  useEffect(() => {
    function isInPage(node) {
      return node === document.body ? false : document.body.contains(node);
    }

    if (user.isFan && !isInPage) {
      document.body.appendChild(script);
    }

    return () => {
      if (isInPage(script)) {
        document.body.removeChild(script);
      }
    };
  }, []);
  return null;
}
