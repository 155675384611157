export const ncaaHeadCoaches = {
  // using RTN ID as identifier

  MALE: {},

  FEMALE: {
    21: {
      team: 'Eastern Michigan',
      headCoach: 'Katie Minasola',
    },
    45: {
      team: 'Northern Illinois',
      headCoach: 'Sam Morreale',
    },
    74: {
      team: 'Western Michigan',
      headCoach: 'Penny Jernigan',
    },
    32: {
      team: 'Kent State',
      headCoach: 'Brice Biggin',
    },
    17: {
      team: 'Central Michigan',
      headCoach: 'Christine MacDonald',
    },
    11: {
      team: 'Bowling Green',
      headCoach: 'Kerrie Turner',
    },
    9: {
      team: 'Ball State',
      headCoach: 'Joanna Saleem',
    },
    41: {
      team: 'Nebraska',
      headCoach: 'Heather Brink',
    },
    49: {
      team: 'Penn State',
      headCoach: 'Sarah Brown',
    },
    53: {
      team: 'Rutgers',
      headCoach: 'Umme Salim-Beasley',
    },
    36: {
      team: 'Maryland',
      headCoach: 'Brett Nelligan',
    },
    28: {
      team: 'Illinois',
      headCoach: 'Nadalie Walsh',
    },
    29: {
      team: 'Iowa',
      headCoach: 'Quest Hayden',
    },
    37: {
      team: 'Michigan',
      headCoach: 'Bev Plocki',
    },
    39: {
      team: 'Minnesota',
      headCoach: 'Jenny Hansen',
    },
    46: {
      team: 'Ohio State',
      headCoach: 'Meredith Paulicivic',
    },
    38: {
      team: 'Michigan State',
      headCoach: 'Mike Rowe',
    },
    58: {
      team: 'Southern Conn.',
      headCoach: 'Byron Knox',
    },
    75: {
      team: 'William & Mary',
      headCoach: 'Kelsey Hinton',
    },
    18: {
      team: 'Cornell',
      headCoach: 'Melanie Hall',
    },
    12: {
      team: 'Bridgeport',
      headCoach: 'Lorraine Galow',
    },
    82: {
      team: 'Yale',
      headCoach: 'Andrew Leis',
    },
    14: {
      team: 'Brown',
      headCoach: 'Brittany Harris',
    },
    72: {
      team: 'West Chester',
      headCoach: 'Barbara Cordova',
    },
    50: {
      team: 'Pennsylvania',
      headCoach: 'Cassie Hageman',
    },
    44: {
      team: 'North Carolina State',
      headCoach: 'Kim Landrus',
    },
    163: {
      team: 'Clemson',
      headCoach: 'Amy Smith',
    },
    51: {
      team: 'Pittsburgh',
      headCoach: 'Casey Jo MacPherson',
    },
    43: {
      team: 'North Carolina',
      headCoach: 'Danna Durante',
    },
    33: {
      team: 'Kentucky',
      headCoach: 'Tim Garrison',
    },
    6: {
      team: 'Arkansas',
      headCoach: 'Jordyn Wieber',
    },
    4: {
      team: 'Arizona',
      headCoach: 'John Court',
    },
    34: {
      team: 'LSU',
      headCoach: 'Jay Clark',
    },
    48: {
      team: 'Oregon State',
      headCoach: 'Tanya Chaplin',
    },
    10: {
      team: 'Boise State',
      headCoach: 'Tina Bird',
    },
    8: {
      team: 'BYU',
      headCoach: 'Guard Young',
    },
    20: {
      team: 'Denver',
      headCoach: 'Melissa Kutcher-Rinehart',
    },
    66: {
      team: 'UCLA',
      headCoach: 'Janelle McDonald',
    },
    5: {
      team: 'Arizona State',
      headCoach: 'Jay Santos',
    },
    23: {
      team: 'George Washington',
      headCoach: 'Margie Cunningham',
    },
    71: {
      team: 'Washington',
      headCoach: 'Jen Llewellyn',
    },
    15: {
      team: 'California',
      headCoach: 'Liz Crandall-Howell',
    },
    7: {
      team: 'Auburn',
      headCoach: 'Jeff Graba',
    },
    61: {
      team: 'Stanford',
      headCoach: 'Tabitha Yim',
    },
    59: {
      team: 'Southern Utah',
      headCoach: 'Scott Bauman',
    },
    56: {
      team: 'San Jose State',
      headCoach: 'Joanne Bowers',
    },
    1: {
      team: 'Air Force',
      headCoach: 'Jennifer Green',
    },
    65: {
      team: 'UC Davis',
      headCoach: 'Tanya Ho',
    },
    55: {
      team: 'Sacramento State',
      headCoach: 'Melissa Genovese',
    },
    16: {
      team: 'Centenary College',
      headCoach: 'Meg Crowley',
    },
    156: {
      team: 'Fisk',
      headCoach: 'Corrinne Tarver',
    },
    35: {
      team: 'Lindenwood',
      headCoach: 'Catelyn Orel',
    },
    54: {
      team: 'S.E. Missouri',
      headCoach: 'Ashley Lawson',
    },
    63: {
      team: "Texas Woman's",
      headCoach: 'Lisa Bowerman',
    },
    70: {
      team: 'Utah State',
      headCoach: 'Kristin White',
    },
    165: {
      team: 'Talladega',
      headCoach: 'Aja Sims-Fletcher',
    },
  },
};
