import { useEffect, forwardRef } from 'react';
import { Button, Tooltip, OverlayTrigger, Row } from 'react-bootstrap';
import { timerIcon } from '../helpers/icons';

const content = 'Toggle Timer';

const ToolTip = forwardRef(({ popper, children, show: _, ...props }, ref) => {
  useEffect(() => {
    popper.scheduleUpdate();
  }, [children, popper]);

  return (
    <Tooltip ref={ref} {...props}>
      {children}
    </Tooltip>
  );
});

export function ToggleTimer({ setTimerOn, timerOn }) {
  const handleClick = () => {
    setTimerOn(!timerOn);
  };

  return (
    <div className="toggleTimer vCenter">
      <Row>
        <OverlayTrigger
          placement="top"
          delay={{ show: 700, hide: 0 }}
          overlay={
            <ToolTip id="popover-contained">
              {content + (timerOn ? ' Off' : ' On')}
            </ToolTip>
          }
        >
          <Button
            variant="light"
            onClick={handleClick}
            className={['vCenter', timerOn ? 'toggled' : null].join(' ')}
          >
            {timerIcon}
          </Button>
        </OverlayTrigger>
      </Row>
    </div>
  );
}
