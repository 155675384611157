import CreateInquiry from '../mutations/CreateInquiry.graphql';
import UpdateInquiry from '../mutations/UpdateInquiry.graphql';
import GetFullRoutine from '../queries/GetFullRoutine.graphql';

const newOptimisticInquiry = () => ({
  __typename: 'Inquiry',
  id: 'FAKE-INQUIRY-ID',
  _version: 0,
});

export function createInquiry(apolloClient, input) {
  const { getRoutine } = apolloClient.readQuery({
    query: GetFullRoutine,
    variables: {
      id: input.routineId,
    },
  });
  return apolloClient.mutate({
    mutation: CreateInquiry,
    variables: {
      input,
    },
    optimisticResponse: {
      __typename: 'Mutation',
      createInquiry: {
        ...newOptimisticInquiry(),
        ...input,
        routine: getRoutine,
      },
    },
    update: (apolloProxy, { data: { createInquiry } }) => {
      const { getRoutine } = apolloProxy.readQuery({
        query: GetFullRoutine,
        variables: {
          id: input.routineId,
        },
      });
      apolloProxy.writeQuery({
        query: GetFullRoutine,
        variables: {
          id: input.routineId,
        },
        data: {
          getRoutine: {
            ...getRoutine,
            inquiries: {
              ...getRoutine.inquiries,
              items: [...getRoutine.inquiries.items, createInquiry],
            },
          },
        },
      });
    },
  });
}

export function updateInquiry(apolloClient, currentData, newData) {
  const { getRoutine } = apolloClient.readQuery({
    query: GetFullRoutine,
    variables: {
      id: currentData.routineId,
    },
  });
  return apolloClient.mutate({
    mutation: UpdateInquiry,
    variables: {
      input: {
        id: currentData.id,
        routineId: currentData.routineId,
        _version: currentData._version,
        ...newData,
      },
    },
    optimisticResponse: {
      __typename: 'Mutation',
      updateInquiry: {
        ...currentData,
        updatedAt: new Date(),
        ...newData,
        routine: getRoutine,
      },
    },
  });
}
