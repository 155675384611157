import * as htmlToImage from 'html-to-image';
import { useHotkeys } from 'react-hotkeys-hook';

export function saveAs(blob, fileName) {
  const elem = window.document.createElement('a');
  elem.href = blob;
  elem.download = fileName;
  elem.style = 'display:none;';
  (document.body || document.documentElement).appendChild(elem);
  if (typeof elem.click === 'function') {
    elem.click();
  } else {
    elem.target = '_blank';
    elem.dispatchEvent(
      new MouseEvent('click', {
        view: window,
        bubbles: true,
        cancelable: true,
      })
    );
  }
  URL.revokeObjectURL(elem.href);
  elem.remove();
}

export function Html2Image({ idName, className, show, enabled }) {
  useHotkeys(
    'shift+s',
    (e) => {
      e.preventDefault();
      exportAsPicture();
    },
    [enabled, className]
  );

  const exportAsPicture = () => {
    const dataByClass = document.getElementsByClassName(className);
    const dataById = document.getElementById(idName);
    const data = dataById ? dataById : dataByClass;

    for (let i = 0; i < data.length; ++i) {
      htmlToImage.toPng(data[i], { cacheBust: true }).then((dataUrl) => {
        saveAs(dataUrl, `${idName ? idName : className}.png`);
      });
    }
  };

  return show ? (
    <div className="html2imageContainer">
      <button onClick={exportAsPicture}>capture</button>
    </div>
  ) : null;
}

Html2Image.defaultProps = {
  enabled: false,
  className: 'main',
  show: false,
};
