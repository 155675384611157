import React, { useCallback } from 'react';

const InputAutofocus = ({
  name,
  value,
  handler,
  disabled,
  maxLength,
  cancel = false,
}) => {
  const autoFocus = useCallback((el) => (el ? el.focus() : null), []);

  return (
    <input
      type="text"
      name={name}
      ref={cancel ? null : autoFocus}
      value={value}
      onChange={handler}
      autoComplete="off"
      autoCapitalize="off"
      disabled={disabled}
      maxLength={maxLength ? maxLength : null}
    />
  );
};

export default InputAutofocus;
