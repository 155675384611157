import React, { useState, useEffect } from 'react';
import { Container, Row, Col, ButtonGroup, Button } from 'react-bootstrap';
import Header from './helpers/header';
import { useSelector, useDispatch } from 'react-redux';
import UserBubble from './helpers/userbubble';
import MenuBubble from './helpers/menububble';
import SetupSession from './setup/setupsession';
import { sessionActions, adminActions } from '../redux/_actions';
import { heroLogo } from './helpers/logos';
import { useHistory } from 'react-router-dom';
import { useQueryParams } from '../utilities/query';

const modeType = {
  TEAM_A: 'TEAM_A',
  TEAM_B: 'TEAM_B',
  JUDGES: 'JUDGES',
  SESSION: 'SESSION',
  VIDEO: 'VIDEO',
  CLOSE: null,
};

function Setup() {
  const [mode, setMode] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const sessionKey = useSelector((state) => state.session.sessionKey);
  const sessionId = useSelector((state) => state.session.id);
  const isSessionAdminLoaded = !!useSelector(
    (state) => state.admin.sessions.allIds.length
  );
  const dispatch = useDispatch();
  const history = useHistory();
  const query = useQueryParams();

  useEffect(() => {
    if (
      (sessionKey === null && query.get('s') !== null) ||
      !isSessionAdminLoaded
    ) {
      setIsLoading(true);
      dispatch(sessionActions.load(query.get('s')));
      dispatch(adminActions.getSession(query.get('s')));
    } else {
      if (isLoading && isSessionAdminLoaded) {
        setIsLoading(false);
      }
    }
  }, [dispatch, history, sessionKey, query, isSessionAdminLoaded]);

  const handleMode = (mode) => {
    setMode(mode);
  };

  return (
    <div className="main">
      <Header>
        <UserBubble />
        <MenuBubble />
      </Header>
      <SetupSession
        mode={mode}
        modeType={modeType}
        handleMode={handleMode}
        sessionId={sessionId}
        sessionKey={sessionKey}
        isLoading={isLoading}
      />
      <Container>
        <Row style={{ height: 'calc(40vh - 72px)' }} />
        <Row style={{ height: '15vh' }}>
          <Col className="center" style={{ display: 'flex', flexWrap: 'wrap' }}>
            <div className="heroLogo"> {heroLogo} </div>
            <div className="heroMessage">
              Setup the session details and video.
            </div>
          </Col>
        </Row>
        <Row style={{ height: '45vh' }}>
          <Col className="center" style={{ display: 'flex', flexWrap: 'wrap' }}>
            <div className="startContainer">
              <ButtonGroup>
                {/*<Button
                  variant="outline-secondary"
                  onClick={() => handleMode(modeType.TEAM_A)}
                >
                  Team A
                </Button>*/}
                <Button
                  variant="outline-secondary"
                  onClick={() => handleMode(modeType.SESSION)}
                >
                  Session
                </Button>
                <Button
                  variant="outline-secondary"
                  onClick={() => handleMode(modeType.VIDEO)}
                >
                  Video
                </Button>
                {/*<Button
                  variant="outline-secondary"
                  onClick={() => handleMode(modeType.JUDGES)}
                >
                  Judges
                </Button>*/}
                {/*<Button
                  variant="outline-secondary"
                  onClick={() => handleMode(modeType.TEAM_B)}
                >
                  Team B
                </Button>*/}
              </ButtonGroup>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Setup;
