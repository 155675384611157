import { useRef, useState } from 'react';
import Countdown from 'react-countdown';
import { Container, ButtonGroup, Button } from 'react-bootstrap';
import styled from 'styled-components';

// const ButtonStyled = styled.button`
//   border-radius: 5;
//   margin: 0 10px 0 0px;
//   outline: none;
//   border: none;
//   padding: 6px 14px;
//   color: #404549;
//   border-radius: 3px;
//   border: solid 1px #404549;
//   &: hover {
//     box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
//     cursor: pointer;
//   }
// `;

const DigitsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 5px;
  &: first-child {
    margin-left: 0;
  }
`;

const Title = styled.span`
  font-size: 12px;
  margin-bottom: 5px;
`;

const TimerTitle = styled.h1`
  font-size: 4rem;
  line-height: 1;
  font-family: Carmen Sans, sans-serif;
  font-weight: 800;
`;

const DigitContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0;
  font-family: arial;
`;

const SingleDigit = styled.span`
  position: relative;
  display: flex;
  flex: 0 1 25%;
  font-size: 30px;
  background-color: #404549;
  border-radius: 5px;
  padding: 10px 12px;
  color: white;
  &:first-child {
    margin-right: 2px;
  }
  &:after {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 50%;
    bottom: 50%;
    content: '';
    width: '100%';
    height: 2px;
    background-color: #232323;
    opacity: 0.4;
  }
`;

const TimerContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 1rem;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const SeparatorContainer = styled.span`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: flex-end;
  margin: 0 0 10px 0px;
`;

const Separator = styled.span`
  display: inline-block;
  width: 6px;
  height: 6px;
  background-color: #404549;
  border-radius: 6px;
  margin: 5px 0px;
`;

const Period = styled.span`
  display: inline-block;
  width: 6px;
  height: 6px;
  background-color: #404549;
  border-radius: 6px;
  margin: 5px 0px;
`;

// function Button(props) {
//   return <ButtonStyled {...props} />;
// }

const TIMER_STATE = {
  START: 'Start',
  STOP: 'Stop',
};

export function Digit({ value, title, single = false }) {
  const leftDigit = value >= 10 ? value.toString()[0] : '0';
  const rightDigit = value >= 10 ? value.toString()[1] : value.toString();
  return (
    <DigitsContainer>
      <Title>{title}</Title>
      <DigitContainer>
        <SingleDigit>{leftDigit}</SingleDigit>
        {!single ? <SingleDigit>{rightDigit}</SingleDigit> : null}
      </DigitContainer>
    </DigitsContainer>
  );
}

export function Timer({ start, endMessage, title }) {
  const { START, STOP } = TIMER_STATE;
  const [duration, setDuration] = useState(Date.now() + start);
  const [timerState, setTimerState] = useState(START);
  const ref = useRef();

  const handleStart = () => ref.current.start();
  const handlePause = () => ref.current.pause();
  const handleReset = () => {
    ref.current.pause();
    setTimerState(START);
    setDuration(Date.now() + start);
  };
  const handleState = () => {
    switch (timerState) {
      case START:
        setTimerState(STOP);
        handleStart();
        break;
      case STOP:
        setTimerState(START);
        handlePause();
        break;
      default:
        break;
    }
  };
  const changeTime = (time) => setDuration(Date.now() + time);

  // Random component
  //const Completionist = () => <span>{endMessage}</span>;

  // Renderer callback with condition
  const renderer = ({ minutes, seconds, milliseconds, completed }) => {
    return (
      <>
        <TitleContainer>
          <TimerTitle>{`${title}`}</TimerTitle>
        </TitleContainer>
        <TimerContainer>
          <Digit value={minutes} title="MINUTES" addSeparator />
          <SeparatorContainer>
            <Separator />
            <Separator />
          </SeparatorContainer>
          <Digit value={seconds} title="SECONDS" />
          <SeparatorContainer>
            <Period />
          </SeparatorContainer>
          <Digit value={milliseconds} title="MS" single={true} />
        </TimerContainer>
        <TimerContainer>
          <Button
            variant="secondary"
            style={{ margin: '0 0.5rem' }}
            onClick={handleState}
          >
            {timerState}
          </Button>
          <Button
            variant="secondary"
            style={{ margin: '0 0.5rem' }}
            onClick={handleReset}
          >
            Reset
          </Button>
        </TimerContainer>
        <TimerContainer>
          <ButtonGroup>
            <Button variant="secondary" onClick={() => changeTime(60000)}>
              1
            </Button>
            <Button variant="secondary" onClick={() => changeTime(120000)}>
              2
            </Button>
            <Button variant="secondary" onClick={() => changeTime(240000)}>
              4
            </Button>
            <Button variant="secondary" onClick={() => changeTime(600000)}>
              10
            </Button>
            <Button variant="secondary" onClick={() => changeTime(900000)}>
              15
            </Button>
            <Button variant="secondary" onClick={() => changeTime(1200000)}>
              20
            </Button>
            <Button variant="secondary" onClick={() => changeTime(1800000)}>
              30
            </Button>
          </ButtonGroup>
        </TimerContainer>
      </>
    );
  };

  return (
    <Countdown
      date={duration}
      renderer={renderer}
      precision={1}
      intervalDelay={0}
      ref={ref}
      autoStart={false}
    />
  );
}

export function CenterTimer(props) {
  return (
    <Container className="vCenter" style={{ transform: 'scale(2)' }}>
      <div className="vCenter">
        <Timer {...props} />
      </div>
    </Container>
  );
}
