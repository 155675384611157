import { Col, Row, Dropdown } from 'react-bootstrap';
import { MEDIAURL } from '../../utilities/constants';
import { virtiusV } from '../helpers/logos';
import { useSelector } from 'react-redux';
import {
  convertMillipointsToDisplay,
  useTeamsByApparatusData,
} from '../../utilities/scoring';
import {
  numToApparatus,
  abbvToApparatusName,
  teamBrand,
} from '../../utilities/conversions';
import { producerActions } from '../../redux/_actions';
import { useDispatch } from 'react-redux';
import { useQuery } from '@apollo/client';
import { SessionTeamScoreType } from '../../models';
import GetFullSession from '../../apollo/queries/GetFullSession.graphql';
import { useDisplayConfig, useLineups } from './hooks';
import * as Icons from '../helpers/icons';
import TeamScoreCard from './teamscorecard';
import './teambanner.css';
import producerStyles from './producer.module.css';

export function TeamBannerSelector({
  teams,
  selected,
  side,
  name,
  logo,
  lineupId,
}) {
  const dispatch = useDispatch();

  const handleChange = (teamId, side) => {
    dispatch(producerActions.teamBannerChange(teamId, side));
  };

  return (
    <Dropdown className="teamBannerSelector">
      <Dropdown.Toggle variant="outline">
        <Row>
          <Col className="vCenter logo" xs={3}>
            {logo ? (
              <img
                src={`${MEDIAURL}${logo}`}
                alt={name ? `${name} logo` : ''}
              />
            ) : (
              virtiusV
            )}
          </Col>
          <Col xs={9} className="vCenter team">
            {name ? name : 'Team ?'}
          </Col>
        </Row>
      </Dropdown.Toggle>
      <Dropdown.Menu
        className="teamBannerSelector-menu"
        data-display="static"
        align={side === 'teamB' ? 'right' : 'left'}
      >
        <ul>
          {teams.map((team, index) => {
            const { displayName } = team || {};
            const { logo } = teamBrand(team);
            const isSelected = selected === index && lineupId === team.lineupId;

            return (
              <li key={`${side}_${index}_${name}`}>
                <Dropdown.Item
                  as="button"
                  variant="outline"
                  disabled={isSelected}
                  onSelect={() => handleChange(index, side)}
                >
                  <Col xs={3}>
                    {logo ? (
                      <img
                        src={`${MEDIAURL}${logo}`}
                        alt={displayName ? `${displayName} logo` : ''}
                      />
                    ) : (
                      virtiusV
                    )}
                  </Col>
                  <Col xs={9}>
                    <div className={[isSelected ? 'selected' : null].join(' ')}>
                      {displayName}
                    </div>
                  </Col>
                </Dropdown.Item>
              </li>
            );
          })}
        </ul>
      </Dropdown.Menu>
    </Dropdown>
  );
}

export function TeamBanner({ teamSide }) {
  const teamId = useSelector((state) => state.producer[teamSide].teamId);
  const lineupId = useSelector((state) => state.producer[teamSide].lineupId);
  const teamScoringType = useSelector((state) => state.session.teamScoring);
  const producerScores = useSelector((state) => state.producer.scores);
  const shouldShowScores =
    producerScores && teamScoringType !== SessionTeamScoreType.NONE;
  const round = useSelector((state) => state.producer.round);
  const gender = useSelector((state) => state.session.gender);
  const apparatus = numToApparatus(round, true, gender);
  const apparatusIcon = Icons?.[`${apparatus}noText`];
  const displayConfig = useDisplayConfig();

  const sessionId = useSelector((state) => state.session.id);
  const teamsData = useTeamsByApparatusData();

  const { data } = useQuery(GetFullSession, {
    variables: { id: sessionId },
  });

  const scoreData = teamsData(data.getSession);
  const teams = scoreData.teams.sort((a, b) => a.order - b.order);
  const team = teams.find((t) => t.lineupId === lineupId);
  const { displayName, finalScore } = team || {};
  const { logo } = teamBrand(team);

  return (
    <div className="teamBanner">
      <Row style={{ height: '100%' }}>
        <Col xs={8} className="vCenter">
          <TeamBannerSelector
            name={`${displayName}${
              shouldShowScores
                ? ''
                : ` » ${abbvToApparatusName[apparatus].toUpperCase()}`
            }`}
            teams={teams}
            selected={teamId}
            side={teamSide}
            lineupId={lineupId}
            logo={logo}
          ></TeamBannerSelector>
        </Col>
        <Col className="vCenter score" xs={4}>
          {shouldShowScores
            ? finalScore ||
              convertMillipointsToDisplay(0, displayConfig.scorePrecision)
            : apparatusIcon}
        </Col>
      </Row>
    </div>
  );
}

export function TeamBannerCompact() {
  const sessionId = useSelector((state) => state.session.id);
  const teamsData = useTeamsByApparatusData();

  const { data } = useQuery(GetFullSession, {
    variables: { id: sessionId },
  });

  const scoreData = teamsData(data.getSession);
  const teams = scoreData.teams.sort((a, b) => a.order - b.order);

  return (
    <div className="teamBannerCompact">
      <Row style={{ height: '100%' }}>
        <Col xs={12} className="scoreContainer vCenter">
          <ul>
            {teams.map((team, index) => {
              const { logos, teamId, finalScore } = team || {};
              const logo =
                (logos && JSON.parse(logos)?.metaData?.filename) || null;

              return (
                <li key={`${teamId}_${index}`}>
                  <span key={`${teamId}`}>
                    <img
                      className="scoreboardHeaderLogo"
                      src={`${MEDIAURL}${logo}`}
                      alt=""
                    />
                    {finalScore || '0.000'}
                  </span>
                </li>
              );
            })}
          </ul>
        </Col>
      </Row>
    </div>
  );
}

export function TeamBannerCompactBeta() {
  const sessionId = useSelector((state) => state.session.id);
  const teamsData = useTeamsByApparatusData();

  const { data } = useQuery(GetFullSession, {
    variables: { id: sessionId },
  });

  const scoreData = teamsData(data.getSession);
  const lineups = useLineups(sessionId);
  const teams = scoreData.teams.sort((a, b) => a.order - b.order);

  return (
    <div className="teamBannerCompact">
      <Row style={{ height: '100%' }}>
        <Col xs={12} className="scoreContainer vCenter">
          <ul>
            {teams.map((team, index) => {
              const lineup = lineups?.[team?.lineupId];
              const { logos } = team || {};
              const logo =
                (logos && JSON.parse(logos)?.metaData?.filename) || null;
              const teamId = lineup?.teamId;

              return (
                <li key={`${teamId}_${index}`}>
                  <span key={`${teamId}`}>
                    <img
                      className="scoreboardHeaderLogo"
                      src={`${MEDIAURL}${logo}`}
                      alt=""
                    />
                    <TeamScoreCard team={team} lineup={lineup} />
                  </span>
                </li>
              );
            })}
          </ul>
        </Col>
      </Row>
    </div>
  );
}

export function TeamBannerLarge() {
  const sessionId = useSelector((state) => state.session.id);
  const teamsData = useTeamsByApparatusData();

  const { data } = useQuery(GetFullSession, {
    variables: { id: sessionId },
  });

  const scoreData = teamsData(data.getSession);
  const teams = scoreData.teams.sort((a, b) => a.order - b.order);
  const fontSize = 3 - 0.2 * Math.max(teams.length - 4, 0);
  const imgPadding = teams.length > 4 ? 1 : 0.5;

  return (
    <div className="teamBannerLarge vCenter">
      <Row style={{ height: '100%' }}>
        <Col xs={12}>
          <ul>
            {teams.map((team, index) => {
              const { logos, teamId, finalScore } = team || {};
              const logo =
                (logos && JSON.parse(logos)?.metaData?.filename) || null;

              return (
                <li
                  key={`${teamId}_${index}`}
                  style={{ width: `${95 / teams.length}%` }}
                >
                  <span
                    key={`${teamId}`}
                    style={{ fontSize: `${fontSize}rem` }}
                  >
                    <img
                      className={`${producerStyles.scoreboardHeaderLogo} scoreboardHeaderLogo`}
                      src={`${MEDIAURL}${logo}`}
                      alt=""
                      style={{ padding: `${imgPadding}rem` }}
                    />
                    {finalScore || '0.000'}
                  </span>
                </li>
              );
            })}
          </ul>
        </Col>
      </Row>
    </div>
  );
}
