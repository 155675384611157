import React, { useState, useEffect } from 'react';
import { Modal, Button, Row, Alert, Spinner, Form } from 'react-bootstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { userActions, alertActions } from '../../redux/_actions';
import { uiState } from '../../redux/_constants';
import InputAutofocus from '../helpers/inputautofocus';
import { useHistory } from 'react-router-dom';

function ResetPW() {
  const [isRequesting, setIsRequesting] = useState(false);
  const dispatch = useDispatch();
  const { alert } = useSelector((state) => state);
  const history = useHistory();

  useEffect(() => {
    if (!alert.clear) {
      setIsRequesting(false);
    }
  }, [alert.clear]);

  const handleMode = (mode) => {
    history.push(mode);
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Required'),
  });

  const { handleSubmit, handleChange, values, errors } = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit(values) {
      setIsRequesting(true);
      dispatch(alertActions.clear());
      dispatch(userActions.getPasswordResetCode(values.email, history));
    },
  });

  const resetBody = () => {
    return (
      <Form onSubmit={handleSubmit}>
        {!alert.clear ? (
          <Row>
            <Alert variant="danger">{alert.message}</Alert>
          </Row>
        ) : null}
        <Row>
          <label>
            Email
            {errors.email ? (
              <span className="form-error">{errors.email}</span>
            ) : null}
          </label>
          {InputAutofocus({
            name: 'email',
            value: values.email,
            handler: handleChange,
            disabled: isRequesting,
          })}
          <div className="passwordSpecs">
            Send code to your email to reset password.
          </div>
        </Row>
        <Row>
          <Button
            variant="outline-primary"
            className="loginButton"
            type="submit"
            disabled={isRequesting}
          >
            {isRequesting ? (
              <>
                {'Requesting code... '}
                <Spinner
                  variant="primary"
                  role="status"
                  animation="border"
                  size="sm"
                />
              </>
            ) : (
              'Send code'
            )}
          </Button>
        </Row>
        <Row>
          <div className="newToVirtius">
            <span>Back to</span>
            <button
              type="button"
              className="signUp"
              disabled={isRequesting}
              onClick={() => handleMode(uiState.LOGIN)}
            >
              Log In
            </button>
          </div>
        </Row>
      </Form>
    );
  };

  return (
    <>
      <Modal.Header className="loginHeader">
        <span>Forgot your password?</span>
      </Modal.Header>
      <Modal.Body className="loginForm">{resetBody()}</Modal.Body>
    </>
  );
}

export default ResetPW;
