import React from 'react';

function TeamStripes(props) {
  const { colorA, colorB } = props;

  return (
    <>
      <div id="teamStripeLeft" style={{ background: colorA }} />
      <div id="teamStripeRight" style={{ background: colorB }} />
    </>
  );
}

export default TeamStripes;
