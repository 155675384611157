import { userConstants, initialUserState } from '../_constants';

export function user(state = initialUserState, action) {
  switch (action.type) {
    case userConstants.LOGIN_SUCCESS:
    case userConstants.REGISTER_SUCCESS:
      return {
        ...state,
        user: action.user,
      };
    case userConstants.LOGOUT_SUCCESS:
      return {
        ...state,
        user: null,
        initialLoad: false,
        profile: null,
      };
    case userConstants.GETUSER_SUCCESS:
      return {
        ...state,
        user: action.user,
      };
    case userConstants.USER_BY_EMAIL_SUCCESS:
      return {
        ...state,
        profile: action.profile,
        initialLoad: false,
      };
    case userConstants.USER_BY_EMAIL_FAILURE:
    case userConstants.GETUSER_FAILURE:
      return {
        ...state,
        initialLoad: false,
      };
    case userConstants.GETUSER_REQUEST:
      return {
        ...state,
        initialLoad: true,
      };
    case userConstants.GET_SESSIONS_SUCCESS:
      return {
        ...state,
        sessions: {
          byId: action.data.sessions.entities.sessions,
          allIds: action.data.sessions.result,
        },
      };
    case userConstants.ADD_SESSION_SUCCESS:
      return {
        ...state,
        sessions: {
          byId: {
            ...state.sessions.byId,
            [action.data.id]: action.data,
          },
          allIds: [...state.sessions.allIds, ...action.data.id],
        },
      };
    case userConstants.SET_POST_LOGIN_REDIRECT:
      return {
        ...state,
        postLoginRedirectUrl: action.redirectUrl,
      };
    case userConstants.LOGIN_REQUEST:
    case userConstants.LOGIN_FAILURE:
    case userConstants.LOGOUT_FAILURE:
    case userConstants.LOGOUT_REQUEST:
    case userConstants.FBLOGIN_REQUEST:
    case userConstants.GOOGLELOGIN_REQUEST:
    case userConstants.CODE_REQUEST:
    case userConstants.CODE_SUCCESS:
    case userConstants.CODE_FAILURE:
    case userConstants.VERIFY_REQUEST:
    case userConstants.VERIFY_SUCCESS:
    case userConstants.VERIFY_FAILURE:
    case userConstants.REGISTER_REQUEST:
    case userConstants.REGISTER_FAILURE:
    case userConstants.REQUEST_VERIFICATION_CODE_REQUEST:
    case userConstants.REQUEST_VERIFICATION_CODE_SUCCESS:
    case userConstants.REQUEST_VERIFICATION_CODE_FAILURE:
    default:
      return state;
  }
}
