import CreateRoutine from '../mutations/CreateRoutine.graphql';
import UpdateRoutine from '../mutations/UpdateRoutine.graphql';
import GetLineupWithRoutines from '../queries/GetLineupWithRoutines.graphql';
//import GetRoutines from '../queries/GetRoutines.graphql';

export function makeRoutine({ updatedAt, scores }) {
  const routine = newOptimisticRoutine();

  routine.updatedAt = updatedAt ?? routine.updatedAt;
  routine.scores.items = scores?.items ?? routine.scores.items;

  return routine;
}

const newOptimisticRoutine = () => ({
  __typename: 'Routine',
  id: 'FAKE-ROUTINE-ID',
  updatedAt: new Date(),
  createdAt: new Date(),
  startedAt: new Date(),
  completedAt: new Date(),
  _version: 0,
  isEdited: false,
  lineup: null,
  score: null,
  stageId: null,
  athlete: {
    id: 'FAKE-ATHLETE-ID',
    __typename: 'Athlete',
    name: null,
    profileImg: null,
    rtnId: null,
  },
  scores: {
    __typename: 'ModelScoreConnection',
    items: [],
  },
  inquiries: {
    __typename: 'ModelInquiryConnection',
    items: [],
  },
  clips: {
    __typename: 'ModelClipConnection',
    items: [],
  },
});

export function createRoutine(apolloClient, input) {
  const { getLineup } = apolloClient.readQuery({
    query: GetLineupWithRoutines,
    variables: {
      id: input.lineupId,
    },
  });

  /*
  const { SessionRoutines } = apolloClient.readQuery({
    query: GetRoutines,
    variables: {
      sessionId: input.sessionId,
    },
  });*/

  return apolloClient.mutate({
    mutation: CreateRoutine,
    variables: {
      input,
    },
    optimisticResponse: {
      __typename: 'Mutation',
      createRoutine: {
        ...newOptimisticRoutine(),
        ...input,
        lineup: getLineup,
      },
    },
    update: (apolloProxy, { data: { createRoutine } }) => {
      apolloProxy.writeQuery({
        query: GetLineupWithRoutines,
        variables: {
          id: input.lineupId,
        },
        data: {
          getLineup: {
            ...getLineup,
            routines: {
              ...getLineup.routines,
              items: [...getLineup.routines.items, createRoutine],
            },
          },
        },
      });
    },
  });

  /*
  apolloClient.mutate({
    mutation: CreateRoutine,
    variables: {
      input,
    },
    optimisticResponse: {
      __typename: 'Mutation',
      createRoutine: {
        ...newOptimisticRoutine(),
        ...input,
      },
    },
    update: (apolloProxy, { data: { createRoutine } }) => {
      apolloProxy.writeQuery({
        query: GetRoutines,
        variables: {
          sessionId: input.sessionId,
        },
        data: {
          SessionRoutines: {
            ...SessionRoutines,
            items: [...SessionRoutines.items, createRoutine],
          },
        },
      });
    },
  });*/
}

export function updateRoutine(apolloClient, currentData, newData) {
  return apolloClient.mutate({
    mutation: UpdateRoutine,
    variables: {
      input: {
        id: currentData.id,
        lineupId: currentData.lineupId,
        order: currentData.order,
        _version: currentData._version,
        ...newData,
      },
    },
    optimisticResponse: {
      __typename: 'Mutation',
      updateRoutine: {
        ...currentData,
        updatedAt: new Date(),
        lineup: null,
        ...newData,
      },
    },
  });
}
