import { useState, useEffect } from 'react';

const PING_RESOURCE = '/ping.txt';
const TIMEOUT_TIME_MS = 3000;
const onlinePollingInterval = 10000;

function timeout(time: number, promise: Promise) {
  return new Promise(function (resolve, reject) {
    setTimeout(() => {
      reject(new Error('Request timed out.'));
    }, time);
    promise.then(resolve, reject);
  });
}

async function checkOnlineStatus() {
  const controller = new AbortController();
  const { signal } = controller;

  // If the browser has no network connection return offline
  if (!navigator.onLine) return navigator.onLine;

  //
  try {
    await timeout(
      TIMEOUT_TIME_MS,
      fetch(PING_RESOURCE, {
        method: 'GET',
        signal,
      })
    );
    console.log('checking ping.txt');
    return true;
  } catch (error) {
    // Error Log
    console.error(error);

    // This can be because of request timed out
    // so we abort the request for any case
    controller.abort();
  }
  return false;
}

function getNetworkConnection() {
  return (
    navigator.connection ||
    navigator.mozConnection ||
    navigator.webkitConnection ||
    null
  );
}

function getNetworkConnectionInfo() {
  const connection = getNetworkConnection();
  if (!connection) {
    return {};
  }
  return {
    rtt: connection.rtt,
    type: connection.type,
    saveData: connection.saveData,
    downLink: connection.downLink,
    downLinkMax: connection.downLinkMax,
    effectiveType: connection.effectiveType,
  };
}

export function useNetwork(initialState) {
  const [state, setState] = useState(() => {
    return {
      since: undefined,
      online: navigator.onLine,
      polling: initialState?.polling,
      ...getNetworkConnectionInfo(),
    };
  });

  useEffect(() => {
    const checkStatus = async () => {
      const online = await checkOnlineStatus();
      if (online) {
        handleOnline();
      }
    };

    const handleOnline = () => {
      setState((prevState) => ({
        ...prevState,
        online: true,
        since: new Date().toString(),
      }));
    };

    const handleOffline = () => {
      setState((prevState) => ({
        ...prevState,
        online: false,
        since: new Date().toString(),
      }));
    };

    const handleConnectionChange = () => {
      setState((prevState) => ({
        ...prevState,
        ...getNetworkConnectionInfo(),
      }));
    };

    // Add polling incase of slow connection
    const id = () => {
      setInterval(() => {
        checkStatus();
      }, onlinePollingInterval);
    };

    if (state.polling) {
      id();
    }

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    const connection = getNetworkConnection();
    connection?.addEventListener('change', handleConnectionChange);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
      if (state.polling) {
        clearInterval(id);
      }
      connection?.removeEventListener('change', handleConnectionChange);
    };
  }, []);

  return state;
}
