import React from 'react';
import { Modal } from 'react-bootstrap';
import Signup from './signup';
import Login from './login';
import ResetPW from './resetpw';
import ResetPWCode from './resetpwcode';
import Verify from './verify';
import { useSelector, useDispatch } from 'react-redux';
import { alertActions } from '../../redux/_actions';
import { uiState } from '../../redux/_constants';
import { useHistory, useLocation } from 'react-router-dom';

function LoginOrSignup() {
  const alert = useSelector((state) => state.alert);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  //const [reset, setReset] = useState(false);
  //const [resetCode, setResetCode] = useState(false);
  const { LOGIN, SIGNUP, HOME, FORGOT_PW, RESET_PW, VERIFY } = uiState;

  /*
  useEffect(() => {
    if (!alert.clear) {
      dispatch(alertActions.clear());
    }
  }, [dispatch, alert.clear]);
  */

  const handleMode = (mode) => {
    if (!alert.clear) {
      dispatch(alertActions.clear());
    }
    history.push(mode);
  };

  const formType = () => {
    switch (location.pathname) {
      case LOGIN:
        return <Login />;
      case SIGNUP:
        return <Signup />;
      case FORGOT_PW:
        return <ResetPW />;
      case RESET_PW:
        return <ResetPWCode />;
      case VERIFY:
        return <Verify />;
      default:
        return null;
    }
  };

  return (
    <Modal
      show={[LOGIN, SIGNUP, FORGOT_PW, RESET_PW, VERIFY].includes(
        location.pathname
      )}
      onHide={() => handleMode(HOME)}
      centered
      dialogClassName="loginModal"
      animation={false}
    >
      {formType()}
    </Modal>
  );
}

export default LoginOrSignup;
