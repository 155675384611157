export const espnTricodes = {
  ACU: 'ABILENE CHRISTIAN',
  AFA: 'AIR FORCE',
  AKR: 'AKRON',
  ALA: 'ALABAMA',
  AAMU: 'ALABAMA A&M',
  ALST: 'ALABAMA ST',
  ALBY: 'ALBANY',
  ALCN: 'ALCORN ST',
  AMER: 'AMERICAN U',
  APP: 'APPALACHIAN ST',
  ARIZ: 'ARIZONA',
  ASU: 'ARIZONA ST',
  ARK: 'ARKANSAS',
  ARST: 'ARKANSAS ST',
  UAPB: 'ARKANSAS-PINE BLUFF',
  ARMY: 'ARMY',
  AUB: 'AUBURN',
  PEAY: 'AUSTIN PEAY',
  BALL: 'BALL ST',
  BAY: 'BAYLOR',
  BEL: 'BELMONT',
  BCU: 'BETHUNE-COOKMAN',
  BING: 'BINGHAMTON',
  BSU: 'BOISE ST',
  BC: 'BOSTON COLLEGE',
  BU: 'BOSTON UNIVERSITY',
  BGSU: 'BOWLING GREEN',
  BRAD: 'BRADLEY',
  BRWN: 'BROWN',
  BRY: 'BRYANT',
  BUCK: 'BUCKNELL',
  BUFF: 'BUFFALO',
  BUT: 'BUTLER',
  BYU: 'BRIGHAM YOUNG',
  CP: 'CAL POLY',
  CSB: 'CAL STATE BAKERSFIELD',
  CSF: 'CAL STATE FULLERTON',
  CSUN: 'CAL STATE NORTHRIDGE',
  CAL: 'CALIFORNIA',
  CAMP: 'CAMPBELL',
  CAN: 'CANISIUS',
  UCA: 'CENTRAL ARKANSAS',
  CCSU: 'CENTRAL CONNECTICUT ST',
  UCF: 'CENTRAL FLORIDA',
  CMU: 'CENTRAL MICHIGAN',
  COFC: 'CHARLESTON',
  CHSO: 'CHARLESTON SOUTHERN',
  CHAR: 'CHARLOTTE',
  CHAT: 'CHATTANOOGA',
  CHS: 'CHICAGO ST',
  CIN: 'CINCINNATI',
  CLEM: 'CLEMSON',
  CLEV: 'CLEVELAND ST',
  CCU: 'COASTAL CAROLINA',
  COLG: 'COLGATE',
  COLO: 'COLORADO',
  CSU: 'COLORADO ST',
  CLMB: 'COLUMBIA',
  CONN: 'CONNECTICUT',
  COPP: 'COPPIN ST',
  COR: 'CORNELL',
  CREI: 'CREIGHTON',
  DART: 'DARTMOUTH',
  DAV: 'DAVIDSON',
  DAY: 'DAYTON',
  DEL: 'DELAWARE',
  DSU: 'DELAWARE ST',
  DEN: 'DENVER',
  DEP: 'DEPAUL',
  DET: 'DETROIT MERCY',
  DRKE: 'DRAKE',
  DREX: 'DREXEL',
  DUKE: 'DUKE',
  DUQ: 'DUQUESNE',
  ECU: 'EAST CAROLINA',
  ETSU: 'EAST TENNESSEE ST',
  EIU: 'EASTERN ILLINOIS',
  EKY: 'EASTERN KENTUCKY',
  EMU: 'EASTERN MICHIGAN',
  EWU: 'EASTERN WASHINGTON',
  ELON: 'ELON',
  EVAN: 'EVANSVILLE',
  FAIR: 'FAIRFIELD',
  FDU: 'FAIRLEIGH DICKINSON',
  FLA: 'FLORIDA',
  FAMU: 'FLORIDA A&M',
  FAU: 'FLORIDA ATLANTIC',
  FGCU: 'FLORIDA GULF COAST',
  FIU: 'FLORIDA INTERNATIONAL',
  FSU: 'FLORIDA ST',
  FOR: 'FORDHAM',
  IPFW: 'FORT WAYNE',
  FRES: 'FRESNO ST',
  FUR: 'FURMAN',
  WEBB: 'GARDNER-WEBB',
  GMU: 'GEORGE MASON',
  GW: 'GEORGE WASHINGTON',
  GTWN: 'GEORGETOWN',
  UGA: 'GEORGIA',
  GASO: 'GEORGIA SOUTHERN',
  GAST: 'GEORGIA ST',
  GT: 'GEORGIA TECH',
  GONZ: 'GONZAGA',
  GRAM: 'GRAMBLING ST',
  GCU: 'GRAND CANYON',
  GB: 'GREEN BAY',
  HAMP: 'HAMPTON',
  HART: 'HARTFORD',
  HARV: 'HARVARD',
  HAW: 'HAWAII',
  HP: 'HIGH POINT',
  HOF: 'HOFSTRA',
  HC: 'HOLY CROSS',
  HOU: 'HOUSTON',
  HBU: 'HOUSTON BAPTIST',
  HOW: 'HOWARD',
  IDHO: 'IDAHO',
  IDST: 'IDAHO ST',
  ILL: 'ILLINOIS',
  ILST: 'ILLINOIS ST',
  IW: 'INCARNATE WORD',
  IND: 'INDIANA',
  INST: 'INDIANA ST',
  IONA: 'IONA',
  IOWA: 'IOWA',
  ISU: 'IOWA ST',
  IUPU: 'IUPUI',
  JKST: 'JACKSON ST',
  JAC: 'JACKSONVILLE',
  JVST: 'JACKSONVILLE ST',
  JMU: 'JAMES MADISON',
  KU: 'KANSAS',
  KSU: 'KANSAS ST',
  KENN: 'KENNESAW ST',
  KENT: 'KENT ST',
  UK: 'KENTUCKY',
  LAS: 'LA SALLE',
  LAF: 'LAFAYETTE',
  LAM: 'LAMAR',
  LEH: 'LEHIGH',
  LIB: 'LIBERTY',
  LIN: 'LINDENWOOD',
  LIP: 'LIPSCOMB',
  UALR: 'LITTLE ROCK',
  LIU: 'LIU BROOKLYN',
  LBSU: 'LONG BEACH ST',
  LONG: 'LONGWOOD',
  ULL: 'LOUISIANA',
  LT: 'LOUISIANA TECH',
  ULM: 'LOUISIANA-MONROE',
  LOU: 'LOUISVILLE',
  LCHI: 'LOYOLA (CHICAGO)',
  'L-MD': 'LOYOLA (MD)',
  LMU: 'LOYOLA MARYMOUNT',
  LSU: 'LSU',
  MAINE: 'MAINE',
  MAN: 'MANHATTAN',
  MRST: 'MARIST',
  MARQ: 'MARQUETTE',
  MRSH: 'MARSHALL',
  MD: 'MARYLAND',
  UMASS: 'MASSACHUSETTS',
  MCNS: 'MCNEESE ST',
  MEM: 'MEMPHIS',
  MER: 'MERCER',
  MIA: 'MIAMI (FL)',
  'M-OH': 'MIAMI (OH)',
  MICH: 'MICHIGAN',
  MSU: 'MICHIGAN ST',
  MTSU: 'MIDDLE TENNESSEE',
  MILW: 'MILWAUKEE',
  MINN: 'MINNESOTA',
  MSST: 'MISSISSIPPI ST',
  MVSU: 'MISSISSIPPI VALLEY ST',
  MIZ: 'MISSOURI',
  MOST: 'MISSOURI ST',
  MONM: 'MONMOUTH',
  MONT: 'MONTANA',
  MTST: 'MONTANA ST',
  MORE: 'MOREHEAD ST',
  MORG: 'MORGAN ST',
  MSM: "MOUNT ST. MARY'S",
  MURR: 'MURRAY ST',
  NAVY: 'NAVY',
  NCCU: 'NC CENTRAL',
  NEB: 'NEBRASKA',
  OMA: 'NEBRASKA-OMAHA',
  NEV: 'NEVADA',
  UNH: 'NEW HAMPSHIRE',
  UNM: 'NEW MEXICO',
  NMSU: 'NEW MEXICO ST',
  UNO: 'NEW ORLEANS',
  NIAG: 'NIAGARA',
  NICH: 'NICHOLLS ST',
  NJIT: 'NJIT',
  NSU: 'NORFOLK ST',
  UNC: 'NORTH CAROLINA',
  NCAT: 'NORTH CAROLINA A&T',
  NCST: 'NC ST',
  UND: 'NORTH DAKOTA',
  NDSU: 'NORTH DAKOTA ST',
  UNF: 'NORTH FLORIDA',
  UNT: 'NORTH TEXAS',
  NE: 'NORTHEASTERN',
  NAU: 'NORTHERN ARIZONA',
  UNCO: 'NORTHERN COLORADO',
  NIU: 'NORTHERN ILLINOIS',
  UNI: 'NORTHERN IOWA',
  NKU: 'NORTHERN KENTUCKY',
  NW: 'NORTHWESTERN',
  NWST: 'NORTHWESTERN ST',
  ND: 'NOTRE DAME',
  OAK: 'OAKLAND',
  OHIO: 'OHIO',
  OSU: 'OHIO ST',
  OKLA: 'OKLAHOMA',
  OKST: 'OKLAHOMA ST',
  ODU: 'OLD DOMINION',
  MISS: 'OLE MISS',
  ORU: 'ORAL ROBERTS',
  ORE: 'OREGON',
  ORST: 'OREGON ST',
  PAC: 'PACIFIC',
  PSU: 'PENN ST',
  PENN: 'PENNSYLVANIA',
  PEPP: 'PEPPERDINE',
  PITT: 'PITTSBURGH',
  PORT: 'PORTLAND',
  PRST: 'PORTLAND ST',
  PV: 'PRAIRIE VIEW A&M',
  PRE: 'PRESBYTERIAN',
  PRIN: 'PRINCETON',
  PROV: 'PROVIDENCE',
  PUR: 'PURDUE',
  QU: 'QUINNIPIAC',
  RAD: 'RADFORD',
  URI: 'RHODE ISLAND',
  RICE: 'RICE',
  RICH: 'RICHMOND',
  RID: 'RIDER',
  RMU: 'ROBERT MORRIS',
  RUTG: 'RUTGERS',
  SEMO: 'SOUTHEAST MISSOURI ST',
  SAC: 'SACRAMENTO ST',
  SHU: 'SACRED HEART',
  JOES: "SAINT JOSEPH'S",
  SLU: 'SAINT LOUIS',
  SMC: "SAINT MARY'S",
  SPU: "SAINT PETER'S",
  SHSU: 'SAM HOUSTON ST',
  SAM: 'SAMFORD',
  USD: 'SAN DIEGO',
  SDSU: 'SAN DIEGO ST',
  SF: 'SAN FRANCISCO',
  SJSU: 'SAN JOSE ST',
  SCU: 'SANTA CLARA',
  SAV: 'SAVANNAH ST',
  SEA: 'SEATTLE',
  HALL: 'SETON HALL',
  SIE: 'SIENA',
  SIUE: 'SIU EDWARDSVILLE',
  USA: 'SOUTH ALABAMA',
  SC: 'SOUTH CAROLINA',
  SCST: 'SOUTH CAROLINA ST',
  SDAK: 'SOUTH DAKOTA',
  SDST: 'SOUTH DAKOTA ST',
  USF: 'SOUTH FLORIDA',
  SELA: 'SOUTHEAST LOUISIANA',
  SOU: 'SOUTHERN',
  USC: 'SOUTHERN CALIFORNIA',
  SIU: 'SOUTHERN ILLINOIS',
  SMU: 'SOUTHERN METHODIST',
  USM: 'SOUTHERN MISS',
  SUU: 'SOUTHERN UTAH',
  SBON: 'ST. BONAVENTURE',
  SFNY: 'ST. FRANCIS (BROOKLYN)',
  SFU: 'ST. FRANCIS (PA)',
  SJU: "ST. JOHN'S",
  STAN: 'STANFORD',
  SFA: 'STEPHEN F. AUSTIN',
  STET: 'STETSON',
  STON: 'STONY BROOK',
  SYR: 'SYRACUSE',
  TEM: 'TEMPLE',
  TENN: 'TENNESSEE',
  TNST: 'TENNESSEE ST',
  TNTC: 'TENNESSEE TECH',
  UTM: 'TENNESSEE-MARTIN',
  TEX: 'TEXAS',
  TAMU: 'TEXAS A&M',
  AMCC: 'TEXAS A&M-CORPUS CHRISTI',
  TCU: 'TEXAS CHRISTIAN',
  TXSO: 'TEXAS SOUTHERN',
  TXST: 'TEXAS ST',
  TTU: 'TEXAS TECH',
  CIT: 'THE CITADEL',
  TOL: 'TOLEDO',
  TOWS: 'TOWSON',
  TROY: 'TROY',
  TULN: 'TULANE',
  TLSA: 'TULSA',
  UAB: 'UAB',
  UCD: 'UC DAVIS',
  UCI: 'UC IRVINE',
  UCRV: 'UC RIVERSIDE',
  UCSB: 'UC SANTA BARBARA',
  UCLA: 'UCLA',
  UIC: 'UIC',
  UML: 'UMASS LOWELL',
  UMBC: 'UMBC',
  UMES: 'UMES',
  UMKC: 'UMKC',
  UNCA: 'UNC ASHEVILLE',
  UNCG: 'UNC GREENSBORO',
  UNCW: 'UNC-WILMINGTON',
  UNLV: 'UNLV',
  UPST: 'USC UPSTATE',
  UTA: 'UT ARLINGTON',
  TRGV: 'UT-RIO GRANDE VALLEY',
  UTAH: 'UTAH',
  USU: 'UTAH ST',
  UVU: 'UTAH VALLEY',
  UTEP: 'UTEP',
  UTSA: 'UTSA',
  VALP: 'VALPARAISO',
  VAN: 'VANDERBILT',
  UVM: 'VERMONT',
  NOVA: 'VILLANOVA',
  UVA: 'VIRGINIA',
  VCU: 'VIRGINIA COMMONWEALTH',
  VT: 'VIRGINIA TECH',
  VMI: 'VMI',
  WAG: 'WAGNER',
  WAKE: 'WAKE FOREST',
  WASH: 'WASHINGTON',
  WSU: 'WASHINGTON ST',
  WEB: 'WEBER ST',
  WVU: 'WEST VIRGINIA',
  WCU: 'WESTERN CAROLINA',
  WIU: 'WESTERN ILLINOIS',
  WKU: 'WESTERN KENTUCKY',
  WMU: 'WESTERN MICHIGAN',
  WICH: 'WICHITA ST',
  'W&M': 'WILLIAM & MARY',
  WIN: 'WINTHROP',
  WISC: 'WISCONSIN',
  WOF: 'WOFFORD',
  WRST: 'WRIGHT ST',
  WYO: 'WYOMING',
  XAV: 'XAVIER',
  YALE: 'YALE',
  YSU: 'YOUNGSTOWN ST',
};
