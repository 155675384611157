import CreateRosterLink from '../mutations/CreateRosterLink.graphql';
import GetRoster from '../queries/GetRoster.graphql';

export function makeRosterLink({ id, createdAt, rosterId, athleteId, active }) {
  const rosterLink = newOptimisticRosterLink();

  rosterLink.id = id ?? rosterLink.id;
  rosterLink.createdAt = createdAt ?? rosterLink.createdAt;
  rosterLink.rosterId = rosterId ?? rosterLink.rosterId;
  rosterLink.athleteId = athleteId ?? rosterLink.athleteId;
  rosterLink.active = active ?? rosterLink.active;

  return rosterLink;
}

const newOptimisticRosterLink = () => ({
  __typename: 'RosterLink',
  id: 'FAKE-ROSTERLINK-ID',
  createdAt: new Date(),
  _version: 0,
});

export function createRosterLink(apolloClient, input) {
  const { getRoster } = apolloClient.readQuery({
    query: GetRoster,
    variables: {
      id: input.rosterId,
    },
  });

  return apolloClient.mutate({
    mutation: CreateRosterLink,
    variables: {
      input,
    },
    optimisticResponse: {
      __typename: 'Mutation',
      createRosterLink: {
        ...newOptimisticRosterLink(),
        roster: getRoster,
        ...input,
      },
    },
    update: (apolloProxy, { data: { createRosterLink } }) => {
      apolloProxy.writeQuery({
        query: GetRoster,
        variables: {
          id: input.rosterId,
        },
        data: {
          getRoster: {
            ...getRoster,
            athletes: {
              ...getRoster.athletes,
              items: [...getRoster.athletes.items, createRosterLink],
            },
          },
        },
      });
    },
  });
}
