/**
 * log event to google analytics
 * for more info, checkout https://developers.google.com/gtagjs/reference/api#event
 * @param eventName: string. can be a recommended event (see link above)
 * or a custom event
 * @param details: key-value object (see link above for more info)
 */
export function logEventToGoogleAnalytics(eventName, details = {}) {
  // gtag is installed at the head of index.html
  const gtag = window.gtag;

  if (!gtag || !eventName || !Object.keys(details).length) {
    return;
  }

  gtag('event', eventName, details);
}
