import React, { useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useQuery, useApolloClient } from '@apollo/client';
import {
  RoutineStatus,
  GenderType,
  ScoreType,
  InquiryStatus,
} from '../../models';
import {
  wGymEvent,
  mGymEvent,
  wGymAbbv,
  mGymAbbv,
} from '../../utilities/export';
import { SessionSubscriptionManager } from './hooks';
import {
  convertPointsToMillipoints,
  convertMillipointsToPoints,
  newestScoreOfType,
} from '../../utilities/scoring';
import GetSession from '../../apollo/queries/GetFullSession.graphql';
import GetLineupWithRoutines from '../../apollo/queries/GetLineupWithRoutines.graphql';
import CreateRoutine from '../../apollo/mutations/CreateRoutine.graphql';
import UpdateRoutine from '../../apollo/mutations/UpdateRoutine.graphql';
import CreateScore from '../../apollo/mutations/CreateScore.graphql';
import CreateInquiry from '../../apollo/mutations/CreateInquiry.graphql';

function sortRoutines(routines) {
  const sorted = [];
  routines.forEach((routine) => {
    if (!sorted[routine.rotation]) {
      sorted[routine.rotation] = [];
    }
    sorted[routine.rotation][routine.order] = routine;
  });

  return sorted;
}

function createRoutine({
  apolloClient,
  athleteId,
  sessionId,
  lineupId,
  rotation,
  order,
  apparatus,
}) {
  return apolloClient
    .mutate({
      mutation: CreateRoutine,
      variables: {
        input: {
          sessionId,
          lineupId,
          rotation,
          order,
          athleteId,
          status: RoutineStatus.ON_EVAL,
          apparatus,
        },
      },
    })
    .then(({ data, error }) => {
      if (error) {
        throw error;
      }
      return data.createRoutine;
    });
}

function createScore({ apolloClient, userId, session, routine, type, value }) {
  return apolloClient
    .mutate({
      mutation: CreateScore,
      variables: {
        input: {
          routineId: routine.id,
          sessionId: session.id,
          value,
          type,
          userId,
        },
      },
    })
    .then(() => routine);
}

function createInquiry({ apolloClient, session, routine }) {
  return apolloClient
    .mutate({
      mutation: CreateInquiry,
      variables: {
        input: {
          routineId: routine.id,
          sessionId: session.id,
          status: InquiryStatus.CLOSED,
        },
      },
    })
    .then(() => routine);
}

function syncScores({ routine, evaluation, userId, apolloClient, session }) {
  function createScoreInternal(type, value) {
    return createScore({ apolloClient, userId, session, routine, type, value });
  }

  const subScores = evaluation?.evalPanel?.subScores;

  if (!subScores) {
    return routine;
  }

  return Promise.resolve()
    .then(() => {
      const scoreD = newestScoreOfType(routine.scores.items, ScoreType.D);
      if (!subScores.hasOwnProperty('D')) {
        return;
      }
      const competitorScore = convertPointsToMillipoints(
        newestScoreOfType(subScores?.D, ScoreType.D)?.score
      );

      if (isNaN(competitorScore)) {
        return;
      }

      if (scoreD?.value !== competitorScore) {
        return createScoreInternal(ScoreType.D, competitorScore);
      }
    })
    .then(() => {
      const scoreD1 = newestScoreOfType(routine.scores.items, ScoreType.D1);
      if (!subScores.hasOwnProperty('D1')) {
        return;
      }
      const competitorScore = convertPointsToMillipoints(
        newestScoreOfType(subScores?.D1, ScoreType.D1)?.score
      );

      if (isNaN(competitorScore)) {
        return;
      }

      if (scoreD1?.value !== competitorScore) {
        return createScoreInternal(ScoreType.D1, competitorScore);
      }
    })
    .then(() => {
      const scoreD2 = newestScoreOfType(routine.scores.items, ScoreType.D2);
      if (!subScores.hasOwnProperty('D2')) {
        return;
      }
      const competitorScore = convertPointsToMillipoints(
        newestScoreOfType(subScores?.D2, ScoreType.D2)?.score
      );

      if (isNaN(competitorScore)) {
        return;
      }

      if (scoreD2?.value !== competitorScore) {
        return createScoreInternal(ScoreType.D2, competitorScore);
      }
    })
    .then(() => {
      const scoreE1 = newestScoreOfType(routine.scores.items, ScoreType.E1);
      if (!subScores.hasOwnProperty('E1')) {
        return;
      }
      const competitorScore =
        subScores?.E1 &&
        convertPointsToMillipoints(
          newestScoreOfType(subScores?.E1, ScoreType.E1)?.score
        );

      if (isNaN(competitorScore)) {
        return;
      }

      if (scoreE1 !== competitorScore) {
        return createScoreInternal(ScoreType.E1, competitorScore);
      }
    })
    .then(() => {
      const scoreE2 = newestScoreOfType(routine.scores.items, ScoreType.E2);
      if (!subScores.hasOwnProperty('E2')) {
        return;
      }
      const competitorScore =
        subScores?.E2 &&
        convertPointsToMillipoints(
          newestScoreOfType(subScores?.E2, ScoreType.E2)?.score
        );

      if (isNaN(competitorScore)) {
        return;
      }

      if (scoreE2 !== competitorScore) {
        return createScoreInternal(ScoreType.E2, competitorScore);
      }
    })
    .then(() => {
      const scoreE3 = newestScoreOfType(routine.scores.items, ScoreType.E3);
      if (!subScores.hasOwnProperty('E3')) {
        return;
      }
      const competitorScore =
        subScores?.E3 &&
        convertPointsToMillipoints(
          newestScoreOfType(subScores?.E3, ScoreType.E3)?.score
        );

      if (isNaN(competitorScore)) {
        return;
      }

      if (scoreE3 !== competitorScore) {
        return createScoreInternal(ScoreType.E3, competitorScore);
      }
    })
    .then(() => {
      const scoreE4 = newestScoreOfType(routine.scores.items, ScoreType.E4);
      if (!subScores.hasOwnProperty('E4')) {
        return;
      }
      const competitorScore =
        subScores?.E4 &&
        convertPointsToMillipoints(
          newestScoreOfType(subScores?.E4, ScoreType.E4)?.score
        );

      if (isNaN(competitorScore)) {
        return;
      }

      if (scoreE4 !== competitorScore) {
        return createScoreInternal(ScoreType.E4, competitorScore);
      }
    })
    .then(() => {
      const scoreE5 = newestScoreOfType(routine.scores.items, ScoreType.E5);
      if (!subScores.hasOwnProperty('E5')) {
        return;
      }
      const competitorScore =
        subScores?.E5 &&
        convertPointsToMillipoints(
          newestScoreOfType(subScores?.E5, ScoreType.E5)?.score
        );

      if (isNaN(competitorScore)) {
        return;
      }

      if (scoreE5 !== competitorScore) {
        return createScoreInternal(ScoreType.E5, competitorScore);
      }
    })
    .then(() => {
      const scoreE6 = newestScoreOfType(routine.scores.items, ScoreType.E6);
      if (!subScores.hasOwnProperty('E6')) {
        return;
      }
      const competitorScore =
        subScores?.E6 &&
        convertPointsToMillipoints(
          newestScoreOfType(subScores?.E6, ScoreType.E6)?.score
        );

      if (isNaN(competitorScore)) {
        return;
      }

      if (scoreE6 !== competitorScore) {
        return createScoreInternal(ScoreType.E6, competitorScore);
      }
    })
    .then(() => {
      const scoreND =
        newestScoreOfType(routine.scores.items, ScoreType.ND)?.value || 0;
      if (!subScores.hasOwnProperty('ND')) {
        return;
      }
      const competitorScore = convertPointsToMillipoints(
        newestScoreOfType(subScores?.ND, ScoreType.ND)?.score
      );

      if (isNaN(competitorScore)) {
        return;
      }

      if (scoreND !== competitorScore) {
        return createScoreInternal(ScoreType.ND, competitorScore);
      }
    })
    .then(() => {
      const scoreSB =
        newestScoreOfType(routine.scores.items, ScoreType.SB)?.value || 0;
      if (!subScores.hasOwnProperty('SB')) {
        return;
      }
      const competitorScore = convertPointsToMillipoints(
        newestScoreOfType(subScores?.SB, ScoreType.SB)?.score
      );

      if (isNaN(competitorScore)) {
        return;
      }

      if (scoreSB !== competitorScore) {
        return createScoreInternal(ScoreType.SB, competitorScore);
      }
    })
    .then(() => routine);
}

function syncInquiries({ routine, evaluation, apolloClient, session }) {
  if (!evaluation) {
    return routine;
  }
  const { inquiry, resolved } = evaluation?.evalPanel?.flags;

  return Promise.resolve()
    .then(() => {
      if (inquiry && resolved && routine?.inquiries?.items.length === 0) {
        console.log('processing inquiry');
        return createInquiry({ apolloClient, session, routine });
      }
    })
    .then(() => routine);
}

export default function ScoreConverter({ sessionId }) {
  const { data } = useQuery(GetSession, { variables: { id: sessionId } });

  if (!data) {
    return <>Loading...</>;
  }

  const session = data.getSession;

  return (
    <>
      <h1>{session.name}</h1>
      <h2>Status</h2>
      <LineupStatus lineupId={session.lineups.items[0].id} session={session} />
      <LineupStatus lineupId={session.lineups.items[1].id} session={session} />
      <SessionSubscriptionManager sessionId={sessionId} />
    </>
  );
}

function LineupStatus({ lineupId, session }) {
  const [converting, setConverting] = useState(false);
  const apolloClient = useApolloClient();
  const userId = useSelector((state) => state.user.profile?.id);

  const { data } = useQuery(GetLineupWithRoutines, {
    variables: { id: lineupId },
  });

  function rotationNumberToEventName(rotation) {
    return session.gender === GenderType.FEMALE
      ? wGymEvent(rotation)
      : mGymEvent(rotation);
  }

  let lineup, oldData, sortedNewRoutines;

  const handleConvert = useCallback(() => {
    function rotationNumberToEventAbbv(rotation) {
      return session.gender === GenderType.FEMALE
        ? wGymAbbv(rotation)
        : mGymAbbv(rotation);
    }

    if (!converting) {
      setConverting(true);

      let chain = Promise.resolve();

      Object.keys(oldData).forEach((roundNumber) => {
        oldData[roundNumber].forEach((competitor, order) => {
          const newRoutine = sortedNewRoutines[roundNumber]?.[order];

          if (!competitor.evaluation && !newRoutine) {
            return;
          }

          chain = chain
            .then(() => {
              return (
                newRoutine ||
                createRoutine({
                  apolloClient,
                  athleteId: competitor.athleteId,
                  sessionId: session.id,
                  lineupId,
                  rotation: roundNumber,
                  order,
                  apparatus: rotationNumberToEventAbbv(roundNumber),
                })
              );
            })
            .then((routine) =>
              syncScores({
                routine,
                evaluation: competitor.evaluation,
                userId,
                apolloClient,
                session,
              })
            )
            .then((routine) =>
              syncInquiries({
                routine,
                evaluation: competitor.evaluation,
                apolloClient,
                session,
              })
            )
            .then((routine) => {
              const competitorScore = competitor.evaluation
                ? convertPointsToMillipoints(competitor.evaluation.score)
                : routine.score;

              if (
                routine.score !== competitorScore ||
                routine.apparatus === null
              ) {
                if (routine.apparatus === null) {
                  console.log('updating apparatus');
                }
                return apolloClient.mutate({
                  mutation: UpdateRoutine,
                  variables: {
                    input: {
                      id: routine.id,
                      lineupId,
                      athleteId: competitor.athleteId,
                      sessionId: session.id,
                      order: routine.order,
                      status: RoutineStatus.COMPLETE,
                      score: competitorScore,
                      _version: routine._version,
                      apparatus:
                        routine?.apparatus ??
                        rotationNumberToEventAbbv(roundNumber),
                    },
                  },
                });
              }
            });
        });
      });

      return chain
        .catch((error) => {
          console.error(error);
        })
        .then(() => {
          setConverting(false);
        });
    }
  }, [
    apolloClient,
    lineupId,
    session,
    userId,
    converting,
    oldData,
    sortedNewRoutines,
  ]);

  if (!data) {
    return null;
  }

  lineup = data.getLineup;
  oldData = JSON.parse(lineup.lineupData);
  sortedNewRoutines = sortRoutines(lineup.routines.items);

  //console.log(lineup)

  return (
    <>
      <h3>
        {lineup.team.name}
        <button disabled={converting} onClick={handleConvert}>
          Convert
        </button>
      </h3>
      <ul>
        {oldData &&
          Object.keys(oldData).map((roundNumber) => {
            const sublineup = oldData[roundNumber];
            return (
              <li key={`${lineupId}-round-${roundNumber}`}>
                {rotationNumberToEventName(roundNumber)}
                <ul>
                  {sublineup.map((competitor, order) => {
                    const newRoutine = sortedNewRoutines[roundNumber]?.[order];

                    return competitor.evaluation || newRoutine ? (
                      <li
                        key={`${lineupId}-round-${roundNumber}-competitor-${order}`}
                      >
                        {order + 1}) {competitor.name || '<No name>'}:&nbsp;
                        {competitor?.evaluation?.score ||
                          convertMillipointsToPoints(newRoutine?.score).toFixed(
                            3
                          )}
                        {!newRoutine
                          ? '🔴'
                          : newRoutine.status === RoutineStatus.COMPLETE &&
                            newRoutine.score ===
                              convertPointsToMillipoints(
                                competitor?.evaluation?.score
                              )
                          ? '🟢'
                          : '🟡'}
                      </li>
                    ) : null;
                  })}
                </ul>
              </li>
            );
          })}
      </ul>
    </>
  );
}
